import styled, { css } from "styled-components";

export const MonthAndDayNr = styled("span")`
    font-weight: 500;
    font-size: 18px;
`;

export const Weekday = styled("span")`
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
`;

export const DateAndTimeWrapper = styled("div")<{ $warning?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    cursor: pointer;
    flex-direction: column;

    ${(p) =>
        p.$warning &&
        css`
            color: ${(p) => p.theme.palette.warning.main};
        `}
`;

export const DateWrapper = styled("div")`
    display: flex;
    flex-direction: column;
`;

export const SeparatorVertical = styled("div")`
    background: #9c9c9c;
    width: 1px;
    height: 40px;
    margin-right: 16px;
`;

export const Time = styled("span")`
    font-weight: 500;
    font-size: 48px;
    margin-right: 16px;
`;

export const PaymentTextPrimary = styled("span")`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 4px;
    color: #9c9c9c;
    text-align: center;
`;

export const PaymentTextSecondary = styled("span")`
    font-weight: 500;
    font-size: 18px;
    color: ${(p) => p.theme.palette.success.main};
`;

export const PaymentInfoWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SeparatorHorizontal = styled("div")`
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    margin-bottom: 24px;
`;

export const OrderDetailsWrapper = styled("div")`
    padding: 24px;
`;

export const PinkTopBar = styled("div")`
    background-color: ${(p) => p.theme.palette.primary.main};
    height: 8px;
    width: 100%;
`;

export const PanelContainer = styled("div")`
    height: 100%;
    max-width: 367px;
    width: 100%;
    margin-left: auto;

    ${(p) => p.theme.breakpoints.down("md")} {
        max-width: unset;
        order: -1;
        margin-bottom: 32px;
    }
`;

export const Panel = styled("div")`
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    background: white;
`;

export const WarrantyBox = styled("div")`
    cursor: pointer;
    border-radius: 15px;
    background: #dff1ea;
    display: flex;
    align-items: center;
    padding: 16px;
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
`;

export const WarrantyImg = styled("img")`
    margin-right: 16px;
`;

export const WarrantyUnderline = styled("span")`
    font-weight: 500;
    cursor: pointer;
`;
