import ContentContainer from "@FEClient/views/commonComps/ContentContainer/ContentContainer";
import Box from "@FEShared/components/UI/Box/Box";
import Button from "@FEShared/components/UI/Button/Button";
import Text from "@FEShared/components/UI/Text/Text";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import niceIdGen from "@Shared/util/niceIdGen";
import { dateFormatFull } from "@Shared/util/dateFormat";
import lRandom from "lodash/random";
import * as S from "./AdminPromo.styled";
import { TransMsg } from "@FEShared/i18n";

const AdminPromo: React.FC = observer(() => {
    const LS = useLocalObservable(() => ({
        code: undefined as undefined | string,
        expiresOn: undefined as undefined | Date,
    }));

    return (
        <ContentContainer padding={3}>
            <S.GlobalStyles />
            <Text variant="h1">
                <TransMsg
                    default={"Admin nuolaidos kodo generatorius"}
                    id="I4H0mDSy"
                />
            </Text>
            <Box>
                <Button
                    sx={{
                        mt: 2,
                        mb: 2,
                    }}
                    onClick={() => {
                        runInAction(() => {
                            LS.code = [
                                niceIdGen(),
                                "LT",
                                lRandom(1000, 9999),
                            ].join("");
                            LS.expiresOn = new Date(
                                Date.now() + 7 * 24 * 60 * 60 * 1000
                            );
                        });
                    }}
                >
                    <TransMsg default={"Generuoti"} id="dilPiUNB" />
                </Button>
                <Text>
                    <TransMsg
                        default={"Nuolaidos kodas: {code}"}
                        data={{ code: LS.code }}
                        id="EEYVMG2c"
                    />
                </Text>
                <Text>
                    <TransMsg
                        default={"Galioja iki: {expiresOn}"}
                        data={{
                            expiresOn:
                                LS.expiresOn && dateFormatFull(LS.expiresOn),
                        }}
                        id="fKK6aXkU"
                    />
                </Text>
                <Text semiBold>
                    <TransMsg
                        default={
                            "Šie kodai skirti tik partnerių klientams. Nesidalinkite šiais kodais su kitais asmenimis."
                        }
                        id="vvbf2PxG"
                    />
                </Text>
            </Box>
        </ContentContainer>
    );
});

export default AdminPromo;
