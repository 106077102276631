import styled, { css } from "styled-components";
import { default as MUIButton } from "@mui/material/Button";
import { CircularProgress as CircularProgressMUI } from "@mui/material";

export const Button = styled(MUIButton)<{ $circle?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;

    &.Mui-disabled {
        pointer-events: initial;
        cursor: not-allowed;
        opacity: 0.6;
        background-color: ${(p) =>
            p.color
                ? p.theme.palette[p.color].main
                : p.theme.palette.primary.main};

        color: ${(p) =>
            p.color
                ? p.theme.palette[p.color].contrastText
                : p.theme.palette.primary.contrastText};
    }

    ${(p) =>
        p.$circle &&
        css`
            border-radius: ${(p) => p.theme.shape.borderRadius}px;
            padding: 0;
            min-width: 0;
            width: ${(p) => p.theme.sizes.inputHeight}px;
            height: ${(p) => p.theme.sizes.inputHeight}px;
        `}
`;

export const CircularProgress = styled(CircularProgressMUI)`
    margin-left: 8px;
    color: white;
`;

export const LeftIcon = styled("i")`
    font-size: 16px;
    :not(:only-child) {
        margin-right: 8px;
    }
`;

export const RightIcon = styled("i")`
    font-size: 16px;
    :not(:only-child) {
        margin-left: 8px;
    }
`;
