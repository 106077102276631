import useIsMobile from "@FEShared/hooks/useIsMobile";
import { observer } from "mobx-react-lite";
import React from "react";
import { WorkshopDTO } from "../../Workshop.types";
import * as S from "./WorkshopServiceRow.styled";
import Text from "@FEShared/components/UI/Text/Text";
import Box from "@FEShared/components/UI/Box/Box";
import { MergedServiceOrPackageForWorkshop } from "@Shared/util/mergeServicesWithPackagesForWorkshop";
import useStore from "@FEClient/logic/store";
import { Skeleton } from "@mui/material";
import { TranslatableView, transStr } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";

export interface WorkshopServiceRowP {
    service: MergedServiceOrPackageForWorkshop;
    onBtnClick: (serviceOrPackage: MergedServiceOrPackageForWorkshop) => void;
    hidden?: boolean;
    selectedWorkshop: WorkshopDTO;
    isSelected: (s: MergedServiceOrPackageForWorkshop) => boolean;
    isLast?: boolean;
}

const WorkshopServiceRow: React.FC<WorkshopServiceRowP> = observer((p) => {
    let btnContent: JSX.Element | string;
    const isMobile = useIsMobile();
    const isSelected = p.isSelected(p.service);
    const GS = useStore();

    if (isSelected) {
        btnContent = isMobile ? (
            <S.MobileBtnIcon className="icon-checkmark" />
        ) : (
            transStr("Pridėta", { id: "NHtwubal" })
        );
    } else {
        btnContent = isMobile ? (
            <S.MobileBtnIcon className="icon-plus" />
        ) : (
            transStr("Pridėti", { id: "5Bb7w1l6" })
        );
    }

    const isLoading = GS.workshopPageState.isLoading;
    return p.service.package ? (
        <S.WorkshopServiceRow
            $bulky
            $hidden={p.hidden}
            $last={p.isLast}
            onClick={() => p.onBtnClick(p.service)}
        >
            {p.service.imgUrl && <S.ServiceImg src={p.service.imgUrl} />}
            <Box displayFlex vertical flex={1}>
                <S.WorkshopServiceTitle>
                    <TranslatableView translatable={p.service.serviceName} />
                </S.WorkshopServiceTitle>
                <Box displayFlex>
                    <S.WorkshopServiceTitlesWrap>
                        <S.WorkshopServiceTitle>
                            {p.service.important && (
                                <Text
                                    mr={0.5}
                                    component="i"
                                    className="icon-star-full"
                                    color="#FFB600"
                                />
                            )}
                            {p.service.specialized && (
                                <S.Specialized className="icon-wrench-full" />
                            )}
                        </S.WorkshopServiceTitle>
                        {p.service.additionalTexts &&
                            p.service.additionalTexts.map((t) => (
                                <S.AdditionalText key={t.id}>
                                    • <TranslatableView translatable={t} />
                                </S.AdditionalText>
                            ))}
                        {p.service.recommendationText && (
                            <S.RecommendationText>
                                <TranslatableView
                                    translatable={p.service.recommendationText}
                                />
                            </S.RecommendationText>
                        )}
                    </S.WorkshopServiceTitlesWrap>
                    <S.WorkshopServiceRowItemWrap ml="auto">
                        <Box mb={0.5}>
                            {isLoading ? (
                                <>
                                    <Skeleton
                                        width="60px"
                                        sx={{
                                            height: "20px",
                                            borderRadius: "15px",
                                        }}
                                        variant="rectangular"
                                    />
                                    <Skeleton
                                        width="30px"
                                        sx={{
                                            ml: 1,
                                            height: "20px",
                                            borderRadius: "15px",
                                        }}
                                        variant="rectangular"
                                    />
                                </>
                            ) : (
                                <>
                                    <Box displayFlex alignVertical="center">
                                        <S.Price>
                                            {
                                                p.service.priceNDuration
                                                    .servicesPrice.text
                                            }
                                        </S.Price>
                                        <S.Duration>
                                            {
                                                p.service.priceNDuration
                                                    .duration.text
                                            }
                                        </S.Duration>
                                    </Box>
                                    {p.service.priceNDuration.partsPrice
                                        .text && (
                                        <Text
                                            mt={0.5}
                                            fontSize={12}
                                            color="#7B7F93"
                                            center
                                        >
                                            +{transCommonTranslatable("PARTS")}:{" "}
                                            {
                                                p.service.priceNDuration
                                                    .partsPrice.text
                                            }
                                        </Text>
                                    )}
                                </>
                            )}
                        </Box>
                        <S.SelectButton $selected={isSelected}>
                            {btnContent}
                        </S.SelectButton>
                    </S.WorkshopServiceRowItemWrap>
                </Box>
            </Box>
        </S.WorkshopServiceRow>
    ) : (
        <S.WorkshopServiceRow
            $hidden={p.hidden}
            $last={p.isLast}
            onClick={() => p.onBtnClick(p.service)}
            className={
                p.hidden ? undefined : S.VISIBLE_WORKSHOP_SERVICE_ROW_CLASSNAME
            }
        >
            {p.service.imgUrl && <S.ServiceImg src={p.service.imgUrl} />}
            <S.WorkshopServiceTitlesWrap>
                <S.WorkshopServiceTitle>
                    {p.service.important && (
                        <Text
                            mr={0.5}
                            component="i"
                            className="icon-star-full"
                            color="#FFB600"
                        />
                    )}
                    <TranslatableView translatable={p.service.serviceName} />
                    {p.service.specialized && (
                        <S.Specialized className="icon-wrench-full" />
                    )}
                </S.WorkshopServiceTitle>
                {p.service.priceNDuration.priceObj.type !== "HOURLY" && (
                    <S.WorkshopServiceSubtitle>
                        {isLoading ? (
                            <Skeleton
                                width="60px"
                                sx={{ height: "20px", borderRadius: "15px" }}
                                variant="rectangular"
                            />
                        ) : (
                            p.service.priceNDuration.duration.text
                        )}
                    </S.WorkshopServiceSubtitle>
                )}
            </S.WorkshopServiceTitlesWrap>
            <S.WorkshopServiceRowItemWrap>
                <Box mb={0.5}>
                    {isLoading ? (
                        <Skeleton
                            width="60px"
                            sx={{ height: "22.5px", borderRadius: "15px" }}
                            variant="rectangular"
                        />
                    ) : (
                        <>
                            <S.Price>
                                {p.service.priceNDuration.servicesPrice.text}
                            </S.Price>
                            {p.service.priceNDuration.partsPrice.text && (
                                <Text fontSize={12} color="#7B7F93" center>
                                    +{transCommonTranslatable("PARTS")}:{" "}
                                    {p.service.priceNDuration.partsPrice.text}
                                </Text>
                            )}
                        </>
                    )}
                </Box>
                <S.SelectButton $selected={isSelected}>
                    {btnContent}
                </S.SelectButton>
            </S.WorkshopServiceRowItemWrap>
        </S.WorkshopServiceRow>
    );
});

export default WorkshopServiceRow;
