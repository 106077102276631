import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import InternalLink from "@FEShared/components/InternalLink/InternalLink";
import styled from "styled-components";

export const Title = styled("h2")`
    text-align: center;
    margin-bottom: 48px;
`;

export const FeaturesWrapper = styled("div")`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const FeatureWrapper = styled(InternalLink)`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background: #f0f2f8;
    border-radius: 25px;
    margin-right: 16px;
    width: 33.33%;
    margin-bottom: 16px;
    background-color: #e5e7f0;
    color: inherit;

    &:nth-child(3n) {
        margin-right: 0;
    }
`;

export const FeatureImg = styled("img")`
    margin-right: 24px;
    align-self: flex-start;
    max-height: 72px;
`;

export const FeatureTitle = styled("div")`
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 500;
`;

export const NewBenefitsSection = styled(ContentContainer)`
    ${(p) => p.theme.breakpoints.down("md")} {
        ${FeaturesWrapper} {
            flex-direction: column;
        }
        ${FeatureWrapper} {
            margin-bottom: 16px;
            width: 100%;
            justify-content: flex-start;
        }
    }
`;
