import { transStringFunctionalFE } from "@FEShared/i18n";
import {
    PART_CATEGORIES_TRANSLATABLES,
    SERVICES_TRANSLATABLES,
    SUB_CATEGORIES_TRANSLATABLES,
} from "@Shared/consts/SHARED_TRANSLATABLES";
import { Translatable } from "@Shared/i18n/i18n.types";
import {
    TranslatableCustomID_PartCategories,
    TranslatableCustomID_PopularServices,
    TranslatableCustomID_SubCategories,
} from "@Shared/types/enums/TranslatableCustomID";
import isEnumValue from "@Shared/util/isEnumValue";

export default function translatableCustomIDToTransString(
    translatableCustomID:
        | TranslatableCustomID_PartCategories
        | TranslatableCustomID_PopularServices
        | TranslatableCustomID_SubCategories
): string {
    let translatable: Translatable | undefined;
    if (
        isEnumValue(TranslatableCustomID_PartCategories, translatableCustomID)
    ) {
        translatable = PART_CATEGORIES_TRANSLATABLES.find(
            (t) => t.customID === translatableCustomID
        );
    } else if (
        isEnumValue(TranslatableCustomID_PopularServices, translatableCustomID)
    ) {
        translatable = SERVICES_TRANSLATABLES.find(
            (t) => t.serviceName.customID === translatableCustomID
        )?.serviceName;
    } else if (
        isEnumValue(TranslatableCustomID_SubCategories, translatableCustomID)
    ) {
        translatable = SUB_CATEGORIES_TRANSLATABLES.find(
            (t) => t.customID === translatableCustomID
        );
    }

    if (!translatable) {
        throw new Error(
            `Translatable not found for customID: ${translatableCustomID}`
        );
    }

    return transStringFunctionalFE(translatable);
}
