import styled, { css } from "styled-components";

export const StarIcon = styled("i")<{
    $empty?: boolean;
}>`
    margin: 0 2px;
    margin-top: -4px;
    font-size: 16px;
    color: #ffb600;

    ${(p) =>
        p.className?.includes("workshop-page-big") && // this should be reworked into styled component.
        css`
            color: #ffb600;
            line-height: 30px;
            font-size: 20px;
        `}

    ${(p) =>
        p.className?.includes("workshop-page-small") &&
        css`
            color: #ffb600;
            line-height: 30px;
            font-size: 18px;
        `}
        

    ${(p) =>
        p.$empty &&
        css`
            color: #d9d9d9;
        `}

    ${(p) => p.theme.breakpoints.down("xl")} {
        margin: 0 1px;
        margin-top: -2px;
        font-size: 14px;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        margin: 0 0.5px;
        margin-top: -1px;
        font-size: 12px;
    }
`;
