import React from "react";
import * as S from "./PartnerRecomendationCards.styled";
import ProviderCard from "./ProviderCard/ProviderCard";
import { reviews } from "./PartnerRecomendationCards.consts";

const PartnerRecomendationCards = () => (
    <S.PartnerImgGroup>
        {reviews.map((review, index) => (
            <ProviderCard key={index} {...review} />
        ))}
    </S.PartnerImgGroup>
);

export default PartnerRecomendationCards;
