import lRemove from "lodash/remove";

/** If exists, removes the value, if doesnt - adds */
export default function remOrAddToArr<T>(arr: T[], val: T): T[] {
    if (arr.includes(val)) {
        lRemove(arr, (v) => v === val);
    } else {
        arr.push(val);
    }
    return arr;
}
