import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import React from "react";
import * as S from "./Warranty.styled";
import { TransMsg } from "@FEShared/i18n";
import WarrantyContent from "./WarrantyContent/WarrantyContent";

const Warranty: React.FC = () => {
    return (
        <ContentContainer pt="32px">
            <S.WarrantyTitle style={{ marginBottom: 8 }}>
                <TransMsg default={"Techninė garantija"} id="DXUrUcMD" />
            </S.WarrantyTitle>
            <WarrantyContent />
        </ContentContainer>
    );
};

export default Warranty;
