import React from "react";
import { Helmet } from "react-helmet";

interface P {
    title: string | (() => string);
    descr?: string;
}
const MetaWrapper: React.FCC<P> = (p) => {
    return (
        <>
            <Helmet>
                <title>
                    {typeof p.title === "function" ? p.title() : p.title}
                </title>
                {p.descr && <meta name="description" content={p.descr} />}
            </Helmet>
            {p.children}
        </>
    );
};

export default MetaWrapper;
