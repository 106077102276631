import {
    TranslatableCustomID_PartCategories,
    TranslatableCustomID_SubCategories,
} from "@Shared/types/enums/TranslatableCustomID";

const SUBCATEGORIES_ORDER = {
    [TranslatableCustomID_PartCategories.MAINTENANCE]: {
        [TranslatableCustomID_SubCategories.OIL_CHANGE_AND_FILTERS]: 100013,
        [TranslatableCustomID_SubCategories.TIMING_BELT_AND_CHAIN]: 10009,
    },
    [TranslatableCustomID_PartCategories.ELECTRICITY]: {
        [TranslatableCustomID_SubCategories.DIAGNOSTICS]: 100055,
        [TranslatableCustomID_SubCategories.ELECTRICAL_REPAIR_AUTO_ELECTRICIAN]: 100044,
        [TranslatableCustomID_SubCategories.PARK_ASSIST]: 100034,
        [TranslatableCustomID_SubCategories.CAR_ALARM_KEYS_LOCKS]: 100031,
        [TranslatableCustomID_SubCategories.AUDIO_VIDEO_SYSTEMS]: 100022,
        [TranslatableCustomID_SubCategories.GPS_SYSTEM]: 100016,
        [TranslatableCustomID_SubCategories.COMFORT_SYSTEMS]: 100012,
        [TranslatableCustomID_SubCategories.CAMERAS]: 10008,
        [TranslatableCustomID_SubCategories.OTHER]: 10004,
    },
    [TranslatableCustomID_PartCategories.EXHAUST_SYSTEM]: {
        [TranslatableCustomID_SubCategories.EXHAUST_REPAIR]: 100015,
        [TranslatableCustomID_SubCategories.EXHAUST_DIAGNOSTICS]: 100010,
        [TranslatableCustomID_SubCategories.EMISSIONS_TESTS]: 10003,
    },
    [TranslatableCustomID_PartCategories.CONDITIONER]: {
        [TranslatableCustomID_SubCategories.AC_RECHARGE]: 100012,
        [TranslatableCustomID_SubCategories.HVAC_REPAIR]: 10008,
    },
    [TranslatableCustomID_PartCategories.BODY]: {
        [TranslatableCustomID_SubCategories.COATING_POLISHING_BLASTING]: 100033,
        [TranslatableCustomID_SubCategories.PAINTING]: 100027,
        [TranslatableCustomID_SubCategories.BODY_REPAIR]: 100017,
        [TranslatableCustomID_SubCategories.CHASSIS_STRAIGHTENING]: 100011,
        [TranslatableCustomID_SubCategories.OTHER]: 10006,
    },
    [TranslatableCustomID_PartCategories.GEARBOX]: {
        [TranslatableCustomID_SubCategories.CLUTCH]: 100021,
        [TranslatableCustomID_SubCategories.TRANSMISSION_FLUID_CHANGE]: 100018,
        [TranslatableCustomID_SubCategories.TRANSMISSION_REPAIR]: 100014,
        [TranslatableCustomID_SubCategories.GEARBOX_REPAIR]: 10007,
    },
    [TranslatableCustomID_PartCategories.WHEELS]: {
        [TranslatableCustomID_SubCategories.TIRE_CHANGE]: 100023,
        [TranslatableCustomID_SubCategories.RIMS]: 10006,
    },
    [TranslatableCustomID_PartCategories.INTERIOR]: {
        [TranslatableCustomID_SubCategories.AIRBAG_SRS]: 100022,
        [TranslatableCustomID_SubCategories.CAR_SEATS]: 100019,
        [TranslatableCustomID_SubCategories.UPHOLSTERY]: 100011,
    },
    [TranslatableCustomID_PartCategories.BRAKES]: {
        [TranslatableCustomID_SubCategories.BRAKE_REPAIR]: 100016,
    },
    [TranslatableCustomID_PartCategories.GLASS]: {
        [TranslatableCustomID_SubCategories.REPAIR]: 100014,
        [TranslatableCustomID_SubCategories.REPLACEMENT]: 100011,
        [TranslatableCustomID_SubCategories.TINTING]: 10007,
    },
    [TranslatableCustomID_PartCategories.STEERING]: {},
    [TranslatableCustomID_PartCategories.SUSPENSION]: {
        [TranslatableCustomID_SubCategories.SUSPENSION_REPAIR]: 100018,
        [TranslatableCustomID_SubCategories.SHOCKS]: 10009,
    },
    [TranslatableCustomID_PartCategories.ENGINE]: {
        [TranslatableCustomID_SubCategories.ENGINE_REPAIR]: 100049,
        [TranslatableCustomID_SubCategories.TIMING_BELT_AND_CHAIN]: 100043,
        [TranslatableCustomID_SubCategories.FUEL_SYSTEM]: 100039,
        [TranslatableCustomID_SubCategories.FUEL_INJECTORS]: 100035,
        [TranslatableCustomID_SubCategories.TURBO]: 100027,
        [TranslatableCustomID_SubCategories.COOLING_SYSTEM]: 100023,
        [TranslatableCustomID_SubCategories.SPARK_PLUGS]: 100015,
        [TranslatableCustomID_SubCategories.LPG]: 100012,
        [TranslatableCustomID_SubCategories.SUPERCHARGER]: 10008,
        [TranslatableCustomID_SubCategories.FUEL_TANK]: 10003,
    },
    [TranslatableCustomID_PartCategories.DETAILING]: {
        [TranslatableCustomID_SubCategories.INTERIOR_DETAILING]: 100016,
        [TranslatableCustomID_SubCategories.EXTERIOR_DETAILING]: 10007,
    },
    [TranslatableCustomID_PartCategories.HEADLIGHTS]: undefined,
    [TranslatableCustomID_PartCategories.POPULAR]: undefined,
    [TranslatableCustomID_PartCategories.INSPECTION]: undefined,
    [TranslatableCustomID_PartCategories.OTHER]: undefined,
} as const;

export default SUBCATEGORIES_ORDER;
