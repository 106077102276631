import Country from "@Shared/types/enums/Country";
import React from "react";
import WarrantyContent_EN from "./WarrantyContent_EN";
import WarrantyContent_LT from "./WarrantyContent_LT";

const WarrantyContent: React.FC = () => {
    return window._COUNTRY === Country.LT ? (
        <WarrantyContent_LT />
    ) : (
        <WarrantyContent_EN />
    );
};

export default WarrantyContent;
