import { SeoPageData } from "@FEShared/types/common";
import { DISTRICTS_URL_PREFIX } from "@Shared/consts/FECLIENT_DISTRICT_SEO_PAGES";
import FECLIENT_DISTRICT_SEO_PAGES from "@Shared/consts/FECLIENT_DISTRICT_SEO_PAGES";
import capFirst from "@Shared/util/capFirst";
import FECLIENT_BRANDS_SEO_PAGES, {
    BRANDS_URL_PREFIX,
} from "@Shared/consts/FECLIENT_BRANDS_SEO_PAGES";
import FECLIENT_ALL_SERVICES_SEO_PAGES, {
    MOST_POPULAR_SERVICES_LANDINGS,
} from "@Shared/consts/FECLIENT_ALL_SERVICES_SEO_PAGES";
import { i18nService, transStringFunctionalFE } from "@FEShared/i18n";
import Country from "@Shared/types/enums/Country";

type SeoType = "DISTRICT" | "BRAND" | "SERVICE";
type SeoData = SeoPageData & { type: SeoType };

export function getSeoPageData(): SeoData | undefined {
    const urlSlug = window.location.pathname;

    if (
        window.location.pathname.includes(DISTRICTS_URL_PREFIX(window._COUNTRY))
    ) {
        const seoPageData = FECLIENT_DISTRICT_SEO_PAGES(
            transStringFunctionalFE,
            window._COUNTRY
        )[urlSlug] as undefined | SeoPageData;

        return seoPageData ? { ...seoPageData, type: "DISTRICT" } : undefined;
    } else if (
        window.location.pathname.includes(BRANDS_URL_PREFIX(window._COUNTRY))
    ) {
        const seoPageData = FECLIENT_BRANDS_SEO_PAGES(
            transStringFunctionalFE,
            window._COUNTRY
        )[urlSlug] as undefined | SeoPageData;

        return seoPageData
            ? {
                  ...seoPageData,
                  type: "BRAND", // ah would have been neater if this was added in the actual const files, like FECLIENT_BRANDS_SEO_PAGES
              }
            : undefined;
    } else {
        const currentLangCode = i18nService.currentLocale.languageCode;
        console.log("🚀 ~ getSeoPageData ~ currentLocale:", currentLangCode);

        // force english content if user has changed the language in the website for SEO pages
        const country =
            currentLangCode !== window._COUNTRY_META.langCode
                ? Country.CA
                : window._COUNTRY;

        const matchingEntry = [
            ...Object.entries(MOST_POPULAR_SERVICES_LANDINGS(window._COUNTRY)),
            ...Object.entries(
                FECLIENT_ALL_SERVICES_SEO_PAGES(
                    transStringFunctionalFE,
                    window._COUNTRY
                )
            ),
        ].find(([seoPageDataUrlSlug, _seoPageData]) => {
            return seoPageDataUrlSlug === urlSlug;
        });

        if (!matchingEntry) return undefined;

        const [_matchingUrlSlug, domainSeoPageData] = matchingEntry;

        const serviceSeoEntry = [
            ...Object.entries(MOST_POPULAR_SERVICES_LANDINGS(window._COUNTRY)),
            ...Object.entries(
                FECLIENT_ALL_SERVICES_SEO_PAGES(
                    transStringFunctionalFE,
                    window._COUNTRY
                )
            ),
        ].find(([_seoPageDataUrlSlug, seoPageData]) => {
            return (
                seoPageData.serviceDefinitionID ===
                domainSeoPageData.serviceDefinitionID
            );
        });

        if (!serviceSeoEntry) return undefined;

        const [__matchingUrlSlug, seoPageData] = serviceSeoEntry;

        console.log("foundSeoPageData", seoPageData);

        console.log("country chosen", country);

        console.log("seoPageData", seoPageData);

        if (seoPageData) {
            seoPageData.faq.map((faq) => {
                faq.question = capFirst(
                    faq.question
                        .replace(/ {carModel}/g, "")
                        .replace(/{carModel}/g, "")
                        .replace(/{serviceName}/g, seoPageData.seoServiceName)
                );

                faq.answer = capFirst(
                    faq.answer
                        .replace(/ {carModel}/g, "")
                        .replace(/{carModel}/g, "")
                        .replace(/{serviceName}/g, seoPageData.seoServiceName)
                );

                return faq;
            });
        }

        return seoPageData
            ? {
                  ...seoPageData,
                  type: "SERVICE",
              }
            : undefined;
    }
}
