import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import React from "react";
import * as S from "./FAQSection.styled";
import useStore from "@FEClient/logic/store";
import { WorkshopDTO } from "../Workshop.types";
import { TransCarDataObj } from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.types";
import formatVehicleDescriptionFE from "@FEShared/utils/formatVehicleDescriptionFE";
import { OTHER_BRAND } from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.consts";
import { TransMsg, transStr } from "@FEShared/i18n";
import { translatable } from "@Shared/i18n/i18n";

const my = translatable("mano", { id: "XMvN293z" }); // doesnt work otherwise

export function formQuestions(p: {
    fixedCarBrands: string[];
    carDataObj?: Partial<TransCarDataObj>;
}) {
    const QUESTIONS = [
        {
            question: transStr(
                "Kada galite mane priimti automobilio remontui/aptarnavimams?",
                { id: "Pxow8LIA" }
            ),
            answer: transStr(
                'Artimiausią laisvą laiką vizitui galite pasirinkti sekcijoje "Laisvi laikai registracijai". Anksčiausias matomas laikas yra artimiausias laikas, kada autoservisas gali tave priimti.',
                { id: "J1Q8D46n" }
            ),
        },
        {
            question: transStr("Kiek kainuos automobilio remontas/darbai?", {
                id: "2QvN2G1w",
            }),
            answer: transStr(
                "Visos prie paslaugų matomos kainos yra nurodytos pačio autoserviso (neįskaičiuojant dalių ar medžiagų). Tikslesnę kainą įmanoma nustatyti tik meistrui apžiūrėjus automobilį ir įvertinus jo stovį, bei remonto sudėtingumą.",
                { id: "Ig7lk5M5" }
            ),
        },
        {
            question: transStr(
                "Ar remontuojate {vehicleBrandOrMy} automobilį?",
                {
                    data: {
                        vehicleBrandOrMy: p.carDataObj?.vehicleBrand
                            ? formatVehicleDescriptionFE(p.carDataObj)
                            : my,
                    },
                    id: "OQZdFLMN",
                }
            ),
            answer: [
                p.carDataObj?.vehicleBrand
                    ? p.fixedCarBrands.includes(p.carDataObj?.vehicleBrand)
                        ? transStr("Taip, remontuojame šį automobilį.", {
                              id: "Ljfa0M5U",
                          })
                        : transStr("Deja, šio automobilio neremontuojame.", {
                              id: "jLPISHtW",
                          })
                    : undefined,
                transStr(
                    "Autoservisas remontuoja šias automobilių markes: {fixedCarBrands}",
                    {
                        data: {
                            fixedCarBrands: p.fixedCarBrands
                                .filter((b) => b !== OTHER_BRAND)
                                .join(", "),
                        },
                        id: "mmnrVtbO",
                    }
                ),
            ]
                .filter(Boolean)
                .join(" "),
        },
        {
            question: transStr("Kiek laiko užtruks darbai?", {
                id: "SDr5C2qC",
            }),
            answer: transStr(
                "Preliminari remontų trukmė nurodyta prie kiekvienos paslaugos. Tiksliau remonto trukmę įmanoma pasakyti tik meistrui apžiūrėjus automobilį ir įvertinus jo stovį, bei remonto sudėtingumą.",
                { id: "o7NxbIOS" }
            ),
        },
    ];
    return QUESTIONS;
}

const FAQSection: React.FC<{ selectedWorkshop: WorkshopDTO }> = (p) => {
    const GS = useStore();
    const questions = formQuestions({
        fixedCarBrands: p.selectedWorkshop.servicedBrands,
        carDataObj: GS.searchState.carData,
    });
    return (
        <Box pt={3}>
            <Text variant="h2" mb={3}>
                <TransMsg
                    default={"Dažnai užduodami klausimai"}
                    id="lLuAuBQY"
                />
            </Text>
            {questions.map((q) => (
                <S.QAccordion
                    arrowColor={"black"}
                    answerColor={"#7B7F93"}
                    key={q.question}
                    question={q.question}
                    answer={q.answer}
                />
            ))}
        </Box>
    );
};

export default FAQSection;
