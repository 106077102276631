import React from "react";
import * as S from "./WorkshopLogos.styled";

//@ts-expect-error
const imageContext = require.context("./assets", false, /\.webp$/);

const imgAssetPaths = Array.from({ length: 18 }, (_, i) => i).map((i) => {
    const logoUrl = imageContext(`./${i}.webp`).default;
    return logoUrl;
});

const backLinksUrls = [
    {
        imgId: 3,
        backLinkUrl: "https://flixauto.lt",
    },
    {
        imgId: 4,
        backLinkUrl: "https://edler.lt",
    },
    {
        imgId: 12,
        backLinkUrl: "https://inrada.lt",
    },
];

const logosArray: { img: string; backLink: string | null }[] =
    imgAssetPaths.map((imgUrl, index) => {
        const backLinkEntry = backLinksUrls.find(
            (link) => link.imgId === index
        );
        return {
            img: imgUrl,
            backLink: backLinkEntry ? backLinkEntry.backLinkUrl : null,
        };
    });

const WorkshopLogos = () => {
    return (
        <S.LogosContentContainer>
            {logosArray.map((logo, index) =>
                logo.backLink ? (
                    <a key={index} href={logo.backLink}>
                        <S.Logo src={logo.img} key={index} />
                    </a>
                ) : (
                    <S.Logo src={logo.img} key={index} />
                )
            )}
        </S.LogosContentContainer>
    );
};

export default WorkshopLogos;
