import { TextField } from "@mui/material";
import { StandardCSSProperties } from "@mui/system";
import styled, { css } from "styled-components";

export const HELPER_TEXT_HEIGHT_PX = 23;

export const InputIcon = styled("i")<{ color?: string }>`
    font-size: 20px;
    ${(p) =>
        p.color &&
        css`
            color: ${p.color};
        `}
`;

// tbd: I think dynamicInput/helperText will fuck up heights on small inputs.
export const Input = styled(TextField)<{
    $dynamicHeight?: boolean;
    error?: boolean;
    /** This prop is only used for DatePicker / ServicePicker / CarAutocomplete, when we want the input to be disabled
     * and prevent getting focus on the input on click, since focus event causes problems on mobile devices,
     * like scrolling into input */
    $removeDisabledStyles?: boolean;
    $withHelperText?: boolean;
    $transparentBg?: boolean;
    $noBorder?: boolean;
    $bgcolor?: string;
}>`
    .MuiInputBase-root {
        ${(p) =>
            p.$dynamicHeight &&
            css`
                flex: 1;
                flex-wrap: wrap;
                height: unset;
                min-height: ${p.theme.sizes.inputHeight}px;
            `}

        cursor: pointer;
    }

    .MuiChip-root {
        cursor: pointer;
    }

    .MuiInputAdornment-root {
        color: inherit !important;
    }

    ${(p) =>
        p.$bgcolor &&
        css`
            .MuiInputBase-root {
                background: ${p.$bgcolor};
            }
        `}

    ${(p) =>
        p.$transparentBg &&
        css`
            .MuiInputBase-root {
                background: transparent;
            }
        `}

    ${(p) =>
        p.$removeDisabledStyles &&
        css`
            .MuiInputBase-input {
                cursor: pointer;
                color: black;
                -webkit-text-fill-color: black;
            }

            .Mui-disabled .MuiOutlinedInput-notchedOutline {
                border-color: ${(p) =>
                    (
                        p.theme.components?.MuiOutlinedInput?.styleOverrides
                            ?.notchedOutline as StandardCSSProperties
                    )?.borderColor || "#222222"};
            }

            .Mui-error .MuiOutlinedInput-notchedOutline {
                border-color: ${(p) => p.theme.palette.error.main};
            }

            .MuiInputBase-root {
                color: black;
            }
        `}
        
        ${(p) =>
        p.$noBorder &&
        css`
            .MuiOutlinedInput-notchedOutline {
                border: unset;
            }
        `}


    // tbd might just make sense to delete this completely.
    ${(p) =>
        p.$dynamicHeight &&
        p.$withHelperText &&
        css`
            .MuiInputBase-root {
                min-height: ${p.theme.sizes.inputHeight}px;
            }

            /** a trick I came up with, to act as space prefiller for helperText. If helperText appears, this will shrink. This is only neccessary for dynamic inputs only */
            &::after {
                content: " ";
                display: flex;
                width: 100%;
                min-height: ${HELPER_TEXT_HEIGHT_PX}px;

                ${
                    p.error &&
                    css`
                        height: 0;
                        min-height: 0;
                    `
                }
        `}
`;
