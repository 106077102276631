import styled, { css } from "styled-components";
import Button from "../Button/Button";

export const BackArrow = styled("i")`
    font-size: 14px;
`;

export const BackButton = styled(Button)<{ $circle?: boolean }>`
    height: 35px;
    border-radius: 15px;

    ${(p) =>
        p.$circle &&
        css`
            height: 42px;
            width: 42px;
            min-width: 42px;
            border-radius: 100px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            ${BackArrow} {
                margin-right: 0;
            }
        `}
`;
