export default function countdownRenderer({
    days,
    hours,
    minutes,
    seconds,
}): string {
    return [
        days && `${days}d`,
        (hours || days) && `${hours}val`,
        `${minutes}m`,
        `${seconds}s`,
    ]
        .filter(Boolean)
        .join(". ");
}
