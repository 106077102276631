import styled, { css } from "styled-components";
import Text from "@FEShared/components/UI/Text/Text";

export const StarIcon = styled("i")<{ $top?: boolean }>`
    margin: 0 2px;
    font-size: 14px;
    color: rgb(255, 182, 0);
    ${(p) =>
        p.$top &&
        css`
            color: #e96c6e;
        `}
`;

export const PriceText = styled(Text)<{ $top?: boolean }>`
    font-size: 14px;
    font-weight: 500;
`;

const CollapsedMapPointHoverStyle = css`
    background: ${(p) => p.theme.palette.primary.main};
    color: white !important;
    z-index: 2;

    ${PriceText} {
        color: white;
    }
`;

export const ReviewScore = styled("span")`
    font-weight: 500;
    font-size: 16px;
`;

export const PriceDollars = styled("span")`
    font-size: 12px;
    font-weight: 500;
    color: black;
    background-color: #dddddd;
    border-radius: 100px;
    padding: 4px 8px;
    margin-left: 4px;
`;

export const SpecializedIcon = styled("i")`
    background: ${(p) => p.theme.palette.primary.main};
    color: white;
    border-radius: 25px;
    font-size: 12px;
    padding: 4px 8px;
    margin-left: 4px;
`;

export const HiddenMapPointContainer = styled("div")`
    background: white;
    height: 10px;
    width: 16px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px 0px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 28px;
    cursor: pointer;
`;

export const CollapsedMapPointParentCont = styled("div")`
    height: 28px;
    min-width: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CollapsedMapPointContainer = styled("div")<{
    $isActive?: boolean;
    $top?: boolean;
}>`
    height: 28px;
    background: #ffffff;

    position: relative;
    border-radius: 12px;
    font-size: 16px;
    display: flex;
    padding: 0 8px;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    border-radius: 100px;

    /* ${(p) => p.theme.breakpoints.down("md")} {
        
    } */

    &:hover {
        ${CollapsedMapPointHoverStyle}
    }

    ${(p) => p.$isActive && CollapsedMapPointHoverStyle}

    ${(p) => p.theme.breakpoints.down("md")} {
        ${ReviewScore} {
            font-size: 14px;
        }
        ${StarIcon} {
            font-size: 12px;
        }
    }
`;
