import useStore from "@FEClient/logic/store";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import * as S from "./CollapsedMapPoint.styled";
import { WorkshopOmitted } from "@FEClient/views/pages/Search/Search.types";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";

export const COLLAPSED_MAP_POINT_CLASSNAME_IDENTIFIER = "CollapsedMapPoint";

interface P {
    price: number;
    workshop: WorkshopOmitted;
    onClick: () => void;
    review?: {
        count: number;
        rating: number;
    };
    priceRanges: {
        oneDollarRange: number;
        twoDollarRange: number;
        threeDollarRange: number;
    };
    specialized: boolean;
}
const CollapsedMapPoint: React.FC<P> = observer((p) => {
    const GS = useStore();
    const isMobile = useIsMobile();
    const isTopRedWorkshop = GS.searchPageState.sortedTopWorkshopIDs
        .slice(0, 2)
        .includes(p.workshop.ID);

    const priceNDuration = GS.getSelectedServicesPriceNDurationForWorkshop(
        p.workshop,
        true,
        true
    );

    console.log("priceNDuration", priceNDuration);

    // const dollarsSign = (price: number) => {
    //     if (price <= p.priceRanges.oneDollarRange) {
    //         return "${window._COUNTRY_META.currencySymbol}";
    //     }
    //     if (
    //         p.priceRanges.oneDollarRange < price &&
    //         price <= p.priceRanges.twoDollarRange
    //     ) {
    //         return "${window._COUNTRY_META.currencySymbol}${window._COUNTRY_META.currencySymbol}";
    //     }
    //     if (
    //         p.priceRanges.twoDollarRange < price &&
    //         price >= p.priceRanges.twoDollarRange
    //     ) {
    //         return "${window._COUNTRY_META.currencySymbol}${window._COUNTRY_META.currencySymbol}${window._COUNTRY_META.currencySymbol}";
    //     }
    // };

    return (
        <S.CollapsedMapPointParentCont
            className={COLLAPSED_MAP_POINT_CLASSNAME_IDENTIFIER}
            onClick={() => {
                if (isMobile) {
                    runInAction(() => {
                        GS.searchPageState.selectedServiceId = p.workshop.ID;
                    });
                }
            }}
            onMouseEnter={() => {
                runInAction(() => {
                    GS.searchPageState.hoveringOnServiceId = p.workshop.ID;
                });
            }}
            onMouseLeave={() => {
                runInAction(() => {
                    GS.searchPageState.hoveringOnServiceId = undefined;
                });
            }}
        >
            {GS.searchPageState.sortedTopWorkshopIDs.includes(p.workshop.ID) ||
            GS.searchPageState.hoveringOnServiceId === p.workshop.ID ||
            GS.searchPageState.selectedServiceId === p.workshop.ID ? (
                <S.CollapsedMapPointContainer
                    className={COLLAPSED_MAP_POINT_CLASSNAME_IDENTIFIER}
                    onClick={p.onClick}
                    $isActive={
                        GS.searchPageState.hoveringOnServiceId ===
                            p.workshop.ID ||
                        GS.searchPageState.selectedServiceId === p.workshop.ID
                    }
                >
                    {isTopRedWorkshop && GS.searchPageState.page === 1 && (
                        <S.StarIcon
                            className="icon-star-full"
                            $top={
                                isTopRedWorkshop &&
                                GS.searchPageState.page === 1
                            }
                        />
                    )}
                    <S.PriceText $top={isTopRedWorkshop}>
                        {priceNDuration?.servicesPrice.text || (
                            <>
                                {window._COUNTRY_META.currencySymbol}
                                {p.workshop.hourCost}/
                                {transCommonTranslatable("HOUR_SHORT")}
                            </>
                        )}
                        {p.specialized && (
                            <S.SpecializedIcon className="icon-wrench-full" />
                        )}
                    </S.PriceText>
                    {/* <ABTest
                        defaultComponent={
                            <>
                                <S.StarIcon
                                    className="icon-star-full"
                                    $top={
                                        isTopRedWorkshop &&
                                        GS.searchPageState.page === 1
                                    }
                                />
                                <S.ReviewScore>
                                    {p.review
                                        ? p.review.rating.toFixed(1)
                                        : Number(5).toFixed(1)}
                                </S.ReviewScore>
                                {p.specialized && (
                                    <S.SpecializedIcon className="icon-wrench-full" />
                                )}
                            </>
                        }
                        newComponent={<></>}
                        experimentKey="COLLAPSED_MAP_POINT_PRICE"
                    /> */}
                </S.CollapsedMapPointContainer>
            ) : (
                <S.HiddenMapPointContainer />
            )}
        </S.CollapsedMapPointParentCont>
    );
});

export default CollapsedMapPoint;
