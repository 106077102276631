/* Make sure you don't import any non const/util files, since whole app assumes that window_COUNTRY and window._COUNTRY_META will eixst*/

import Country from "@Shared/types/enums/Country";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import DOMAINS from "@Shared/consts/DOMAINS";
import countryToMeta, { CountryMeta } from "@Shared/util/countryToMeta";
import { SEARCH_STATE_LS_KEY } from "@FEClient/consts/consts";

window._IS_PROD = (() => {
    return Object.entries(DOMAINS.FE_CLIENT).some(([_country, domain]) => {
        return window.location.host.includes(domain);
    });
})();

window._COUNTRY = (() => {
    const urlParams = new URLSearchParams(new URL(window.location.href).search);
    if (urlParams.get(QUERY_PARAMS.FORCE_COUNTRY)) {
        // this is just for development - check country forcing.
        // clear localStorage search state if switching countries (for )
        localStorage.removeItem(SEARCH_STATE_LS_KEY);

        const country = urlParams.get(QUERY_PARAMS.FORCE_COUNTRY);
        if (country && Object.values(Country).includes(country as Country)) {
            localStorage.setItem(QUERY_PARAMS.FORCE_COUNTRY, country);
            return country as Country;
        }
    }

    const foundPair = Object.entries(DOMAINS.FE_CLIENT).find(
        ([_country, domain]) => {
            return window.location.host.includes(domain);
        }
    );
    if (foundPair) {
        const [country, _domain] = foundPair;
        return country as Country;
    } else {
        const localStorageCountry = localStorage.getItem(
            QUERY_PARAMS.FORCE_COUNTRY
        );
        if (localStorageCountry) {
            return localStorageCountry as Country;
        } else {
            console.warn("Country not found, defaulting to CA");
            return "CA" as Country;
        }
    }
})();

window._COUNTRY_META = countryToMeta(window._COUNTRY);

window._APP_TYPE = (() => {
    if (window.location.host.includes("localhost")) {
        return window.location.host.includes("localhost:8080")
            ? "CLIENT"
            : "WORKSHOP";
    } else {
        return Object.values(DOMAINS.FE_WORKSHOP).some((domain) => {
            return window.location.host.includes(domain);
        })
            ? "WORKSHOP"
            : "CLIENT";
    }
})();

declare global {
    interface Window {
        _IS_PROD: boolean;
        _COUNTRY: Country;
        _COUNTRY_META: CountryMeta;
        _APP_TYPE: "CLIENT" | "WORKSHOP";

        // all below are optional because of adblockers
        hj?: (...arguments: any[]) => void;
        fbq?: (...arguments: any[]) => void;
        gtag?: (...arguments: any[]) => void;
        _chatlio?: Record<string, (...arguments: any[]) => void>;
    }
}
