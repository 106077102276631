import React from "react";
import Section from "../Section/Section";
import * as S from "./WorkshopReviewsSection.styled";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import PartnerRecomendationCards from "@FEClient/views/commonComps/PartnerRecomendationCards/PartnerRecomendationCards";
import { TransMsg, transStr } from "@FEShared/i18n";

const WorkshopReviewsSection: React.FC = () => {
    return (
        <Section
            title={transStr("Partnerių atsiliepimai", { id: "Dgy6UW14" })}
            titleProps={{ variant: "h2" }}
        >
            <PartnerRecomendationCards />
            <S.BtnLink
                to={countryClientPageToMeta(window._COUNTRY).BECOME_PARTNER.url}
            >
                <S.BecomePartnerBtn>
                    <TransMsg default={"Tapti partneriu"} id="FXymbhyJ" />
                </S.BecomePartnerBtn>
            </S.BtnLink>
        </Section>
    );
};

export default WorkshopReviewsSection;
