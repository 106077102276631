import { InternalLink } from "@FEShared/components/InternalLink/InternalLink.styled";
import Button from "@FEShared/components/UI/Button/Button";
import styled, { css } from "styled-components";
import Typography from "@mui/material/Typography";
import Box from "@FEShared/components/UI/Box/Box";
import IconComp from "@FEShared/components/UI/Icon/Icon";

// const P1920_LIST_ITEM_CONTAINER_WIDTH = 550;

const DESKTOP_LIST_ITEM_HEIGHT = 210;

export const Pic = styled("div")<{ $imgUrl: string }>`
    height: ${DESKTOP_LIST_ITEM_HEIGHT}px;
    min-width: ${DESKTOP_LIST_ITEM_HEIGHT}px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-image: url(${(p) => p.$imgUrl});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;

export const Info = styled("div")`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 24px;
`;

export const TopSection = styled("div")`
    display: flex;
    flex-direction: column;
`;

export const BottomSection = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: auto;
`;

export const Left = styled("div")``;

export const Right = styled("div")`
    display: flex;
    margin-left: auto;
`;

export const StarIcon = styled("i").attrs({ className: "icon-star-full" })<{
    $promo?: boolean;
}>`
    margin: 0 2px;
    margin-top: 2px;
    font-size: 16px;
    color: ${(p) => (p.$promo ? "#e96c6e" : "#ffb600")};
`;

export const Title = styled("div")`
    color: #000000;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 8px;
    display: flex;
    width: 100%;
    //test commit
`;

export const Detail = styled(Box)<{
    $special?: boolean;
    $warning?: boolean;
    $box?: boolean;
}>`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: black;

    ${(p) =>
        p.$box &&
        css`
            border-radius: 5px;
            background: #f0f2f9;
            padding: 4px;
            color: black;
        `}

    ${(p) =>
        p.$special &&
        css`
            color: ${(p) => p.theme.palette.primary.main}!important;

            ${Text} {
                color: ${(p) => p.theme.palette.primary.main};
            }
        `}

    ${(p) =>
        p.$warning &&
        css`
            color: ${(p) => p.theme.palette.warning.main}!important;

            ${Text} {
                color: ${(p) => p.theme.palette.warning.main};
            }
        `}
`;

export const Icon = styled(IconComp)`
    font-size: 12px;
    margin-right: 4px;
`;

export const Text = styled(Typography).attrs({ variant: "subtitle1" })`
    color: black;
`;

export const OpenCloseText = styled(Text)<{ $open: boolean }>`
    margin-right: 4px;
    color: ${(p) => (p.$open ? "#41B46F" : "#E69152")};
`;

export const ReviewCountText = styled(Text)`
    font-weight: 500;
`;

export const InfoScoreComponent = styled("div")`
    font-size: 18px;
    display: flex;
    align-items: center;
`;

export const Price = styled("div")`
    margin-left: auto;
`;

export const PriceLabel = styled(Typography)`
    margin-right: 16px;
    display: inline-flex;
    align-items: center;
`;

export const Score = styled("span")`
    font-size: 18px;
    font-weight: 500;
`;

export const AllServicesBtn = styled(Button)`
    max-width: 180px;
    width: 100%;
    padding: 12px;
    height: unset;
`;

export const ArrowRight = styled("i")`
    margin-left: 8px;
    font-size: 12px;
`;

export const ListItemContainer = styled(InternalLink)`
    color: black;
    display: flex;
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 25px;
    background: #ffffff;
    height: ${DESKTOP_LIST_ITEM_HEIGHT}px;
    width: 100%;

    ${(p) => p.theme.breakpoints.down("md")} {
        width: 100%;
        height: unset;
        min-height: 350px;
        margin-right: unset;
        padding: 0;
        flex-direction: column;

        ${BottomSection} {
            justify-content: center;
        }

        ${Detail} {
            margin-left: 0;
        }

        ${Info} {
            padding: 12px;
        }

        ${PriceLabel} {
            font-size: 14px;
        }

        ${AllServicesBtn} {
            margin-left: auto;
        }

        ${Pic} {
            width: 100%;
            border-radius: 0;
        }

        ${AllServicesBtn} {
            font-size: 14px;
        }
    }
`;
