import Box from "@FEShared/components/UI/Box/Box";
import React from "react";
import Text from "@FEShared/components/UI/Text/Text";
import FECLIENT_DISTRICT_SEO_PAGES from "@Shared/consts/FECLIENT_DISTRICT_SEO_PAGES";
import * as S from "./Sitemap.styled";
import CITIES from "@Shared/consts/CITIES";
import { UrlPathnameParser } from "../Search/Search.logic";
import FECLIENT_BRANDS_SEO_PAGES from "@Shared/consts/FECLIENT_BRANDS_SEO_PAGES";
import FECLIENT_ALL_SERVICES_SEO_PAGES, {
    MOST_POPULAR_SERVICES_LANDINGS,
} from "@Shared/consts/FECLIENT_ALL_SERVICES_SEO_PAGES";
import { TransMsg, transStr, transStringFunctionalFE } from "@FEShared/i18n";
import { SERVICES_TRANSLATABLES } from "@Shared/consts/SHARED_TRANSLATABLES";

const Sitemap: React.FC = () => {
    return (
        <Box padding={3}>
            <Text variant="h1" center mb={2}>
                <TransMsg default={"Svetainės struktūra"} id="xBsi6JQx" />
            </Text>
            <Box
                displayFlex
                justifyContent="space-between"
                sx={{
                    "&>*": {
                        flex: 1,
                    },
                }}
            >
                <Box vertical mr={2}>
                    <Text variant="h2" mb={2}>
                        <TransMsg
                            default={"Autoservisai pagal miesta ir paslaugą"}
                            id="UOetnehN"
                        />
                    </Text>
                    {CITIES[window._COUNTRY].flatMap((city) => {
                        return (
                            <>
                                <Text variant="h3">{city}</Text>
                                {SERVICES_TRANSLATABLES.map((service) => {
                                    const transServiceName =
                                        transStringFunctionalFE(
                                            service.serviceName
                                        );
                                    return (
                                        <S.Url
                                            key={transServiceName + city}
                                            href={UrlPathnameParser.paramsToPathname(
                                                {
                                                    city: city,
                                                    serviceDefinitionID:
                                                        service.ID,
                                                }
                                            )}
                                        >
                                            {transStr(
                                                "{serviceName} | Autoservisai {city}",
                                                {
                                                    data: {
                                                        serviceName:
                                                            transServiceName,
                                                        city,
                                                    },
                                                    id: "8Lqk6yrL",
                                                }
                                            )}
                                        </S.Url>
                                    );
                                })}
                            </>
                        );
                    })}
                </Box>
                <Box vertical mr={2}>
                    <Text variant="h2" mb={2}>
                        <TransMsg
                            default={"Autoservisai pagal mikrorajonus"}
                            id="jihc0a4D"
                        />
                    </Text>
                    {Object.keys(
                        FECLIENT_DISTRICT_SEO_PAGES(
                            transStringFunctionalFE,
                            window._COUNTRY
                        )
                    ).map((url) => (
                        <S.Url key={url} href={url}>
                            {url.replace(/-/g, " ").replace("/", "")}
                        </S.Url>
                    ))}
                </Box>
                <Box vertical mr={2}>
                    <Text variant="h2" mb={2}>
                        <TransMsg
                            default={"Autoservisai pagal markes"}
                            id="rm4WrBE2"
                        />
                    </Text>
                    {Object.keys(
                        FECLIENT_BRANDS_SEO_PAGES(
                            transStringFunctionalFE,
                            window._COUNTRY
                        )
                    ).map((url) => (
                        <S.Url key={url} href={url}>
                            {url.replace(/-/g, " ").replace("/", "")}
                        </S.Url>
                    ))}
                </Box>
                <Box vertical>
                    <Text variant="h2" mb={2}>
                        <TransMsg
                            default={"Automobilių remonto paslaugos"}
                            id="ljynu9A0"
                        />
                    </Text>
                    {Object.keys(
                        MOST_POPULAR_SERVICES_LANDINGS(window._COUNTRY)
                    ).map((url) => (
                        <S.Url key={url} href={url}>
                            {url.replace(/-/g, " ").replace("/", "")}
                        </S.Url>
                    ))}
                    {Object.keys(
                        FECLIENT_ALL_SERVICES_SEO_PAGES(
                            transStringFunctionalFE,
                            window._COUNTRY
                        )
                    ).map((url) => (
                        <S.Url key={url} href={url}>
                            {url.replace(/-/g, " ").replace("/", "")}
                        </S.Url>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Sitemap;
