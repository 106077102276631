import { AccordionSummary, AccordionDetails } from "@mui/material";
import React from "react";
import Text from "@FEShared/components/UI/Text/Text";
import * as S from "./QuestionAccordion.styled";

const QuestionAccordion: React.FC<{
    question: string;
    answer: string;
    defaultExpanded?: boolean;
    className?: string;
    answerColor?: string;
    arrowColor?: string;
}> = (p) => {
    return (
        <S.AccordionContainer
            defaultExpanded={p.defaultExpanded}
            className={p.className}
        >
            <AccordionSummary
                expandIcon={
                    <Text
                        component="i"
                        className="icon-arrow-down"
                        fontSize={12}
                        color={p.arrowColor ? p.arrowColor : undefined}
                    />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Text semiBold>{p.question}</Text>
            </AccordionSummary>
            <AccordionDetails>
                <Text color={p.answerColor}>{p.answer}</Text>
            </AccordionDetails>
        </S.AccordionContainer>
    );
};

export default QuestionAccordion;
