import { css } from "styled-components";

export const absoluteHorizontalCenter = css`
    left: 50%;
    transform: translateX(-50%);
`;

export const absoluteVerticalCenter = css`
    top: 50%;
    transform: translateY(-50%);
`;

export const absoluteHorizontalVerticalCenter = css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
