import { Weekday } from "@Shared/types/calendar";
import { TranslatableCustomID_PartCategories } from "@Shared/types/enums/TranslatableCustomID";

export const CALENDAR_FULL_DAY_SEARCH_TIME = {
    hour: 23,
    minute: 59,
    second: 0,
    ms: 0,
};

export const TEST_WORKSHOP_TOKEN = "[T]";

export const WEEKDAYS = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
] as Weekday[];

export const PART_CATEGORIES_ORDER = [
    TranslatableCustomID_PartCategories.POPULAR,
    TranslatableCustomID_PartCategories.MAINTENANCE,
    TranslatableCustomID_PartCategories.INSPECTION,
    TranslatableCustomID_PartCategories.BRAKES,
    TranslatableCustomID_PartCategories.SUSPENSION,
    TranslatableCustomID_PartCategories.ENGINE,
    TranslatableCustomID_PartCategories.GEARBOX,
    TranslatableCustomID_PartCategories.ELECTRICITY,
    TranslatableCustomID_PartCategories.WHEELS,
    TranslatableCustomID_PartCategories.BODY,
    TranslatableCustomID_PartCategories.CONDITIONER,
    TranslatableCustomID_PartCategories.INTERIOR,
    TranslatableCustomID_PartCategories.EXHAUST_SYSTEM,
    TranslatableCustomID_PartCategories.HEADLIGHTS,
    TranslatableCustomID_PartCategories.GLASS,
    TranslatableCustomID_PartCategories.STEERING,
    TranslatableCustomID_PartCategories.DETAILING,
    TranslatableCustomID_PartCategories.OTHER,
] as const;

export const ONESIGNAL_WORKSHOP_ID_PREFIX = "WORKSHOP_";

export const STANDARD_FEE = 0.2;

export const FEE_CONFIG = {
    MIN: 0.165,
    MAX: STANDARD_FEE,
    CLIENT_MISS: 0.015,
    CLIENT_ACCEPT: 0.005,
};

export const STANDARD_WORKHOURS_DURATION = 9;

export const MINS_FOR_IMMEDIATE_BOOKING = 120;
