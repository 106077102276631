import { transStr } from "@FEShared/i18n";

export const getTitleAndEmoji = (rating: number) => {
    switch (rating) {
        case 1:
        case 2:
            return {
                title: transStr(
                    "Atsiprašome už nesklandumus. Į situaciją atsižvelgsime ir tobulėsime.",
                    { id: "gJvXNOCs" }
                ),
                emoji: "🙁",
            };
        case 3:
            return {
                title: transStr(
                    "Ačiū už įvertinimą. Jūsų komentarai padeda mums tobulėti.",
                    { id: "Gff2mZvh" }
                ),
                emoji: "😕",
            };
        case 4:
        case 5:
            return {
                title: transStr("Ačiū už įvertinimą!", { id: "By9TLKc3" }),
                emoji: "🎉",
            };
        default:
            throw new Error("Invalid rating");
    }
};
