import styled from "styled-components";
import MUIAutocomplete from "@mui/material/Autocomplete";
import MUIBackdrop from "@mui/material/Backdrop";
import Popper from "@mui/material/Popper";
import Input from "../Input/Input";
import Chip from "@mui/material/Chip/Chip";
import { AUTOCOMPLETE_WITH_MODAL_CLASS_NAME } from "./AutocompleteWithModal";

export const DropdownContainer = styled("div")`
    background: white;
    padding: 8px;
    overflow: hidden;
    flex: 1;

    .MuiListSubheader-root {
        max-height: 40px;
        line-height: unset;
    }
    .MuiAutocomplete-option,
    .MuiListSubheader-root {
        padding-left: 16px;
        padding-right: 16px;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        height: 100%;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 24px;

        .MuiListSubheader-root {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;

export const Backdrop = styled(MUIBackdrop)`
    z-index: 1000;
`;

export const PopperComponent = styled(Popper).attrs(() => ({
    className: AUTOCOMPLETE_WITH_MODAL_CLASS_NAME,
}))`
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    overflow: hidden;
    box-shadow: ${(p) => p.theme.shadows[4]};

    .MuiAutocomplete-listbox {
        overscroll-behavior: contain;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        border-radius: unset;
        overflow: unset;
        box-shadow: unset;
        height: 100%;
        position: static !important;
        transform: unset !important;

        .MuiAutocomplete-listbox {
            padding-bottom: 400px;
        }
    }
`;

export const Autocomplete = styled(MUIAutocomplete)`
    .MuiInputBase-input {
        flex-shrink: 30;
    }

    .MuiAutocomplete-inputRoot {
        padding-right: 16px !important;
    }

    .MuiAutocomplete-input {
        padding-left: 0 !important;
    }

    .MuiAutocomplete-tag {
        max-width: calc(100% - 80px);
    }

    .MuiAutocomplete-endAdornment {
        top: unset;
    }

    ${(p) => p.theme.breakpoints.down("sm")} {
        .MuiAutocomplete-inputRoot {
            padding-right: 30px;
        }
        .MuiAutocomplete-input {
            padding-right: 0 !important;
        }
        .MuiFormControl-root {
            margin-top: 0;
        }
    }
` as typeof MUIAutocomplete;

export const MobileInputWrapper = styled("div")`
    pointer-events: all;
    position: relative;
    z-index: 2;
    cursor: pointer;
`;

export const MobileInput = styled(Input)`
    pointer-events: none;
    .right-icon {
        font-size: 8px;
    }
    .MuiInputBase-root {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 16px;
    }
    .MuiInputBase-input {
        flex-shrink: 10;
        &::placeholder {
            font-size: 16px;
        }
    }
`;

export const ExpansionValueChip = styled(Chip)`
    margin: 3px;
`;
