import styled, { css } from "styled-components";
import COLOR_CIRCLE_IMG from "./assets/color-circle.svg";
import * as WS from "../Workshop.styled";
import Carousel from "react-multi-carousel";
import Text from "@FEShared/components/UI/Text/Text";
import Icon from "@FEShared/components/UI/Icon/Icon";

export const CarouselWrap = styled("div")`
    position: relative;
    width: 70%;

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-left: -16px;
        margin-right: -16px;
        margin-top: -16px;
        width: calc(100% + 32px);
    }
`;

export const ColorCircle = styled("div")`
    position: absolute;
    top: 16px;
    left: 16px;
    width: 85px;
    height: 85px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background: url(${COLOR_CIRCLE_IMG}) no-repeat;
    color: white;
    font-size: 12px;
    text-align: center;
    z-index: 2;
    flex-direction: column;
`;

export const CircleStar = styled("i")<{ $big?: boolean }>`
    color: white;
    font-size: 7px;
    &:not(:last-child) {
        margin-right: 4px;
    }
    ${(p) =>
        p.$big &&
        css`
            font-size: 10px;
        `}
`;

export const WarrantyBox = styled("div")`
    cursor: pointer;
    background: rgba(224, 224, 224, 0.25);
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    display: flex;
    align-items: center;
    padding: 16px;
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    color: ${(p) => p.theme.palette.primary.main};
`;

export const WarrantyPrimaryDetail = styled(WS.PrimaryDetail)`
    font-size: 18px;
    font-weight: 500;
`;

export const WarrantyDetails = styled(WS.DetailsWrap)`
    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: column;
    }
`;

export const IconShield = styled("i")`
    font-size: 16px;
    margin-right: 16px;
`;

export const FeatureText = styled(Text)`
    color: #7b7f93;
    margin-bottom: 4px;
`;

export const FeatureIcon = styled(Icon)`
    margin-right: 8px;
    width: 16px;
    text-align: center;
    display: inline-block;
`;

export const Address = styled("div")`
    font-size: 16px;
    cursor: pointer;
`;

export const OpenCloseTime = styled("div")`
    font-size: 16px;
    margin-left: 24px;
`;

export const InfoRowIcon = styled("i")`
    margin-right: 8px;
    color: #727272;
    min-width: 16px;
    display: inline-block;
    text-align: center;
`;

export const WarrantyUnderline = styled("span")`
    text-decoration: underline;
`;

export const NevaziuojaText = styled("span")`
    color: ${(p) => p.theme.palette.primary.main};
`;

export const SCarousel = styled(Carousel)`
    border-radius: 10px;
    height: 250px;

    .react-multiple-carousel__arrow {
        background: white;
        &::before {
            color: black;
            font-weight: 600;
        }
    }

    .react-multi-carousel-item {
        margin-right: 8px;
    }
    .react-multi-carousel-track {
        height: 100%;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        order: -1;
        width: 100%;
        height: 250px;
        position: relative;
        border-radius: initial;
    }
`;

export const WorkshopImg = styled("img")`
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;

    ${(p) => p.theme.breakpoints.down("md")} {
        border-radius: initial;
        width: 100%;
    }
`;

export const WorkshopDetails = styled("div")`
    display: flex;
    margin-bottom: 32px;

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: column;
    }
`;

export const WorkshopDetailsBox = styled("div")<{ $warning?: boolean }>`
    display: flex;
    margin-right: 10px;
    background: rgba(224, 224, 224, 0.25);
    padding: 16px 24px;
    border-radius: 15px;
    align-items: flex-start;

    ${(p) =>
        p.$warning &&
        css`
            /* background: ${(p) => p.theme.palette.warning.main} */
        `}

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-bottom: 8px;
        margin-right: initial;
        padding: 0;
        border-radius: initial;
        background: initial;
    }
`;

export const WarrantyDetailsBox = styled(WorkshopDetailsBox)`
    cursor: pointer;
    padding: 16px;
    background: #efebff;
    border-radius: 25px;
    align-items: center;
    margin-top: auto;

    ${(p) => p.theme.breakpoints.down("md")} {
        padding: 8px 16px;
        order: 100;
    }
`;
