import { absoluteHorizontalCenter } from "FEShared/styled/util.styled";
import styled from "styled-components";
import Box from "@mui/material/Box/Box";
import { Typography } from "@mui/material";
import Button from "@FEShared/components/UI/Button/Button";

export const Specialized = styled("div")`
    background: ${(p) => p.theme.palette.primary.main};
    font-weight: 500;
    font-size: 12px;
    color: white;
    padding: 4px;
    margin-left: auto;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

export const MobileServiceSelectionContainer = styled(Box).attrs({
    sx: {
        boxShadow: 4,
    },
})`
    position: fixed;
    bottom: 85px;
    ${absoluteHorizontalCenter}
    border-radius:25px;
    background: white;
    width: calc(100% - ${(p) => p.theme.padding.contentPadding}*2);
    max-height: 140px;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
`;

export const PicInfoWrapper = styled("div")`
    display: flex;
    height: 97px;
`;

export const Title = styled("span")`
    font-weight: 500;
    font-size: 16px;
    display: flex;
`;

export const Address = styled(Typography).attrs({ variant: "subtitle2" })``;

export const InfoWrap = styled("div")`
    padding: 4px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${(p) => p.theme.palette.text.primary};
    flex: 1;
`;

export const Pic = styled("img")`
    height: 100%;
    width: 100px;
`;

export const PicWrapper = styled("div")`
    position: relative;
`;

export const BottomWrap = styled("div")`
    display: flex;
    justify-content: space-between;
`;

export const StarsWrapper = styled("div")`
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-left: auto;
`;

export const StarIcon = styled("i")`
    margin: 0 2px;
    margin-top: -4px;
    color: #edb117;
    font-size: 14px;
`;

export const ReviewCount = styled("span")`
    font-size: 14px;
    color: #727272;
`;

export const ReviewScore = styled("span")`
    font-weight: 500;
    font-size: 14px;
    margin-right: 4px;
`;

export const Price = styled("div")``;

export const PriceLabel = styled("span")`
    font-weight: 500;
    font-size: 16px;
`;

export const PriceText = styled("span")`
    font-weight: 400;
    font-size: 16px;
    color: #727272;
`;

export const BottomBtn = styled(Button)`
    margin-top: 0;
    border-radius: unset;
    height: 43px;
`;
