const QUERY_PARAMS = {
    INVITATION_ID: "i",
    MAGIC_LINK_REDIRECT_URL: "r",
    MAGIC_LINK_QUERY_PARAMS: "q",
    USER_ORDER_TOKEN: "t",
    USER_ORDER_TOKEN_LEGACY: "token", // deprecated, to be removed.
    BOOK_NOW: "book-now",
    ERROR: "error",
    TEST_MODE_ENABLE_QUERY_PARAM: "test1337",
    IGNORE_REDIRECT: "ignore-redirect",
    /* Only used in dev to change the country */
    FORCE_COUNTRY: "force-country",
    /* used to force redirect in workshop software, since if any param is provided it wont redirect to dashboard */
    PREVENT_REDIRECT: "s",
    /* filter test workshops */
    FILTER_TEST: "filter-test",
    SHOW_ALL_MAP_WORKSHOPS: "show-all-map-workshops",
} as const;
export default QUERY_PARAMS;
