import React from "react";
import * as S from "./StarsComponent.styled";
import classnames from "classnames";

const TOTAL_STARS = 5;

interface StarsComponentP {
    rating: number;
    className?: string;
}
const StarsComponent: React.FC<StarsComponentP> = (p) => {
    const fullStarsCount = Math.floor(p.rating);
    const starsArray = Array.from({ length: TOTAL_STARS }, (_, i) => i + 1);

    const determinStarType = (starNumber: number) => {
        if (starNumber <= fullStarsCount) {
            return (
                <S.StarIcon
                    key={starNumber}
                    className={classnames([p.className, "icon-star-full"])}
                />
            );
        } else if (
            starNumber > p.rating &&
            starNumber === Math.ceil(p.rating)
        ) {
            const decimalPart = p.rating % 1;
            return (
                <S.StarIcon
                    key={starNumber}
                    className={classnames([
                        p.className,
                        decimalPart >= 0.3
                            ? "icon-star-half-empty"
                            : "icon-star-empty",
                    ])}
                />
            );
        } else if (starNumber > Math.ceil(p.rating)) {
            return (
                <S.StarIcon
                    key={starNumber}
                    className={classnames([p.className, "icon-star-full"])}
                    $empty
                />
            );
        }
    };
    return <>{starsArray.map((starNumber) => determinStarType(starNumber))}</>;
};

export default StarsComponent;
