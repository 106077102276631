import React from "react";
import { useTheme } from "styled-components";
import Rating from "FEShared/components/UI/Rating/Rating";
import InputsGroup from "@FEShared/components/UI/InputsGroup/InputsGroup";
import WorkshopDetailsHeader from "../WorkshopDetailsHeader/WorkshopDetailsHeader";
import * as S from "./ReviewForm.styled";
import { AlignToBottomOnMobile } from "../ReviewPage.styled";
import { SaveReviewParams } from "./ReviewForm.types";
import Input from "@FEShared/components/UI/Input/Input";
import { OrderReviewSummaryQuery } from "@FEShared/graphql/generated/graphql";
import Box from "@FEShared/components/UI/Box/Box";
import { TransMsg, transStr } from "@FEShared/i18n";

const ReviewForm: React.FC<{
    order: OrderReviewSummaryQuery["order"];
    onSave: (params: SaveReviewParams) => void;
}> = (p) => {
    const RATING_LABELS = {
        1: transStr("Labai blogai", { id: "uMO76MdA" }),
        2: transStr("Blogai", { id: "bpieIS7q" }),
        3: transStr("Patenkinamai", { id: "guwblZ6K" }),
        4: transStr("Gerai", { id: "e0Z8R7SY" }),
        5: transStr("Puikiai", { id: "2MzfOHrx" }),
    };

    const theme = useTheme();
    const [rating, setRating] = React.useState<number | null>(null);
    const [name, setName] = React.useState("");
    const [reviewText, setReviewText] = React.useState("");

    const save = () => {
        if (!rating) return;

        p.onSave({
            name,
            rating,
            reviewText,
        });
    };

    const onChangeRating = (_: React.SyntheticEvent, value: number | null) => {
        if (value !== null) {
            setRating(value);
        }
    };

    if (!p.order.acceptedService) {
        console.error("No accepted service in ReviewForm", p.order);
        return null;
    }

    return (
        <>
            <WorkshopDetailsHeader
                name={p.order.acceptedService?.name}
                address={p.order.acceptedService?.address}
            />
            <S.ReviewPageContentContainer
                pt="32px"
                sx={{ maxWidth: "610px!important" }}
            >
                <S.RatingContainer>
                    <S.RatingTitle>
                        <TransMsg
                            default={
                                "Kaip vertinate {RatingTitleWorkshopName <{name}>} atliktas paslaugas?"
                            }
                            data={{
                                name: p.order.acceptedService?.name,
                                RatingTitleWorkshopName: (body) => (
                                    <S.RatingTitleWorkshopName>
                                        {body}
                                    </S.RatingTitleWorkshopName>
                                ),
                            }}
                            id="3gORvHRo"
                        />
                    </S.RatingTitle>
                    <Rating
                        size="large"
                        value={rating}
                        fillColor={theme.palette.primary.main}
                        onChange={onChangeRating}
                    />
                    <S.RatingLabel>
                        {rating && RATING_LABELS[rating]}
                    </S.RatingLabel>
                </S.RatingContainer>
                <AlignToBottomOnMobile>
                    <Box mt="32px" mb="32px">
                        <InputsGroup>
                            <Input
                                bgcolor="#ffffff"
                                fullWidth
                                placeholder={transStr("Vardas", {
                                    id: "AwukLaAB",
                                })}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <S.CommentInput
                                bgcolor="#ffffff"
                                fullWidth
                                multiline
                                placeholder={transStr("Komentaras", {
                                    id: "tVN549AW",
                                })}
                                value={reviewText}
                                onChange={(e) => setReviewText(e.target.value)}
                            />
                        </InputsGroup>
                        <S.ConfirmBtn
                            disabled={!(rating && (name || reviewText))}
                            onClick={save}
                        >
                            <TransMsg default={"Baigta"} id="iuk3L4e9" />
                        </S.ConfirmBtn>
                    </Box>
                </AlignToBottomOnMobile>
            </S.ReviewPageContentContainer>
        </>
    );
};

export default ReviewForm;
