export default function normalizeJsonLDString(
    s: string | undefined | null,
    cutString?: number
) {
    if (!s) return "";
    s = s.replace(/"/g, "'");
    if (cutString) {
        s = s.slice(0, cutString);
    }
    return s;
}
