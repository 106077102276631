export default function mapMapValues<K, V, T>(
    map: Map<K, V>,
    transform: (value: V, key: K) => T
): Map<K, T> {
    const result = new Map<K, T>();
    for (const [key, value] of map.entries()) {
        result.set(key, transform(value, key));
    }
    return result;
}
