import styled, { css } from "styled-components";

export const InputsGroupContainer = styled("div")<{ $horizontal?: boolean }>`
    ${(p) =>
        p.$horizontal
            ? css`
                  display: flex;
                  align-items: center;
                  & > *:not(.MuiBackdrop-root) {
                      flex: 1;
                      margin-right: 8px;
                      overflow: hidden;
                  }
                  & > :last-child {
                      margin-right: 0;
                  }
              `
            : css`
                  .MuiFormControl-root {
                      margin-bottom: 16px;
                  }
                  & > .MuiFormControl-root:last-child {
                      margin-bottom: 0;
                  }
              `}
`;
