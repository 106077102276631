import Fade from "@mui/material/Fade";
import React from "react";
import Button from "../Button/Button";
import * as S from "./Modal.styled";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";

const Modal: React.FCC<{
    isOpen: boolean;
    onClose: () => void;
    onChooseClick?: () => void;
    className?: string;
    header?: React.ReactNode;
    maxWidth?: string;
    fixedHeight?: string;
    goBack?: () => void;
}> = (p) => {
    return (
        <S.MUIModalContainer
            className={p.className}
            open={p.isOpen}
            onClose={(_e, _reason) => {
                p.onClose();
            }}
        >
            <Fade in={p.isOpen}>
                <S.ModalContent
                    $maxWidth={p.maxWidth}
                    // $fixedHeight={p.fixedHeight}
                >
                    {p.header !== null && (
                        <S.Head>
                            {p.header !== undefined ? (
                                p.header
                            ) : (
                                <>
                                    {p.goBack && (
                                        <S.HeadBackButton
                                            onClick={() => {
                                                if (p.goBack) {
                                                    p.goBack();
                                                } else {
                                                    p.onClose();
                                                }
                                            }}
                                            circle
                                        />
                                    )}
                                    {p.onChooseClick && (
                                        <S.FooterBtn onClick={p.onChooseClick}>
                                            {transCommonTranslatable("CONFIRM")}
                                        </S.FooterBtn>
                                    )}
                                    {!p.goBack && (
                                        <Button
                                            sx={{ ml: "auto" }}
                                            circle
                                            color="white"
                                            onClick={p.onClose}
                                        >
                                            <i className="icon-cross" />
                                        </Button>
                                    )}
                                </>
                            )}
                        </S.Head>
                    )}
                    <S.Body>{p.children}</S.Body>
                </S.ModalContent>
            </Fade>
        </S.MUIModalContainer>
    );
};

export default Modal;
