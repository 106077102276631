import DOMAINS from "@Shared/consts/DOMAINS";
import { createClient } from "urql";

export default function createUrqlClient(credentials?: boolean) {
    return createClient({
        url: window._IS_PROD
            ? `https://api.${DOMAINS.FE_CLIENT[window._COUNTRY]}/graphql`
            : `http://${window.location.hostname}:3000/graphql`, // hostname instead of localhost, because maybe you will want to test on your IP rather than localhost
        ...(credentials && {
            fetchOptions: {
                credentials: "include",
            },
        }),
    });
}
