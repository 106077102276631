import useStore from "@FEClient/logic/store";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import * as S from "./ListItem.styled";
import { Workshop } from "@FEClient/types/types";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import isGoogleBot from "@FEShared/utils/isGoogleBot";
import Box from "@FEShared/components/UI/Box/Box";
import isWorkshopSpecialized from "@FEClient/logic/utils/isWorkshopSpecialized";
import Text from "@FEShared/components/UI/Text/Text";
import { TransMsg } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";
import { dateToMonthHourPrettyText } from "@FEShared/utils/dateFormatFE";

export const LIST_ITEM_CLASSNAME_IDENTIFIER = "ListItem";

const ListItem: React.FC<{
    workshop: Workshop;
    topWorkshop?: boolean;
}> = observer((p) => {
    const GS = useStore();
    const SPS = GS.searchPageState;
    const isMobile = useIsMobile();

    const priceNDuration = GS.getSelectedServicesPriceNDurationForWorkshop(
        p.workshop,
        true /* make sure this matches the setting in `SearchSidebar setting! */
    );

    const specialization = isWorkshopSpecialized(p.workshop, {
        vehicleBrand: GS.searchState.carData.vehicleBrand,
        servicesIDs: GS.searchState.selectedServicesDefinitionsIDs,
    });

    const newArrivalTime = GS.searchPageState.altFlow.newWorkshopSuggestedTime(
        p.workshop.ID
    );

    return (
        <S.ListItemContainer
            to={GS.searchPageState.workshopChooseUrl({
                ID: p.workshop.ID,
                name: p.workshop.name,
            })}
            className={LIST_ITEM_CLASSNAME_IDENTIFIER}
            onMouseEnter={() => {
                runInAction(() => {
                    GS.searchPageState.hoveringOnServiceId = p.workshop.ID;
                });
            }}
            onMouseLeave={() => {
                runInAction(() => {
                    GS.searchPageState.hoveringOnServiceId = undefined;
                });
            }}
            onClick={(e) => {
                if (isMobile) {
                    if (!isGoogleBot()) {
                        e.preventDefault();
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                        runInAction(() => {
                            SPS.selectedServiceId = p.workshop.ID;
                        });
                    } else {
                        // for google bot do nothing, just redirect to page
                    }
                } else {
                    e.preventDefault();
                    SPS.onWorkshopChoose(p.workshop);
                }
            }}
        >
            <S.Pic $imgUrl={p.workshop.logoURL || "#"} />
            <S.Info>
                <S.TopSection>
                    <S.Title>
                        {p.workshop.name}
                        <S.Right>
                            <S.StarIcon $promo={p.topWorkshop} />
                            <S.Score>
                                {(p.workshop.reviewRatingGmap || 5).toFixed(1)}{" "}
                            </S.Score>
                            <S.ReviewCountText ml={0.5}>
                                ({p.workshop.reviewCountGmap || 1})
                            </S.ReviewCountText>
                        </S.Right>
                    </S.Title>
                    <S.Detail>
                        <S.Icon className="icon-pin" />
                        <S.Text>
                            {[
                                p.workshop.microdistrict,
                                p.workshop.address,
                                p.workshop.city,
                            ]
                                .filter(Boolean)
                                .join(", ")}
                        </S.Text>
                    </S.Detail>
                    {newArrivalTime && (
                        <S.Detail $warning={newArrivalTime.new}>
                            <S.Icon className="icon-clock" />
                            <S.Text>{newArrivalTime.text}</S.Text>
                        </S.Detail>
                    )}
                    {specialization.isSpecializedForUser && (
                        <S.Detail $special>
                            <S.Icon className="icon-wrench-full" />
                            <S.Text>{specialization.displayString}</S.Text>
                        </S.Detail>
                    )}
                    {p.workshop.earliestAvailableDate &&
                        !GS.searchPageState.altFlow.isAlt && (
                            <S.Detail>
                                <S.Icon className="icon-clock-rotate" />
                                <S.Text>
                                    <TransMsg
                                        default={"Artimiausias laikas:"}
                                        id="ZL2AiDaS"
                                    />{" "}
                                    <Text semiBold span variant="inherit">
                                        {dateToMonthHourPrettyText(
                                            new Date(
                                                p.workshop.earliestAvailableDate
                                            )
                                        )}
                                    </Text>
                                </S.Text>
                            </S.Detail>
                        )}
                    {p.workshop.badgeOrdersCount ? (
                        <S.Detail $box alignSelf="flex-start">
                            <S.Icon
                                className="icon-chart-up"
                                color="#40BD81"
                                fontSize={10}
                            />
                            <S.Text>
                                <TransMsg
                                    default={"{ordersCount}+ užsakymų per 30d."}
                                    data={{
                                        ordersCount:
                                            p.workshop.badgeOrdersCount,
                                    }}
                                    id="k5BSvETe"
                                />
                            </S.Text>
                        </S.Detail>
                    ) : undefined}
                </S.TopSection>
                <S.BottomSection>
                    <Box mr={1}>
                        <S.Price>
                            {priceNDuration ? (
                                <S.PriceLabel>
                                    {priceNDuration.servicesPrice.text}
                                    {/* <PriceTooltip /> */}
                                </S.PriceLabel>
                            ) : (
                                <S.PriceLabel>
                                    {window._COUNTRY_META.currencySymbol}
                                    {p.workshop.hourCost}/
                                    {transCommonTranslatable("HOUR_SHORT")}
                                </S.PriceLabel>
                            )}
                        </S.Price>
                        {/* {priceNDurationStrings.duration && (
                            <S.Detail>
                                <S.Text color={"#7B7F93!important"}>
                                    {priceNDurationStrings.duration}
                                </S.Text>
                            </S.Detail>
                        )} */}
                    </Box>
                    <S.AllServicesBtn color={"primary"}>
                        {SPS.workshopBtnText()}
                    </S.AllServicesBtn>
                </S.BottomSection>
            </S.Info>
        </S.ListItemContainer>
    );
});

export default ListItem;
