import { PART_CATEGORIES_ORDER } from "@Shared/consts/commonConsts";
import STAR_IMG from "./assets/star.png";
import CHASSIS_IMG from "./assets/chassis.png";
import ENGINE_IMG from "./assets/engine.png";
import GEARBOX_IMG from "./assets/gearbox.png";
import OIL_IMG from "./assets/oil.png";

import DOOR_IMG from "./assets/door.png";
import INSPECTION_IMG from "./assets/inspection.png";
import CONDITIONER_IMG from "./assets/conditioner.png";
import EXHAUST_IMG from "./assets/exhaust.png";
import SEAT_IMG from "./assets/seat.png";
import WHEEL_IMG from "./assets/wheel.png";
import CABELS_IMG from "./assets/cabels.png";
import BRAKES_IMG from "./assets/brakes.png";
import BULB_IMG from "./assets/bulb.png";
import STEERING_WHEEL from "./assets/steering-wheel.png";
import BRUSH_IMG from "./assets/brush.png";
import WINDOW_IMG from "./assets/window.png";
import { TranslatableCustomID_PartCategories } from "@Shared/types/enums/TranslatableCustomID";

export const PART_CATEG_TO_IMG_URL: Record<
    (typeof PART_CATEGORIES_ORDER)[number],
    string | undefined
> = {
    [TranslatableCustomID_PartCategories.POPULAR]: STAR_IMG,
    [TranslatableCustomID_PartCategories.MAINTENANCE]: OIL_IMG,
    [TranslatableCustomID_PartCategories.ENGINE]: ENGINE_IMG,
    [TranslatableCustomID_PartCategories.GEARBOX]: GEARBOX_IMG,
    [TranslatableCustomID_PartCategories.SUSPENSION]: CHASSIS_IMG,
    [TranslatableCustomID_PartCategories.EXHAUST_SYSTEM]: EXHAUST_IMG,
    [TranslatableCustomID_PartCategories.ELECTRICITY]: CABELS_IMG,
    [TranslatableCustomID_PartCategories.DETAILING]: BRUSH_IMG,
    [TranslatableCustomID_PartCategories.CONDITIONER]: CONDITIONER_IMG,
    [TranslatableCustomID_PartCategories.BODY]: DOOR_IMG,
    [TranslatableCustomID_PartCategories.INSPECTION]: INSPECTION_IMG,
    [TranslatableCustomID_PartCategories.WHEELS]: WHEEL_IMG,
    [TranslatableCustomID_PartCategories.INTERIOR]: SEAT_IMG,
    [TranslatableCustomID_PartCategories.BRAKES]: BRAKES_IMG,
    [TranslatableCustomID_PartCategories.GLASS]: WINDOW_IMG,
    [TranslatableCustomID_PartCategories.STEERING]: STEERING_WHEEL,
    [TranslatableCustomID_PartCategories.HEADLIGHTS]: BULB_IMG,
    [TranslatableCustomID_PartCategories.OTHER]: undefined,
};
