import styled from "styled-components";
import "./commonAssets/icomoon/style.css";
import "react-toastify/dist/ReactToastify.css";

export const AllContentContainer = styled("div")<{ $disableScroll?: boolean }>`
    background: ${(p) => p.theme.palette.background.default};
    display: flex;
    flex-direction: column;
    min-height: 100svh;
`;

export const PageContainer = styled("div")``;
