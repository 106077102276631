import {
    LocaleTranslations,
    setEmbeddedTranslationEditorSocket,
} from "@hediet/i18n-frontend/embedded-editor";
import { observable, runInAction, configure } from "mobx";

configure({ isolateGlobalState: true });

const embeddedI18nEditorConfigKey = "embeddedI18nEditorConfig";

type Config = {
    version: 1;
    enabled: boolean;
    editorDeployment:
        | "embedded"
        | {
              /**
               * This url parameter allows to link a custom embedded editor widget to satisfy the conditions of
               * LGPLv3.
               */
              url: string;
          };
};

export class EmbeddedTranslationEditorService {
    public readonly initialized: Promise<void>;

    @observable private _localeTranslations: LocaleTranslations | undefined;

    public get localeTranslations(): LocaleTranslations | undefined {
        return this._localeTranslations;
    }

    private config!: Config;

    constructor() {
        this.loadConfig();

        const i18nEditorQueryParam = new URLSearchParams(
            window.location.search
        ).get("embeddedI18nEditor");

        if (i18nEditorQueryParam !== null) {
            if (i18nEditorQueryParam === "localhost") {
                this.setConfig({
                    version: 1,
                    enabled: true,
                    editorDeployment: {
                        url: "http://localhost:8080/embedded-editor.js",
                    },
                });
            } else if (i18nEditorQueryParam === "disable") {
                this.setConfig({
                    version: 1,
                    enabled: false,
                    editorDeployment: "embedded",
                });
            } else {
                this.setConfig({
                    version: 1,
                    enabled: true,
                    editorDeployment: "embedded",
                });
            }
        }

        if (this.config.enabled) {
            this.initialized = new Promise((res) => {
                setEmbeddedTranslationEditorSocket({
                    set: (localeTranslations) => {
                        runInAction(() => {
                            this._localeTranslations = localeTranslations;
                        });
                    },
                    initialized: () => {
                        res();
                    },
                    close: () => {
                        this.setConfig({
                            version: 1,
                            enabled: false,
                            editorDeployment: "embedded",
                        });
                        window.location.reload();
                    },
                });
            });
            this.loadEmbeddedTranslationEditor();
        } else {
            this.initialized = Promise.resolve();
        }
    }

    private setConfig(config: Config): void {
        this.config = config;
        this.saveConfig();
    }

    private loadConfig(): void {
        const config = localStorage.getItem(embeddedI18nEditorConfigKey);
        if (config === null) {
            this.config = {
                version: 1,
                enabled: false,
                editorDeployment: "embedded",
            };
        } else {
            this.config = JSON.parse(config);
        }
    }

    private saveConfig(): void {
        if (this.config.enabled) {
            localStorage.setItem(
                embeddedI18nEditorConfigKey,
                JSON.stringify(this.config)
            );
        } else {
            localStorage.removeItem(embeddedI18nEditorConfigKey);
        }
    }

    public async loadEmbeddedTranslationEditor(): Promise<void> {
        if (this.config.editorDeployment === "embedded") {
            await import(
                /* webpackChunkName: 'i18n-editor' */ "@hediet/i18n-frontend/dist/embedded-editor/index.css"
            );
            await import(
                /* webpackChunkName: 'i18n-editor' */ "@hediet/i18n-frontend/dist/embedded-editor/index.js"
            );
        } else {
            loadScript(this.config.editorDeployment.url);
        }
    }
}

function loadScript(url: string) {
    const pluginScript = document.createElement("script");
    pluginScript.type = "text/javascript";
    pluginScript.src = url;
    document.getElementsByTagName("head")[0].appendChild(pluginScript);
}
