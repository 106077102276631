import Box from "@FEShared/components/UI/Box/Box";
import styled, { css } from "styled-components";

export const ContentContainer = styled(Box)<{
    $insidePadding?: boolean;
    $noMobilePadding?: boolean;
    $fullWidth?: boolean;
}>`
    max-width: ${(p) =>
        p.$fullWidth ? "100%" : p.theme.sizes.pageContentWidth};
    margin: 0 auto;
    width: 100%;

    ${(p) =>
        p.$fullWidth &&
        css`
            padding-left: 32px;
            padding-right: 32px;
        `}

    ${(p) =>
        p.$insidePadding &&
        css`
            padding: ${p.theme.padding.contentPadding};
        `}

    ${(p) => p.theme.breakpoints.down("lg")} {
        && {
            width: calc(100% - ${(p) => p.theme.padding.contentPadding}*2);
        }
    }
    ${(p) => p.theme.breakpoints.down("md")} {
        ${(p) =>
            p.$noMobilePadding &&
            css`
                && {
                    width: 100%;
                }
            `}
    }
`;
