import styled from "styled-components";

export const PartnerImgGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    ${(p) => p.theme.breakpoints.down("lg")} {
        grid-template-columns: repeat(2, 1fr);
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        grid-template-columns: 1fr;
    }
`;

export const Review = styled("span")`
    font-size: 18px;
    color: #ffffff;

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-left: 80px;
    }
`;
