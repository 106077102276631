import Chip from "@mui/material/Chip/Chip";
import styled, { css } from "styled-components";
import Input from "../Input/Input";

export const InputWrapper = styled("div")<{ $reserveMoreSpace?: boolean }>`
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 10px;
    ${(p) =>
        p.$reserveMoreSpace &&
        css`
            min-height: 80px;
        `}
`;

export const ExpansionInput = styled(Input)<{
    $isChipsInput?: boolean;
    $fakePlaceholder?: boolean;
}>`
    width: 100%;
    margin-bottom: 0px;
    cursor: pointer;

    .MuiInputBase-root {
        cursor: pointer;
        color: black;
    }

    .MuiInputBase-input {
        color: black !important;
        -webkit-text-fill-color: black !important;
        width: auto;
        position: relative;
        cursor: pointer;
        padding-left: 1px;
        flex: 1;

        ${(p) =>
            p.$fakePlaceholder &&
            css`
                background-position: left center;
                background-repeat: no-repeat;
                background-image: linear-gradient(black, black);
                background-size: 1px 20px;

                @keyframes blink {
                    0% {
                        background-image: linear-gradient(black, black);
                    }
                    100% {
                        background-image: none;
                    }
                }
                animation: blink 1s linear infinite;
            `}

        .MuiInputBase-root {
            color: black !important;
        }
    }
`;

export const ExpansionValueChip = styled(Chip)`
    margin: 3px;
`;
