import { Fade } from "@mui/material";
import React from "react";
import * as S from "./LoaderScreen.styled";
import Country from "@Shared/types/enums/Country";

const LoaderScreen: React.FC<{ show: boolean }> = (p) => {
    return (
        <Fade
            in={p.show}
            timeout={{
                exit: 500,
                appear: 0,
            }}
        >
            <S.LoaderContainer>
                <S.LoaderLogo $repairPunk={window._COUNTRY === Country.CA} />
                <S.Loader />
            </S.LoaderContainer>
        </Fade>
    );
};

export default LoaderScreen;
