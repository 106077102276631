import { transStr } from "@FEShared/i18n";
import showToast from "@FEShared/utils/showToast";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import React from "react";
import { Redirect } from "react-router-dom";

const ClientOnly: React.FC = () => {
    React.useEffect(() => {
        showToast.warn(
            transStr(
                "Šis puslapis skirtas tik klientui, todėl buvote nukreiptas į pagrindinį puslapį.",
                { id: "MYrBtZrV" }
            )
        );
    }, []);
    return <Redirect to={countryClientPageToMeta(window._COUNTRY).HOME.url} />;
};

export default ClientOnly;
