import { SwipeableDrawer } from "@mui/material";
import styled from "styled-components";
import BackButton from "../BackButton/BackButton";
import Button from "../Button/Button";

export const Drawer = styled(SwipeableDrawer)`
    box-shadow: 0px -200px 0px 0px rgb(0 0 0 / 70%);

    .MuiPaper-root {
        height: calc(100% - ${(p) => p.theme.sizes.spaceBetweenDrawerAndTop});
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        padding: 8px 16px;
        padding-bottom: 0;
        overflow: hidden;
    }
`;

export const DrawerContent = styled("div")`
    overflow-y: auto;
    flex: 1;
    padding-bottom: 16px;
`;

export const Head = styled("div")`
    margin-bottom: 8px;
    display: flex;
`;

export const HeadBackButton = styled(BackButton)`
    height: 40px;
    width: 40px;
    min-width: unset;
    text-align: center;
    padding: 0;
`;

export const ConfirmBtn = styled(Button)`
    margin-left: auto;
    height: 40px;
`;
