import React from "react";
import * as S from "./NavBar.styled";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import useStore from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import { transStringFunctionalFE } from "@FEShared/i18n";
import { translatable } from "@Shared/i18n/i18n";
import Country from "@Shared/types/enums/Country";

export const NAV_ITEMS = [
    {
        name: translatable("100% Darbų garantija", { id: "iyFjPZcT" }),
        url: countryClientPageToMeta(window._COUNTRY).WARRANTY.url,
        showOnDesktop: window._COUNTRY === Country.LT,
    },
    {
        name: translatable("Autoservisams", { id: "zpvLa5Ki" }),
        url: countryClientPageToMeta(window._COUNTRY).BECOME_PARTNER.url,
        showOnDesktop: window._COUNTRY === Country.CA,
    },
    {
        name: translatable("Autoserviso paieška", { id: "HJAEBxs7" }),
        url: countryClientPageToMeta(window._COUNTRY).SEARCH.url,
    },
];

const NavBar: React.FC<{
    isSideBar?: boolean;
}> = observer((p) => {
    const GS = useStore();
    return (
        <S.NavBarContainer $page={GS.activePage}>
            {NAV_ITEMS.filter((i) => {
                if (p.isSideBar) {
                    return true;
                } else {
                    if (i.showOnDesktop) {
                        return true;
                    }
                }
                return false;
            }).map((item) => {
                return (
                    <S.NavBarLink key={item.url} to={item.url}>
                        {transStringFunctionalFE(item.name)}
                    </S.NavBarLink>
                );
            })}
        </S.NavBarContainer>
    );
});

export default NavBar;
