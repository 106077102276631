import React from "react";
import ContentContainer from "../../commonComps/ContentContainer/ContentContainer";

const SeoServices_LT: React.FC = () => {
    return (
        <ContentContainer>
            <h1>Automobilių serviso paslaugos</h1>
            <p>
                Sugedo automobilis? Reikia periodinio aptarnavimo? Autoserviso
                paie&scaron;ką pradėkite čia!
            </p>
            <p>
                Nesvarbu ar reikalingas automobilio remontas, periodinis
                aptarnavimas, sezoninis ratų montavimas ar kondicionieriaus
                pildymas, visoms paslaugoms jūsų automobiliui autoservisą galite
                rasti Nevaziuoja.lt portale.
            </p>
            <h3>Autoservisų siūlomos paslaugos</h3>
            <p>
                Nevaziuoja.lt į savo portalą pritraukė įvairių sričių
                specialistus ir autoservisus, kurie gali atlikti
                prakti&scaron;kai bet kokius automobilio remonto darbus. Todėl
                jei reikia atlikti žemiau paminėtus darbus, čia tikrai rasite ne
                vieną autoservisą, pasirengusį suteikti jums kokybi&scaron;kas
                paslaugas jūsų pasirinktu laiku.
            </p>
            <p>
                Dažniausiai reikalingos remonto, diagnostikos bei aptarnavimo
                paslaugos:
            </p>
            <ul>
                <li>
                    <a href="/tepalu-keitimas">Tepalų keitimas</a>
                </li>
                <li>
                    <a href="/padangu-ratu-montavimas">
                        Padangų/ratų montavimas
                    </a>
                </li>
                <li>
                    <a href="/vaziuokles-pakabos-patikra">
                        Važiuoklės (pakabos) patikra
                    </a>
                </li>
                <li>
                    <a href="/vaziuokles-pakabos-remontas">
                        Važiuoklės (pakabos) remontas
                    </a>
                </li>
                <li>
                    <a href="/stabdziu-remontas">Stabdžių remontas</a>
                </li>
                <li>
                    <a href="/stabdziu-kaladeliu-keitimas">
                        Stabdžių kaladėlių keitimas
                    </a>
                </li>
                <li>
                    <a href="/stabdziu-disku-keitimas">
                        Stabdžių diskų keitimas
                    </a>
                </li>
                <li>
                    <a href="/automobilio-patikra">Automobilio patikra</a>
                </li>
                <li>
                    <a href="/kondicionieriu-remontas">
                        Kondicionierių remontas
                    </a>
                </li>
                <li>
                    <a href="/kondicionieriaus-pildymas">
                        Kondicionieriaus pildymas
                    </a>
                </li>
                <li>
                    <a href="/automatines-pavaru-dezes-tepalu-keitimas">
                        Automatinės pavarų dėžės tepalų keitimas
                    </a>
                </li>
                <li>
                    <a href="/zibintu-reguliavimas">Žibintų reguliavimas</a>
                </li>
                <li>
                    <a href="/zibintu-remontas">Žibintų remontas</a>
                </li>
                <li>
                    <a href="/variklio-diagnostika">Variklio diagnostika</a>
                </li>
                <li>
                    <a href="/variklio-remontas">Variklio remontas</a>
                </li>
            </ul>
            <h3>Svarbu rinktis kokybi&scaron;kas paslaugas</h3>
            <p>
                &Scaron;iuolaikiniame pasaulyje automobilis yra būtinybė. Tas
                pats pasakytina ir apie jo remontą. Reguliari techninė patikra
                ir remontas yra labai reikalingi, kad jūsų automobilis veiktų
                tvarkingai bei jaustumėtės saugūs kelyje.
            </p>
            <p>
                Nepaisant to, rasti patikimą autoservisą ne visada taip
                paprasta. Nesunku susipainioti kai aplink tiek autoservisų
                siūlančių įvairias paslaugas jūsų automobiliui. Todėl
                Nevaziuoja.lt siekia palengvinti &scaron;ią užduotį bei surinkti
                visus kokybi&scaron;kas paslaugas teikiančius ir gerus
                atsiliepimus turinčius autoservisus į vieną vietą. Taip jūs
                galite lengviau i&scaron;sirinkti autoservisą, rezervuoti laiką
                patikrai ar remontui.
            </p>
            <h3>Reguliari patikra</h3>
            <p>
                Reguliariai prižiūrint automobilį, atliekant periodinius
                patikrinimus ir techninį aptarnavimą, jums nereikės bijoti
                netikėtų gedimų bei nuolat automobilio remontuoti, todėl sumažės
                nereikalingos i&scaron;laidos. Atlikite savo automobilio
                techninę priežiūrą, reguliariai ir laiku keiskite automobilio
                alyvą, patikrinkite au&scaron;inimo skystį, stabdžius,
                sezoni&scaron;kai keiskite bei subalansuokite ratus.
            </p>
            <p>
                Atkreipkite dėmesį į padangų slėgį, ypač keičiantis orams.
                Įsitikinkite, kad slėgis tinkamas, nes tai būtina saugiam
                važiavimui, degalų taupymui ir padangų tarnavimo laikui
                užtikrinti.
            </p>
            <p>
                Įsitikinkite, kad &scaron;ias paslaugas reguliariai atliks
                profesionalus technikas. Tai jums kainuos pigiau nei automobilio
                remontas &scaron;ių darbų neatlikus. Be to, periodi&scaron;kas
                techninis automobilio patikrinimas bei diagnostika yra ne tik
                ekonomi&scaron;ka, bet ir lengva užduotis, todėl neužima daug
                brangaus jūsų laiko.
            </p>
            <p>&nbsp;</p>
        </ContentContainer>
    );
};

export default SeoServices_LT;
