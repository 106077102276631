import React from "react";
import DatePicker, {
    DatePickerP,
} from "@FEShared/components/UI/DatePicker/DatePicker";
import _uniq from "lodash/uniq";
import ServicesAutocomplete from "@FEShared/components/UI/ServicesAutocomplete/ServicesAutocomplete";
import useStore from "@FEClient/logic/store";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import InputsGroup from "@FEShared/components/UI/InputsGroup/InputsGroup";
import Dropdown from "@FEShared/components/UI/Dropdown/Dropdown";
import { CITIES_WITH_ALL } from "@Shared/consts/CITIES";
import { PickPartial } from "@Shared/types/utils";
import { DateObj } from "@Shared/types/types";
import mergeServicesWithPackages from "@Shared/util/mergeServicesWithPackages";
import CarAutocomplete from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete";
import { transStringFunctionalFE } from "@FEShared/i18n";
import mapKeyBy from "@Shared/util/mapKeyBy";
import { transStr } from "@FEShared/i18n";

export const CITY_DROPDOWN_CLASSNAME = "CITY_DROPDOWN";
export const CITY_DROPDOWN_WRAPPER_CLASSNAME =
    "CITY_DROPDOWN_WRAPPER_CLASSNAME";

// tbd every search input props should be moved into it's own object
const SearchInputs: React.FC<{
    horizontal?: boolean;
    className?: string;
    datePicker?: PickPartial<
        DatePickerP,
        | "calendar"
        | "city"
        | "blockedTimeslots"
        | "earliestAvailableDate"
        | "employees"
        | "fnsRef"
        | "wantedServices"
        | "showImmediateCheckbox"
        | "dateObj"
        | "onDateChange"
        | "lunchCalendar"
    >;
    onClose?: () => void;
    forceErrors?: boolean;
    showInputErrors?: boolean;
    allInputsRequired?: boolean;
    carsAutocompleteFnsRef?: React.RefObject<{ open: () => void }>;
    allowedCarBrands?: string[];
    visibleInputs?: {
        carPicker?: boolean;
        servicesPicker?: boolean;
        datePicker?: boolean;
        cityPicker?: boolean;
    };
}> = observer((p) => {
    const GS = useStore();
    const LS = useLocalObservable(() => ({
        onServicesAutocompleteChange(
            selectedServicesTranslatedNames: string[]
        ) {
            const searchServicesMapByTransName = mapKeyBy(
                GS.searchState.searchServicesOptions,
                (s) => transStringFunctionalFE(s.serviceName)
            );

            const servicesDefinitionsIDs = selectedServicesTranslatedNames
                .map((translServiceName) => {
                    const selectedService =
                        searchServicesMapByTransName.get(translServiceName);
                    if (!selectedService) {
                        return console.error(
                            `Didn't find matching service with transl name: ${translServiceName}`
                        );
                    }
                    return selectedService.ID;
                })
                .filter(Boolean) as string[];

            GS.searchState.selectedServicesDefinitionsIDs =
                servicesDefinitionsIDs;
        },
    }));

    const servicesOptions = React.useMemo(() => {
        const mergedServicesWithPackages = mergeServicesWithPackages({
            services: GS.searchState.searchServicesOptions,
            packages: GS.searchState.servicePackages,
        });
        return mergedServicesWithPackages;
    }, [GS.searchState.searchServicesOptions, GS.searchState.servicePackages]);

    const togglePageScroll = React.useCallback(
        (isEnabled: boolean) => {
            GS.togglePageScroll(isEnabled);
        },
        [] //eslint-disable-line react-hooks/exhaustive-deps
    );

    const onDateChange = React.useCallback(
        (dateObj: DateObj) => {
            runInAction(() => {
                GS.searchState.date.obj = {
                    ...GS.searchState.date.obj,
                    ...dateObj,
                };
            });
        },
        [GS.searchState.date]
    );

    return (
        <InputsGroup className={p.className} horizontal={p.horizontal}>
            {p.visibleInputs?.servicesPicker && (
                <ServicesAutocomplete
                    forceInputErr={p.forceErrors}
                    services={servicesOptions}
                    value={GS.searchState.selectedServicesTransNames}
                    onChange={LS.onServicesAutocompleteChange}
                    onClose={p.onClose}
                    errorsEnabled={p.allInputsRequired}
                />
            )}
            {p.visibleInputs?.carPicker && (
                <CarAutocomplete
                    fnsRef={p.carsAutocompleteFnsRef}
                    allowedCarBrands={p.allowedCarBrands}
                    forceInputErr={p.forceErrors}
                    value={GS.searchState.carDataArr}
                    onChange={(val) => {
                        runInAction(() => {
                            GS.searchState.carDataArr = val;
                        });
                    }}
                    onClose={p.onClose}
                    placeholder={
                        p.allInputsRequired
                            ? transStr("Automobilis *", { id: "HRaI1Iug" })
                            : undefined
                    }
                    errorsEnabled={p.allInputsRequired}
                />
            )}
            {p.visibleInputs?.datePicker && (
                <DatePicker
                    wantedServices={p.datePicker?.wantedServices}
                    lunchCalendar={p.datePicker?.lunchCalendar}
                    blockedTimeslots={p.datePicker?.blockedTimeslots}
                    fnsRef={p.datePicker?.fnsRef}
                    disablePortal={false}
                    earliestAvailableDate={
                        p.datePicker?.earliestAvailableDate ||
                        GS.searchState.earliestAvailableTime ||
                        new Date()
                    }
                    employees={p.datePicker?.employees}
                    dateObj={p.datePicker?.dateObj || GS.searchState.date.obj}
                    onDateChange={p.datePicker?.onDateChange || onDateChange}
                    togglePageScroll={togglePageScroll}
                    forceInputError={p.forceErrors}
                    onClose={p.onClose}
                    placeholder={
                        p.allInputsRequired
                            ? transStr("Data ir laikas *", { id: "7RMCzn6T" })
                            : undefined
                    }
                    calendar={p.datePicker?.calendar}
                    city={p.datePicker?.city}
                    errorsEnabled={p.allInputsRequired}
                    showImmediateCheckbox={
                        p.datePicker?.showImmediateCheckbox ||
                        GS.searchState.showImmediateCheckbox()
                    }
                    immediateValChange={GS.searchState.immediateValChange}
                    immediateValue={GS.searchState.immediate}
                />
            )}
            {p.visibleInputs?.cityPicker && (
                <Dropdown
                    id="city-picker"
                    className={CITY_DROPDOWN_WRAPPER_CLASSNAME}
                    menuClassName={CITY_DROPDOWN_CLASSNAME}
                    leftIconClass="icon-pin"
                    placeholder={transStr("Miestas", { id: "zdPxtM2e" })}
                    value={GS.searchState.city}
                    onChange={(e) => {
                        runInAction(() => {
                            GS.searchState.city = e.target
                                .value as typeof GS.searchState.city;
                        });
                        if (p.onClose) p.onClose();
                    }}
                    fullWidth
                    options={CITIES_WITH_ALL[window._COUNTRY].map((c) => ({
                        value: c,
                        text: c,
                    }))}
                />
            )}
        </InputsGroup>
    );
});

SearchInputs.defaultProps = {
    visibleInputs: {
        carPicker: true,
        servicesPicker: true,
        datePicker: true,
        cityPicker: true,
    },
};

export default SearchInputs;
