import Box from "@FEShared/components/UI/Box/Box";
import { Skeleton } from "@mui/material";
import React from "react";

const ListItemSkeleton: React.FC = () => {
    return (
        <Box displayFlex width={1} alignVertical="top" mb={2}>
            <Skeleton
                variant="rectangular"
                height={192}
                width={192}
                sx={{ mr: 2, minWidth: 192, borderRadius: "15px 0 0 15px" }}
            />
            <Box vertical width={1} padding={"24px"} height={192}>
                <Box displayFlex>
                    <Skeleton height={19} width={"25%"} />
                    <Skeleton height={19} width={50} sx={{ ml: "auto" }} />
                </Box>
                <Skeleton height={19} width={"30%"} sx={{ mt: 1 }} />
                <Box displayFlex alignVertical="bottom" sx={{ mt: "auto" }}>
                    <Box>
                        <Skeleton height={19} width={50} />
                        <Skeleton height={19} width={100} />
                    </Box>
                    <Skeleton
                        variant="rounded"
                        width={130}
                        height={51}
                        sx={{ ml: "auto" }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default ListItemSkeleton;
