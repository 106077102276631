import { transStringFunctionalFE } from "@FEShared/i18n";
import {
    PART_CATEGORIES_TRANSLATABLES,
    SUB_CATEGORIES_TRANSLATABLES,
} from "@Shared/consts/SHARED_TRANSLATABLES";
import mapKeyBy from "@Shared/util/mapKeyBy";

/* Returns Map<string (translated name), Translatable> */
export default function getTransNameToTranslatableMap() {
    return {
        partCategory: mapKeyBy(PART_CATEGORIES_TRANSLATABLES, (partCateg) =>
            transStringFunctionalFE(partCateg)
        ),
        subCategory: mapKeyBy(SUB_CATEGORIES_TRANSLATABLES, (subCateg) =>
            transStringFunctionalFE(subCateg)
        ),
    };
}
