import {
    PriceObj,
    ServiceDataForWorkshopPriceNDuration,
} from "@Shared/types/types";
import { ServicePriceType } from "../graphql/generated/graphql";
import { transStringFunctionalFE } from "@FEShared/i18n";

export default function getServicePriceObj(
    service: ServiceDataForWorkshopPriceNDuration,
    hourCost: number,
    showAverage?: boolean
): PriceObj {
    const priceObj: PriceObj = {
        type: ServicePriceType.Fixed,
        fixedPrice: 0,
        fromPrice: 0,
        toPrice: 0,
        price: 0,
        hourlyPrice: 0,
        defaultUnit: undefined as undefined | string,
        partsPrice: 0,
    };

    priceObj.hourlyPrice = hourCost;

    if (service.type.priceType === ServicePriceType.Hourly) {
        priceObj.type = ServicePriceType.Hourly;
        priceObj.price = priceObj.hourlyPrice;
        // dont sum up anything
    } else if (service.fixedPrice) {
        priceObj.fixedPrice = service.fixedPrice;
        priceObj.price = priceObj.fixedPrice;
        priceObj.type = ServicePriceType.Fixed;
    } else if (service.fromPrice && service.toPrice) {
        priceObj.fromPrice = service.fromPrice;
        priceObj.toPrice = service.toPrice;
        priceObj.price = showAverage
            ? Math.floor((priceObj.fromPrice + priceObj.toPrice) / 2)
            : priceObj.fromPrice;
        priceObj.type = ServicePriceType.FromTo;
    } else if (service.fromPrice) {
        // Below cases should pretty much never happen anymore after implementation of AI price estimates.
        priceObj.fromPrice = service.fromPrice;
        priceObj.price = priceObj.fromPrice;
        priceObj.type = ServicePriceType.From;
    } else if (service.durationFromMins) {
        priceObj.fromPrice = Math.ceil(
            (service.durationFromMins / 60) * hourCost
        );
        priceObj.price = priceObj.fromPrice;
    } else {
        priceObj.fromPrice = Math.ceil(
            (service.type.durationMinsPerUnit / 60) * hourCost
        );
        priceObj.type = ServicePriceType.From;
        priceObj.price = priceObj.fromPrice;
    }
    priceObj.partsPrice = service.partsPrice || 0;
    priceObj.defaultUnit = service.type.defaultUnit
        ? transStringFunctionalFE(service.type.defaultUnit)
        : undefined;

    return priceObj;
}
