import React from "react";
import * as WS from "../Workshop.styled";
import Box from "@FEShared/components/UI/Box/Box";
import CATALOGS_IMG from "./assets/catalogs.png";
import HISTORY_IMG from "./assets/history.png";
import WORKSHOP_IMG from "./assets/workshop.png";
import Text from "@FEShared/components/UI/Text/Text";
import Icon from "@FEShared/components/UI/Icon/Icon";
import { TransMsg, transStr } from "@FEShared/i18n";

const PriceSection = () => {
    const BOXES = [
        {
            img: WORKSHOP_IMG,
            text: transStr("Autoserviso pateiktos kainos", { id: "EIeoXvU2" }),
        },
        {
            img: CATALOGS_IMG,
            text: transStr("Praeities užsakymų kainos", { id: "EUODfziI" }),
        },
        {
            img: HISTORY_IMG,
            text: transStr("Dalių ir remontų duombazės", { id: "wDzc5iiA" }),
        },
        // {
        //     img: AI_IMG,
        //     text: "Dirbtinis intelektas",
        // },
    ];

    return (
        <WS.WorkshopSection $marginBottom={32}>
            <WS.ServicesTitleWrap>
                <WS.PurpleVerticalBar />
                <WS.ServicesTitleSubWrap>
                    {/* <WS.ServicesSubTitle>
                                Ieškai daugiau?
                            </WS.ServicesSubTitle> */}
                    <WS.ServicesTitle>
                        <TransMsg default={"Kainų nustatymas"} id="IIHGHzVo" />
                    </WS.ServicesTitle>
                </WS.ServicesTitleSubWrap>
            </WS.ServicesTitleWrap>
            <Text mb={1} fontSize={12}>
                <TransMsg
                    default={
                        "Preliminarios kainos gaunamos naudojant autoserviso pateiktas kainas, praeities užsakymų duomenis, dalių ir remontų duomenų bazes pasirinktam automobiliui. Galutinė kaina gali kisti, priklausomai nuo automobilio būklės, remonto sudėtingumo ar pasirinktų dalių."
                    }
                    id="Ap0IyGZK"
                />
            </Text>
            <Box displayFlex alignItems="center">
                {BOXES.map((box, index) => (
                    <>
                        <Box
                            key={box.text}
                            displayFlex
                            bgcolor="#F0F2F8"
                            rounded
                            padding={1.5}
                            flex={1}
                            sx={(theme) => ({
                                [theme.breakpoints.down("md")]: {
                                    flexDirection: "column",
                                    alignItems: "center",
                                    // maxWidth: 68,
                                },
                            })}
                        >
                            <Box
                                width="40px"
                                height="40px"
                                displayFlex
                                sx={(theme) => ({
                                    mr: 1,
                                    [theme.breakpoints.down("md")]: {
                                        mb: 1,
                                        mr: 0,
                                    },
                                })}
                            >
                                <img src={box.img} />
                            </Box>
                            <Text
                                semiBold
                                fontSize={14}
                                sx={(theme) => ({
                                    [theme.breakpoints.down("md")]: {
                                        fontSize: 12,
                                        textAlign: "center",
                                    },
                                })}
                            >
                                {box.text}
                            </Text>
                        </Box>
                        {index !== BOXES.length - 1 && (
                            <Icon
                                fontSize={20}
                                className="icon-plus"
                                color="#B6B9C6"
                                ml={1}
                                mr={1}
                            />
                        )}
                    </>
                ))}
            </Box>
        </WS.WorkshopSection>
    );
};

export default PriceSection;
