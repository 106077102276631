import React from "react";
import * as S from "./Sidebar.styled";
import { runInAction } from "mobx";
import NavItem from "./NavItem/NavItem";
import { NAV_ITEMS } from "../Header/NavBar/NavBar";
import { observer } from "mobx-react-lite";
import useStore from "@FEClient/logic/store";
import { InternalLink } from "@FEShared/components/InternalLink/InternalLink.styled";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import { transStringFunctionalFE } from "@FEShared/i18n";

const Sidebar: React.FC = observer(() => {
    const GS = useStore();
    return (
        <S.SideBarContainer
            open={GS.showSidebar}
            onClose={() =>
                runInAction(() => {
                    GS.showSidebar = false;
                })
            }
        >
            <InternalLink
                to={countryClientPageToMeta(window._COUNTRY).HOME.url}
                onClick={() => {
                    runInAction(() => {
                        GS.showSidebar = false;
                    });
                }}
            >
                <S.Logo />
            </InternalLink>
            {NAV_ITEMS.map((i) => (
                <NavItem
                    key={i.url}
                    url={i.url}
                    name={transStringFunctionalFE(i.name)}
                />
            ))}
        </S.SideBarContainer>
    );
});

export default Sidebar;
