import capFirst from "./capFirst";
import LangCode from "@Shared/types/enums/LangCode";
import pickByLangCode from "./pickByLangCode";

export const getUnknownName = (langCode: LangCode) =>
    pickByLangCode(langCode, {
        en: "Initials hidden",
        lt: "Vardas nenurodytas",
    });

/** Return "Name S." format of name */
export default function formatReviewName(
    fullName: string,
    langCode: LangCode
): string {
    if (fullName === getUnknownName(langCode)) return fullName;

    return fullName
        .trim()
        .toLowerCase()
        .split(" ")
        .slice(0, 2)
        .map((namePart) => namePart.trim())
        .filter(Boolean) // clear empty strings
        .map((namePart, i) =>
            i > 0
                ? `${namePart.slice(0, 1).toUpperCase()}.`
                : capFirst(namePart)
        )
        .join(" ");
}
