import { transStr, transStringFunctionalFE } from "@FEShared/i18n";
import { translatable } from "@Shared/i18n/i18n";
import { toast } from "react-toastify";

export const TOAST_CLASSNAME = "Toastify";

const UNEXPECTED_ERROR_TEXT = translatable(
    "Jeigu klaida kartosis susisiekite su nevažiuoja administracija",
    { id: "DSZGLFMF" }
);

function createToast(
    type: "warn" | "error" | "info" | "success",
    message: string
) {
    let adjustedMsg: string;
    if (message.toLowerCase().includes("failed to fetch")) {
        adjustedMsg = transStr(
            "Įvyko netikėta klaida. Klaidas kodas: #DC. {UNEXPECTED_ERROR_TEXT}",
            { data: { UNEXPECTED_ERROR_TEXT }, id: "78PwIkvF" }
        );
    } else {
        adjustedMsg = transStringFunctionalFE(message);
    }

    adjustedMsg = adjustedMsg.replace("[GraphQL]", ""); // [GraphQL] part is added by the FE GQL lib.

    // TBD: Add logging for every error and warning into here.
    toast[type](adjustedMsg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
    });
}

const showToast = {
    error: (message: string, breakingError?: boolean) => {
        if (breakingError) console.error(message);

        createToast(
            "error",
            [
                message,
                breakingError && transStringFunctionalFE(UNEXPECTED_ERROR_TEXT),
            ]
                .filter(Boolean)
                .join(". ")
        );
    },
    warn: (message: string) => createToast("warn", message),
    info: (message: string) => createToast("info", message),
    success: (message: string) => createToast("success", message),
};

export default showToast;
