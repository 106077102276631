export default function createAdjustedBounds(
    bounds: google.maps.LatLngBounds,
    factor: number
) {
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    const north = northEast.lat();
    const east = northEast.lng();
    const south = southWest.lat();
    const west = southWest.lng();

    const centerLat = (north + south) / 2;
    const centerLng = (east + west) / 2;

    const newNorth = centerLat + (north - centerLat) * factor;
    const newSouth = centerLat + (south - centerLat) * factor;
    const newEast = centerLng + (east - centerLng) * factor;
    const newWest = centerLng + (west - centerLng) * factor;

    const newBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(newSouth, newWest),
        new google.maps.LatLng(newNorth, newEast)
    );

    return newBounds;
}
