import { Wrapper } from "@googlemaps/react-wrapper";
import React from "react";
import GoogleMap from "./GoogleMap/GoogleMap";

const GoogleMapMinimal = (p: {
    zoom: number;
    lat: number;
    lng: number;
    className?: string;
}) => (
    <Wrapper
        apiKey={
            window._IS_PROD
                ? "AIzaSyDUaz1Wnv_95NR6Dcm9s24jV_VvaSX0zv0"
                : "AIzaSyAQXWud2x-ccHSAILkHn8Rrz_AZoLQ79_g"
        }
    >
        <GoogleMap
            zoom={p.zoom}
            servicePos={{
                lat: p.lat,
                lng: p.lng,
            }}
            className={p.className}
        />
    </Wrapper>
);

export default GoogleMapMinimal;
