import React from "react";
import TV3_IMG from "./assets/tv3.png";
import LRYTAS_IMG from "./assets/lrytas.png";
import KAS_VYKSTA_KAUNE_IMG from "./assets/kasVykstaKaune.svg";
import STARTUP_FAIR from "./assets/startup-fair.png";
import MADE_IN_VILNIUS from "./assets/madeinvilnius.webp";
import * as S from "./NewsPortals.styled";
import Box from "@FEShared/components/UI/Box/Box";
import ContentContainer from "@FEClient/views/commonComps/ContentContainer/ContentContainer";

const NewsPortals = () => {
    return (
        <Box bgcolor="#DDDFE8" pt={3} pb={3}>
            <ContentContainer>
                <Box displayFlex justifyContent={"space-between"}>
                    <S.Img src={LRYTAS_IMG} alt="Lrytas" />
                    <S.Img src={TV3_IMG} alt="TV3" />
                    <S.Img src={KAS_VYKSTA_KAUNE_IMG} alt="KasVykstaKaune" />
                    <S.Img src={STARTUP_FAIR} alt="StartupFair" />
                    <S.Img src={MADE_IN_VILNIUS} alt="MadeInVilnius" />
                </Box>
            </ContentContainer>
        </Box>
    );
};

export default NewsPortals;
