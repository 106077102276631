import { Calendar } from "@Shared/types/calendar";
import isNullableValue from "./isNullableValue";

export default function getCalendarMinMaxHours(calendar?: Calendar): {
    minHour: number;
    maxHour: number;
} {
    if (!calendar)
        return {
            minHour: 7,
            maxHour: 19,
        };
    if (
        Object.values(calendar).filter((v) => !!v && typeof v === "object")
            .length === 0
    )
        return {
            minHour: 7,
            maxHour: 19,
        };

    const closeHours: number[] = Object.values(calendar)
        .map((day) => {
            return day && typeof day === "object"
                ? day.close.minute > 0
                    ? day.close.hour + 1
                    : day.close.hour
                : undefined;
        })
        .filter((v) => !isNullableValue(v)) as number[];

    const openHours: number[] = Object.values(calendar)
        .map((day) => {
            return day && typeof day === "object" ? day.open.hour : undefined;
        })
        .filter((v) => !isNullableValue(v)) as number[];

    const minHour = Math.min(...openHours);
    const maxHour = Math.max(...closeHours);

    return {
        minHour: minHour,
        maxHour: Math.min(maxHour, 23),
    };
}
