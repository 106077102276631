import { Calendar, CalendarDay } from "@FEShared/graphql/generated/graphql";
import { Weekday } from "@Shared/types/calendar";
import BusinessDateHandler, {
    NotAvailableReason,
} from "@Shared/util/BusinessDateHandler";
import { DateTime } from "luxon";
import { TimeSlot } from "./DatePicker.types";
import { dateObjToDate } from "@Shared/util/dateFormat";
import getCalendarMinMaxHours from "@Shared/util/getCalendarMinMaxHours";

const timeslotsCache = new Map<string, string[]>();

// keep this here instead of .consts file, otherwise circular dependency and timeslotsCache errors
export const TIME_SLOTS = generateTimes(
    window._COUNTRY === "LT"
        ? {
              minHour: 7,
              maxHour: 19,
          }
        : {
              minHour: 0,
              maxHour: 23,
          }
);

export function generateTimes(
    p: { calendar: Calendar } | { minHour: number; maxHour: number }
): string[] {
    let minHour: number, maxHour: number;
    const cacheKey = JSON.stringify(p);

    if (timeslotsCache) {
        const cacheVal = timeslotsCache.get(cacheKey);
        if (cacheVal) {
            return cacheVal;
        }
    }

    if ("calendar" in p) {
        const res = getCalendarMinMaxHours(p.calendar);
        minHour = res.minHour;
        maxHour = res.maxHour;
    } else {
        minHour = p.minHour;
        maxHour = p.maxHour;
    }

    const timeSlots: string[] = [];
    for (let i = minHour; i < maxHour; i++) {
        for (let j = 0; j < 4; j++) {
            timeSlots.push(
                `${i.toString().padStart(2, "0")}:${(j * 15)
                    .toString()
                    .padStart(2, "0")}`
            );
        }
    }
    timeslotsCache.set(JSON.stringify(cacheKey), timeSlots);
    return timeSlots;
}

export function getCalendarDay(
    date: Date,
    calendar: Calendar
): CalendarDay | undefined {
    const weekday = date
        .toLocaleDateString("en-EN", { weekday: "long" })
        .toLowerCase() as Weekday;
    const calendarDay = calendar[weekday];
    return calendarDay as CalendarDay | undefined;
}

export function filterTimes(
    /* Calendar will be used to generate all the timeslots. */
    p:
        | {
              YYYY_MM_DD: string;
              earliestAvailableDate: Date;
              /* find atleast one free timelot and stop */
              findOne?: boolean;
              calendar: Calendar | undefined;
          }
        | {
              YYYY_MM_DD: string;
              bizDateHandler: BusinessDateHandler;
              earliestAvailableDate?: Date;
              clientWillWait?: boolean;
              findOne?: boolean;
              calendar: Calendar | undefined;
          }
): TimeSlot[] {
    const filteredTimeslots: TimeSlot[] = [];

    // a quick heuristic to check if this day should have any timepicker slots at all and avoid uneccessary calculations.
    if (
        "bizDateHandler" in p &&
        !p.bizDateHandler.isDTBusinessDay(
            DateTime.fromJSDate(
                dateObjToDate(
                    {
                        YYYY_MM_DD: p.YYYY_MM_DD,
                        HH_MM: "12:00", // The time doesnot matter at all, since are just checking if this is a business day.
                    },
                    window._COUNTRY_META.defaultTimezone
                )
            )
        )
    ) {
        return [];
    }
    const timeSlots = p.calendar
        ? generateTimes({ calendar: p.calendar })
        : TIME_SLOTS;

    for (const t of timeSlots) {
        const thisDT = DateTime.fromISO(`${p.YYYY_MM_DD}T${t}`, {
            zone: window._COUNTRY_META.defaultTimezone, // TBD this will have to be adjusted by city, when there will be multiple timezones in country;
        });
        const thisTimeDate = thisDT.toJSDate();

        if ("bizDateHandler" in p) {
            const isAfterEarliestAvlDate = p.earliestAvailableDate
                ? thisTimeDate.getTime() >= p.earliestAvailableDate.getTime()
                : true;

            if (!isAfterEarliestAvlDate) {
                // filteredTimeslots.push({
                //     time: t,
                //     enabled: false,
                // });
                continue;
            }

            const isAvailableRes = p.bizDateHandler.isAvailableForServices(
                DateTime.fromJSDate(thisTimeDate),
                {
                    immediateReservation: p.clientWillWait,
                }
            );

            if (isAvailableRes.isAvailable) {
                filteredTimeslots.push({
                    time: t,
                    enabled: true,
                });
            } else if (
                [
                    NotAvailableReason.LUNCH,
                    NotAvailableReason.BLOCKED_TIMESLOT,
                ].includes(isAvailableRes.reason)
            ) {
                filteredTimeslots.push({
                    time: t,
                    enabled: false,
                });
            }
            // if its disabled and not one of the reason we should show for, just filter it out completely.
        } else if (
            thisTimeDate.getTime() >= p.earliestAvailableDate.getTime()
        ) {
            // used in search mostly, when
            filteredTimeslots.push({
                time: t,
                enabled: true,
            });
        }

        if (
            p.findOne &&
            filteredTimeslots.some((timeslot) => timeslot.enabled)
        ) {
            break;
        }
    }

    return filteredTimeslots;
}
