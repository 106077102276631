import { WEEKDAYS } from "@Shared/consts/commonConsts";
import { Weekday } from "@Shared/types/calendar";
import React from "react";
import { WorkshopDTO } from "../Workshop.types";
import openToCloseTimeString from "@FEClient/logic/utils/openToCloseTimeString";
import * as WS from "../Workshop.styled";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import * as S from "./AboutWorkshopSection.styled";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import capFirst from "@Shared/util/capFirst";
import { TransMsg } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";
import LoadOnView from "@FEShared/components/LoadOnView/LoadOnView";

const AboutWorkshopSection: React.FC<{ selectedWorkshop: WorkshopDTO }> = (
    p
) => {
    const isMobile = useIsMobile();
    return (
        <WS.ReviewsSection>
            <WS.ServicesTitleWrap>
                <WS.PurpleVerticalBar />
                <WS.ServicesTitleSubWrap>
                    <WS.ServicesTitle>
                        <TransMsg default={"Apie autoservisą"} id="u7QxQeZ2" />
                    </WS.ServicesTitle>
                </WS.ServicesTitleSubWrap>
            </WS.ServicesTitleWrap>
            <WS.ServiceInformationWrap>
                <WS.ServiceInformationSection id="location">
                    <Box position="relative" width={1} mr={4}>
                        <LoadOnView>
                            <S.Map
                                zoom={15}
                                lat={p.selectedWorkshop.posX}
                                lng={p.selectedWorkshop.posY}
                                className="reservation-workshop"
                            />
                        </LoadOnView>

                        <S.MapAddressWrapper>
                            <Text fontWeight={500}>
                                {p.selectedWorkshop.name}
                            </Text>
                            <Text color="#727272">
                                {p.selectedWorkshop.address}
                            </Text>
                            <Text color="#727272" mb={[0, 0, 2]}>
                                {[
                                    p.selectedWorkshop.microdistrict,
                                    capFirst(p.selectedWorkshop.city),
                                ]
                                    .filter(Boolean)
                                    .join(", ")}
                            </Text>
                            <Text
                                component="a"
                                color="black"
                                href={`https://www.google.com/maps/search/${[
                                    p.selectedWorkshop.posX,
                                    p.selectedWorkshop.posY,
                                ].join(", ")}/`}
                                target="_blank"
                            >
                                <Text
                                    component="span"
                                    sx={{ textDecoration: "underline" }}
                                >
                                    <TransMsg
                                        default={"Žiūrėti žemėlapyje"}
                                        id="mHbneb9c"
                                    />
                                </Text>
                                <Text
                                    ml={1}
                                    className="icon-outside-arrow"
                                    component="i"
                                />
                            </Text>
                        </S.MapAddressWrapper>
                    </Box>
                    <WS.WorkCalendar>
                        {isMobile && (
                            <WS.ServicesTitleWrap>
                                <WS.PurpleVerticalBar />
                                <WS.ServicesTitleSubWrap>
                                    <WS.ServicesTitle>
                                        <TransMsg
                                            default={"Darbo laikas"}
                                            id="c2xk8vQI"
                                        />
                                    </WS.ServicesTitle>
                                </WS.ServicesTitleSubWrap>
                            </WS.ServicesTitleWrap>
                        )}
                        {Object.keys(p.selectedWorkshop.workHoursCalendar)
                            .filter((key) => key !== "__typename")
                            .sort(
                                (a, b) =>
                                    WEEKDAYS.indexOf(a as Weekday) -
                                    WEEKDAYS.indexOf(b as Weekday)
                            )
                            .map((weekday) => (
                                <WS.WorkCalendarRow key={weekday}>
                                    <div>
                                        <WS.OpenCircle
                                            $open={
                                                p.selectedWorkshop
                                                    .workHoursCalendar[weekday]
                                            }
                                        />
                                        <WS.DayOfWeek
                                            $className={
                                                p.selectedWorkshop
                                                    .workHoursCalendar[weekday]
                                                    ? "open"
                                                    : "close"
                                            }
                                        >
                                            {transCommonTranslatable(
                                                weekday as Weekday
                                            )}
                                        </WS.DayOfWeek>
                                    </div>
                                    <WS.WorkTimeRange
                                        $className={
                                            p.selectedWorkshop
                                                .workHoursCalendar[weekday]
                                                ? "open"
                                                : "close"
                                        }
                                    >
                                        {openToCloseTimeString(
                                            p.selectedWorkshop
                                                .workHoursCalendar[weekday]
                                        )}
                                    </WS.WorkTimeRange>
                                </WS.WorkCalendarRow>
                            ))}
                    </WS.WorkCalendar>
                </WS.ServiceInformationSection>
                {p.selectedWorkshop.description && (
                    <WS.AboutWorkshop>
                        <WS.AboutWorkshopTitle>
                            {p.selectedWorkshop.name}
                        </WS.AboutWorkshopTitle>
                        <WS.AboutWorkshopDescription>
                            {p.selectedWorkshop.description}
                        </WS.AboutWorkshopDescription>
                    </WS.AboutWorkshop>
                )}
            </WS.ServiceInformationWrap>
        </WS.ReviewsSection>
    );
};

export default AboutWorkshopSection;
