import InternalLink from "@FEShared/components/InternalLink/InternalLink";
import styled, { createGlobalStyle, css } from "styled-components";
import ContentContainer from "../../commonComps/ContentContainer/ContentContainer";

interface AgreementTextP {
    $success?: boolean;
}
export const AgreementText = styled("div")<AgreementTextP>`
    font-size: 16px;
    /* margin-top: 17px; */

    ${(p) =>
        p.$success &&
        css`
            font-weight: 500;
            color: #64c584;
        `}
`;

export const TosAgreementText = styled("div")`
    text-align: center;
    color: #9c9c9c;
    font-size: 16px;
    margin-top: 8px;
`;

export const TosLink = styled(InternalLink)`
    color: inherit;
`;

export const PrivacyLink = styled(InternalLink)`
    color: inherit;
    font-weight: 500;
    color: ${(p) => p.theme.palette.primary.main};
    pointer-events: none;
`;

export const OrderProceedSection = styled("div")``;

export const LeftPanel = styled("div")`
    display: flex;
    flex-direction: column;
    max-width: 615px;
    width: 100%;
    margin-right: 32px;
`;

export const Banner = styled("div")`
    width: 100%;
    font-size: 16px;
    background: ${(p) => p.theme.palette.success.main};
`;

export const ReservationConfirmationContainer = styled("div")`
    position: relative;
`;

export const BoldText = styled("span")`
    font-weight: 500;
`;

export const ConfirmationContainer = styled("div")`
    position: relative;
    min-height: 100svh;
    background: #f0f2f8;
`;

export const ConfirmationContentContainer = styled(ContentContainer)`
    display: flex;
    position: relative;
    padding: 32px 0;

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: column;

        ${LeftPanel} {
            max-width: unset;
            width: 100%;
        }
    }
`;

export const Advice = styled("span")`
    font-weight: 500;
`;

export const CommentDescr = styled("div")`
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
`;

export const ReservConfGlobalStyles = createGlobalStyle`
    #chatlio-widget-container {
        display:none;
    }
`;
