import { FormattingInfo, KnownGlobalRenderers } from "./i18n.types";

export function isFormattingInfo(e: unknown): e is FormattingInfo {
    return typeof e === "object" && !!e && "transformer" in e;
}

/**
 * Writing translatable("You have deposited {coins}.", {coins: Cur(123)}) is equivalent to translatable("You have
 * deposited {Cur {coins}}", {coins: 123}) which is equivalent to (only possible in frontend) translatable("You
 * have deposited {Cur {coins}}", {coins: 123, Cur: coins => <Coins value={coins} />})
 */
export const formatting: {
    [k in keyof KnownGlobalRenderers]: (
        arg: KnownGlobalRenderers[k]
    ) => FormattingInfo;
} = {
    // CurrencyView(data: { value: number; unit?: WalletUnit }): FormattingInfo {
    // 	return { transformer: "Cur", value: { ...data } };
    // },
    Br() {
        return { transformer: "Br", value: undefined };
    },
    Link(data: { url: string; content: string }) {
        return { transformer: "Link", value: data };
    },
    LocalDate(timestamp: string): FormattingInfo {
        return { transformer: "LocalDate", value: timestamp };
    },
};
