import styled from "styled-components";

export const Container = styled("div")`
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e0e0e0;
`;

export const Title = styled("div")`
    font-size: 18px;
    font-weight: 600;
`;

export const Subtitle = styled("div")`
    font-size: 16px;
    font-weight: 400;
    color: #727272;
`;
