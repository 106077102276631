import React from "react";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import Button from "@FEShared/components/UI/Button/Button";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import {
    OrderReviewSummaryQuery,
    ServicesCompletionType,
} from "@FEShared/graphql/generated/graphql";
import { TransMsg, transStr } from "@FEShared/i18n";

const ServicesProvidedStep: React.FC<{
    onBtnClick: () => void;
    order: OrderReviewSummaryQuery["order"];
    allServicesReceived?: ServicesCompletionType;
    onChange: (value: ServicesCompletionType) => void;
    btnText: string;
}> = observer((p) => {
    const LS = useLocalObservable(() => ({
        error: false,
    }));

    return (
        <Box>
            <Box displayFlex vertical>
                <FormControl>
                    <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        sx={{ color: "black!important", fontWeight: 500 }}
                    >
                        <TransMsg
                            default={
                                "Ar autoservisas atliko visas norimas/reikiamas paslaugas?"
                            }
                            id="oZ8v23kT"
                        />
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={p.allServicesReceived}
                        onChange={(e) => {
                            p.onChange(
                                e.target.value as ServicesCompletionType
                            );
                        }}
                    >
                        <FormControlLabel
                            value={ServicesCompletionType.No}
                            control={<Radio />}
                            label={transStr("Ne", { id: "KKnJ4V0A" })}
                        />
                        <FormControlLabel
                            value={ServicesCompletionType.NoMeetingBooked}
                            control={<Radio />}
                            label={transStr(
                                "Ne, bet esu užsiregistravęs kitam vizitui užbaigti darbus",
                                { id: "my4VcZc4" }
                            )}
                        />
                        <FormControlLabel
                            value={ServicesCompletionType.Yes}
                            control={<Radio />}
                            label={transStr("Taip", { id: "STgP1x2N" })}
                        />
                    </RadioGroup>
                </FormControl>
                {LS.error && p.allServicesReceived === undefined && (
                    <Text color="error">
                        <TransMsg
                            default={
                                "Pasirinkite ar buvo suteiktos reikiamos paslaugos"
                            }
                            id="pXAwAd0X"
                        />
                    </Text>
                )}
                <Button
                    sx={{ minWidth: 240, mt: 1 }}
                    onClick={async () => {
                        if (p.allServicesReceived === undefined) {
                            runInAction(() => {
                                LS.error = true;
                            });
                            return;
                        }

                        p.onBtnClick();
                    }}
                >
                    {p.btnText}
                </Button>
            </Box>
        </Box>
    );
});

export default ServicesProvidedStep;
