import { Amenity, GqlCountry } from "@FEShared/graphql/generated/graphql";
import { translatable } from "@Shared/i18n/i18n";
import { Translatable } from "@Shared/i18n/i18n.types";

export const GROUPED_AMENITIES: (country: GqlCountry) => {
    leftText?: Translatable;
    amenities: Amenity[];
    iconClass: string;
}[] = (country: GqlCountry) => [
    {
        leftText: translatable("Kalba:", { id: "ieoMEZUU" }),
        iconClass: "icon-chat1",
        amenities:
            country === GqlCountry.Lt
                ? [
                      Amenity.LanguageLt,
                      Amenity.LanguageEn,
                      Amenity.LanguageRu,
                      Amenity.LanguagePl,
                  ]
                : [Amenity.LanguageEn, Amenity.LanguageFr],
    },
    {
        leftText: translatable("Atsiskaitymas:", { id: "a5KaqWPA" }),
        iconClass: "icon-card",
        amenities: [
            Amenity.PaymentCash,
            Amenity.PaymentBank,
            Amenity.PaymentCard,
        ],
    },
    {
        iconClass: "icon-couch",
        amenities: [
            Amenity.WaitingArea,
            Amenity.Wifi,
            Amenity.RestaurantNearby,
        ],
    },
    {
        iconClass: "icon-car-1",
        amenities: [
            Amenity.ReplacementVehicle,
            Amenity.VehicleDropOff,
            Amenity.VehicleReturnService,
        ],
    },
];
