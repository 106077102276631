import React from "react";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import Button from "@FEShared/components/UI/Button/Button";
import { OrderReviewSummaryQuery } from "@FEShared/graphql/generated/graphql";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import NumberInput from "@FEShared/components/UI/NumberInput/NumberInput";
import { TransMsg, transStr } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";

const CompletionSumStep: React.FC<{
    order: OrderReviewSummaryQuery["order"];
    onBtnClick: () => void;
    goodPrice?: boolean;
    onChange: (value: boolean) => void;
    onNewPriceChange: (num?: number) => void;
    clientProvidedPrice?: number;
}> = observer((p) => {
    const LS = useLocalObservable(() => ({
        forceError: false,
        error: undefined as undefined | "NO_OPTION" | "NO_PRICE",
    }));

    return (
        <Box>
            <Box>
                <Text fontSize="48px" semiBold span>
                    {window._COUNTRY_META.currencySymbol}
                    {p.order.paymentSum}
                </Text>
                <Text span ml={1} variant="subtitle1">
                    <TransMsg
                        default={"galutinė suma (su PVM)"}
                        id="b9o7VSGR"
                    />
                </Text>
            </Box>
            <Box displayFlex vertical>
                <Text mr="12px">
                    <TransMsg
                        default={"Šiai sumai bus suteikta garantija."}
                        id="UaA8MdVb"
                    />
                </Text>
                <FormControl sx={{ mt: 3 }}>
                    <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        sx={{ color: "black!important", fontWeight: 500 }}
                    >
                        <TransMsg
                            default={
                                "Sumokėta suma autoservisui už visas suteiktas paslaugas (įskaitant paslaugas kurių nesirinkote registracijos metu) ir detales/medžiagas, su PVM:"
                            }
                            id="bo2SZveo"
                        />
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={p.goodPrice}
                        onChange={(e) => {
                            p.onChange(e.target.value === "true");
                        }}
                    >
                        <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label={transStr("Neteisinga", { id: "NSkMvFG9" })}
                        />
                        {p.goodPrice === false && (
                            <NumberInput
                                leftIconClass={
                                    window._COUNTRY_META.currencyIconClass
                                }
                                error={!p.clientProvidedPrice}
                                forceError={LS.forceError}
                                helperText={transStr(
                                    "Įveskite sumą kurią sumokėjote autoservisui",
                                    { id: "HsRJlzRF" }
                                )}
                                value={p.clientProvidedPrice}
                                placeholder={transStr(
                                    "Suma kurią sumokėjote autoservisui",
                                    { id: "5xeFqRum" }
                                )}
                                onNumChange={(num) => p.onNewPriceChange(num)}
                            />
                        )}

                        <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label={transStr("Teisinga", { id: "1WbrV4xH" })}
                        />
                    </RadioGroup>
                </FormControl>
                {LS.error === "NO_OPTION" && p.goodPrice === undefined && (
                    <Text color="error" fontSize={12}>
                        <TransMsg
                            default={"Patvirtinkite ar kaina yra teisinga"}
                            id="w5EP6sKS"
                        />
                    </Text>
                )}
                <Button
                    sx={{ minWidth: 240, mt: 1 }}
                    onClick={async () => {
                        runInAction(() => {
                            LS.forceError = true;
                        });
                        if (p.goodPrice === undefined) {
                            runInAction(() => {
                                LS.error = "NO_OPTION";
                            });
                            return;
                        }
                        if (
                            p.goodPrice === false &&
                            p.clientProvidedPrice === undefined
                        ) {
                            runInAction(() => {
                                LS.error = "NO_PRICE";
                            });
                            return;
                        }

                        p.onBtnClick();
                    }}
                >
                    {transCommonTranslatable("CONTINUE")}
                </Button>
            </Box>
        </Box>
    );
});

export default CompletionSumStep;
