import {
    I18nBackend,
    HostedI18nBackendConfig,
    Translations,
} from "@Shared/i18n/i18n.types";

export default class HostedI18nBackend implements I18nBackend {
    private loadedLanguages: {
        supportedLanguages: { languageCode: string }[];
    } | null = null;

    constructor(private readonly config: HostedI18nBackendConfig) {}

    public async getSupportedLanguages(): Promise<{ languageCode: string }[]> {
        if (!this.loadedLanguages) {
            this.loadedLanguages = await this.fetchJson<{
                supportedLanguages: { languageCode: string }[];
            }>(
                `${this.config.serverUrl}/orgs/${this.config.org}/projects/${this.config.project}/versions/${this.config.version}/languages`
            );

            this.loadedLanguages.supportedLanguages.forEach((lang) => {
                lang.languageCode = lang.languageCode.split("-")[0];
            });
        }

        return this.loadedLanguages.supportedLanguages;
    }

    public async fetchTranslations(
        acceptedLanguages: ReadonlyArray<string>
    ): Promise<Translations> {
        console.time("i18n fetch translations");
        const acceptedLanguagesStr = acceptedLanguages.join(",").toLowerCase();
        const translations = await this.fetchJson<Translations>(
            `${this.config.serverUrl}/orgs/${this.config.org}/projects/${this.config.project}/versions/${this.config.version}/translations?lang=${acceptedLanguagesStr}`
        );
        console.timeEnd("i18n fetch translations");
        return translations;
    }

    private async fetchJson<T>(url: string): Promise<T> {
        const fetchResult = await fetch(url);
        if (!fetchResult.ok) {
            throw new Error("Got non-ok response from I18nBackend.");
        }
        if (
            !(fetchResult.headers.get("content-type") || "").includes(
                "application/json"
            )
        ) {
            throw new Error("Got invalid content-type from I18nBackend.");
        }
        const json = (await fetchResult.json()) as T;
        return json;
    }
}
