import { Translatable } from "@FEShared/graphql/generated/graphql";
import WorkshopDTO from "@FEWorkshop/types/WorkshopDTO";

export default function pickHourCost(p: {
    workshop: Pick<WorkshopDTO, "hourCosts" | "hourCost">;
    service: {
        type: {
            partCategory: Translatable;
        };
    };
}) {
    const partCategoryHourCost =
        p.workshop.hourCosts?.[p.service.type.partCategory.customID as string];

    return partCategoryHourCost || p.workshop.hourCost;
}
