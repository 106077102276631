import React from "react";
import * as S from "./InputsGroup.styled";

const InputsGroup: React.FCC<{
    className?: string;
    horizontal?: boolean;
}> = (p) => {
    return (
        <S.InputsGroupContainer
            className={p.className}
            $horizontal={p.horizontal}
        >
            {p.children}
        </S.InputsGroupContainer>
    );
};

export default InputsGroup;
