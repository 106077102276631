import styled from "styled-components";

export const Container = styled("div")<{ $bgImg: string; $bgColor: string }>`
    background-image: url(${(props) => props.$bgImg});
    background-color: ${(props) => props.$bgColor};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;
    padding: 16px;
    height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${(p) => p.theme.breakpoints.down("md")} {
        height: 140px;
    }

    ${(p) => p.theme.breakpoints.down("sm")} {
        background-position: left;
    }
`;

export const BottomSection = styled("div")`
    ${(p) => p.theme.breakpoints.down("md")} {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
`;

export const NameCompanyGroup = styled("div")<{ $even: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: ${(p) => (p.$even ? "flex-end" : "flex-start")};

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: flex-end;
    }
`;

export const CompanyName = styled("span")`
    font-size: 17.85px;
    color: #ffffff;
    font-weight: 600;
`;

export const PersonName = styled("span")`
    font-size: 10.42px;
    color: #ffffff;
    font-weight: 400;

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-right: 2px;
    }
`;

export const TopRatedWrap = styled("div")`
    padding: 12px;
    background: ${(p) => p.theme.palette.primary.main};
    border-radius: 45px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
`;

export const Star = styled("i")`
    color: #ffb200;
    font-size: 8px;
    margin-right: 4px;
`;

export const TopRatedText = styled("span")`
    font-size: 10px;
    font-weight: 700;
    color: #ffffff;
    line-height: 11px;
`;
