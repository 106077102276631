import { SeoPageDataGen, SeoPages } from "@FEShared/types/common";
import capFirst from "@Shared/util/capFirst";
import urlifyString from "@FEShared/utils/urlifyString";
import brandsModelsData from "@FEShared/components/UI/CarAutocomplete/LTBrandsModelsData.json";
import { translatable } from "@Shared/i18n/i18n";
import pickByCountry from "@Shared/util/pickByCountry";
import Country from "@Shared/types/enums/Country";

const BRANDS = Object.entries(brandsModelsData).filter(
    ([brand, _models]) => brand !== "-Kita-"
);
export const BRANDS_URL_PREFIX = (country: Country) =>
    pickByCountry(country, {
        LT: "servisas",
        CA: "mechanic",
    });

const TEMPLATE_FAQ = [
    {
        question: translatable(
            "Kur galiu rasti _carModel_ autoservisą, kuriame atliekamas _carModel_ remontas?",
            { id: "Y8bdYTsh" }
        ),
        answer: translatable(
            "Paieškoje rasi _carModel_ autoservisus, kuriuose atliekamas visų populiariausių paslaugų _carModel_ remontas - _carModel_ diagnostika, _carModel_ variklių remontas, _carModel_ žibintų remontas, _carModel_ automatinių dėžių remontas, _carModel_ važiuoklės remontas, _carModel_ chip tuning ir daugelis kitų.",
            { id: "KSECXdYR" }
        ),
    },
    {
        question: translatable(
            "Ar atliekamas _carModel_ remontas Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
            { id: "yIZ0dEzo" }
        ),
        answer: translatable(
            "Taip, pasirink _carModel_ automobilio modelį, norimas paslaugas, atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje matytum autoservisus, kur atliekamas _carModel_ remontas Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            { id: "yad7Bbup" }
        ),
    },
    {
        question: translatable(
            "Ar atliekamas _carModel_ remontas savaitgalį (šeštadienį ir sekmadienį)?",
            { id: "6wfMF8h6" }
        ),
        answer: translatable(
            "Taip, pasirink _carModel_ automobilio modelį, norimas paslaugas, atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje matytum autoservisus, kur atliekamas _carModel_ remontas savaitgalį (šeštadienį/sekmadienį).",
            { id: "lifb60OP" }
        ),
    },
    {
        question: translatable(
            "Ar atliekamas _carModel_ remontas po darbo valandų?",
            { id: "cvPE2Vec" }
        ),
        answer: translatable(
            "Taip, pasirink _carModel_ automobilio modelį, norimas paslaugas, atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje matytum autoservisus, kur atliekamas _carModel_ remontas po darbo valandų.",
            { id: "I2OQLMmZ" }
        ),
    },
    {
        question: translatable("Ar atliekamas pigus _carModel_ remontas?", {
            id: "9q3FYekj",
        }),
        answer: translatable(
            "Taip, pasirink _carModel_ automobilio modelį, norimas paslaugas, atvykimo laiką bei reikiamą miestą, tuomet paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekamas pigus _carModel_ remontas.",
            { id: "DkM96sdZ" }
        ),
    },
];

const FECLIENT_BRANDS_SEO_PAGES: SeoPageDataGen = (translate, country) => {
    return BRANDS.reduce((acc, [brand, models]) => {
        const translatedPrefix = BRANDS_URL_PREFIX(country);

        acc["/" + urlifyString(`${brand}-${translatedPrefix}`.toLowerCase())] =
            {
                seoServiceName: translate(
                    translatable("{brand} Servisas", {
                        data: { brand },
                        id: "cMY4MTBN",
                        description: "FECLIENT_BRANDS_SEO_PAGES seoServiceName",
                    })
                ),
                sortOrder: +models.c,
                carBrand: brand,
                faq: TEMPLATE_FAQ.map((f) => {
                    return {
                        question: capFirst(
                            translate(f.question).replace(/_carModel_/g, brand),
                            false
                        ),
                        answer: capFirst(
                            translate(f.answer).replace(/_carModel_/g, brand),
                            false
                        ),
                    };
                }),
            };
        return acc;
    }, {} as SeoPages);
};

export default FECLIENT_BRANDS_SEO_PAGES;
