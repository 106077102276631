import { WorkshopOrder } from "@FEShared/graphql/generated/graphql";
import removeUndefinedOrNull from "@Shared/util/removeUndefinedOrNull";

export default function mergeOrder<
    T extends {
        workshopOrder?: any;
    }
>(order: T): T {
    if (!order.workshopOrder) return order;

    const refinedWorkshopOrder = removeUndefinedOrNull(
        order.workshopOrder
    ) as Partial<WorkshopOrder>;

    if (refinedWorkshopOrder && "ID" in refinedWorkshopOrder) {
        delete refinedWorkshopOrder["ID"]; // we don't want to rewrite the ID of the order.
    }

    return {
        ...order,
        ...refinedWorkshopOrder,
    };
}
