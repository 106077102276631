import Country from "@Shared/types/enums/Country";

const DOMAINS = {
    FE_CLIENT: {
        [Country.LT]: "nevaziuoja.lt",
        [Country.CA]: "repairpunk.com",
    },
    FE_WORKSHOP: {
        [Country.LT]: "servisas.nevaziuoja.lt",
        [Country.CA]: "autoshop.repairpunk.com",
    },
} as const;

export const DOMAIN_TO_COUNTRY = Object.values(DOMAINS).reduce(
    (acc, countryDomainPair) => {
        Object.entries(countryDomainPair).forEach(([country, domain]) => {
            acc[domain] = country as Country;
        });
        return acc;
    },
    {} as Record<string /* domain */, Country | undefined>
);

export default DOMAINS;
