import gql from "graphql-tag";
import * as Urql from "urql";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Json: any;
};

export type ActivateCancelDiscountInput = {
    accessToken: Scalars["String"];
    cancelPromoType: CustomerOrderCancelPromoType;
    orderID: Scalars["Float"];
    reason: Scalars["String"];
};

export enum Amenity {
    LanguageEn = "LANGUAGE_EN",
    LanguageFr = "LANGUAGE_FR",
    LanguageLt = "LANGUAGE_LT",
    LanguagePl = "LANGUAGE_PL",
    LanguageRu = "LANGUAGE_RU",
    PaymentBank = "PAYMENT_BANK",
    PaymentCard = "PAYMENT_CARD",
    PaymentCash = "PAYMENT_CASH",
    ReplacementVehicle = "REPLACEMENT_VEHICLE",
    RestaurantNearby = "RESTAURANT_NEARBY",
    VehicleDropOff = "VEHICLE_DROP_OFF",
    VehicleReturnService = "VEHICLE_RETURN_SERVICE",
    WaitingArea = "WAITING_AREA",
    Wifi = "WIFI",
}

export type AuthedInvitation = {
    __typename?: "AuthedInvitation";
    ID: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    expiresAt: Scalars["DateTime"];
    newSuggestedArrivalDate?: Maybe<Scalars["DateTime"]>;
    order: AuthedOrder;
    primary: Scalars["Boolean"];
    service: Workshop;
    status: ParticipationStatus;
    updatedAt: Scalars["DateTime"];
};

export type AuthedOrder = {
    __typename?: "AuthedOrder";
    ID: Scalars["Float"];
    acceptedService?: Maybe<Workshop>;
    arrivalDate?: Maybe<Scalars["DateTime"]>;
    billable: Scalars["Boolean"];
    calendarEvents: Array<CalendarEvent>;
    categories: Array<ServiceCategory>;
    city: Scalars["String"];
    clientArrived?: Maybe<Scalars["Boolean"]>;
    clientWillWait: Scalars["Boolean"];
    createdAt: Scalars["DateTime"];
    createdByWorkshop: Scalars["Boolean"];
    customData?: Maybe<Scalars["Json"]>;
    customerComment?: Maybe<Scalars["String"]>;
    customerPickTimeoutAt?: Maybe<Scalars["DateTime"]>;
    deletionDate?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    fromBookNow: Scalars["Boolean"];
    fullName?: Maybe<Scalars["String"]>;
    goodPrice?: Maybe<Scalars["Boolean"]>;
    invitations: Array<Invitation>;
    invoice?: Maybe<Invoice>;
    linksAdded: Scalars["Boolean"];
    marketingConsent: Scalars["Boolean"];
    paymentSum?: Maybe<Scalars["Float"]>;
    phoneNumber?: Maybe<Scalars["String"]>;
    servicesCompletion?: Maybe<ServicesCompletionType>;
    servicesJoinTimeoutAt?: Maybe<Scalars["DateTime"]>;
    status: OrderStatus;
    vehicleBody?: Maybe<Scalars["String"]>;
    vehicleBrand?: Maybe<Scalars["String"]>;
    vehicleLicensePlate?: Maybe<Scalars["String"]>;
    vehicleModel?: Maybe<Scalars["String"]>;
    vehicleVIN?: Maybe<Scalars["String"]>;
    vehicleYear?: Maybe<Scalars["Float"]>;
    workshopCancelReason?: Maybe<Scalars["String"]>;
    workshopOrder?: Maybe<WorkshopOrder>;
};

export type AuthedWorkshop = {
    __typename?: "AuthedWorkshop";
    ID: Scalars["Float"];
    acceptanceDaysMargin?: Maybe<Scalars["Float"]>;
    acceptanceDaysMarginSystem?: Maybe<Scalars["Float"]>;
    acceptsClientsWithOwnParts: Scalars["Boolean"];
    address: Scalars["String"];
    altFlowEnabled: Scalars["Boolean"];
    amenities: Array<Amenity>;
    availabilityValidUntil?: Maybe<Scalars["DateTime"]>;
    averageRatingScore: Scalars["Float"];
    badgeOrdersCount?: Maybe<Scalars["Float"]>;
    blockedTimeslots: Array<BlockedTimeslot>;
    city: Scalars["String"];
    clientArrived: Scalars["Boolean"];
    company: Company;
    country: GqlCountry;
    creationDate: Scalars["DateTime"];
    description?: Maybe<Scalars["String"]>;
    disableReason?: Maybe<DisableReason>;
    disabled: Scalars["Boolean"];
    earliestAvailableDate?: Maybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    employees: Array<WorkshopEmployee>;
    firstEnabledDate?: Maybe<Scalars["DateTime"]>;
    gmapUrl: Scalars["String"];
    hourCost: Scalars["Float"];
    hourCosts?: Maybe<HourCosts>;
    internalNotes?: Maybe<Scalars["String"]>;
    isTrustedPartner: Scalars["Boolean"];
    linksAdded: Scalars["Boolean"];
    logoURL: Scalars["String"];
    lunchHoursCalendar: Calendar;
    microdistrict?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    onboardingCompleted: Scalars["Boolean"];
    opp: Scalars["Boolean"];
    orderReviews: Array<OrderReview>;
    phoneNumber: Scalars["String"];
    photosUrls: Array<Scalars["String"]>;
    posX: Scalars["Float"];
    posY: Scalars["Float"];
    ratingScore: Scalars["Float"];
    ratingScoreDetails: RatingScoreDetails;
    reviewCountGmap?: Maybe<Scalars["Float"]>;
    reviewRatingGmap?: Maybe<Scalars["Float"]>;
    scrapeGmapUrl?: Maybe<Scalars["String"]>;
    selfDisabled: Scalars["Boolean"];
    servicePackages: Array<ServicePackage>;
    servicedBrands: Array<Scalars["String"]>;
    services: Array<WorkshopService>;
    specialized: Specialized;
    up: Scalars["Boolean"];
    workHoursCalendar: Calendar;
    workingSinceYear?: Maybe<Scalars["Float"]>;
};

export type AuthedWorkshopServicesArgs = {
    vehicle?: InputMaybe<VehicleInput>;
};

export type BlockedTimeslot = {
    __typename?: "BlockedTimeslot";
    blockedServicesIDs?: Maybe<Array<Scalars["String"]>>;
    employeesIDs?: Maybe<Array<Scalars["Float"]>>;
    endDate: Scalars["DateTime"];
    startDate: Scalars["DateTime"];
};

export type BlockedTimeslotInput = {
    blockedServicesIDs?: InputMaybe<Array<Scalars["String"]>>;
    employeesIDs?: InputMaybe<Array<Scalars["Float"]>>;
    endDate: Scalars["DateTime"];
    startDate: Scalars["DateTime"];
};

export type BookServiceInput = {
    accessToken: Scalars["String"];
    orderID: Scalars["Float"];
    serviceID: Scalars["Float"];
};

export type BookServiceWithoutOrderInput = {
    arrivalDate: Scalars["DateTime"];
    clid?: InputMaybe<Scalars["String"]>;
    clientWillWait?: InputMaybe<Scalars["Boolean"]>;
    customData?: InputMaybe<Scalars["Json"]>;
    customerComment?: InputMaybe<Scalars["String"]>;
    email: Scalars["String"];
    fromBookNow?: InputMaybe<Scalars["Boolean"]>;
    marketingConsent: Scalars["Boolean"];
    phoneNumber: Scalars["String"];
    promoCode?: InputMaybe<Scalars["String"]>;
    rebookingFromOrderID?: InputMaybe<Scalars["Float"]>;
    servicesDefinitionsIDs: Array<Scalars["String"]>;
    vehicleBody?: InputMaybe<Scalars["String"]>;
    vehicleBrand: Scalars["String"];
    vehicleLicensePlate?: InputMaybe<Scalars["String"]>;
    vehicleModel?: InputMaybe<Scalars["String"]>;
    vehicleVIN?: InputMaybe<Scalars["String"]>;
    vehicleYear?: InputMaybe<Scalars["Float"]>;
    workshopID: Scalars["Float"];
};

export type Calendar = {
    __typename?: "Calendar";
    friday?: Maybe<CalendarDay>;
    monday?: Maybe<CalendarDay>;
    saturday?: Maybe<CalendarDay>;
    sunday?: Maybe<CalendarDay>;
    thursday?: Maybe<CalendarDay>;
    tuesday?: Maybe<CalendarDay>;
    wednesday?: Maybe<CalendarDay>;
};

export type CalendarDay = {
    __typename?: "CalendarDay";
    close: Time;
    open: Time;
};

export type CalendarDayInput = {
    close: TimeInput;
    open: TimeInput;
};

export type CalendarEvent = {
    __typename?: "CalendarEvent";
    ID: Scalars["Float"];
    assignedEmployees: Array<WorkshopEmployee>;
    comments?: Maybe<Scalars["String"]>;
    creationDate: Scalars["DateTime"];
    endDate: Scalars["DateTime"];
    items: Array<CalendarEventItem>;
    order: AuthedOrder;
    startDate: Scalars["DateTime"];
    status: CalendarEventStatus;
    subType?: Maybe<CalendarEventSubType>;
    type: CalendarEventType;
};

export type CalendarEventItem = {
    __typename?: "CalendarEventItem";
    ID: Scalars["String"];
    belongsToItem?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    partsPrice?: Maybe<Scalars["Float"]>;
    price?: Maybe<Scalars["Float"]>;
    serviceDefinition?: Maybe<Scalars["String"]>;
    type: CalendarEventItemType;
};

export type CalendarEventItemInput = {
    ID: Scalars["String"];
    belongsToItem?: InputMaybe<Scalars["String"]>;
    name: Scalars["String"];
    partsPrice?: InputMaybe<Scalars["Float"]>;
    price?: InputMaybe<Scalars["Float"]>;
    serviceDefinition?: InputMaybe<Scalars["String"]>;
    type: CalendarEventItemType;
};

export enum CalendarEventItemType {
    Filler = "FILLER",
    Other = "OTHER",
    Part = "PART",
    Service = "SERVICE",
}

export enum CalendarEventStatus {
    Done = "done",
    Failed = "failed",
    Processing = "processing",
    Waiting = "waiting",
}

export enum CalendarEventSubType {
    TimeslotBlockEmployee = "timeslotBlock_employee",
    TimeslotBlockWorkshop = "timeslotBlock_workshop",
}

export enum CalendarEventType {
    Arrival = "arrival",
    Standard = "standard",
    TimeslotBlock = "timeslotBlock",
}

export type CalendarInput = {
    friday?: InputMaybe<CalendarDayInput>;
    monday?: InputMaybe<CalendarDayInput>;
    saturday?: InputMaybe<CalendarDayInput>;
    sunday?: InputMaybe<CalendarDayInput>;
    thursday?: InputMaybe<CalendarDayInput>;
    tuesday?: InputMaybe<CalendarDayInput>;
    wednesday?: InputMaybe<CalendarDayInput>;
};

export type CancelOrder = {
    accessToken: Scalars["String"];
    orderID: Scalars["Float"];
    reason: Scalars["String"];
};

export type Categorization = {
    __typename?: "Categorization";
    partCategory: Translatable;
    sortOrder: Scalars["Float"];
    subCategory?: Maybe<Translatable>;
};

export type CategorizationInput = {
    partCategory: TranslatableInput;
    sortOrder: Scalars["Float"];
    subCategory?: InputMaybe<TranslatableInput>;
};

export type Company = {
    __typename?: "Company";
    ID: Scalars["Float"];
    accountingEmail?: Maybe<Scalars["String"]>;
    address?: Maybe<Scalars["String"]>;
    companyCode?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    feePercent: Scalars["Float"];
    name: Scalars["String"];
    paymentDetails?: Maybe<Scalars["String"]>;
    phoneNumber?: Maybe<Scalars["String"]>;
    trialEndsOnDate?: Maybe<Scalars["DateTime"]>;
    vatCode?: Maybe<Scalars["String"]>;
};

export type CompanyInput = {
    ID: Scalars["Float"];
    accountingEmail?: InputMaybe<Scalars["String"]>;
    address?: InputMaybe<Scalars["String"]>;
    companyCode?: InputMaybe<Scalars["String"]>;
    email?: InputMaybe<Scalars["String"]>;
    feePercent: Scalars["Float"];
    name: Scalars["String"];
    paymentDetails?: InputMaybe<Scalars["String"]>;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    trialEndsOnDate?: InputMaybe<Scalars["DateTime"]>;
    vatCode?: InputMaybe<Scalars["String"]>;
};

export type CreateCalendarEventInput = {
    assignedEmployeesIDs?: InputMaybe<Array<Scalars["Float"]>>;
    comments?: InputMaybe<Scalars["String"]>;
    endDate: Scalars["DateTime"];
    items?: InputMaybe<Array<CalendarEventItemInput>>;
    order?: InputMaybe<OrderInput>;
    startDate: Scalars["DateTime"];
    status: CalendarEventStatus;
    subType?: InputMaybe<CalendarEventSubType>;
    type: CalendarEventType;
};

export type CreateReviewInput = {
    authorName?: InputMaybe<Scalars["String"]>;
    rating: Scalars["Float"];
    text?: InputMaybe<Scalars["String"]>;
};

export enum CustomerOrderCancelPromoType {
    Cancel = "CANCEL",
    ChangeDate = "CHANGE_DATE",
    NoCancel = "NO_CANCEL",
    ReReg = "RE_REG",
}

export type DashboardMonthStats = {
    __typename?: "DashboardMonthStats";
    date: Scalars["String"];
    stats: DashboardStats;
};

export type DashboardStats = {
    __typename?: "DashboardStats";
    acceptedClients: Scalars["Float"];
    avgOrderSize: Scalars["Float"];
    avgOrdersRating: Scalars["Float"];
    billableOrdersSum: Scalars["Float"];
    bookNowOrdersSum: Scalars["Float"];
    declinedClients: Scalars["Float"];
    declinedPunishableClients: Scalars["Float"];
    dynamicFee: Scalars["Boolean"];
    expiredClients: Scalars["Float"];
    externalOrdersCount: Scalars["Float"];
    externalOrdersSum: Scalars["Float"];
    feePercentage: Scalars["Float"];
    feeSum: Scalars["Float"];
    ordersCount: Scalars["Float"];
    ordersSum: Scalars["Float"];
    returningOrdersCount: Scalars["Float"];
    reviewsCount: Scalars["Float"];
};

export enum DisableReason {
    Churn = "CHURN",
    ExpiredInvitations = "EXPIRED_INVITATIONS",
    Fake = "FAKE",
    OnboardingNotFinished = "ONBOARDING_NOT_FINISHED",
    Other = "OTHER",
    Suspended = "SUSPENDED",
    Terminated = "TERMINATED",
    UnfinishedOrders = "UNFINISHED_ORDERS",
    UnpaidBills = "UNPAID_BILLS",
}

export type DownloadInvoiceInput = {
    ID: Scalars["String"];
};

export type EarliestAvailableTime = {
    __typename?: "EarliestAvailableTime";
    earliestAvailableTime: Scalars["DateTime"];
};

export type EmployeeTimeblocks = {
    __typename?: "EmployeeTimeblocks";
    ID: Scalars["Float"];
    servicesDefinitionsIDs: Array<Scalars["String"]>;
    workHoursCalendar?: Maybe<Calendar>;
};

export type FinalizeCalendarEventOrderInput = {
    orderID: Scalars["Float"];
    paymentSum?: InputMaybe<Scalars["Float"]>;
    sendTakeCarMessage: Scalars["Boolean"];
};

export type FindAllCategoriesInput = {
    includeNotActiveServices?: InputMaybe<Scalars["Boolean"]>;
    serviceID?: InputMaybe<Scalars["Float"]>;
};

export enum FuelType {
    Diesel = "diesel",
    Electric = "electric",
    Lpg = "lpg",
    Other = "other",
    Petrol = "petrol",
}

export enum GqlCountry {
    Ca = "CA",
    Lt = "LT",
}

export type GetEarliestAvailableTimeInput = {
    fromDate?: InputMaybe<Scalars["DateTime"]>;
    serviceID?: InputMaybe<Scalars["Float"]>;
};

export type GetWorkshopCalendarEventsInput = {
    fromDate: Scalars["DateTime"];
    toDate: Scalars["DateTime"];
};

export type HourCosts = {
    __typename?: "HourCosts";
    PC_BODY?: Maybe<Scalars["Float"]>;
    PC_BRAKES?: Maybe<Scalars["Float"]>;
    PC_CONDITIONER?: Maybe<Scalars["Float"]>;
    PC_DETAILING?: Maybe<Scalars["Float"]>;
    PC_ELECTRICITY?: Maybe<Scalars["Float"]>;
    PC_ENGINE?: Maybe<Scalars["Float"]>;
    PC_EXHAUST_SYSTEM?: Maybe<Scalars["Float"]>;
    PC_GEARBOX?: Maybe<Scalars["Float"]>;
    PC_GLASS?: Maybe<Scalars["Float"]>;
    PC_HEADLIGHTS?: Maybe<Scalars["Float"]>;
    PC_INSPECTION?: Maybe<Scalars["Float"]>;
    PC_INTERIOR?: Maybe<Scalars["Float"]>;
    PC_MAINTENANCE?: Maybe<Scalars["Float"]>;
    PC_STEERING?: Maybe<Scalars["Float"]>;
    PC_SUSPENSION?: Maybe<Scalars["Float"]>;
    PC_WHEELS?: Maybe<Scalars["Float"]>;
};

export type HourCostsInput = {
    PC_BODY?: InputMaybe<Scalars["Float"]>;
    PC_BRAKES?: InputMaybe<Scalars["Float"]>;
    PC_CONDITIONER?: InputMaybe<Scalars["Float"]>;
    PC_DETAILING?: InputMaybe<Scalars["Float"]>;
    PC_ELECTRICITY?: InputMaybe<Scalars["Float"]>;
    PC_ENGINE?: InputMaybe<Scalars["Float"]>;
    PC_EXHAUST_SYSTEM?: InputMaybe<Scalars["Float"]>;
    PC_GEARBOX?: InputMaybe<Scalars["Float"]>;
    PC_GLASS?: InputMaybe<Scalars["Float"]>;
    PC_HEADLIGHTS?: InputMaybe<Scalars["Float"]>;
    PC_INSPECTION?: InputMaybe<Scalars["Float"]>;
    PC_INTERIOR?: InputMaybe<Scalars["Float"]>;
    PC_MAINTENANCE?: InputMaybe<Scalars["Float"]>;
    PC_STEERING?: InputMaybe<Scalars["Float"]>;
    PC_SUSPENSION?: InputMaybe<Scalars["Float"]>;
    PC_WHEELS?: InputMaybe<Scalars["Float"]>;
};

export type Invitation = {
    __typename?: "Invitation";
    ID: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    expiresAt: Scalars["DateTime"];
    newSuggestedArrivalDate?: Maybe<Scalars["DateTime"]>;
    order: Order;
    primary: Scalars["Boolean"];
    service: Workshop;
    status: ParticipationStatus;
    updatedAt: Scalars["DateTime"];
};

export enum InvitationRejectReason {
    CannotProvideSelectedServices = "cannotProvideSelectedServices",
    CannotRepairSelectedCar = "cannotRepairSelectedCar",
    CannotStartRepairSoon = "cannotStartRepairSoon",
    NotWorthRepairing = "notWorthRepairing",
    Other = "other",
    Vacation = "vacation",
}

export type Invoice = {
    __typename?: "Invoice";
    ID: Scalars["String"];
    address: Scalars["String"];
    clientAddress?: Maybe<Scalars["String"]>;
    clientCompanyCode?: Maybe<Scalars["String"]>;
    clientEmail?: Maybe<Scalars["String"]>;
    clientName?: Maybe<Scalars["String"]>;
    clientPhoneNumber?: Maybe<Scalars["String"]>;
    clientVatCode?: Maybe<Scalars["String"]>;
    comments?: Maybe<Scalars["String"]>;
    companyCode: Scalars["String"];
    creationDate: Scalars["DateTime"];
    issuanceDate: Scalars["DateTime"];
    items: Array<InvoiceItem>;
    order: Scalars["Float"];
    paymentDetails?: Maybe<Scalars["String"]>;
    paymentDueDate: Scalars["DateTime"];
    series?: Maybe<Scalars["String"]>;
    seriesID?: Maybe<Scalars["Float"]>;
    type: InvoiceType;
    vatCode?: Maybe<Scalars["String"]>;
};

export type InvoiceInput = {
    ID: Scalars["String"];
    address: Scalars["String"];
    clientAddress?: InputMaybe<Scalars["String"]>;
    clientCompanyCode?: InputMaybe<Scalars["String"]>;
    clientEmail?: InputMaybe<Scalars["String"]>;
    clientName?: InputMaybe<Scalars["String"]>;
    clientPhoneNumber?: InputMaybe<Scalars["String"]>;
    clientVatCode?: InputMaybe<Scalars["String"]>;
    comments?: InputMaybe<Scalars["String"]>;
    companyCode: Scalars["String"];
    creationDate: Scalars["DateTime"];
    issuanceDate: Scalars["DateTime"];
    items: Array<InvoiceItemUpsert>;
    order: Scalars["Float"];
    paymentDetails?: InputMaybe<Scalars["String"]>;
    paymentDueDate: Scalars["DateTime"];
    series?: InputMaybe<Scalars["String"]>;
    seriesID?: InputMaybe<Scalars["Float"]>;
    type: InvoiceType;
    vatCode?: InputMaybe<Scalars["String"]>;
};

export type InvoiceItem = {
    __typename?: "InvoiceItem";
    ID: Scalars["String"];
    belongsToItem?: Maybe<Scalars["String"]>;
    count: Scalars["Float"];
    name: Scalars["String"];
    partCode?: Maybe<Scalars["String"]>;
    price: Scalars["Float"];
    serviceDefinition?: Maybe<Scalars["String"]>;
    type: InvoiceItemType;
};

export enum InvoiceItemType {
    Other = "OTHER",
    Part = "PART",
    Service = "SERVICE",
}

export type InvoiceItemUpsert = {
    ID: Scalars["String"];
    belongsToItem?: InputMaybe<Scalars["String"]>;
    count: Scalars["Float"];
    name: Scalars["String"];
    partCode?: InputMaybe<Scalars["String"]>;
    price: Scalars["Float"];
    serviceDefinition?: InputMaybe<Scalars["String"]>;
    type: InvoiceItemType;
};

export enum InvoiceType {
    BillToWorkshop = "BILL_TO_WORKSHOP",
    Invoice = "INVOICE",
    OrderForm = "ORDER_FORM",
}

export type InvoiceUpsert = {
    ID?: InputMaybe<Scalars["String"]>;
    clientAddress?: InputMaybe<Scalars["String"]>;
    clientCompanyCode?: InputMaybe<Scalars["String"]>;
    clientEmail?: InputMaybe<Scalars["String"]>;
    clientName?: InputMaybe<Scalars["String"]>;
    clientPhoneNumber?: InputMaybe<Scalars["String"]>;
    clientVatCode?: InputMaybe<Scalars["String"]>;
    comments?: InputMaybe<Scalars["String"]>;
    issuanceDate: Scalars["DateTime"];
    items: Array<InvoiceItemUpsert>;
    order: Scalars["Float"];
    paymentDueDate: Scalars["DateTime"];
    seriesID?: InputMaybe<Scalars["Float"]>;
    type: InvoiceType;
};

export type Mutation = {
    __typename?: "Mutation";
    acceptInvitationAuthed: Scalars["Boolean"];
    activateCancelDiscount: Scalars["Boolean"];
    availabilityUpdated: Scalars["Boolean"];
    bookService: Order;
    bookServiceWithoutOrder: Order;
    createCalendarEvent: CalendarEvent;
    createEvents: Array<CalendarEvent>;
    createReview: OrderReview;
    customerCancelOrder: Scalars["Boolean"];
    customersAcceptInvitation: Order;
    deleteCalendarEvent: Scalars["ID"];
    deleteOrder: Scalars["Boolean"];
    downloadInvoice: Scalars["String"];
    duplicateWorkshop: Scalars["Boolean"];
    finalizeCalendarEventOrder: Array<CalendarEvent>;
    initiateSearch: SearchResult;
    moreRepairsbooked: Scalars["Boolean"];
    optOutOfServices: Scalars["Boolean"];
    orderSearch: Array<AuthedOrder>;
    rejectInvitationAuthed: Array<CalendarEvent>;
    sendInvoiceToEmail: Scalars["Boolean"];
    signUpWorkshop: WorkshopSignupResult;
    updateCalendarEvent: CalendarEvent;
    updateClientArrival: AuthedOrder;
    updateOrder: Order;
    updateWarrantyInfo: Scalars["Boolean"];
    updateWorkshop: AuthedWorkshop;
    upsertInvoice: Invoice;
    workshopCancelOrder: Array<CalendarEvent>;
    workshopLogin: Scalars["Boolean"];
};

export type MutationAcceptInvitationAuthedArgs = {
    invitationID: Scalars["Float"];
};

export type MutationActivateCancelDiscountArgs = {
    p: ActivateCancelDiscountInput;
};

export type MutationBookServiceArgs = {
    p: BookServiceInput;
};

export type MutationBookServiceWithoutOrderArgs = {
    p: BookServiceWithoutOrderInput;
};

export type MutationCreateCalendarEventArgs = {
    event: CreateCalendarEventInput;
    sendMessages?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateEventsArgs = {
    events: Array<CreateCalendarEventInput>;
};

export type MutationCreateReviewArgs = {
    accessToken: Scalars["String"];
    orderID: Scalars["Int"];
    params: CreateReviewInput;
};

export type MutationCustomerCancelOrderArgs = {
    p: CancelOrder;
};

export type MutationCustomersAcceptInvitationArgs = {
    accessToken?: InputMaybe<Scalars["String"]>;
    invitationID: Scalars["Float"];
    newArrivalDate?: InputMaybe<Scalars["DateTime"]>;
};

export type MutationDeleteCalendarEventArgs = {
    eventID: Scalars["Float"];
};

export type MutationDeleteOrderArgs = {
    orderID: Scalars["Float"];
};

export type MutationDownloadInvoiceArgs = {
    p: DownloadInvoiceInput;
};

export type MutationFinalizeCalendarEventOrderArgs = {
    p: FinalizeCalendarEventOrderInput;
};

export type MutationInitiateSearchArgs = {
    params: SearchInput;
};

export type MutationMoreRepairsbookedArgs = {
    orderID: Scalars["Float"];
};

export type MutationOrderSearchArgs = {
    p: OrderSearchInput;
};

export type MutationRejectInvitationAuthedArgs = {
    p: RejectInvitationAuthed;
};

export type MutationSendInvoiceToEmailArgs = {
    p: SendInvoiceToEmailInput;
};

export type MutationSignUpWorkshopArgs = {
    input: WorkshopSignupInput;
};

export type MutationUpdateCalendarEventArgs = {
    event: UpdateCalendarEventInput;
};

export type MutationUpdateClientArrivalArgs = {
    p: UpdateClientArrivalInput;
};

export type MutationUpdateOrderArgs = {
    p: UpdateOrderInput;
};

export type MutationUpdateWarrantyInfoArgs = {
    p: UpdateWarrantyInfo;
};

export type MutationUpdateWorkshopArgs = {
    p: UpdateWorkshopInput;
};

export type MutationUpsertInvoiceArgs = {
    invoice: InvoiceUpsert;
};

export type MutationWorkshopCancelOrderArgs = {
    p: WorkshopCancelOrder;
};

export type MutationWorkshopLoginArgs = {
    email: Scalars["String"];
};

export type Order = {
    __typename?: "Order";
    ID: Scalars["Float"];
    acceptedService?: Maybe<Workshop>;
    arrivalDate?: Maybe<Scalars["DateTime"]>;
    billable: Scalars["Boolean"];
    categories: Array<ServiceCategory>;
    city: Scalars["String"];
    clientWillWait?: Maybe<Scalars["Boolean"]>;
    createdAt: Scalars["DateTime"];
    createdByWorkshop: Scalars["Boolean"];
    customData?: Maybe<Scalars["Json"]>;
    customerComment?: Maybe<Scalars["String"]>;
    customerPickTimeoutAt?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    fullName?: Maybe<Scalars["String"]>;
    goodPrice?: Maybe<Scalars["Boolean"]>;
    invitations: Array<Invitation>;
    marketingConsent: Scalars["Boolean"];
    paymentSum?: Maybe<Scalars["Float"]>;
    phoneNumber?: Maybe<Scalars["String"]>;
    servicesCompletion?: Maybe<ServicesCompletionType>;
    status: OrderStatus;
    userAccessToken: Scalars["String"];
    vehicleBody?: Maybe<Scalars["String"]>;
    vehicleBrand?: Maybe<Scalars["String"]>;
    vehicleLicensePlate?: Maybe<Scalars["String"]>;
    vehicleModel?: Maybe<Scalars["String"]>;
    vehicleVIN?: Maybe<Scalars["String"]>;
    vehicleYear?: Maybe<Scalars["Float"]>;
    workshopOrder?: Maybe<WorkshopOrder>;
};

export type OrderInput = {
    ID?: InputMaybe<Scalars["Float"]>;
    workshopOrder?: InputMaybe<WorkshopOrderInput>;
};

export type OrderReview = {
    __typename?: "OrderReview";
    ID: Scalars["Float"];
    authorName?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    rating: Scalars["Float"];
    serviceNames?: Maybe<Array<Translatable>>;
    text?: Maybe<Scalars["String"]>;
    vehicle?: Maybe<Vehicle>;
    workshop: ReviewWorkshop;
};

export type OrderSearchInput = {
    searchText?: InputMaybe<Scalars["String"]>;
};

export enum OrderStatus {
    AltServiceBooked = "altServiceBooked",
    AltServicesCustomerPick = "altServicesCustomerPick",
    AltServicesCustomerPickTimeout = "altServicesCustomerPickTimeout",
    AltServicesNotFound = "altServicesNotFound",
    AltServicesRequested = "altServicesRequested",
    BookingRequested = "bookingRequested",
    ClientCancelled = "clientCancelled",
    ClientRebooked = "clientRebooked",
    Completed = "completed",
    Fake = "fake",
    SearchMade = "searchMade",
    ServiceBooked = "serviceBooked",
    WorkshopCancelled = "workshopCancelled",
}

export enum ParticipationStatus {
    Accepted = "accepted",
    CancelledPrimaryAccepted = "cancelledPrimaryAccepted",
    CustomerCancelled = "customerCancelled",
    CustomerRejected = "customerRejected",
    Expired = "expired",
    Fake = "fake",
    Invited = "invited",
    OtherAccepted = "otherAccepted",
    PendingCustomerApproval = "pendingCustomerApproval",
    ServiceDeclined = "serviceDeclined",
}

export type Query = {
    __typename?: "Query";
    authedWorkshopOrders: Array<AuthedOrder>;
    dashboardStats: Array<DashboardMonthStats>;
    findAllCategories: Array<ServiceCategory>;
    findAllServicePackages: Array<ServicePackage>;
    getEarliestAvailableTime: EarliestAvailableTime;
    getNextSeriesID: Scalars["Int"];
    getServices: Array<Workshop>;
    getWorkshopCalendarEventsBetweenDates: Array<CalendarEvent>;
    invitation: Invitation;
    order: Order;
    orderReview?: Maybe<OrderReview>;
    pendingInvitations: Array<AuthedInvitation>;
    recentReviews: Array<OrderReview>;
    recommendedWorkshops: Array<Workshop>;
    service: Workshop;
    workshopFromSession?: Maybe<AuthedWorkshop>;
};

export type QueryFindAllCategoriesArgs = {
    params: FindAllCategoriesInput;
};

export type QueryGetEarliestAvailableTimeArgs = {
    params: GetEarliestAvailableTimeInput;
};

export type QueryGetServicesArgs = {
    IDs: Array<Scalars["Int"]>;
};

export type QueryGetWorkshopCalendarEventsBetweenDatesArgs = {
    p: GetWorkshopCalendarEventsInput;
};

export type QueryInvitationArgs = {
    ID: Scalars["Int"];
    accessToken?: InputMaybe<Scalars["String"]>;
};

export type QueryOrderArgs = {
    ID: Scalars["Int"];
    accessToken: Scalars["String"];
};

export type QueryOrderReviewArgs = {
    accessToken: Scalars["String"];
    orderID: Scalars["Int"];
};

export type QueryServiceArgs = {
    ID: Scalars["Int"];
};

export type RatingScoreDetails = {
    __typename?: "RatingScoreDetails";
    acceptedInvitations: Scalars["Float"];
    amenitiesScore: Scalars["Float"];
    availabilityScore: Scalars["Float"];
    cityRank: Scalars["Float"];
    cityRankLowest: Scalars["Float"];
    descriptionScore: Scalars["Float"];
    expiredDeclinedInvitations: Scalars["Float"];
    expiresDeclinesPenaltyScore: Scalars["Float"];
    finalScore: Scalars["Float"];
    ordersReports: Scalars["Float"];
    ordersReportsPenaltyScore: Scalars["Float"];
    photosScore: Scalars["Float"];
    priceScore: Scalars["Float"];
};

export type RejectInvitationAuthed = {
    blockedDates?: InputMaybe<Array<BlockedTimeslotInput>>;
    comment?: InputMaybe<Scalars["String"]>;
    disableServicesDefinitionIDs?: InputMaybe<Array<Scalars["String"]>>;
    invitationID: Scalars["Float"];
    reason: InvitationRejectReason;
};

export type ReviewWorkshop = {
    __typename?: "ReviewWorkshop";
    city: Scalars["String"];
    microdistrict?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
};

export type SearchInput = {
    arrivalDate?: InputMaybe<Scalars["DateTime"]>;
    city: Scalars["String"];
    clid?: InputMaybe<Scalars["String"]>;
    clientWillWait?: InputMaybe<Scalars["Boolean"]>;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    servicesDefinitionsIDs?: InputMaybe<Array<Scalars["String"]>>;
    vehicleBrand?: InputMaybe<Scalars["String"]>;
    vehicleModel?: InputMaybe<Scalars["String"]>;
    vehicleYear?: InputMaybe<Scalars["Float"]>;
};

export type SearchResult = {
    __typename?: "SearchResult";
    order: Order;
    services: Array<Workshop>;
};

export type SendInvoiceToEmailInput = {
    ID: Scalars["String"];
    email: Scalars["String"];
};

export type ServiceCategory = {
    __typename?: "ServiceCategory";
    ID: Scalars["String"];
    categorization: Array<Categorization>;
    defaultUnit?: Maybe<Translatable>;
    defaultUnitCount: Scalars["Float"];
    durationMinsPerUnit: Scalars["Float"];
    important: Scalars["Boolean"];
    partCategory: Translatable;
    priceType: ServicePriceType;
    searchAliases?: Maybe<Translatable>;
    serviceName: Translatable;
    sortOrder: Scalars["Float"];
};

export type ServiceCategoryInput = {
    ID: Scalars["String"];
    categorization: Array<CategorizationInput>;
    defaultUnit?: InputMaybe<TranslatableInput>;
    defaultUnitCount: Scalars["Float"];
    durationMinsPerUnit: Scalars["Float"];
    important: Scalars["Boolean"];
    partCategory: TranslatableInput;
    priceType: ServicePriceType;
    searchAliases?: InputMaybe<TranslatableInput>;
    serviceName: TranslatableInput;
    sortOrder: Scalars["Float"];
};

export type ServicePackage = {
    __typename?: "ServicePackage";
    ID: Scalars["Float"];
    imgUrl: Scalars["String"];
    name: Translatable;
    partCategory: Translatable;
    recommendationText?: Maybe<Translatable>;
    servicesDefinitionsIDs: Array<Scalars["String"]>;
    sortOrder: Scalars["Float"];
};

export enum ServicePriceType {
    Fixed = "FIXED",
    From = "FROM",
    FromTo = "FROM_TO",
    Hourly = "HOURLY",
}

export enum ServicesCompletionType {
    No = "NO",
    NoConfirmed = "NO_CONFIRMED",
    NoMeetingBooked = "NO_MEETING_BOOKED",
    NoMeetingBookedConfirmed = "NO_MEETING_BOOKED_CONFIRMED",
    Yes = "YES",
}

export type Specialized = {
    __typename?: "Specialized";
    servicesIDs: Array<Scalars["String"]>;
    vehicleBrands: Array<Scalars["String"]>;
};

export type Time = {
    __typename?: "Time";
    hour: Scalars["Float"];
    minute: Scalars["Float"];
};

export type TimeInput = {
    hour: Scalars["Float"];
    minute: Scalars["Float"];
};

export type Translatable = {
    __typename?: "Translatable";
    customID?: Maybe<Scalars["String"]>;
    defaultTranslation: Scalars["String"];
    id: Scalars["String"];
};

export type TranslatableInput = {
    customID?: InputMaybe<Scalars["String"]>;
    defaultTranslation: Scalars["String"];
    id: Scalars["String"];
};

export type UpdateCalendarEventInput = {
    ID: Scalars["Float"];
    assignedEmployeesIDs?: InputMaybe<Array<Scalars["Float"]>>;
    comments?: InputMaybe<Scalars["String"]>;
    endDate: Scalars["DateTime"];
    items?: InputMaybe<Array<CalendarEventItemInput>>;
    order?: InputMaybe<UpdateCalendarEventOrderInput>;
    startDate: Scalars["DateTime"];
    status: CalendarEventStatus;
    subType?: InputMaybe<CalendarEventSubType>;
    type: CalendarEventType;
};

export type UpdateCalendarEventOrderInput = {
    ID: Scalars["Float"];
    workshopOrder?: InputMaybe<WorkshopOrderInput>;
};

export type UpdateClientArrivalInput = {
    clientArrived: Scalars["Boolean"];
    orderID: Scalars["Float"];
};

export type UpdateEmployeeInput = {
    ID?: InputMaybe<Scalars["Float"]>;
    disabled: Scalars["Boolean"];
    fullName: Scalars["String"];
    role: Scalars["String"];
    servicesDefinitionsIDs: Array<Scalars["String"]>;
    workHoursCalendar?: InputMaybe<CalendarInput>;
};

export type UpdateOrderInput = {
    ID: Scalars["Float"];
    accessToken: Scalars["String"];
    arrivalDate: Scalars["DateTime"];
    clid?: InputMaybe<Scalars["String"]>;
    clientWillWait: Scalars["Boolean"];
    customerComment?: InputMaybe<Scalars["String"]>;
    email?: InputMaybe<Scalars["String"]>;
    fromBookNow?: InputMaybe<Scalars["Boolean"]>;
    marketingConsent?: InputMaybe<Scalars["Boolean"]>;
    phoneNumber: Scalars["String"];
    promoCode?: InputMaybe<Scalars["String"]>;
    servicesDefinitionsIDs?: InputMaybe<Array<Scalars["String"]>>;
    vehicleBrand?: InputMaybe<Scalars["String"]>;
    vehicleModel?: InputMaybe<Scalars["String"]>;
    vehicleYear?: InputMaybe<Scalars["Float"]>;
};

export type UpdateWarrantyInfo = {
    accessToken: Scalars["String"];
    clientProvidedPrice?: InputMaybe<Scalars["Float"]>;
    goodPrice?: InputMaybe<Scalars["Boolean"]>;
    orderID: Scalars["Float"];
    servicesCompletion?: InputMaybe<ServicesCompletionType>;
};

export type UpdateWorkshopInput = {
    acceptanceDaysMargin?: InputMaybe<Scalars["Float"]>;
    address?: InputMaybe<Scalars["String"]>;
    altFlowEnabled?: InputMaybe<Scalars["Boolean"]>;
    amenities?: InputMaybe<Array<Amenity>>;
    city?: InputMaybe<Scalars["String"]>;
    company?: InputMaybe<CompanyInput>;
    description?: InputMaybe<Scalars["String"]>;
    disableReason?: InputMaybe<DisableReason>;
    disabled?: InputMaybe<Scalars["Boolean"]>;
    email?: InputMaybe<Scalars["String"]>;
    employees?: InputMaybe<Array<UpdateEmployeeInput>>;
    firstEnabledDate?: InputMaybe<Scalars["DateTime"]>;
    gmapUrl?: InputMaybe<Scalars["String"]>;
    hourCost?: InputMaybe<Scalars["Float"]>;
    hourCosts?: InputMaybe<HourCostsInput>;
    internalNotes?: InputMaybe<Scalars["String"]>;
    linksAdded?: InputMaybe<Scalars["Boolean"]>;
    logoURL?: InputMaybe<Scalars["String"]>;
    lunchHoursCalendar?: InputMaybe<CalendarInput>;
    microdistrict?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    onboardingCompleted?: InputMaybe<Scalars["Boolean"]>;
    opp?: InputMaybe<Scalars["Boolean"]>;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    photosUrls?: InputMaybe<Array<Scalars["String"]>>;
    posX?: InputMaybe<Scalars["Float"]>;
    posY?: InputMaybe<Scalars["Float"]>;
    scrapeGmapUrl?: InputMaybe<Scalars["String"]>;
    selfDisabled?: InputMaybe<Scalars["Boolean"]>;
    servicedBrands?: InputMaybe<Array<Scalars["String"]>>;
    services?: InputMaybe<Array<WorkshopServiceInput>>;
    up?: InputMaybe<Scalars["Boolean"]>;
    workHoursCalendar?: InputMaybe<CalendarInput>;
    workingSinceYear?: InputMaybe<Scalars["Float"]>;
};

export type Vehicle = {
    __typename?: "Vehicle";
    brand?: Maybe<Scalars["String"]>;
    model?: Maybe<Scalars["String"]>;
    year?: Maybe<Scalars["Float"]>;
};

export type VehicleInput = {
    brand?: InputMaybe<Scalars["String"]>;
    model?: InputMaybe<Scalars["String"]>;
    year?: InputMaybe<Scalars["Float"]>;
};

export type Workshop = {
    __typename?: "Workshop";
    ID: Scalars["Float"];
    acceptsClientsWithOwnParts: Scalars["Boolean"];
    address: Scalars["String"];
    amenities: Array<Amenity>;
    badgeOrdersCount?: Maybe<Scalars["Float"]>;
    blockedTimeslots: Array<BlockedTimeslot>;
    city: Scalars["String"];
    country: GqlCountry;
    description?: Maybe<Scalars["String"]>;
    disableReason?: Maybe<DisableReason>;
    disabled: Scalars["Boolean"];
    earliestAvailableDate?: Maybe<Scalars["DateTime"]>;
    employees: Array<EmployeeTimeblocks>;
    hourCost: Scalars["Float"];
    hourCosts?: Maybe<HourCosts>;
    isTrustedPartner: Scalars["Boolean"];
    logoURL: Scalars["String"];
    lunchHoursCalendar: Calendar;
    microdistrict?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    opp: Scalars["Boolean"];
    orderReviews: Array<OrderReview>;
    photosUrls: Array<Scalars["String"]>;
    posX: Scalars["Float"];
    posY: Scalars["Float"];
    ratingScore: Scalars["Float"];
    reviewCountGmap?: Maybe<Scalars["Float"]>;
    reviewRatingGmap?: Maybe<Scalars["Float"]>;
    selfDisabled: Scalars["Boolean"];
    servicePackages: Array<ServicePackage>;
    servicedBrands: Array<Scalars["String"]>;
    services: Array<WorkshopService>;
    specialized: Specialized;
    up: Scalars["Boolean"];
    workHoursCalendar: Calendar;
    workingSinceYear?: Maybe<Scalars["Float"]>;
};

export type WorkshopServicesArgs = {
    vehicle?: InputMaybe<VehicleInput>;
};

export type WorkshopCancelOrder = {
    orderID: Scalars["Float"];
    reason?: InputMaybe<Scalars["String"]>;
};

export type WorkshopEmployee = {
    __typename?: "WorkshopEmployee";
    ID: Scalars["Float"];
    disabled: Scalars["Boolean"];
    fullName: Scalars["String"];
    role: Scalars["String"];
    servicesDefinitionsIDs: Array<Scalars["String"]>;
    workHoursCalendar?: Maybe<Calendar>;
};

export type WorkshopEmployeeInput = {
    ID: Scalars["Float"];
    disabled: Scalars["Boolean"];
    fullName: Scalars["String"];
    role: Scalars["String"];
    servicesDefinitionsIDs: Array<Scalars["String"]>;
    workHoursCalendar?: InputMaybe<CalendarInput>;
};

export type WorkshopOrder = {
    __typename?: "WorkshopOrder";
    ID: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    customerComment?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    engineCapacityLiters?: Maybe<Scalars["Float"]>;
    engineHorsePowerKw?: Maybe<Scalars["Float"]>;
    fuelType?: Maybe<Array<FuelType>>;
    fullName?: Maybe<Scalars["String"]>;
    mileage?: Maybe<Scalars["Float"]>;
    phoneNumber?: Maybe<Scalars["String"]>;
    vehicleBrand?: Maybe<Scalars["String"]>;
    vehicleLicensePlate?: Maybe<Scalars["String"]>;
    vehicleModel?: Maybe<Scalars["String"]>;
    vehicleVIN?: Maybe<Scalars["String"]>;
    vehicleYear?: Maybe<Scalars["Float"]>;
};

export type WorkshopOrderInput = {
    customerComment?: InputMaybe<Scalars["String"]>;
    email?: InputMaybe<Scalars["String"]>;
    engineCapacityLiters?: InputMaybe<Scalars["Float"]>;
    engineHorsePowerKw?: InputMaybe<Scalars["Float"]>;
    fuelType?: InputMaybe<Array<FuelType>>;
    fullName?: InputMaybe<Scalars["String"]>;
    mileage?: InputMaybe<Scalars["Float"]>;
    phoneNumber?: InputMaybe<Scalars["String"]>;
    vehicleBrand?: InputMaybe<Scalars["String"]>;
    vehicleLicensePlate?: InputMaybe<Scalars["String"]>;
    vehicleModel?: InputMaybe<Scalars["String"]>;
    vehicleVIN?: InputMaybe<Scalars["String"]>;
    vehicleYear?: InputMaybe<Scalars["Float"]>;
};

export type WorkshopService = {
    __typename?: "WorkshopService";
    disabled: Scalars["Boolean"];
    durationFromMins?: Maybe<Scalars["Float"]>;
    durationToMins?: Maybe<Scalars["Float"]>;
    fixedPrice?: Maybe<Scalars["Float"]>;
    fromPrice?: Maybe<Scalars["Float"]>;
    partsPrice?: Maybe<Scalars["Float"]>;
    specialized: Scalars["Boolean"];
    toPrice?: Maybe<Scalars["Float"]>;
    type: ServiceCategory;
};

export type WorkshopServiceInput = {
    disabled: Scalars["Boolean"];
    durationFromMins?: InputMaybe<Scalars["Float"]>;
    durationToMins?: InputMaybe<Scalars["Float"]>;
    fixedPrice?: InputMaybe<Scalars["Float"]>;
    fromPrice?: InputMaybe<Scalars["Float"]>;
    partsPrice?: InputMaybe<Scalars["Float"]>;
    specialized: Scalars["Boolean"];
    toPrice?: InputMaybe<Scalars["Float"]>;
    type: Scalars["String"];
};

export type WorkshopSignupInput = {
    adminPhone: Scalars["String"];
    companyCode: Scalars["String"];
    workshopAddress: Scalars["String"];
    workshopCity: Scalars["String"];
    workshopEmail: Scalars["String"];
    workshopName: Scalars["String"];
};

export type WorkshopSignupResult = {
    __typename?: "WorkshopSignupResult";
    magicLink: Scalars["String"];
};

export type InitSearchFormInfoQueryVariables = Exact<{
    getEarliestAvailableTimeParams: GetEarliestAvailableTimeInput;
    findAllCategoriesParams: FindAllCategoriesInput;
    includeGetEarliestAvailableTime: Scalars["Boolean"];
}>;

export type InitSearchFormInfoQuery = {
    __typename?: "Query";
    findAllCategories: Array<{
        __typename?: "ServiceCategory";
        ID: string;
        defaultUnitCount: number;
        durationMinsPerUnit: number;
        sortOrder: number;
        important: boolean;
        priceType: ServicePriceType;
        serviceName: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        };
        searchAliases?: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        } | null;
        partCategory: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        };
        categorization: Array<{
            __typename?: "Categorization";
            sortOrder: number;
            partCategory: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
            subCategory?: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            } | null;
        }>;
    }>;
    getEarliestAvailableTime?: {
        __typename?: "EarliestAvailableTime";
        earliestAvailableTime: any;
    };
    findAllServicePackages: Array<{
        __typename?: "ServicePackage";
        ID: number;
        servicesDefinitionsIDs: Array<string>;
        sortOrder: number;
        imgUrl: string;
        name: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        };
        partCategory: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        };
        recommendationText?: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        } | null;
    }>;
};

export type CustomersAcceptInvitationMutationVariables = Exact<{
    invitationId: Scalars["Float"];
    newArrivalDate?: InputMaybe<Scalars["DateTime"]>;
    accessToken?: InputMaybe<Scalars["String"]>;
}>;

export type CustomersAcceptInvitationMutation = {
    __typename?: "Mutation";
    customersAcceptInvitation: {
        __typename?: "Order";
        ID: number;
        createdAt: any;
        city: string;
        phoneNumber?: string | null;
        email?: string | null;
        vehicleBrand?: string | null;
        vehicleModel?: string | null;
        vehicleYear?: number | null;
        userAccessToken: string;
        status: OrderStatus;
        arrivalDate?: any | null;
        createdByWorkshop: boolean;
        billable: boolean;
        customerComment?: string | null;
        goodPrice?: boolean | null;
        servicesCompletion?: ServicesCompletionType | null;
        categories: Array<{
            __typename?: "ServiceCategory";
            ID: string;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
    };
};

export type OrderQueryVariables = Exact<{
    id: Scalars["Int"];
    accessToken: Scalars["String"];
}>;

export type OrderQuery = {
    __typename?: "Query";
    order: {
        __typename?: "Order";
        ID: number;
        createdAt: any;
        city: string;
        email?: string | null;
        phoneNumber?: string | null;
        vehicleBrand?: string | null;
        vehicleModel?: string | null;
        vehicleYear?: number | null;
        vehicleVIN?: string | null;
        vehicleBody?: string | null;
        vehicleLicensePlate?: string | null;
        userAccessToken: string;
        status: OrderStatus;
        marketingConsent: boolean;
        arrivalDate?: any | null;
        customerPickTimeoutAt?: any | null;
        customerComment?: string | null;
        createdByWorkshop: boolean;
        billable: boolean;
        goodPrice?: boolean | null;
        servicesCompletion?: ServicesCompletionType | null;
        clientWillWait?: boolean | null;
        acceptedService?: {
            __typename?: "Workshop";
            ID: number;
            address: string;
            name: string;
            city: string;
            hourCost: number;
            posX: number;
            posY: number;
            logoURL: string;
            reviewCountGmap?: number | null;
            reviewRatingGmap?: number | null;
            microdistrict?: string | null;
            ratingScore: number;
            opp: boolean;
            up: boolean;
            badgeOrdersCount?: number | null;
            earliestAvailableDate?: any | null;
            hourCosts?: {
                __typename?: "HourCosts";
                PC_MAINTENANCE?: number | null;
                PC_SUSPENSION?: number | null;
                PC_DETAILING?: number | null;
                PC_GEARBOX?: number | null;
                PC_INSPECTION?: number | null;
                PC_STEERING?: number | null;
                PC_HEADLIGHTS?: number | null;
                PC_ELECTRICITY?: number | null;
                PC_EXHAUST_SYSTEM?: number | null;
                PC_CONDITIONER?: number | null;
                PC_WHEELS?: number | null;
                PC_INTERIOR?: number | null;
                PC_ENGINE?: number | null;
                PC_BRAKES?: number | null;
                PC_GLASS?: number | null;
                PC_BODY?: number | null;
            } | null;
            specialized: {
                __typename?: "Specialized";
                vehicleBrands: Array<string>;
                servicesIDs: Array<string>;
            };
            services: Array<{
                __typename?: "WorkshopService";
                fixedPrice?: number | null;
                fromPrice?: number | null;
                toPrice?: number | null;
                durationFromMins?: number | null;
                durationToMins?: number | null;
                partsPrice?: number | null;
                type: {
                    __typename?: "ServiceCategory";
                    ID: string;
                    defaultUnitCount: number;
                    durationMinsPerUnit: number;
                    priceType: ServicePriceType;
                    serviceName: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    };
                    partCategory: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    };
                    defaultUnit?: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    } | null;
                };
            }>;
            lunchHoursCalendar: {
                __typename?: "Calendar";
                monday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                tuesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                wednesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                thursday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                friday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                saturday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                sunday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
            };
            workHoursCalendar: {
                __typename?: "Calendar";
                monday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                tuesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                wednesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                thursday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                friday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                saturday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                sunday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
            };
        } | null;
        categories: Array<{
            __typename?: "ServiceCategory";
            ID: string;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
        invitations: Array<{
            __typename?: "Invitation";
            ID: number;
            primary: boolean;
            status: ParticipationStatus;
            expiresAt: any;
            newSuggestedArrivalDate?: any | null;
            service: {
                __typename?: "Workshop";
                ID: number;
                address: string;
                name: string;
                city: string;
                hourCost: number;
                posX: number;
                posY: number;
                logoURL: string;
                reviewCountGmap?: number | null;
                reviewRatingGmap?: number | null;
                microdistrict?: string | null;
                ratingScore: number;
                opp: boolean;
                up: boolean;
                badgeOrdersCount?: number | null;
                earliestAvailableDate?: any | null;
                blockedTimeslots: Array<{
                    __typename?: "BlockedTimeslot";
                    startDate: any;
                    endDate: any;
                    blockedServicesIDs?: Array<string> | null;
                    employeesIDs?: Array<number> | null;
                }>;
                employees: Array<{
                    __typename?: "EmployeeTimeblocks";
                    ID: number;
                    servicesDefinitionsIDs: Array<string>;
                    workHoursCalendar?: {
                        __typename?: "Calendar";
                        monday?: {
                            __typename?: "CalendarDay";
                            open: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                            close: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                        } | null;
                        tuesday?: {
                            __typename?: "CalendarDay";
                            open: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                            close: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                        } | null;
                        wednesday?: {
                            __typename?: "CalendarDay";
                            open: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                            close: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                        } | null;
                        thursday?: {
                            __typename?: "CalendarDay";
                            open: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                            close: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                        } | null;
                        friday?: {
                            __typename?: "CalendarDay";
                            open: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                            close: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                        } | null;
                        saturday?: {
                            __typename?: "CalendarDay";
                            open: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                            close: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                        } | null;
                        sunday?: {
                            __typename?: "CalendarDay";
                            open: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                            close: {
                                __typename?: "Time";
                                hour: number;
                                minute: number;
                            };
                        } | null;
                    } | null;
                }>;
                hourCosts?: {
                    __typename?: "HourCosts";
                    PC_MAINTENANCE?: number | null;
                    PC_SUSPENSION?: number | null;
                    PC_DETAILING?: number | null;
                    PC_GEARBOX?: number | null;
                    PC_INSPECTION?: number | null;
                    PC_STEERING?: number | null;
                    PC_HEADLIGHTS?: number | null;
                    PC_ELECTRICITY?: number | null;
                    PC_EXHAUST_SYSTEM?: number | null;
                    PC_CONDITIONER?: number | null;
                    PC_WHEELS?: number | null;
                    PC_INTERIOR?: number | null;
                    PC_ENGINE?: number | null;
                    PC_BRAKES?: number | null;
                    PC_GLASS?: number | null;
                    PC_BODY?: number | null;
                } | null;
                specialized: {
                    __typename?: "Specialized";
                    vehicleBrands: Array<string>;
                    servicesIDs: Array<string>;
                };
                services: Array<{
                    __typename?: "WorkshopService";
                    fixedPrice?: number | null;
                    fromPrice?: number | null;
                    toPrice?: number | null;
                    durationFromMins?: number | null;
                    durationToMins?: number | null;
                    partsPrice?: number | null;
                    type: {
                        __typename?: "ServiceCategory";
                        ID: string;
                        defaultUnitCount: number;
                        durationMinsPerUnit: number;
                        priceType: ServicePriceType;
                        serviceName: {
                            __typename?: "Translatable";
                            defaultTranslation: string;
                            id: string;
                            customID?: string | null;
                        };
                        partCategory: {
                            __typename?: "Translatable";
                            defaultTranslation: string;
                            id: string;
                            customID?: string | null;
                        };
                        defaultUnit?: {
                            __typename?: "Translatable";
                            defaultTranslation: string;
                            id: string;
                            customID?: string | null;
                        } | null;
                    };
                }>;
                lunchHoursCalendar: {
                    __typename?: "Calendar";
                    monday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    tuesday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    wednesday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    thursday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    friday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    saturday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    sunday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                };
                workHoursCalendar: {
                    __typename?: "Calendar";
                    monday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    tuesday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    wednesday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    thursday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    friday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    saturday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                    sunday?: {
                        __typename?: "CalendarDay";
                        open: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                        close: {
                            __typename?: "Time";
                            hour: number;
                            minute: number;
                        };
                    } | null;
                };
            };
        }>;
        workshopOrder?: {
            __typename?: "WorkshopOrder";
            phoneNumber?: string | null;
            email?: string | null;
        } | null;
    };
};

export type RecommendedWorkshopsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type RecommendedWorkshopsQuery = {
    __typename?: "Query";
    recommendedWorkshops: Array<{
        __typename?: "Workshop";
        name: string;
        ID: number;
        address: string;
        city: string;
        microdistrict?: string | null;
        reviewRatingGmap?: number | null;
        logoURL: string;
        reviewCountGmap?: number | null;
    }>;
};

export type RecentReviewsQueryVariables = Exact<{ [key: string]: never }>;

export type RecentReviewsQuery = {
    __typename?: "Query";
    recentReviews: Array<{
        __typename?: "OrderReview";
        ID: number;
        rating: number;
        text?: string | null;
        authorName?: string | null;
        createdAt: any;
        serviceNames?: Array<{
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        }> | null;
        vehicle?: {
            __typename?: "Vehicle";
            brand?: string | null;
            model?: string | null;
            year?: number | null;
        } | null;
        workshop: { __typename?: "ReviewWorkshop"; name: string; city: string };
    }>;
};

export type WorkshopFragmentFecFragment = {
    __typename?: "Workshop";
    ID: number;
    address: string;
    name: string;
    city: string;
    hourCost: number;
    posX: number;
    posY: number;
    logoURL: string;
    reviewCountGmap?: number | null;
    reviewRatingGmap?: number | null;
    microdistrict?: string | null;
    ratingScore: number;
    opp: boolean;
    up: boolean;
    badgeOrdersCount?: number | null;
    earliestAvailableDate?: any | null;
    hourCosts?: {
        __typename?: "HourCosts";
        PC_MAINTENANCE?: number | null;
        PC_SUSPENSION?: number | null;
        PC_DETAILING?: number | null;
        PC_GEARBOX?: number | null;
        PC_INSPECTION?: number | null;
        PC_STEERING?: number | null;
        PC_HEADLIGHTS?: number | null;
        PC_ELECTRICITY?: number | null;
        PC_EXHAUST_SYSTEM?: number | null;
        PC_CONDITIONER?: number | null;
        PC_WHEELS?: number | null;
        PC_INTERIOR?: number | null;
        PC_ENGINE?: number | null;
        PC_BRAKES?: number | null;
        PC_GLASS?: number | null;
        PC_BODY?: number | null;
    } | null;
    specialized: {
        __typename?: "Specialized";
        vehicleBrands: Array<string>;
        servicesIDs: Array<string>;
    };
    services: Array<{
        __typename?: "WorkshopService";
        fixedPrice?: number | null;
        fromPrice?: number | null;
        toPrice?: number | null;
        durationFromMins?: number | null;
        durationToMins?: number | null;
        partsPrice?: number | null;
        type: {
            __typename?: "ServiceCategory";
            ID: string;
            defaultUnitCount: number;
            durationMinsPerUnit: number;
            priceType: ServicePriceType;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
            partCategory: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
            defaultUnit?: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            } | null;
        };
    }>;
    lunchHoursCalendar: {
        __typename?: "Calendar";
        monday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        tuesday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        wednesday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        thursday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        friday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        saturday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        sunday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
    };
    workHoursCalendar: {
        __typename?: "Calendar";
        monday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        tuesday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        wednesday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        thursday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        friday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        saturday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        sunday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
    };
};

export type InitiateSearchMutationVariables = Exact<{
    params: SearchInput;
}>;

export type InitiateSearchMutation = {
    __typename?: "Mutation";
    initiateSearch: {
        __typename?: "SearchResult";
        services: Array<{
            __typename?: "Workshop";
            ID: number;
            address: string;
            name: string;
            city: string;
            hourCost: number;
            posX: number;
            posY: number;
            logoURL: string;
            reviewCountGmap?: number | null;
            reviewRatingGmap?: number | null;
            microdistrict?: string | null;
            ratingScore: number;
            opp: boolean;
            up: boolean;
            badgeOrdersCount?: number | null;
            earliestAvailableDate?: any | null;
            hourCosts?: {
                __typename?: "HourCosts";
                PC_MAINTENANCE?: number | null;
                PC_SUSPENSION?: number | null;
                PC_DETAILING?: number | null;
                PC_GEARBOX?: number | null;
                PC_INSPECTION?: number | null;
                PC_STEERING?: number | null;
                PC_HEADLIGHTS?: number | null;
                PC_ELECTRICITY?: number | null;
                PC_EXHAUST_SYSTEM?: number | null;
                PC_CONDITIONER?: number | null;
                PC_WHEELS?: number | null;
                PC_INTERIOR?: number | null;
                PC_ENGINE?: number | null;
                PC_BRAKES?: number | null;
                PC_GLASS?: number | null;
                PC_BODY?: number | null;
            } | null;
            specialized: {
                __typename?: "Specialized";
                vehicleBrands: Array<string>;
                servicesIDs: Array<string>;
            };
            services: Array<{
                __typename?: "WorkshopService";
                fixedPrice?: number | null;
                fromPrice?: number | null;
                toPrice?: number | null;
                durationFromMins?: number | null;
                durationToMins?: number | null;
                partsPrice?: number | null;
                type: {
                    __typename?: "ServiceCategory";
                    ID: string;
                    defaultUnitCount: number;
                    durationMinsPerUnit: number;
                    priceType: ServicePriceType;
                    serviceName: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    };
                    partCategory: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    };
                    defaultUnit?: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    } | null;
                };
            }>;
            lunchHoursCalendar: {
                __typename?: "Calendar";
                monday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                tuesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                wednesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                thursday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                friday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                saturday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                sunday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
            };
            workHoursCalendar: {
                __typename?: "Calendar";
                monday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                tuesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                wednesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                thursday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                friday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                saturday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                sunday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
            };
        }>;
        order: {
            __typename?: "Order";
            ID: number;
            createdAt: any;
            arrivalDate?: any | null;
            phoneNumber?: string | null;
            vehicleBrand?: string | null;
            vehicleModel?: string | null;
            vehicleBody?: string | null;
            vehicleYear?: number | null;
            vehicleVIN?: string | null;
            vehicleLicensePlate?: string | null;
            userAccessToken: string;
            categories: Array<{
                __typename?: "ServiceCategory";
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
        };
    };
};

export type BookServiceOrderResFragment = {
    __typename?: "Order";
    ID: number;
    createdAt: any;
    city: string;
    phoneNumber?: string | null;
    email?: string | null;
    vehicleBrand?: string | null;
    vehicleModel?: string | null;
    vehicleYear?: number | null;
    userAccessToken: string;
    status: OrderStatus;
    arrivalDate?: any | null;
    createdByWorkshop: boolean;
    billable: boolean;
    customerComment?: string | null;
    goodPrice?: boolean | null;
    servicesCompletion?: ServicesCompletionType | null;
    categories: Array<{
        __typename?: "ServiceCategory";
        ID: string;
        serviceName: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        };
    }>;
};

export type BookServiceMutationVariables = Exact<{
    bookServiceInput: BookServiceInput;
    updateOrderInput: UpdateOrderInput;
}>;

export type BookServiceMutation = {
    __typename?: "Mutation";
    updateOrder: { __typename?: "Order"; ID: number };
    bookService: {
        __typename?: "Order";
        ID: number;
        createdAt: any;
        city: string;
        phoneNumber?: string | null;
        email?: string | null;
        vehicleBrand?: string | null;
        vehicleModel?: string | null;
        vehicleYear?: number | null;
        userAccessToken: string;
        status: OrderStatus;
        arrivalDate?: any | null;
        createdByWorkshop: boolean;
        billable: boolean;
        customerComment?: string | null;
        goodPrice?: boolean | null;
        servicesCompletion?: ServicesCompletionType | null;
        categories: Array<{
            __typename?: "ServiceCategory";
            ID: string;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
    };
};

export type BookServiceWithoutOrderMutationVariables = Exact<{
    p: BookServiceWithoutOrderInput;
}>;

export type BookServiceWithoutOrderMutation = {
    __typename?: "Mutation";
    bookServiceWithoutOrder: {
        __typename?: "Order";
        ID: number;
        createdAt: any;
        city: string;
        phoneNumber?: string | null;
        email?: string | null;
        vehicleBrand?: string | null;
        vehicleModel?: string | null;
        vehicleYear?: number | null;
        userAccessToken: string;
        status: OrderStatus;
        arrivalDate?: any | null;
        createdByWorkshop: boolean;
        billable: boolean;
        customerComment?: string | null;
        goodPrice?: boolean | null;
        servicesCompletion?: ServicesCompletionType | null;
        categories: Array<{
            __typename?: "ServiceCategory";
            ID: string;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
    };
};

export type ReservationConfirmationInitDataQueryVariables = Exact<{
    getEarliestAvailableTimeParams: GetEarliestAvailableTimeInput;
    workshopID: Scalars["Int"];
}>;

export type ReservationConfirmationInitDataQuery = {
    __typename?: "Query";
    getEarliestAvailableTime: {
        __typename?: "EarliestAvailableTime";
        earliestAvailableTime: any;
    };
    service: {
        __typename?: "Workshop";
        servicedBrands: Array<string>;
        blockedTimeslots: Array<{
            __typename?: "BlockedTimeslot";
            startDate: any;
            endDate: any;
            blockedServicesIDs?: Array<string> | null;
            employeesIDs?: Array<number> | null;
        }>;
        employees: Array<{
            __typename?: "EmployeeTimeblocks";
            ID: number;
            servicesDefinitionsIDs: Array<string>;
            workHoursCalendar?: {
                __typename?: "Calendar";
                monday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                tuesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                wednesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                thursday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                friday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                saturday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                sunday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
            } | null;
        }>;
    };
};

export type CancelOrderMutationVariables = Exact<{
    p: CancelOrder;
}>;

export type CancelOrderMutation = {
    __typename?: "Mutation";
    customerCancelOrder: boolean;
};

export type ActivateCancelDiscountMutationVariables = Exact<{
    p: ActivateCancelDiscountInput;
}>;

export type ActivateCancelDiscountMutation = {
    __typename?: "Mutation";
    activateCancelDiscount: boolean;
};

export type CreateReviewMutationVariables = Exact<{
    params: CreateReviewInput;
    orderId: Scalars["Int"];
    accessToken: Scalars["String"];
}>;

export type CreateReviewMutation = {
    __typename?: "Mutation";
    createReview: {
        __typename?: "OrderReview";
        ID: number;
        rating: number;
        text?: string | null;
        authorName?: string | null;
        createdAt: any;
    };
};

export type OrderReviewSummaryQueryVariables = Exact<{
    orderId: Scalars["Int"];
    accessToken: Scalars["String"];
}>;

export type OrderReviewSummaryQuery = {
    __typename?: "Query";
    order: {
        __typename?: "Order";
        ID: number;
        paymentSum?: number | null;
        createdByWorkshop: boolean;
        vehicleBrand?: string | null;
        vehicleModel?: string | null;
        vehicleYear?: number | null;
        status: OrderStatus;
        goodPrice?: boolean | null;
        servicesCompletion?: ServicesCompletionType | null;
        acceptedService?: {
            __typename?: "Workshop";
            name: string;
            address: string;
            city: string;
        } | null;
        categories: Array<{
            __typename?: "ServiceCategory";
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
    };
    orderReview?: {
        __typename?: "OrderReview";
        ID: number;
        text?: string | null;
        rating: number;
        authorName?: string | null;
        createdAt: any;
    } | null;
};

export type UpdateWarrantyInfoMutationVariables = Exact<{
    p: UpdateWarrantyInfo;
}>;

export type UpdateWarrantyInfoMutation = {
    __typename?: "Mutation";
    updateWarrantyInfo: boolean;
};

export type GetServiceQueryVariables = Exact<{
    vehicle?: InputMaybe<VehicleInput>;
    id: Scalars["Int"];
}>;

export type GetServiceQuery = {
    __typename?: "Query";
    service: {
        __typename?: "Workshop";
        ID: number;
        name: string;
        address: string;
        city: string;
        hourCost: number;
        logoURL: string;
        posX: number;
        posY: number;
        reviewCountGmap?: number | null;
        reviewRatingGmap?: number | null;
        description?: string | null;
        isTrustedPartner: boolean;
        workingSinceYear?: number | null;
        microdistrict?: string | null;
        photosUrls: Array<string>;
        disabled: boolean;
        selfDisabled: boolean;
        country: GqlCountry;
        acceptsClientsWithOwnParts: boolean;
        servicedBrands: Array<string>;
        amenities: Array<Amenity>;
        opp: boolean;
        up: boolean;
        ratingScore: number;
        disableReason?: DisableReason | null;
        earliestAvailableDate?: any | null;
        hourCosts?: {
            __typename?: "HourCosts";
            PC_MAINTENANCE?: number | null;
            PC_SUSPENSION?: number | null;
            PC_DETAILING?: number | null;
            PC_GEARBOX?: number | null;
            PC_INSPECTION?: number | null;
            PC_STEERING?: number | null;
            PC_HEADLIGHTS?: number | null;
            PC_ELECTRICITY?: number | null;
            PC_EXHAUST_SYSTEM?: number | null;
            PC_CONDITIONER?: number | null;
            PC_WHEELS?: number | null;
            PC_INTERIOR?: number | null;
            PC_ENGINE?: number | null;
            PC_BRAKES?: number | null;
            PC_GLASS?: number | null;
            PC_BODY?: number | null;
        } | null;
        specialized: {
            __typename?: "Specialized";
            vehicleBrands: Array<string>;
            servicesIDs: Array<string>;
        };
        blockedTimeslots: Array<{
            __typename?: "BlockedTimeslot";
            startDate: any;
            endDate: any;
            blockedServicesIDs?: Array<string> | null;
            employeesIDs?: Array<number> | null;
        }>;
        workHoursCalendar: {
            __typename?: "Calendar";
            monday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            tuesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            wednesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            thursday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            friday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            saturday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            sunday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
        };
        lunchHoursCalendar: {
            __typename?: "Calendar";
            monday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            tuesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            wednesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            thursday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            friday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            saturday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            sunday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
        };
        orderReviews: Array<{
            __typename?: "OrderReview";
            text?: string | null;
            authorName?: string | null;
            rating: number;
            createdAt: any;
            serviceNames?: Array<{
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            }> | null;
            vehicle?: {
                __typename?: "Vehicle";
                brand?: string | null;
                model?: string | null;
                year?: number | null;
            } | null;
        }>;
        services: Array<{
            __typename?: "WorkshopService";
            specialized: boolean;
            fixedPrice?: number | null;
            fromPrice?: number | null;
            toPrice?: number | null;
            durationFromMins?: number | null;
            durationToMins?: number | null;
            disabled: boolean;
            partsPrice?: number | null;
            type: {
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                sortOrder: number;
                important: boolean;
                priceType: ServicePriceType;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
                searchAliases?: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                } | null;
                defaultUnit?: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                } | null;
                partCategory: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
                categorization: Array<{
                    __typename?: "Categorization";
                    sortOrder: number;
                    partCategory: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    };
                    subCategory?: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    } | null;
                }>;
            };
        }>;
        employees: Array<{
            __typename?: "EmployeeTimeblocks";
            ID: number;
            servicesDefinitionsIDs: Array<string>;
            workHoursCalendar?: {
                __typename?: "Calendar";
                monday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                tuesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                wednesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                thursday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                friday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                saturday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                sunday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
            } | null;
        }>;
        servicePackages: Array<{
            __typename?: "ServicePackage";
            ID: number;
            servicesDefinitionsIDs: Array<string>;
            sortOrder: number;
            imgUrl: string;
            name: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
            recommendationText?: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            } | null;
            partCategory: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
    };
};

export type TranslatableFragFragment = {
    __typename?: "Translatable";
    defaultTranslation: string;
    id: string;
    customID?: string | null;
};

export type HourCostsFragFragment = {
    __typename?: "HourCosts";
    PC_MAINTENANCE?: number | null;
    PC_SUSPENSION?: number | null;
    PC_DETAILING?: number | null;
    PC_GEARBOX?: number | null;
    PC_INSPECTION?: number | null;
    PC_STEERING?: number | null;
    PC_HEADLIGHTS?: number | null;
    PC_ELECTRICITY?: number | null;
    PC_EXHAUST_SYSTEM?: number | null;
    PC_CONDITIONER?: number | null;
    PC_WHEELS?: number | null;
    PC_INTERIOR?: number | null;
    PC_ENGINE?: number | null;
    PC_BRAKES?: number | null;
    PC_GLASS?: number | null;
    PC_BODY?: number | null;
};

export type CalendarFragFragment = {
    __typename?: "Calendar";
    monday?: {
        __typename?: "CalendarDay";
        open: { __typename?: "Time"; hour: number; minute: number };
        close: { __typename?: "Time"; hour: number; minute: number };
    } | null;
    tuesday?: {
        __typename?: "CalendarDay";
        open: { __typename?: "Time"; hour: number; minute: number };
        close: { __typename?: "Time"; hour: number; minute: number };
    } | null;
    wednesday?: {
        __typename?: "CalendarDay";
        open: { __typename?: "Time"; hour: number; minute: number };
        close: { __typename?: "Time"; hour: number; minute: number };
    } | null;
    thursday?: {
        __typename?: "CalendarDay";
        open: { __typename?: "Time"; hour: number; minute: number };
        close: { __typename?: "Time"; hour: number; minute: number };
    } | null;
    friday?: {
        __typename?: "CalendarDay";
        open: { __typename?: "Time"; hour: number; minute: number };
        close: { __typename?: "Time"; hour: number; minute: number };
    } | null;
    saturday?: {
        __typename?: "CalendarDay";
        open: { __typename?: "Time"; hour: number; minute: number };
        close: { __typename?: "Time"; hour: number; minute: number };
    } | null;
    sunday?: {
        __typename?: "CalendarDay";
        open: { __typename?: "Time"; hour: number; minute: number };
        close: { __typename?: "Time"; hour: number; minute: number };
    } | null;
};

export type BlockedTimeslotsFragFragment = {
    __typename?: "BlockedTimeslot";
    startDate: any;
    endDate: any;
    blockedServicesIDs?: Array<string> | null;
    employeesIDs?: Array<number> | null;
};

export type AuthedWorkshopFragment = {
    __typename?: "AuthedWorkshop";
    ID: number;
    name: string;
    address: string;
    microdistrict?: string | null;
    phoneNumber: string;
    city: string;
    disabled: boolean;
    disableReason?: DisableReason | null;
    selfDisabled: boolean;
    email: string;
    hourCost: number;
    servicedBrands: Array<string>;
    logoURL: string;
    posX: number;
    photosUrls: Array<string>;
    posY: number;
    gmapUrl: string;
    scrapeGmapUrl?: string | null;
    reviewCountGmap?: number | null;
    country: GqlCountry;
    reviewRatingGmap?: number | null;
    workingSinceYear?: number | null;
    altFlowEnabled: boolean;
    onboardingCompleted: boolean;
    creationDate: any;
    opp: boolean;
    up: boolean;
    availabilityValidUntil?: any | null;
    linksAdded: boolean;
    averageRatingScore: number;
    internalNotes?: string | null;
    firstEnabledDate?: any | null;
    description?: string | null;
    acceptanceDaysMargin?: number | null;
    acceptanceDaysMarginSystem?: number | null;
    amenities: Array<Amenity>;
    hourCosts?: {
        __typename?: "HourCosts";
        PC_MAINTENANCE?: number | null;
        PC_SUSPENSION?: number | null;
        PC_DETAILING?: number | null;
        PC_GEARBOX?: number | null;
        PC_INSPECTION?: number | null;
        PC_STEERING?: number | null;
        PC_HEADLIGHTS?: number | null;
        PC_ELECTRICITY?: number | null;
        PC_EXHAUST_SYSTEM?: number | null;
        PC_CONDITIONER?: number | null;
        PC_WHEELS?: number | null;
        PC_INTERIOR?: number | null;
        PC_ENGINE?: number | null;
        PC_BRAKES?: number | null;
        PC_GLASS?: number | null;
        PC_BODY?: number | null;
    } | null;
    blockedTimeslots: Array<{
        __typename?: "BlockedTimeslot";
        startDate: any;
        endDate: any;
        blockedServicesIDs?: Array<string> | null;
        employeesIDs?: Array<number> | null;
    }>;
    ratingScoreDetails: {
        __typename?: "RatingScoreDetails";
        expiresDeclinesPenaltyScore: number;
        acceptedInvitations: number;
        descriptionScore: number;
        photosScore: number;
        ordersReportsPenaltyScore: number;
        ordersReports: number;
        finalScore: number;
        expiredDeclinedInvitations: number;
        priceScore: number;
        availabilityScore: number;
        cityRank: number;
        cityRankLowest: number;
        amenitiesScore: number;
    };
    company: {
        __typename?: "Company";
        ID: number;
        name: string;
        companyCode?: string | null;
        vatCode?: string | null;
        address?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        accountingEmail?: string | null;
        paymentDetails?: string | null;
        trialEndsOnDate?: any | null;
        feePercent: number;
    };
    employees: Array<{
        __typename?: "WorkshopEmployee";
        ID: number;
        fullName: string;
        role: string;
        disabled: boolean;
        servicesDefinitionsIDs: Array<string>;
        workHoursCalendar?: {
            __typename?: "Calendar";
            monday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            tuesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            wednesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            thursday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            friday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            saturday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            sunday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
        } | null;
    }>;
    services: Array<{
        __typename?: "WorkshopService";
        fixedPrice?: number | null;
        specialized: boolean;
        disabled: boolean;
        fromPrice?: number | null;
        toPrice?: number | null;
        durationFromMins?: number | null;
        durationToMins?: number | null;
        type: {
            __typename?: "ServiceCategory";
            ID: string;
            durationMinsPerUnit: number;
            defaultUnitCount: number;
            important: boolean;
            sortOrder: number;
            priceType: ServicePriceType;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
            searchAliases?: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            } | null;
            partCategory: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
            defaultUnit?: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            } | null;
            categorization: Array<{
                __typename?: "Categorization";
                sortOrder: number;
                partCategory: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
                subCategory?: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                } | null;
            }>;
        };
    }>;
    lunchHoursCalendar: {
        __typename?: "Calendar";
        monday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        tuesday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        wednesday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        thursday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        friday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        saturday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        sunday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
    };
    workHoursCalendar: {
        __typename?: "Calendar";
        monday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        tuesday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        wednesday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        thursday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        friday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        saturday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
        sunday?: {
            __typename?: "CalendarDay";
            open: { __typename?: "Time"; hour: number; minute: number };
            close: { __typename?: "Time"; hour: number; minute: number };
        } | null;
    };
};

export type WorkshopFromSessionQueryVariables = Exact<{ [key: string]: never }>;

export type WorkshopFromSessionQuery = {
    __typename?: "Query";
    workshopFromSession?: {
        __typename?: "AuthedWorkshop";
        ID: number;
        name: string;
        address: string;
        microdistrict?: string | null;
        phoneNumber: string;
        city: string;
        disabled: boolean;
        disableReason?: DisableReason | null;
        selfDisabled: boolean;
        email: string;
        hourCost: number;
        servicedBrands: Array<string>;
        logoURL: string;
        posX: number;
        photosUrls: Array<string>;
        posY: number;
        gmapUrl: string;
        scrapeGmapUrl?: string | null;
        reviewCountGmap?: number | null;
        country: GqlCountry;
        reviewRatingGmap?: number | null;
        workingSinceYear?: number | null;
        altFlowEnabled: boolean;
        onboardingCompleted: boolean;
        creationDate: any;
        opp: boolean;
        up: boolean;
        availabilityValidUntil?: any | null;
        linksAdded: boolean;
        averageRatingScore: number;
        internalNotes?: string | null;
        firstEnabledDate?: any | null;
        description?: string | null;
        acceptanceDaysMargin?: number | null;
        acceptanceDaysMarginSystem?: number | null;
        amenities: Array<Amenity>;
        hourCosts?: {
            __typename?: "HourCosts";
            PC_MAINTENANCE?: number | null;
            PC_SUSPENSION?: number | null;
            PC_DETAILING?: number | null;
            PC_GEARBOX?: number | null;
            PC_INSPECTION?: number | null;
            PC_STEERING?: number | null;
            PC_HEADLIGHTS?: number | null;
            PC_ELECTRICITY?: number | null;
            PC_EXHAUST_SYSTEM?: number | null;
            PC_CONDITIONER?: number | null;
            PC_WHEELS?: number | null;
            PC_INTERIOR?: number | null;
            PC_ENGINE?: number | null;
            PC_BRAKES?: number | null;
            PC_GLASS?: number | null;
            PC_BODY?: number | null;
        } | null;
        blockedTimeslots: Array<{
            __typename?: "BlockedTimeslot";
            startDate: any;
            endDate: any;
            blockedServicesIDs?: Array<string> | null;
            employeesIDs?: Array<number> | null;
        }>;
        ratingScoreDetails: {
            __typename?: "RatingScoreDetails";
            expiresDeclinesPenaltyScore: number;
            acceptedInvitations: number;
            descriptionScore: number;
            photosScore: number;
            ordersReportsPenaltyScore: number;
            ordersReports: number;
            finalScore: number;
            expiredDeclinedInvitations: number;
            priceScore: number;
            availabilityScore: number;
            cityRank: number;
            cityRankLowest: number;
            amenitiesScore: number;
        };
        company: {
            __typename?: "Company";
            ID: number;
            name: string;
            companyCode?: string | null;
            vatCode?: string | null;
            address?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            accountingEmail?: string | null;
            paymentDetails?: string | null;
            trialEndsOnDate?: any | null;
            feePercent: number;
        };
        employees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
            role: string;
            disabled: boolean;
            servicesDefinitionsIDs: Array<string>;
            workHoursCalendar?: {
                __typename?: "Calendar";
                monday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                tuesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                wednesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                thursday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                friday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                saturday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                sunday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
            } | null;
        }>;
        services: Array<{
            __typename?: "WorkshopService";
            fixedPrice?: number | null;
            specialized: boolean;
            disabled: boolean;
            fromPrice?: number | null;
            toPrice?: number | null;
            durationFromMins?: number | null;
            durationToMins?: number | null;
            type: {
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                important: boolean;
                sortOrder: number;
                priceType: ServicePriceType;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
                searchAliases?: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                } | null;
                partCategory: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
                defaultUnit?: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                } | null;
                categorization: Array<{
                    __typename?: "Categorization";
                    sortOrder: number;
                    partCategory: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    };
                    subCategory?: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    } | null;
                }>;
            };
        }>;
        lunchHoursCalendar: {
            __typename?: "Calendar";
            monday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            tuesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            wednesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            thursday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            friday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            saturday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            sunday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
        };
        workHoursCalendar: {
            __typename?: "Calendar";
            monday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            tuesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            wednesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            thursday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            friday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            saturday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            sunday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
        };
    } | null;
};

export type AcceptInvitationAuthedMutationVariables = Exact<{
    invitationId: Scalars["Float"];
}>;

export type AcceptInvitationAuthedMutation = {
    __typename?: "Mutation";
    acceptInvitationAuthed: boolean;
};

export type RejectInvitationAuthedMutationVariables = Exact<{
    p: RejectInvitationAuthed;
}>;

export type RejectInvitationAuthedMutation = {
    __typename?: "Mutation";
    rejectInvitationAuthed: Array<{
        __typename?: "CalendarEvent";
        ID: number;
        comments?: string | null;
        status: CalendarEventStatus;
        type: CalendarEventType;
        subType?: CalendarEventSubType | null;
        creationDate: any;
        startDate: any;
        endDate: any;
        items: Array<{
            __typename?: "CalendarEventItem";
            ID: string;
            name: string;
            price?: number | null;
            partsPrice?: number | null;
            serviceDefinition?: string | null;
            type: CalendarEventItemType;
            belongsToItem?: string | null;
        }>;
        order: {
            __typename?: "AuthedOrder";
            ID: number;
            vehicleYear?: number | null;
            fullName?: string | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            status: OrderStatus;
            phoneNumber?: string | null;
            createdAt: any;
            createdByWorkshop: boolean;
            clientArrived?: boolean | null;
            arrivalDate?: any | null;
            customerComment?: string | null;
            servicesJoinTimeoutAt?: any | null;
            customerPickTimeoutAt?: any | null;
            email?: string | null;
            fromBookNow: boolean;
            paymentSum?: number | null;
            clientWillWait: boolean;
            categories: Array<{
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
            workshopOrder?: {
                __typename?: "WorkshopOrder";
                ID: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                vehicleBrand?: string | null;
                vehicleModel?: string | null;
                vehicleYear?: number | null;
                vehicleLicensePlate?: string | null;
                email?: string | null;
                vehicleVIN?: string | null;
                mileage?: number | null;
                fuelType?: Array<FuelType> | null;
                customerComment?: string | null;
                engineHorsePowerKw?: number | null;
                engineCapacityLiters?: number | null;
            } | null;
        };
        assignedEmployees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
        }>;
    }>;
};

export type FindAllCategoriesQueryVariables = Exact<{
    findAllCategoriesParams: FindAllCategoriesInput;
}>;

export type FindAllCategoriesQuery = {
    __typename?: "Query";
    findAllCategories: Array<{
        __typename?: "ServiceCategory";
        ID: string;
        searchAliases?: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        } | null;
        serviceName: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        };
    }>;
};

export type UpdateClientArrivalMutationVariables = Exact<{
    updateClientArrivalInput: UpdateClientArrivalInput;
    updateEventInput: UpdateCalendarEventInput;
}>;

export type UpdateClientArrivalMutation = {
    __typename?: "Mutation";
    updateCalendarEvent: {
        __typename?: "CalendarEvent";
        ID: number;
        comments?: string | null;
        status: CalendarEventStatus;
        type: CalendarEventType;
        subType?: CalendarEventSubType | null;
        creationDate: any;
        startDate: any;
        endDate: any;
        items: Array<{
            __typename?: "CalendarEventItem";
            ID: string;
            name: string;
            price?: number | null;
            partsPrice?: number | null;
            serviceDefinition?: string | null;
            type: CalendarEventItemType;
            belongsToItem?: string | null;
        }>;
        order: {
            __typename?: "AuthedOrder";
            ID: number;
            vehicleYear?: number | null;
            fullName?: string | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            status: OrderStatus;
            phoneNumber?: string | null;
            createdAt: any;
            createdByWorkshop: boolean;
            clientArrived?: boolean | null;
            arrivalDate?: any | null;
            customerComment?: string | null;
            servicesJoinTimeoutAt?: any | null;
            customerPickTimeoutAt?: any | null;
            email?: string | null;
            fromBookNow: boolean;
            paymentSum?: number | null;
            clientWillWait: boolean;
            categories: Array<{
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
            workshopOrder?: {
                __typename?: "WorkshopOrder";
                ID: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                vehicleBrand?: string | null;
                vehicleModel?: string | null;
                vehicleYear?: number | null;
                vehicleLicensePlate?: string | null;
                email?: string | null;
                vehicleVIN?: string | null;
                mileage?: number | null;
                fuelType?: Array<FuelType> | null;
                customerComment?: string | null;
                engineHorsePowerKw?: number | null;
                engineCapacityLiters?: number | null;
            } | null;
        };
        assignedEmployees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
        }>;
    };
    updateClientArrival: {
        __typename?: "AuthedOrder";
        ID: number;
        vehicleYear?: number | null;
        fullName?: string | null;
        vehicleModel?: string | null;
        vehicleLicensePlate?: string | null;
        vehicleBrand?: string | null;
        status: OrderStatus;
        phoneNumber?: string | null;
        createdAt: any;
        arrivalDate?: any | null;
        categories: Array<{
            __typename?: "ServiceCategory";
            ID: string;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
        workshopOrder?: {
            __typename?: "WorkshopOrder";
            ID: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            vehicleBrand?: string | null;
            vehicleModel?: string | null;
            vehicleYear?: number | null;
            vehicleLicensePlate?: string | null;
        } | null;
    };
};

export type FinalizeCalendarEventOrderMutationVariables = Exact<{
    p: FinalizeCalendarEventOrderInput;
}>;

export type FinalizeCalendarEventOrderMutation = {
    __typename?: "Mutation";
    finalizeCalendarEventOrder: Array<{
        __typename?: "CalendarEvent";
        ID: number;
        comments?: string | null;
        status: CalendarEventStatus;
        type: CalendarEventType;
        subType?: CalendarEventSubType | null;
        creationDate: any;
        startDate: any;
        endDate: any;
        items: Array<{
            __typename?: "CalendarEventItem";
            ID: string;
            name: string;
            price?: number | null;
            partsPrice?: number | null;
            serviceDefinition?: string | null;
            type: CalendarEventItemType;
            belongsToItem?: string | null;
        }>;
        order: {
            __typename?: "AuthedOrder";
            ID: number;
            vehicleYear?: number | null;
            fullName?: string | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            status: OrderStatus;
            phoneNumber?: string | null;
            createdAt: any;
            createdByWorkshop: boolean;
            clientArrived?: boolean | null;
            arrivalDate?: any | null;
            customerComment?: string | null;
            servicesJoinTimeoutAt?: any | null;
            customerPickTimeoutAt?: any | null;
            email?: string | null;
            fromBookNow: boolean;
            paymentSum?: number | null;
            clientWillWait: boolean;
            categories: Array<{
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
            workshopOrder?: {
                __typename?: "WorkshopOrder";
                ID: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                vehicleBrand?: string | null;
                vehicleModel?: string | null;
                vehicleYear?: number | null;
                vehicleLicensePlate?: string | null;
                email?: string | null;
                vehicleVIN?: string | null;
                mileage?: number | null;
                fuelType?: Array<FuelType> | null;
                customerComment?: string | null;
                engineHorsePowerKw?: number | null;
                engineCapacityLiters?: number | null;
            } | null;
        };
        assignedEmployees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
        }>;
    }>;
};

export type MoreRepairsbookedMutationVariables = Exact<{
    orderId: Scalars["Float"];
}>;

export type MoreRepairsbookedMutation = {
    __typename?: "Mutation";
    moreRepairsbooked: boolean;
};

export type WorkshopCancelOrderMutationVariables = Exact<{
    p: WorkshopCancelOrder;
}>;

export type WorkshopCancelOrderMutation = {
    __typename?: "Mutation";
    workshopCancelOrder: Array<{
        __typename?: "CalendarEvent";
        ID: number;
        comments?: string | null;
        status: CalendarEventStatus;
        type: CalendarEventType;
        subType?: CalendarEventSubType | null;
        creationDate: any;
        startDate: any;
        endDate: any;
        items: Array<{
            __typename?: "CalendarEventItem";
            ID: string;
            name: string;
            price?: number | null;
            partsPrice?: number | null;
            serviceDefinition?: string | null;
            type: CalendarEventItemType;
            belongsToItem?: string | null;
        }>;
        order: {
            __typename?: "AuthedOrder";
            ID: number;
            vehicleYear?: number | null;
            fullName?: string | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            status: OrderStatus;
            phoneNumber?: string | null;
            createdAt: any;
            createdByWorkshop: boolean;
            clientArrived?: boolean | null;
            arrivalDate?: any | null;
            customerComment?: string | null;
            servicesJoinTimeoutAt?: any | null;
            customerPickTimeoutAt?: any | null;
            email?: string | null;
            fromBookNow: boolean;
            paymentSum?: number | null;
            clientWillWait: boolean;
            categories: Array<{
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
            workshopOrder?: {
                __typename?: "WorkshopOrder";
                ID: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                vehicleBrand?: string | null;
                vehicleModel?: string | null;
                vehicleYear?: number | null;
                vehicleLicensePlate?: string | null;
                email?: string | null;
                vehicleVIN?: string | null;
                mileage?: number | null;
                fuelType?: Array<FuelType> | null;
                customerComment?: string | null;
                engineHorsePowerKw?: number | null;
                engineCapacityLiters?: number | null;
            } | null;
        };
        assignedEmployees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
        }>;
    }>;
};

export type OrderSearchMutationVariables = Exact<{
    p: OrderSearchInput;
}>;

export type OrderSearchMutation = {
    __typename?: "Mutation";
    orderSearch: Array<{
        __typename?: "AuthedOrder";
        ID: number;
        vehicleYear?: number | null;
        fullName?: string | null;
        vehicleModel?: string | null;
        vehicleLicensePlate?: string | null;
        vehicleBrand?: string | null;
        status: OrderStatus;
        phoneNumber?: string | null;
        createdAt: any;
        createdByWorkshop: boolean;
        clientArrived?: boolean | null;
        arrivalDate?: any | null;
        customerComment?: string | null;
        servicesJoinTimeoutAt?: any | null;
        customerPickTimeoutAt?: any | null;
        email?: string | null;
        fromBookNow: boolean;
        paymentSum?: number | null;
        clientWillWait: boolean;
        categories: Array<{
            __typename?: "ServiceCategory";
            ID: string;
            durationMinsPerUnit: number;
            defaultUnitCount: number;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
        workshopOrder?: {
            __typename?: "WorkshopOrder";
            ID: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            vehicleBrand?: string | null;
            vehicleModel?: string | null;
            vehicleYear?: number | null;
            vehicleLicensePlate?: string | null;
            email?: string | null;
            vehicleVIN?: string | null;
            mileage?: number | null;
            fuelType?: Array<FuelType> | null;
            customerComment?: string | null;
            engineHorsePowerKw?: number | null;
            engineCapacityLiters?: number | null;
        } | null;
    }>;
};

export type OrderFragmentFragment = {
    __typename?: "AuthedOrder";
    ID: number;
    vehicleYear?: number | null;
    fullName?: string | null;
    vehicleModel?: string | null;
    vehicleLicensePlate?: string | null;
    vehicleBrand?: string | null;
    status: OrderStatus;
    phoneNumber?: string | null;
    createdAt: any;
    createdByWorkshop: boolean;
    clientArrived?: boolean | null;
    arrivalDate?: any | null;
    customerComment?: string | null;
    servicesJoinTimeoutAt?: any | null;
    customerPickTimeoutAt?: any | null;
    email?: string | null;
    fromBookNow: boolean;
    paymentSum?: number | null;
    clientWillWait: boolean;
    categories: Array<{
        __typename?: "ServiceCategory";
        ID: string;
        durationMinsPerUnit: number;
        defaultUnitCount: number;
        serviceName: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        };
    }>;
    workshopOrder?: {
        __typename?: "WorkshopOrder";
        ID: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        vehicleBrand?: string | null;
        vehicleModel?: string | null;
        vehicleYear?: number | null;
        vehicleLicensePlate?: string | null;
        email?: string | null;
        vehicleVIN?: string | null;
        mileage?: number | null;
        fuelType?: Array<FuelType> | null;
        customerComment?: string | null;
        engineHorsePowerKw?: number | null;
        engineCapacityLiters?: number | null;
    } | null;
};

export type CalendarEventFragmentFragment = {
    __typename?: "CalendarEvent";
    ID: number;
    comments?: string | null;
    status: CalendarEventStatus;
    type: CalendarEventType;
    subType?: CalendarEventSubType | null;
    creationDate: any;
    startDate: any;
    endDate: any;
    items: Array<{
        __typename?: "CalendarEventItem";
        ID: string;
        name: string;
        price?: number | null;
        partsPrice?: number | null;
        serviceDefinition?: string | null;
        type: CalendarEventItemType;
        belongsToItem?: string | null;
    }>;
    order: {
        __typename?: "AuthedOrder";
        ID: number;
        vehicleYear?: number | null;
        fullName?: string | null;
        vehicleModel?: string | null;
        vehicleLicensePlate?: string | null;
        vehicleBrand?: string | null;
        status: OrderStatus;
        phoneNumber?: string | null;
        createdAt: any;
        createdByWorkshop: boolean;
        clientArrived?: boolean | null;
        arrivalDate?: any | null;
        customerComment?: string | null;
        servicesJoinTimeoutAt?: any | null;
        customerPickTimeoutAt?: any | null;
        email?: string | null;
        fromBookNow: boolean;
        paymentSum?: number | null;
        clientWillWait: boolean;
        categories: Array<{
            __typename?: "ServiceCategory";
            ID: string;
            durationMinsPerUnit: number;
            defaultUnitCount: number;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
        workshopOrder?: {
            __typename?: "WorkshopOrder";
            ID: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            vehicleBrand?: string | null;
            vehicleModel?: string | null;
            vehicleYear?: number | null;
            vehicleLicensePlate?: string | null;
            email?: string | null;
            vehicleVIN?: string | null;
            mileage?: number | null;
            fuelType?: Array<FuelType> | null;
            customerComment?: string | null;
            engineHorsePowerKw?: number | null;
            engineCapacityLiters?: number | null;
        } | null;
    };
    assignedEmployees: Array<{
        __typename?: "WorkshopEmployee";
        ID: number;
        fullName: string;
    }>;
};

export type GetWorkshopCalendarEventsBetweenDatesQueryVariables = Exact<{
    p: GetWorkshopCalendarEventsInput;
}>;

export type GetWorkshopCalendarEventsBetweenDatesQuery = {
    __typename?: "Query";
    getWorkshopCalendarEventsBetweenDates: Array<{
        __typename?: "CalendarEvent";
        ID: number;
        comments?: string | null;
        status: CalendarEventStatus;
        type: CalendarEventType;
        subType?: CalendarEventSubType | null;
        creationDate: any;
        startDate: any;
        endDate: any;
        items: Array<{
            __typename?: "CalendarEventItem";
            ID: string;
            name: string;
            price?: number | null;
            partsPrice?: number | null;
            serviceDefinition?: string | null;
            type: CalendarEventItemType;
            belongsToItem?: string | null;
        }>;
        order: {
            __typename?: "AuthedOrder";
            ID: number;
            vehicleYear?: number | null;
            fullName?: string | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            status: OrderStatus;
            phoneNumber?: string | null;
            createdAt: any;
            createdByWorkshop: boolean;
            clientArrived?: boolean | null;
            arrivalDate?: any | null;
            customerComment?: string | null;
            servicesJoinTimeoutAt?: any | null;
            customerPickTimeoutAt?: any | null;
            email?: string | null;
            fromBookNow: boolean;
            paymentSum?: number | null;
            clientWillWait: boolean;
            categories: Array<{
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
            workshopOrder?: {
                __typename?: "WorkshopOrder";
                ID: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                vehicleBrand?: string | null;
                vehicleModel?: string | null;
                vehicleYear?: number | null;
                vehicleLicensePlate?: string | null;
                email?: string | null;
                vehicleVIN?: string | null;
                mileage?: number | null;
                fuelType?: Array<FuelType> | null;
                customerComment?: string | null;
                engineHorsePowerKw?: number | null;
                engineCapacityLiters?: number | null;
            } | null;
        };
        assignedEmployees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
        }>;
    }>;
    pendingInvitations: Array<{
        __typename?: "AuthedInvitation";
        ID: number;
        expiresAt: any;
        createdAt: any;
        status: ParticipationStatus;
        primary: boolean;
        order: {
            __typename?: "AuthedOrder";
            ID: number;
            vehicleYear?: number | null;
            fullName?: string | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            status: OrderStatus;
            phoneNumber?: string | null;
            createdAt: any;
            createdByWorkshop: boolean;
            clientArrived?: boolean | null;
            arrivalDate?: any | null;
            customerComment?: string | null;
            servicesJoinTimeoutAt?: any | null;
            customerPickTimeoutAt?: any | null;
            email?: string | null;
            fromBookNow: boolean;
            paymentSum?: number | null;
            clientWillWait: boolean;
            categories: Array<{
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
            workshopOrder?: {
                __typename?: "WorkshopOrder";
                ID: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                vehicleBrand?: string | null;
                vehicleModel?: string | null;
                vehicleYear?: number | null;
                vehicleLicensePlate?: string | null;
                email?: string | null;
                vehicleVIN?: string | null;
                mileage?: number | null;
                fuelType?: Array<FuelType> | null;
                customerComment?: string | null;
                engineHorsePowerKw?: number | null;
                engineCapacityLiters?: number | null;
            } | null;
        };
    }>;
};

export type UpdateCalendarEventMutationVariables = Exact<{
    event: UpdateCalendarEventInput;
}>;

export type UpdateCalendarEventMutation = {
    __typename?: "Mutation";
    updateCalendarEvent: {
        __typename?: "CalendarEvent";
        ID: number;
        comments?: string | null;
        status: CalendarEventStatus;
        type: CalendarEventType;
        subType?: CalendarEventSubType | null;
        creationDate: any;
        startDate: any;
        endDate: any;
        items: Array<{
            __typename?: "CalendarEventItem";
            ID: string;
            name: string;
            price?: number | null;
            partsPrice?: number | null;
            serviceDefinition?: string | null;
            type: CalendarEventItemType;
            belongsToItem?: string | null;
        }>;
        order: {
            __typename?: "AuthedOrder";
            ID: number;
            vehicleYear?: number | null;
            fullName?: string | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            status: OrderStatus;
            phoneNumber?: string | null;
            createdAt: any;
            createdByWorkshop: boolean;
            clientArrived?: boolean | null;
            arrivalDate?: any | null;
            customerComment?: string | null;
            servicesJoinTimeoutAt?: any | null;
            customerPickTimeoutAt?: any | null;
            email?: string | null;
            fromBookNow: boolean;
            paymentSum?: number | null;
            clientWillWait: boolean;
            categories: Array<{
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
            workshopOrder?: {
                __typename?: "WorkshopOrder";
                ID: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                vehicleBrand?: string | null;
                vehicleModel?: string | null;
                vehicleYear?: number | null;
                vehicleLicensePlate?: string | null;
                email?: string | null;
                vehicleVIN?: string | null;
                mileage?: number | null;
                fuelType?: Array<FuelType> | null;
                customerComment?: string | null;
                engineHorsePowerKw?: number | null;
                engineCapacityLiters?: number | null;
            } | null;
        };
        assignedEmployees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
        }>;
    };
};

export type CreateCalendarEventMutationVariables = Exact<{
    event: CreateCalendarEventInput;
    sendMessages?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CreateCalendarEventMutation = {
    __typename?: "Mutation";
    createCalendarEvent: {
        __typename?: "CalendarEvent";
        ID: number;
        comments?: string | null;
        status: CalendarEventStatus;
        type: CalendarEventType;
        subType?: CalendarEventSubType | null;
        creationDate: any;
        startDate: any;
        endDate: any;
        items: Array<{
            __typename?: "CalendarEventItem";
            ID: string;
            name: string;
            price?: number | null;
            partsPrice?: number | null;
            serviceDefinition?: string | null;
            type: CalendarEventItemType;
            belongsToItem?: string | null;
        }>;
        order: {
            __typename?: "AuthedOrder";
            ID: number;
            vehicleYear?: number | null;
            fullName?: string | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            status: OrderStatus;
            phoneNumber?: string | null;
            createdAt: any;
            createdByWorkshop: boolean;
            clientArrived?: boolean | null;
            arrivalDate?: any | null;
            customerComment?: string | null;
            servicesJoinTimeoutAt?: any | null;
            customerPickTimeoutAt?: any | null;
            email?: string | null;
            fromBookNow: boolean;
            paymentSum?: number | null;
            clientWillWait: boolean;
            categories: Array<{
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
            workshopOrder?: {
                __typename?: "WorkshopOrder";
                ID: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                vehicleBrand?: string | null;
                vehicleModel?: string | null;
                vehicleYear?: number | null;
                vehicleLicensePlate?: string | null;
                email?: string | null;
                vehicleVIN?: string | null;
                mileage?: number | null;
                fuelType?: Array<FuelType> | null;
                customerComment?: string | null;
                engineHorsePowerKw?: number | null;
                engineCapacityLiters?: number | null;
            } | null;
        };
        assignedEmployees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
        }>;
    };
};

export type DeleteCalendarEventMutationVariables = Exact<{
    eventId: Scalars["Float"];
}>;

export type DeleteCalendarEventMutation = {
    __typename?: "Mutation";
    deleteCalendarEvent: string;
};

export type CreateEventsMutationVariables = Exact<{
    events: Array<CreateCalendarEventInput> | CreateCalendarEventInput;
}>;

export type CreateEventsMutation = {
    __typename?: "Mutation";
    createEvents: Array<{
        __typename?: "CalendarEvent";
        ID: number;
        comments?: string | null;
        status: CalendarEventStatus;
        type: CalendarEventType;
        subType?: CalendarEventSubType | null;
        creationDate: any;
        startDate: any;
        endDate: any;
        items: Array<{
            __typename?: "CalendarEventItem";
            ID: string;
            name: string;
            price?: number | null;
            partsPrice?: number | null;
            serviceDefinition?: string | null;
            type: CalendarEventItemType;
            belongsToItem?: string | null;
        }>;
        order: {
            __typename?: "AuthedOrder";
            ID: number;
            vehicleYear?: number | null;
            fullName?: string | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            status: OrderStatus;
            phoneNumber?: string | null;
            createdAt: any;
            createdByWorkshop: boolean;
            clientArrived?: boolean | null;
            arrivalDate?: any | null;
            customerComment?: string | null;
            servicesJoinTimeoutAt?: any | null;
            customerPickTimeoutAt?: any | null;
            email?: string | null;
            fromBookNow: boolean;
            paymentSum?: number | null;
            clientWillWait: boolean;
            categories: Array<{
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
            }>;
            workshopOrder?: {
                __typename?: "WorkshopOrder";
                ID: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                vehicleBrand?: string | null;
                vehicleModel?: string | null;
                vehicleYear?: number | null;
                vehicleLicensePlate?: string | null;
                email?: string | null;
                vehicleVIN?: string | null;
                mileage?: number | null;
                fuelType?: Array<FuelType> | null;
                customerComment?: string | null;
                engineHorsePowerKw?: number | null;
                engineCapacityLiters?: number | null;
            } | null;
        };
        assignedEmployees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
        }>;
    }>;
};

export type AvailabilityUpdatedMutationVariables = Exact<{
    [key: string]: never;
}>;

export type AvailabilityUpdatedMutation = {
    __typename?: "Mutation";
    availabilityUpdated: boolean;
};

export type DashboardStatsQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardStatsQuery = {
    __typename?: "Query";
    dashboardStats: Array<{
        __typename?: "DashboardMonthStats";
        date: string;
        stats: {
            __typename?: "DashboardStats";
            ordersSum: number;
            externalOrdersSum: number;
            feePercentage: number;
            feeSum: number;
            avgOrderSize: number;
            expiredClients: number;
            acceptedClients: number;
            avgOrdersRating: number;
            reviewsCount: number;
            ordersCount: number;
            billableOrdersSum: number;
            dynamicFee: boolean;
            bookNowOrdersSum: number;
            declinedClients: number;
            declinedPunishableClients: number;
            returningOrdersCount: number;
            externalOrdersCount: number;
        };
    }>;
};

export type WorkshopLoginMutationVariables = Exact<{
    email: Scalars["String"];
}>;

export type WorkshopLoginMutation = {
    __typename?: "Mutation";
    workshopLogin: boolean;
};

export type UpsertInvoiceMutationVariables = Exact<{
    invoice: InvoiceUpsert;
}>;

export type UpsertInvoiceMutation = {
    __typename?: "Mutation";
    upsertInvoice: {
        __typename?: "Invoice";
        ID: string;
        seriesID?: number | null;
        series?: string | null;
        order: number;
        comments?: string | null;
        companyCode: string;
        vatCode?: string | null;
        address: string;
        paymentDetails?: string | null;
        clientName?: string | null;
        clientEmail?: string | null;
        clientCompanyCode?: string | null;
        clientVatCode?: string | null;
        clientAddress?: string | null;
        paymentDueDate: any;
        issuanceDate: any;
        creationDate: any;
        type: InvoiceType;
        items: Array<{
            __typename?: "InvoiceItem";
            ID: string;
            name: string;
            price: number;
            count: number;
            type: InvoiceItemType;
            belongsToItem?: string | null;
            partCode?: string | null;
        }>;
    };
};

export type SendInvoiceToEmailMutationVariables = Exact<{
    p: SendInvoiceToEmailInput;
}>;

export type SendInvoiceToEmailMutation = {
    __typename?: "Mutation";
    sendInvoiceToEmail: boolean;
};

export type DownloadInvoiceMutationVariables = Exact<{
    p: DownloadInvoiceInput;
}>;

export type DownloadInvoiceMutation = {
    __typename?: "Mutation";
    downloadInvoice: string;
};

export type GetNextSeriesIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetNextSeriesIdQuery = {
    __typename?: "Query";
    getNextSeriesID: number;
};

export type InvoiceFragFragment = {
    __typename?: "Invoice";
    ID: string;
    seriesID?: number | null;
    series?: string | null;
    order: number;
    comments?: string | null;
    companyCode: string;
    vatCode?: string | null;
    address: string;
    paymentDetails?: string | null;
    clientName?: string | null;
    clientEmail?: string | null;
    clientCompanyCode?: string | null;
    clientVatCode?: string | null;
    clientAddress?: string | null;
    paymentDueDate: any;
    issuanceDate: any;
    creationDate: any;
    type: InvoiceType;
    items: Array<{
        __typename?: "InvoiceItem";
        ID: string;
        name: string;
        price: number;
        count: number;
        type: InvoiceItemType;
        belongsToItem?: string | null;
        partCode?: string | null;
    }>;
};

export type AuthedWorkshopOrdersQueryVariables = Exact<{
    [key: string]: never;
}>;

export type AuthedWorkshopOrdersQuery = {
    __typename?: "Query";
    authedWorkshopOrders: Array<{
        __typename?: "AuthedOrder";
        ID: number;
        createdAt: any;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        vehicleBrand?: string | null;
        vehicleModel?: string | null;
        vehicleYear?: number | null;
        vehicleVIN?: string | null;
        vehicleBody?: string | null;
        createdByWorkshop: boolean;
        vehicleLicensePlate?: string | null;
        clientArrived?: boolean | null;
        status: OrderStatus;
        arrivalDate?: any | null;
        customerComment?: string | null;
        categories: Array<{
            __typename?: "ServiceCategory";
            ID: string;
            durationMinsPerUnit: number;
            defaultUnitCount: number;
            serviceName: {
                __typename?: "Translatable";
                defaultTranslation: string;
                id: string;
                customID?: string | null;
            };
        }>;
        workshopOrder?: {
            __typename?: "WorkshopOrder";
            vehicleYear?: number | null;
            vehicleModel?: string | null;
            vehicleLicensePlate?: string | null;
            vehicleBrand?: string | null;
            phoneNumber?: string | null;
            fullName?: string | null;
            email?: string | null;
        } | null;
        calendarEvents: Array<{
            __typename?: "CalendarEvent";
            comments?: string | null;
            status: CalendarEventStatus;
            type: CalendarEventType;
            assignedEmployees: Array<{
                __typename?: "WorkshopEmployee";
                fullName: string;
            }>;
            items: Array<{
                __typename?: "CalendarEventItem";
                ID: string;
                name: string;
                price?: number | null;
                serviceDefinition?: string | null;
                type: CalendarEventItemType;
                belongsToItem?: string | null;
                partsPrice?: number | null;
            }>;
        }>;
        invoice?: {
            __typename?: "Invoice";
            ID: string;
            seriesID?: number | null;
            series?: string | null;
            order: number;
            comments?: string | null;
            companyCode: string;
            vatCode?: string | null;
            address: string;
            paymentDetails?: string | null;
            clientName?: string | null;
            clientEmail?: string | null;
            clientCompanyCode?: string | null;
            clientVatCode?: string | null;
            clientAddress?: string | null;
            paymentDueDate: any;
            issuanceDate: any;
            creationDate: any;
            type: InvoiceType;
            items: Array<{
                __typename?: "InvoiceItem";
                ID: string;
                name: string;
                price: number;
                count: number;
                type: InvoiceItemType;
                belongsToItem?: string | null;
                partCode?: string | null;
            }>;
        } | null;
    }>;
};

export type DeleteOrderMutationVariables = Exact<{
    orderId: Scalars["Float"];
}>;

export type DeleteOrderMutation = {
    __typename?: "Mutation";
    deleteOrder: boolean;
};

export type DuplicateWorkshopMutationVariables = Exact<{
    [key: string]: never;
}>;

export type DuplicateWorkshopMutation = {
    __typename?: "Mutation";
    duplicateWorkshop: boolean;
};

export type OptOutOfServicesMutationVariables = Exact<{ [key: string]: never }>;

export type OptOutOfServicesMutation = {
    __typename?: "Mutation";
    optOutOfServices: boolean;
};

export type UpdateWorkshopMutationVariables = Exact<{
    p: UpdateWorkshopInput;
}>;

export type UpdateWorkshopMutation = {
    __typename?: "Mutation";
    updateWorkshop: {
        __typename?: "AuthedWorkshop";
        ID: number;
        name: string;
        address: string;
        microdistrict?: string | null;
        phoneNumber: string;
        city: string;
        disabled: boolean;
        disableReason?: DisableReason | null;
        selfDisabled: boolean;
        email: string;
        hourCost: number;
        servicedBrands: Array<string>;
        logoURL: string;
        posX: number;
        photosUrls: Array<string>;
        posY: number;
        gmapUrl: string;
        scrapeGmapUrl?: string | null;
        reviewCountGmap?: number | null;
        country: GqlCountry;
        reviewRatingGmap?: number | null;
        workingSinceYear?: number | null;
        altFlowEnabled: boolean;
        onboardingCompleted: boolean;
        creationDate: any;
        opp: boolean;
        up: boolean;
        availabilityValidUntil?: any | null;
        linksAdded: boolean;
        averageRatingScore: number;
        internalNotes?: string | null;
        firstEnabledDate?: any | null;
        description?: string | null;
        acceptanceDaysMargin?: number | null;
        acceptanceDaysMarginSystem?: number | null;
        amenities: Array<Amenity>;
        hourCosts?: {
            __typename?: "HourCosts";
            PC_MAINTENANCE?: number | null;
            PC_SUSPENSION?: number | null;
            PC_DETAILING?: number | null;
            PC_GEARBOX?: number | null;
            PC_INSPECTION?: number | null;
            PC_STEERING?: number | null;
            PC_HEADLIGHTS?: number | null;
            PC_ELECTRICITY?: number | null;
            PC_EXHAUST_SYSTEM?: number | null;
            PC_CONDITIONER?: number | null;
            PC_WHEELS?: number | null;
            PC_INTERIOR?: number | null;
            PC_ENGINE?: number | null;
            PC_BRAKES?: number | null;
            PC_GLASS?: number | null;
            PC_BODY?: number | null;
        } | null;
        blockedTimeslots: Array<{
            __typename?: "BlockedTimeslot";
            startDate: any;
            endDate: any;
            blockedServicesIDs?: Array<string> | null;
            employeesIDs?: Array<number> | null;
        }>;
        ratingScoreDetails: {
            __typename?: "RatingScoreDetails";
            expiresDeclinesPenaltyScore: number;
            acceptedInvitations: number;
            descriptionScore: number;
            photosScore: number;
            ordersReportsPenaltyScore: number;
            ordersReports: number;
            finalScore: number;
            expiredDeclinedInvitations: number;
            priceScore: number;
            availabilityScore: number;
            cityRank: number;
            cityRankLowest: number;
            amenitiesScore: number;
        };
        company: {
            __typename?: "Company";
            ID: number;
            name: string;
            companyCode?: string | null;
            vatCode?: string | null;
            address?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            accountingEmail?: string | null;
            paymentDetails?: string | null;
            trialEndsOnDate?: any | null;
            feePercent: number;
        };
        employees: Array<{
            __typename?: "WorkshopEmployee";
            ID: number;
            fullName: string;
            role: string;
            disabled: boolean;
            servicesDefinitionsIDs: Array<string>;
            workHoursCalendar?: {
                __typename?: "Calendar";
                monday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                tuesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                wednesday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                thursday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                friday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                saturday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
                sunday?: {
                    __typename?: "CalendarDay";
                    open: { __typename?: "Time"; hour: number; minute: number };
                    close: {
                        __typename?: "Time";
                        hour: number;
                        minute: number;
                    };
                } | null;
            } | null;
        }>;
        services: Array<{
            __typename?: "WorkshopService";
            fixedPrice?: number | null;
            specialized: boolean;
            disabled: boolean;
            fromPrice?: number | null;
            toPrice?: number | null;
            durationFromMins?: number | null;
            durationToMins?: number | null;
            type: {
                __typename?: "ServiceCategory";
                ID: string;
                durationMinsPerUnit: number;
                defaultUnitCount: number;
                important: boolean;
                sortOrder: number;
                priceType: ServicePriceType;
                serviceName: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
                searchAliases?: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                } | null;
                partCategory: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                };
                defaultUnit?: {
                    __typename?: "Translatable";
                    defaultTranslation: string;
                    id: string;
                    customID?: string | null;
                } | null;
                categorization: Array<{
                    __typename?: "Categorization";
                    sortOrder: number;
                    partCategory: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    };
                    subCategory?: {
                        __typename?: "Translatable";
                        defaultTranslation: string;
                        id: string;
                        customID?: string | null;
                    } | null;
                }>;
            };
        }>;
        lunchHoursCalendar: {
            __typename?: "Calendar";
            monday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            tuesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            wednesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            thursday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            friday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            saturday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            sunday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
        };
        workHoursCalendar: {
            __typename?: "Calendar";
            monday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            tuesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            wednesday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            thursday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            friday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            saturday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
            sunday?: {
                __typename?: "CalendarDay";
                open: { __typename?: "Time"; hour: number; minute: number };
                close: { __typename?: "Time"; hour: number; minute: number };
            } | null;
        };
    };
};

export type FindEvenInactiveServicesQueryVariables = Exact<{
    findAllCategoriesParams: FindAllCategoriesInput;
}>;

export type FindEvenInactiveServicesQuery = {
    __typename?: "Query";
    findAllCategories: Array<{
        __typename?: "ServiceCategory";
        ID: string;
        durationMinsPerUnit: number;
        defaultUnitCount: number;
        important: boolean;
        sortOrder: number;
        priceType: ServicePriceType;
        serviceName: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        };
        partCategory: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        };
        defaultUnit?: {
            __typename?: "Translatable";
            defaultTranslation: string;
            id: string;
            customID?: string | null;
        } | null;
    }>;
};

export type SignUpWorkshopMutationVariables = Exact<{
    input: WorkshopSignupInput;
}>;

export type SignUpWorkshopMutation = {
    __typename?: "Mutation";
    signUpWorkshop: { __typename?: "WorkshopSignupResult"; magicLink: string };
};

export const HourCostsFrag = gql`
    fragment HourCostsFrag on HourCosts {
        PC_MAINTENANCE
        PC_SUSPENSION
        PC_DETAILING
        PC_GEARBOX
        PC_INSPECTION
        PC_STEERING
        PC_HEADLIGHTS
        PC_ELECTRICITY
        PC_EXHAUST_SYSTEM
        PC_CONDITIONER
        PC_WHEELS
        PC_INTERIOR
        PC_ENGINE
        PC_BRAKES
        PC_GLASS
        PC_BODY
    }
`;
export const TranslatableFrag = gql`
    fragment TranslatableFrag on Translatable {
        defaultTranslation
        id
        customID
    }
`;
export const CalendarFrag = gql`
    fragment CalendarFrag on Calendar {
        monday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        tuesday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        wednesday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        thursday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        friday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        saturday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        sunday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
    }
`;
export const WorkshopFragmentFec = gql`
    fragment workshopFragmentFEC on Workshop {
        ID
        address
        name
        city
        hourCost
        hourCosts {
            ...HourCostsFrag
        }
        posX
        posY
        logoURL
        reviewCountGmap
        reviewRatingGmap
        microdistrict
        ratingScore
        specialized {
            vehicleBrands
            servicesIDs
        }
        services {
            fixedPrice
            fromPrice
            toPrice
            durationFromMins
            durationToMins
            partsPrice
            type {
                ID
                serviceName {
                    ...TranslatableFrag
                }
                defaultUnitCount
                durationMinsPerUnit
                partCategory {
                    ...TranslatableFrag
                }
                priceType
                defaultUnit {
                    ...TranslatableFrag
                }
            }
        }
        opp
        up
        badgeOrdersCount
        earliestAvailableDate
        lunchHoursCalendar {
            ...CalendarFrag
        }
        workHoursCalendar {
            ...CalendarFrag
        }
    }
    ${HourCostsFrag}
    ${TranslatableFrag}
    ${CalendarFrag}
`;
export const BookServiceOrderRes = gql`
    fragment bookServiceOrderRes on Order {
        ID
        createdAt
        city
        phoneNumber
        email
        vehicleBrand
        vehicleModel
        vehicleYear
        userAccessToken
        status
        arrivalDate
        createdByWorkshop
        billable
        customerComment
        goodPrice
        servicesCompletion
        categories {
            ID
            serviceName {
                ...TranslatableFrag
            }
        }
    }
    ${TranslatableFrag}
`;
export const BlockedTimeslotsFrag = gql`
    fragment blockedTimeslotsFrag on BlockedTimeslot {
        startDate
        endDate
        blockedServicesIDs
        employeesIDs
    }
`;
export const AuthedWorkshop = gql`
    fragment authedWorkshop on AuthedWorkshop {
        ID
        name
        address
        microdistrict
        phoneNumber
        city
        disabled
        disableReason
        selfDisabled
        email
        hourCost
        servicedBrands
        logoURL
        posX
        photosUrls
        posY
        gmapUrl
        scrapeGmapUrl
        reviewCountGmap
        country
        reviewRatingGmap
        workingSinceYear
        altFlowEnabled
        onboardingCompleted
        creationDate
        opp
        up
        availabilityValidUntil
        linksAdded
        averageRatingScore
        internalNotes
        firstEnabledDate
        hourCosts {
            ...HourCostsFrag
        }
        blockedTimeslots {
            startDate
            endDate
            blockedServicesIDs
            employeesIDs
        }
        ratingScoreDetails {
            expiresDeclinesPenaltyScore
            acceptedInvitations
            descriptionScore
            photosScore
            ordersReportsPenaltyScore
            ordersReports
            finalScore
            expiredDeclinedInvitations
            priceScore
            availabilityScore
            cityRank
            cityRankLowest
            amenitiesScore
        }
        company {
            ID
            name
            companyCode
            vatCode
            address
            email
            phoneNumber
            accountingEmail
            paymentDetails
            trialEndsOnDate
            feePercent
        }
        employees {
            ID
            fullName
            role
            disabled
            workHoursCalendar {
                ...CalendarFrag
            }
            servicesDefinitionsIDs
        }
        services {
            type {
                ID
                serviceName {
                    ...TranslatableFrag
                }
                searchAliases {
                    ...TranslatableFrag
                }
                partCategory {
                    ...TranslatableFrag
                }
                durationMinsPerUnit
                defaultUnit {
                    ...TranslatableFrag
                }
                defaultUnitCount
                important
                sortOrder
                priceType
                categorization {
                    sortOrder
                    partCategory {
                        ...TranslatableFrag
                    }
                    subCategory {
                        ...TranslatableFrag
                    }
                }
            }
            fixedPrice
            specialized
            disabled
            fromPrice
            toPrice
            durationFromMins
            durationToMins
        }
        description
        acceptanceDaysMargin
        acceptanceDaysMarginSystem
        amenities
        lunchHoursCalendar {
            ...CalendarFrag
        }
        workHoursCalendar {
            ...CalendarFrag
        }
    }
    ${HourCostsFrag}
    ${CalendarFrag}
    ${TranslatableFrag}
`;
export const OrderFragment = gql`
    fragment orderFragment on AuthedOrder {
        ID
        vehicleYear
        fullName
        vehicleModel
        vehicleLicensePlate
        vehicleBrand
        status
        phoneNumber
        createdAt
        createdByWorkshop
        clientArrived
        categories {
            ID
            serviceName {
                ...TranslatableFrag
            }
            durationMinsPerUnit
            defaultUnitCount
        }
        arrivalDate
        customerComment
        servicesJoinTimeoutAt
        customerPickTimeoutAt
        email
        fromBookNow
        paymentSum
        clientWillWait
        workshopOrder {
            ID
            fullName
            phoneNumber
            vehicleBrand
            vehicleModel
            vehicleYear
            vehicleLicensePlate
            email
            vehicleVIN
            mileage
            fuelType
            customerComment
            engineHorsePowerKw
            engineCapacityLiters
        }
    }
    ${TranslatableFrag}
`;
export const CalendarEventFragment = gql`
    fragment calendarEventFragment on CalendarEvent {
        ID
        comments
        status
        items {
            ID
            name
            price
            partsPrice
            serviceDefinition
            type
            belongsToItem
        }
        order {
            ...orderFragment
        }
        assignedEmployees {
            ID
            fullName
        }
        type
        subType
        creationDate
        startDate
        endDate
        creationDate
    }
    ${OrderFragment}
`;
export const InvoiceFrag = gql`
    fragment InvoiceFrag on Invoice {
        ID
        seriesID
        series
        items {
            ID
            name
            price
            count
            type
            belongsToItem
            partCode
        }
        order
        comments
        companyCode
        vatCode
        seriesID
        address
        paymentDetails
        clientName
        clientEmail
        clientCompanyCode
        clientVatCode
        clientAddress
        paymentDueDate
        issuanceDate
        creationDate
        type
    }
`;
export const InitSearchFormInfo = gql`
    query initSearchFormInfo(
        $getEarliestAvailableTimeParams: GetEarliestAvailableTimeInput!
        $findAllCategoriesParams: FindAllCategoriesInput!
        $includeGetEarliestAvailableTime: Boolean!
    ) {
        findAllCategories(params: $findAllCategoriesParams) {
            serviceName {
                ...TranslatableFrag
            }
            searchAliases {
                ...TranslatableFrag
            }
            ID
            defaultUnitCount
            durationMinsPerUnit
            partCategory {
                ...TranslatableFrag
            }
            sortOrder
            important
            priceType
            categorization {
                sortOrder
                partCategory {
                    ...TranslatableFrag
                }
                subCategory {
                    ...TranslatableFrag
                }
            }
        }
        getEarliestAvailableTime(params: $getEarliestAvailableTimeParams)
            @include(if: $includeGetEarliestAvailableTime) {
            earliestAvailableTime
        }
        findAllServicePackages {
            ID
            name {
                ...TranslatableFrag
            }
            partCategory {
                ...TranslatableFrag
            }
            servicesDefinitionsIDs
            recommendationText {
                ...TranslatableFrag
            }
            sortOrder
            imgUrl
        }
    }
    ${TranslatableFrag}
`;
export const CustomersAcceptInvitation = gql`
    mutation CustomersAcceptInvitation(
        $invitationId: Float!
        $newArrivalDate: DateTime
        $accessToken: String
    ) {
        customersAcceptInvitation(
            invitationID: $invitationId
            newArrivalDate: $newArrivalDate
            accessToken: $accessToken
        ) {
            ...bookServiceOrderRes
        }
    }
    ${BookServiceOrderRes}
`;
export const Order = gql`
    query Order($id: Int!, $accessToken: String!) {
        order(ID: $id, accessToken: $accessToken) {
            ID
            createdAt
            city
            email
            phoneNumber
            vehicleBrand
            vehicleModel
            vehicleYear
            vehicleVIN
            vehicleBody
            vehicleLicensePlate
            userAccessToken
            status
            marketingConsent
            arrivalDate
            customerPickTimeoutAt
            customerComment
            createdByWorkshop
            billable
            goodPrice
            servicesCompletion
            clientWillWait
            acceptedService {
                ...workshopFragmentFEC
            }
            categories {
                ID
                serviceName {
                    ...TranslatableFrag
                }
            }
            invitations {
                ID
                primary
                status
                service {
                    ...workshopFragmentFEC
                    blockedTimeslots {
                        ...blockedTimeslotsFrag
                    }
                    employees {
                        ID
                        workHoursCalendar {
                            ...CalendarFrag
                        }
                        servicesDefinitionsIDs
                    }
                }
                expiresAt
                newSuggestedArrivalDate
            }
            workshopOrder {
                phoneNumber
                email
            }
        }
    }
    ${WorkshopFragmentFec}
    ${TranslatableFrag}
    ${BlockedTimeslotsFrag}
    ${CalendarFrag}
`;
export const RecommendedWorkshops = gql`
    query RecommendedWorkshops {
        recommendedWorkshops {
            name
            ID
            address
            city
            microdistrict
            reviewRatingGmap
            logoURL
            reviewCountGmap
        }
    }
`;
export const RecentReviews = gql`
    query RecentReviews {
        recentReviews {
            ID
            rating
            text
            authorName
            serviceNames {
                ...TranslatableFrag
            }
            vehicle {
                brand
                model
                year
            }
            createdAt
            workshop {
                name
                city
            }
        }
    }
    ${TranslatableFrag}
`;
export const InitiateSearch = gql`
    mutation InitiateSearch($params: SearchInput!) {
        initiateSearch(params: $params) {
            services {
                ...workshopFragmentFEC
            }
            order {
                ID
                createdAt
                arrivalDate
                phoneNumber
                vehicleBrand
                vehicleModel
                vehicleBody
                vehicleYear
                vehicleVIN
                vehicleLicensePlate
                userAccessToken
                categories {
                    serviceName {
                        ...TranslatableFrag
                    }
                }
            }
        }
    }
    ${WorkshopFragmentFec}
    ${TranslatableFrag}
`;
export const BookService = gql`
    mutation BookService(
        $bookServiceInput: BookServiceInput!
        $updateOrderInput: UpdateOrderInput!
    ) {
        updateOrder(p: $updateOrderInput) {
            ID
        }
        bookService(p: $bookServiceInput) {
            ...bookServiceOrderRes
        }
    }
    ${BookServiceOrderRes}
`;
export const BookServiceWithoutOrder = gql`
    mutation BookServiceWithoutOrder($p: BookServiceWithoutOrderInput!) {
        bookServiceWithoutOrder(p: $p) {
            ...bookServiceOrderRes
        }
    }
    ${BookServiceOrderRes}
`;
export const ReservationConfirmationInitData = gql`
    query reservationConfirmationInitData(
        $getEarliestAvailableTimeParams: GetEarliestAvailableTimeInput!
        $workshopID: Int!
    ) {
        getEarliestAvailableTime(params: $getEarliestAvailableTimeParams) {
            earliestAvailableTime
        }
        service(ID: $workshopID) {
            servicedBrands
            blockedTimeslots {
                ...blockedTimeslotsFrag
            }
            employees {
                ID
                workHoursCalendar {
                    ...CalendarFrag
                }
                servicesDefinitionsIDs
            }
        }
    }
    ${BlockedTimeslotsFrag}
    ${CalendarFrag}
`;
export const CancelOrder = gql`
    mutation CancelOrder($p: CancelOrder!) {
        customerCancelOrder(p: $p)
    }
`;
export const ActivateCancelDiscount = gql`
    mutation ActivateCancelDiscount($p: ActivateCancelDiscountInput!) {
        activateCancelDiscount(p: $p)
    }
`;
export const CreateReview = gql`
    mutation CreateReview(
        $params: CreateReviewInput!
        $orderId: Int!
        $accessToken: String!
    ) {
        createReview(
            params: $params
            orderID: $orderId
            accessToken: $accessToken
        ) {
            ID
            rating
            text
            authorName
            createdAt
        }
    }
`;
export const OrderReviewSummary = gql`
    query OrderReviewSummary($orderId: Int!, $accessToken: String!) {
        order(ID: $orderId, accessToken: $accessToken) {
            ID
            acceptedService {
                name
                address
                city
            }
            categories {
                serviceName {
                    ...TranslatableFrag
                }
            }
            paymentSum
            createdByWorkshop
            vehicleBrand
            vehicleModel
            vehicleYear
            status
            goodPrice
            servicesCompletion
        }
        orderReview(orderID: $orderId, accessToken: $accessToken) {
            ID
            text
            rating
            authorName
            createdAt
        }
    }
    ${TranslatableFrag}
`;
export const UpdateWarrantyInfo = gql`
    mutation UpdateWarrantyInfo($p: UpdateWarrantyInfo!) {
        updateWarrantyInfo(p: $p)
    }
`;
export const GetService = gql`
    query getService($vehicle: VehicleInput, $id: Int!) {
        service(ID: $id) {
            ID
            name
            address
            city
            hourCost
            hourCosts {
                ...HourCostsFrag
            }
            logoURL
            posX
            posY
            reviewCountGmap
            reviewRatingGmap
            description
            isTrustedPartner
            workingSinceYear
            microdistrict
            photosUrls
            disabled
            selfDisabled
            country
            acceptsClientsWithOwnParts
            servicedBrands
            amenities
            specialized {
                vehicleBrands
                servicesIDs
            }
            opp
            up
            ratingScore
            disableReason
            blockedTimeslots {
                ...blockedTimeslotsFrag
            }
            workHoursCalendar {
                ...CalendarFrag
            }
            lunchHoursCalendar {
                ...CalendarFrag
            }
            orderReviews {
                text
                authorName
                rating
                createdAt
                serviceNames {
                    ...TranslatableFrag
                }
                vehicle {
                    brand
                    model
                    year
                }
            }
            services(vehicle: $vehicle) {
                type {
                    ID
                    serviceName {
                        ...TranslatableFrag
                    }
                    searchAliases {
                        ...TranslatableFrag
                    }
                    durationMinsPerUnit
                    defaultUnit {
                        ...TranslatableFrag
                    }
                    defaultUnitCount
                    partCategory {
                        ...TranslatableFrag
                    }
                    sortOrder
                    important
                    priceType
                    categorization {
                        sortOrder
                        partCategory {
                            ...TranslatableFrag
                        }
                        subCategory {
                            ...TranslatableFrag
                        }
                    }
                }
                specialized
                fixedPrice
                fromPrice
                toPrice
                durationFromMins
                durationToMins
                disabled
                partsPrice
            }
            employees {
                ID
                servicesDefinitionsIDs
                workHoursCalendar {
                    ...CalendarFrag
                }
            }
            servicePackages {
                ID
                name {
                    ...TranslatableFrag
                }
                recommendationText {
                    ...TranslatableFrag
                }
                partCategory {
                    ...TranslatableFrag
                }
                servicesDefinitionsIDs
                recommendationText {
                    ...TranslatableFrag
                }
                sortOrder
                imgUrl
            }
            earliestAvailableDate
        }
    }
    ${HourCostsFrag}
    ${BlockedTimeslotsFrag}
    ${CalendarFrag}
    ${TranslatableFrag}
`;
export const WorkshopFromSession = gql`
    query WorkshopFromSession {
        workshopFromSession {
            ...authedWorkshop
        }
    }
    ${AuthedWorkshop}
`;
export const AcceptInvitationAuthed = gql`
    mutation AcceptInvitationAuthed($invitationId: Float!) {
        acceptInvitationAuthed(invitationID: $invitationId)
    }
`;
export const RejectInvitationAuthed = gql`
    mutation RejectInvitationAuthed($p: RejectInvitationAuthed!) {
        rejectInvitationAuthed(p: $p) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragment}
`;
export const FindAllCategories = gql`
    query findAllCategories($findAllCategoriesParams: FindAllCategoriesInput!) {
        findAllCategories(params: $findAllCategoriesParams) {
            ID
            searchAliases {
                ...TranslatableFrag
            }
            serviceName {
                ...TranslatableFrag
            }
        }
    }
    ${TranslatableFrag}
`;
export const UpdateClientArrival = gql`
    mutation UpdateClientArrival(
        $updateClientArrivalInput: UpdateClientArrivalInput!
        $updateEventInput: UpdateCalendarEventInput!
    ) {
        updateCalendarEvent(event: $updateEventInput) {
            ...calendarEventFragment
        }
        updateClientArrival(p: $updateClientArrivalInput) {
            ID
            vehicleYear
            fullName
            vehicleModel
            vehicleLicensePlate
            vehicleBrand
            status
            phoneNumber
            createdAt
            categories {
                ID
                serviceName {
                    ...TranslatableFrag
                }
            }
            arrivalDate
            workshopOrder {
                ID
                fullName
                phoneNumber
                vehicleBrand
                vehicleModel
                vehicleYear
                vehicleLicensePlate
            }
        }
    }
    ${CalendarEventFragment}
    ${TranslatableFrag}
`;
export const FinalizeCalendarEventOrder = gql`
    mutation FinalizeCalendarEventOrder($p: FinalizeCalendarEventOrderInput!) {
        finalizeCalendarEventOrder(p: $p) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragment}
`;
export const MoreRepairsbooked = gql`
    mutation MoreRepairsbooked($orderId: Float!) {
        moreRepairsbooked(orderID: $orderId)
    }
`;
export const WorkshopCancelOrder = gql`
    mutation WorkshopCancelOrder($p: WorkshopCancelOrder!) {
        workshopCancelOrder(p: $p) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragment}
`;
export const OrderSearch = gql`
    mutation OrderSearch($p: OrderSearchInput!) {
        orderSearch(p: $p) {
            ...orderFragment
        }
    }
    ${OrderFragment}
`;
export const GetWorkshopCalendarEventsBetweenDates = gql`
    query GetWorkshopCalendarEventsBetweenDates(
        $p: GetWorkshopCalendarEventsInput!
    ) {
        getWorkshopCalendarEventsBetweenDates(p: $p) {
            ...calendarEventFragment
        }
        pendingInvitations {
            ID
            expiresAt
            createdAt
            status
            primary
            order {
                ...orderFragment
            }
        }
    }
    ${CalendarEventFragment}
    ${OrderFragment}
`;
export const UpdateCalendarEvent = gql`
    mutation UpdateCalendarEvent($event: UpdateCalendarEventInput!) {
        updateCalendarEvent(event: $event) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragment}
`;
export const CreateCalendarEvent = gql`
    mutation CreateCalendarEvent(
        $event: CreateCalendarEventInput!
        $sendMessages: Boolean
    ) {
        createCalendarEvent(event: $event, sendMessages: $sendMessages) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragment}
`;
export const DeleteCalendarEvent = gql`
    mutation DeleteCalendarEvent($eventId: Float!) {
        deleteCalendarEvent(eventID: $eventId)
    }
`;
export const CreateEvents = gql`
    mutation CreateEvents($events: [CreateCalendarEventInput!]!) {
        createEvents(events: $events) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragment}
`;
export const AvailabilityUpdated = gql`
    mutation availabilityUpdated {
        availabilityUpdated
    }
`;
export const DashboardStats = gql`
    query DashboardStats {
        dashboardStats {
            stats {
                ordersSum
                externalOrdersSum
                feePercentage
                feeSum
                avgOrderSize
                expiredClients
                acceptedClients
                avgOrdersRating
                reviewsCount
                ordersCount
                billableOrdersSum
                reviewsCount
                dynamicFee
                bookNowOrdersSum
                declinedClients
                declinedPunishableClients
                returningOrdersCount
                externalOrdersCount
            }
            date
        }
    }
`;
export const WorkshopLogin = gql`
    mutation WorkshopLogin($email: String!) {
        workshopLogin(email: $email)
    }
`;
export const UpsertInvoice = gql`
    mutation UpsertInvoice($invoice: InvoiceUpsert!) {
        upsertInvoice(invoice: $invoice) {
            ...InvoiceFrag
        }
    }
    ${InvoiceFrag}
`;
export const SendInvoiceToEmail = gql`
    mutation sendInvoiceToEmail($p: SendInvoiceToEmailInput!) {
        sendInvoiceToEmail(p: $p)
    }
`;
export const DownloadInvoice = gql`
    mutation downloadInvoice($p: DownloadInvoiceInput!) {
        downloadInvoice(p: $p)
    }
`;
export const GetNextSeriesId = gql`
    query getNextSeriesID {
        getNextSeriesID
    }
`;
export const AuthedWorkshopOrders = gql`
    query AuthedWorkshopOrders {
        authedWorkshopOrders {
            ID
            createdAt
            fullName
            email
            phoneNumber
            vehicleBrand
            vehicleModel
            vehicleYear
            vehicleVIN
            vehicleBody
            createdByWorkshop
            vehicleLicensePlate
            clientArrived
            status
            categories {
                ID
                serviceName {
                    ...TranslatableFrag
                }
                durationMinsPerUnit
                defaultUnitCount
            }
            arrivalDate
            customerComment
            workshopOrder {
                vehicleYear
                vehicleModel
                vehicleLicensePlate
                vehicleBrand
                phoneNumber
                fullName
                email
            }
            calendarEvents {
                comments
                assignedEmployees {
                    fullName
                }
                status
                type
                items {
                    ID
                    name
                    price
                    serviceDefinition
                    type
                    belongsToItem
                    partsPrice
                }
            }
            invoice {
                ...InvoiceFrag
            }
        }
    }
    ${TranslatableFrag}
    ${InvoiceFrag}
`;
export const DeleteOrder = gql`
    mutation DeleteOrder($orderId: Float!) {
        deleteOrder(orderID: $orderId)
    }
`;
export const DuplicateWorkshop = gql`
    mutation duplicateWorkshop {
        duplicateWorkshop
    }
`;
export const OptOutOfServices = gql`
    mutation OptOutOfServices {
        optOutOfServices
    }
`;
export const UpdateWorkshop = gql`
    mutation UpdateWorkshop($p: UpdateWorkshopInput!) {
        updateWorkshop(p: $p) {
            ...authedWorkshop
        }
    }
    ${AuthedWorkshop}
`;
export const FindEvenInactiveServices = gql`
    query findEvenInactiveServices(
        $findAllCategoriesParams: FindAllCategoriesInput!
    ) {
        findAllCategories(params: $findAllCategoriesParams) {
            serviceName {
                ...TranslatableFrag
            }
            ID
            partCategory {
                ...TranslatableFrag
            }
            durationMinsPerUnit
            defaultUnit {
                ...TranslatableFrag
            }
            defaultUnitCount
            important
            sortOrder
            priceType
        }
    }
    ${TranslatableFrag}
`;
export const SignUpWorkshop = gql`
    mutation SignUpWorkshop($input: WorkshopSignupInput!) {
        signUpWorkshop(input: $input) {
            magicLink
        }
    }
`;
export const HourCostsFragFragmentDoc = gql`
    fragment HourCostsFrag on HourCosts {
        PC_MAINTENANCE
        PC_SUSPENSION
        PC_DETAILING
        PC_GEARBOX
        PC_INSPECTION
        PC_STEERING
        PC_HEADLIGHTS
        PC_ELECTRICITY
        PC_EXHAUST_SYSTEM
        PC_CONDITIONER
        PC_WHEELS
        PC_INTERIOR
        PC_ENGINE
        PC_BRAKES
        PC_GLASS
        PC_BODY
    }
`;
export const TranslatableFragFragmentDoc = gql`
    fragment TranslatableFrag on Translatable {
        defaultTranslation
        id
        customID
    }
`;
export const CalendarFragFragmentDoc = gql`
    fragment CalendarFrag on Calendar {
        monday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        tuesday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        wednesday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        thursday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        friday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        saturday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
        sunday {
            open {
                hour
                minute
            }
            close {
                hour
                minute
            }
        }
    }
`;
export const WorkshopFragmentFecFragmentDoc = gql`
    fragment workshopFragmentFEC on Workshop {
        ID
        address
        name
        city
        hourCost
        hourCosts {
            ...HourCostsFrag
        }
        posX
        posY
        logoURL
        reviewCountGmap
        reviewRatingGmap
        microdistrict
        ratingScore
        specialized {
            vehicleBrands
            servicesIDs
        }
        services {
            fixedPrice
            fromPrice
            toPrice
            durationFromMins
            durationToMins
            partsPrice
            type {
                ID
                serviceName {
                    ...TranslatableFrag
                }
                defaultUnitCount
                durationMinsPerUnit
                partCategory {
                    ...TranslatableFrag
                }
                priceType
                defaultUnit {
                    ...TranslatableFrag
                }
            }
        }
        opp
        up
        badgeOrdersCount
        earliestAvailableDate
        lunchHoursCalendar {
            ...CalendarFrag
        }
        workHoursCalendar {
            ...CalendarFrag
        }
    }
    ${HourCostsFragFragmentDoc}
    ${TranslatableFragFragmentDoc}
    ${CalendarFragFragmentDoc}
`;
export const BookServiceOrderResFragmentDoc = gql`
    fragment bookServiceOrderRes on Order {
        ID
        createdAt
        city
        phoneNumber
        email
        vehicleBrand
        vehicleModel
        vehicleYear
        userAccessToken
        status
        arrivalDate
        createdByWorkshop
        billable
        customerComment
        goodPrice
        servicesCompletion
        categories {
            ID
            serviceName {
                ...TranslatableFrag
            }
        }
    }
    ${TranslatableFragFragmentDoc}
`;
export const BlockedTimeslotsFragFragmentDoc = gql`
    fragment blockedTimeslotsFrag on BlockedTimeslot {
        startDate
        endDate
        blockedServicesIDs
        employeesIDs
    }
`;
export const AuthedWorkshopFragmentDoc = gql`
    fragment authedWorkshop on AuthedWorkshop {
        ID
        name
        address
        microdistrict
        phoneNumber
        city
        disabled
        disableReason
        selfDisabled
        email
        hourCost
        servicedBrands
        logoURL
        posX
        photosUrls
        posY
        gmapUrl
        scrapeGmapUrl
        reviewCountGmap
        country
        reviewRatingGmap
        workingSinceYear
        altFlowEnabled
        onboardingCompleted
        creationDate
        opp
        up
        availabilityValidUntil
        linksAdded
        averageRatingScore
        internalNotes
        firstEnabledDate
        hourCosts {
            ...HourCostsFrag
        }
        blockedTimeslots {
            startDate
            endDate
            blockedServicesIDs
            employeesIDs
        }
        ratingScoreDetails {
            expiresDeclinesPenaltyScore
            acceptedInvitations
            descriptionScore
            photosScore
            ordersReportsPenaltyScore
            ordersReports
            finalScore
            expiredDeclinedInvitations
            priceScore
            availabilityScore
            cityRank
            cityRankLowest
            amenitiesScore
        }
        company {
            ID
            name
            companyCode
            vatCode
            address
            email
            phoneNumber
            accountingEmail
            paymentDetails
            trialEndsOnDate
            feePercent
        }
        employees {
            ID
            fullName
            role
            disabled
            workHoursCalendar {
                ...CalendarFrag
            }
            servicesDefinitionsIDs
        }
        services {
            type {
                ID
                serviceName {
                    ...TranslatableFrag
                }
                searchAliases {
                    ...TranslatableFrag
                }
                partCategory {
                    ...TranslatableFrag
                }
                durationMinsPerUnit
                defaultUnit {
                    ...TranslatableFrag
                }
                defaultUnitCount
                important
                sortOrder
                priceType
                categorization {
                    sortOrder
                    partCategory {
                        ...TranslatableFrag
                    }
                    subCategory {
                        ...TranslatableFrag
                    }
                }
            }
            fixedPrice
            specialized
            disabled
            fromPrice
            toPrice
            durationFromMins
            durationToMins
        }
        description
        acceptanceDaysMargin
        acceptanceDaysMarginSystem
        amenities
        lunchHoursCalendar {
            ...CalendarFrag
        }
        workHoursCalendar {
            ...CalendarFrag
        }
    }
    ${HourCostsFragFragmentDoc}
    ${CalendarFragFragmentDoc}
    ${TranslatableFragFragmentDoc}
`;
export const OrderFragmentFragmentDoc = gql`
    fragment orderFragment on AuthedOrder {
        ID
        vehicleYear
        fullName
        vehicleModel
        vehicleLicensePlate
        vehicleBrand
        status
        phoneNumber
        createdAt
        createdByWorkshop
        clientArrived
        categories {
            ID
            serviceName {
                ...TranslatableFrag
            }
            durationMinsPerUnit
            defaultUnitCount
        }
        arrivalDate
        customerComment
        servicesJoinTimeoutAt
        customerPickTimeoutAt
        email
        fromBookNow
        paymentSum
        clientWillWait
        workshopOrder {
            ID
            fullName
            phoneNumber
            vehicleBrand
            vehicleModel
            vehicleYear
            vehicleLicensePlate
            email
            vehicleVIN
            mileage
            fuelType
            customerComment
            engineHorsePowerKw
            engineCapacityLiters
        }
    }
    ${TranslatableFragFragmentDoc}
`;
export const CalendarEventFragmentFragmentDoc = gql`
    fragment calendarEventFragment on CalendarEvent {
        ID
        comments
        status
        items {
            ID
            name
            price
            partsPrice
            serviceDefinition
            type
            belongsToItem
        }
        order {
            ...orderFragment
        }
        assignedEmployees {
            ID
            fullName
        }
        type
        subType
        creationDate
        startDate
        endDate
        creationDate
    }
    ${OrderFragmentFragmentDoc}
`;
export const InvoiceFragFragmentDoc = gql`
    fragment InvoiceFrag on Invoice {
        ID
        seriesID
        series
        items {
            ID
            name
            price
            count
            type
            belongsToItem
            partCode
        }
        order
        comments
        companyCode
        vatCode
        seriesID
        address
        paymentDetails
        clientName
        clientEmail
        clientCompanyCode
        clientVatCode
        clientAddress
        paymentDueDate
        issuanceDate
        creationDate
        type
    }
`;
export const InitSearchFormInfoDocument = gql`
    query initSearchFormInfo(
        $getEarliestAvailableTimeParams: GetEarliestAvailableTimeInput!
        $findAllCategoriesParams: FindAllCategoriesInput!
        $includeGetEarliestAvailableTime: Boolean!
    ) {
        findAllCategories(params: $findAllCategoriesParams) {
            serviceName {
                ...TranslatableFrag
            }
            searchAliases {
                ...TranslatableFrag
            }
            ID
            defaultUnitCount
            durationMinsPerUnit
            partCategory {
                ...TranslatableFrag
            }
            sortOrder
            important
            priceType
            categorization {
                sortOrder
                partCategory {
                    ...TranslatableFrag
                }
                subCategory {
                    ...TranslatableFrag
                }
            }
        }
        getEarliestAvailableTime(params: $getEarliestAvailableTimeParams)
            @include(if: $includeGetEarliestAvailableTime) {
            earliestAvailableTime
        }
        findAllServicePackages {
            ID
            name {
                ...TranslatableFrag
            }
            partCategory {
                ...TranslatableFrag
            }
            servicesDefinitionsIDs
            recommendationText {
                ...TranslatableFrag
            }
            sortOrder
            imgUrl
        }
    }
    ${TranslatableFragFragmentDoc}
`;

export function useInitSearchFormInfoQuery(
    options: Omit<Urql.UseQueryArgs<InitSearchFormInfoQueryVariables>, "query">
) {
    return Urql.useQuery<
        InitSearchFormInfoQuery,
        InitSearchFormInfoQueryVariables
    >({ query: InitSearchFormInfoDocument, ...options });
}
export const CustomersAcceptInvitationDocument = gql`
    mutation CustomersAcceptInvitation(
        $invitationId: Float!
        $newArrivalDate: DateTime
        $accessToken: String
    ) {
        customersAcceptInvitation(
            invitationID: $invitationId
            newArrivalDate: $newArrivalDate
            accessToken: $accessToken
        ) {
            ...bookServiceOrderRes
        }
    }
    ${BookServiceOrderResFragmentDoc}
`;

export function useCustomersAcceptInvitationMutation() {
    return Urql.useMutation<
        CustomersAcceptInvitationMutation,
        CustomersAcceptInvitationMutationVariables
    >(CustomersAcceptInvitationDocument);
}
export const OrderDocument = gql`
    query Order($id: Int!, $accessToken: String!) {
        order(ID: $id, accessToken: $accessToken) {
            ID
            createdAt
            city
            email
            phoneNumber
            vehicleBrand
            vehicleModel
            vehicleYear
            vehicleVIN
            vehicleBody
            vehicleLicensePlate
            userAccessToken
            status
            marketingConsent
            arrivalDate
            customerPickTimeoutAt
            customerComment
            createdByWorkshop
            billable
            goodPrice
            servicesCompletion
            clientWillWait
            acceptedService {
                ...workshopFragmentFEC
            }
            categories {
                ID
                serviceName {
                    ...TranslatableFrag
                }
            }
            invitations {
                ID
                primary
                status
                service {
                    ...workshopFragmentFEC
                    blockedTimeslots {
                        ...blockedTimeslotsFrag
                    }
                    employees {
                        ID
                        workHoursCalendar {
                            ...CalendarFrag
                        }
                        servicesDefinitionsIDs
                    }
                }
                expiresAt
                newSuggestedArrivalDate
            }
            workshopOrder {
                phoneNumber
                email
            }
        }
    }
    ${WorkshopFragmentFecFragmentDoc}
    ${TranslatableFragFragmentDoc}
    ${BlockedTimeslotsFragFragmentDoc}
    ${CalendarFragFragmentDoc}
`;

export function useOrderQuery(
    options: Omit<Urql.UseQueryArgs<OrderQueryVariables>, "query">
) {
    return Urql.useQuery<OrderQuery, OrderQueryVariables>({
        query: OrderDocument,
        ...options,
    });
}
export const RecommendedWorkshopsDocument = gql`
    query RecommendedWorkshops {
        recommendedWorkshops {
            name
            ID
            address
            city
            microdistrict
            reviewRatingGmap
            logoURL
            reviewCountGmap
        }
    }
`;

export function useRecommendedWorkshopsQuery(
    options?: Omit<
        Urql.UseQueryArgs<RecommendedWorkshopsQueryVariables>,
        "query"
    >
) {
    return Urql.useQuery<
        RecommendedWorkshopsQuery,
        RecommendedWorkshopsQueryVariables
    >({ query: RecommendedWorkshopsDocument, ...options });
}
export const RecentReviewsDocument = gql`
    query RecentReviews {
        recentReviews {
            ID
            rating
            text
            authorName
            serviceNames {
                ...TranslatableFrag
            }
            vehicle {
                brand
                model
                year
            }
            createdAt
            workshop {
                name
                city
            }
        }
    }
    ${TranslatableFragFragmentDoc}
`;

export function useRecentReviewsQuery(
    options?: Omit<Urql.UseQueryArgs<RecentReviewsQueryVariables>, "query">
) {
    return Urql.useQuery<RecentReviewsQuery, RecentReviewsQueryVariables>({
        query: RecentReviewsDocument,
        ...options,
    });
}
export const InitiateSearchDocument = gql`
    mutation InitiateSearch($params: SearchInput!) {
        initiateSearch(params: $params) {
            services {
                ...workshopFragmentFEC
            }
            order {
                ID
                createdAt
                arrivalDate
                phoneNumber
                vehicleBrand
                vehicleModel
                vehicleBody
                vehicleYear
                vehicleVIN
                vehicleLicensePlate
                userAccessToken
                categories {
                    serviceName {
                        ...TranslatableFrag
                    }
                }
            }
        }
    }
    ${WorkshopFragmentFecFragmentDoc}
    ${TranslatableFragFragmentDoc}
`;

export function useInitiateSearchMutation() {
    return Urql.useMutation<
        InitiateSearchMutation,
        InitiateSearchMutationVariables
    >(InitiateSearchDocument);
}
export const BookServiceDocument = gql`
    mutation BookService(
        $bookServiceInput: BookServiceInput!
        $updateOrderInput: UpdateOrderInput!
    ) {
        updateOrder(p: $updateOrderInput) {
            ID
        }
        bookService(p: $bookServiceInput) {
            ...bookServiceOrderRes
        }
    }
    ${BookServiceOrderResFragmentDoc}
`;

export function useBookServiceMutation() {
    return Urql.useMutation<BookServiceMutation, BookServiceMutationVariables>(
        BookServiceDocument
    );
}
export const BookServiceWithoutOrderDocument = gql`
    mutation BookServiceWithoutOrder($p: BookServiceWithoutOrderInput!) {
        bookServiceWithoutOrder(p: $p) {
            ...bookServiceOrderRes
        }
    }
    ${BookServiceOrderResFragmentDoc}
`;

export function useBookServiceWithoutOrderMutation() {
    return Urql.useMutation<
        BookServiceWithoutOrderMutation,
        BookServiceWithoutOrderMutationVariables
    >(BookServiceWithoutOrderDocument);
}
export const ReservationConfirmationInitDataDocument = gql`
    query reservationConfirmationInitData(
        $getEarliestAvailableTimeParams: GetEarliestAvailableTimeInput!
        $workshopID: Int!
    ) {
        getEarliestAvailableTime(params: $getEarliestAvailableTimeParams) {
            earliestAvailableTime
        }
        service(ID: $workshopID) {
            servicedBrands
            blockedTimeslots {
                ...blockedTimeslotsFrag
            }
            employees {
                ID
                workHoursCalendar {
                    ...CalendarFrag
                }
                servicesDefinitionsIDs
            }
        }
    }
    ${BlockedTimeslotsFragFragmentDoc}
    ${CalendarFragFragmentDoc}
`;

export function useReservationConfirmationInitDataQuery(
    options: Omit<
        Urql.UseQueryArgs<ReservationConfirmationInitDataQueryVariables>,
        "query"
    >
) {
    return Urql.useQuery<
        ReservationConfirmationInitDataQuery,
        ReservationConfirmationInitDataQueryVariables
    >({ query: ReservationConfirmationInitDataDocument, ...options });
}
export const CancelOrderDocument = gql`
    mutation CancelOrder($p: CancelOrder!) {
        customerCancelOrder(p: $p)
    }
`;

export function useCancelOrderMutation() {
    return Urql.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
        CancelOrderDocument
    );
}
export const ActivateCancelDiscountDocument = gql`
    mutation ActivateCancelDiscount($p: ActivateCancelDiscountInput!) {
        activateCancelDiscount(p: $p)
    }
`;

export function useActivateCancelDiscountMutation() {
    return Urql.useMutation<
        ActivateCancelDiscountMutation,
        ActivateCancelDiscountMutationVariables
    >(ActivateCancelDiscountDocument);
}
export const CreateReviewDocument = gql`
    mutation CreateReview(
        $params: CreateReviewInput!
        $orderId: Int!
        $accessToken: String!
    ) {
        createReview(
            params: $params
            orderID: $orderId
            accessToken: $accessToken
        ) {
            ID
            rating
            text
            authorName
            createdAt
        }
    }
`;

export function useCreateReviewMutation() {
    return Urql.useMutation<
        CreateReviewMutation,
        CreateReviewMutationVariables
    >(CreateReviewDocument);
}
export const OrderReviewSummaryDocument = gql`
    query OrderReviewSummary($orderId: Int!, $accessToken: String!) {
        order(ID: $orderId, accessToken: $accessToken) {
            ID
            acceptedService {
                name
                address
                city
            }
            categories {
                serviceName {
                    ...TranslatableFrag
                }
            }
            paymentSum
            createdByWorkshop
            vehicleBrand
            vehicleModel
            vehicleYear
            status
            goodPrice
            servicesCompletion
        }
        orderReview(orderID: $orderId, accessToken: $accessToken) {
            ID
            text
            rating
            authorName
            createdAt
        }
    }
    ${TranslatableFragFragmentDoc}
`;

export function useOrderReviewSummaryQuery(
    options: Omit<Urql.UseQueryArgs<OrderReviewSummaryQueryVariables>, "query">
) {
    return Urql.useQuery<
        OrderReviewSummaryQuery,
        OrderReviewSummaryQueryVariables
    >({ query: OrderReviewSummaryDocument, ...options });
}
export const UpdateWarrantyInfoDocument = gql`
    mutation UpdateWarrantyInfo($p: UpdateWarrantyInfo!) {
        updateWarrantyInfo(p: $p)
    }
`;

export function useUpdateWarrantyInfoMutation() {
    return Urql.useMutation<
        UpdateWarrantyInfoMutation,
        UpdateWarrantyInfoMutationVariables
    >(UpdateWarrantyInfoDocument);
}
export const GetServiceDocument = gql`
    query getService($vehicle: VehicleInput, $id: Int!) {
        service(ID: $id) {
            ID
            name
            address
            city
            hourCost
            hourCosts {
                ...HourCostsFrag
            }
            logoURL
            posX
            posY
            reviewCountGmap
            reviewRatingGmap
            description
            isTrustedPartner
            workingSinceYear
            microdistrict
            photosUrls
            disabled
            selfDisabled
            country
            acceptsClientsWithOwnParts
            servicedBrands
            amenities
            specialized {
                vehicleBrands
                servicesIDs
            }
            opp
            up
            ratingScore
            disableReason
            blockedTimeslots {
                ...blockedTimeslotsFrag
            }
            workHoursCalendar {
                ...CalendarFrag
            }
            lunchHoursCalendar {
                ...CalendarFrag
            }
            orderReviews {
                text
                authorName
                rating
                createdAt
                serviceNames {
                    ...TranslatableFrag
                }
                vehicle {
                    brand
                    model
                    year
                }
            }
            services(vehicle: $vehicle) {
                type {
                    ID
                    serviceName {
                        ...TranslatableFrag
                    }
                    searchAliases {
                        ...TranslatableFrag
                    }
                    durationMinsPerUnit
                    defaultUnit {
                        ...TranslatableFrag
                    }
                    defaultUnitCount
                    partCategory {
                        ...TranslatableFrag
                    }
                    sortOrder
                    important
                    priceType
                    categorization {
                        sortOrder
                        partCategory {
                            ...TranslatableFrag
                        }
                        subCategory {
                            ...TranslatableFrag
                        }
                    }
                }
                specialized
                fixedPrice
                fromPrice
                toPrice
                durationFromMins
                durationToMins
                disabled
                partsPrice
            }
            employees {
                ID
                servicesDefinitionsIDs
                workHoursCalendar {
                    ...CalendarFrag
                }
            }
            servicePackages {
                ID
                name {
                    ...TranslatableFrag
                }
                recommendationText {
                    ...TranslatableFrag
                }
                partCategory {
                    ...TranslatableFrag
                }
                servicesDefinitionsIDs
                recommendationText {
                    ...TranslatableFrag
                }
                sortOrder
                imgUrl
            }
            earliestAvailableDate
        }
    }
    ${HourCostsFragFragmentDoc}
    ${BlockedTimeslotsFragFragmentDoc}
    ${CalendarFragFragmentDoc}
    ${TranslatableFragFragmentDoc}
`;

export function useGetServiceQuery(
    options: Omit<Urql.UseQueryArgs<GetServiceQueryVariables>, "query">
) {
    return Urql.useQuery<GetServiceQuery, GetServiceQueryVariables>({
        query: GetServiceDocument,
        ...options,
    });
}
export const WorkshopFromSessionDocument = gql`
    query WorkshopFromSession {
        workshopFromSession {
            ...authedWorkshop
        }
    }
    ${AuthedWorkshopFragmentDoc}
`;

export function useWorkshopFromSessionQuery(
    options?: Omit<
        Urql.UseQueryArgs<WorkshopFromSessionQueryVariables>,
        "query"
    >
) {
    return Urql.useQuery<
        WorkshopFromSessionQuery,
        WorkshopFromSessionQueryVariables
    >({ query: WorkshopFromSessionDocument, ...options });
}
export const AcceptInvitationAuthedDocument = gql`
    mutation AcceptInvitationAuthed($invitationId: Float!) {
        acceptInvitationAuthed(invitationID: $invitationId)
    }
`;

export function useAcceptInvitationAuthedMutation() {
    return Urql.useMutation<
        AcceptInvitationAuthedMutation,
        AcceptInvitationAuthedMutationVariables
    >(AcceptInvitationAuthedDocument);
}
export const RejectInvitationAuthedDocument = gql`
    mutation RejectInvitationAuthed($p: RejectInvitationAuthed!) {
        rejectInvitationAuthed(p: $p) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragmentFragmentDoc}
`;

export function useRejectInvitationAuthedMutation() {
    return Urql.useMutation<
        RejectInvitationAuthedMutation,
        RejectInvitationAuthedMutationVariables
    >(RejectInvitationAuthedDocument);
}
export const FindAllCategoriesDocument = gql`
    query findAllCategories($findAllCategoriesParams: FindAllCategoriesInput!) {
        findAllCategories(params: $findAllCategoriesParams) {
            ID
            searchAliases {
                ...TranslatableFrag
            }
            serviceName {
                ...TranslatableFrag
            }
        }
    }
    ${TranslatableFragFragmentDoc}
`;

export function useFindAllCategoriesQuery(
    options: Omit<Urql.UseQueryArgs<FindAllCategoriesQueryVariables>, "query">
) {
    return Urql.useQuery<
        FindAllCategoriesQuery,
        FindAllCategoriesQueryVariables
    >({ query: FindAllCategoriesDocument, ...options });
}
export const UpdateClientArrivalDocument = gql`
    mutation UpdateClientArrival(
        $updateClientArrivalInput: UpdateClientArrivalInput!
        $updateEventInput: UpdateCalendarEventInput!
    ) {
        updateCalendarEvent(event: $updateEventInput) {
            ...calendarEventFragment
        }
        updateClientArrival(p: $updateClientArrivalInput) {
            ID
            vehicleYear
            fullName
            vehicleModel
            vehicleLicensePlate
            vehicleBrand
            status
            phoneNumber
            createdAt
            categories {
                ID
                serviceName {
                    ...TranslatableFrag
                }
            }
            arrivalDate
            workshopOrder {
                ID
                fullName
                phoneNumber
                vehicleBrand
                vehicleModel
                vehicleYear
                vehicleLicensePlate
            }
        }
    }
    ${CalendarEventFragmentFragmentDoc}
    ${TranslatableFragFragmentDoc}
`;

export function useUpdateClientArrivalMutation() {
    return Urql.useMutation<
        UpdateClientArrivalMutation,
        UpdateClientArrivalMutationVariables
    >(UpdateClientArrivalDocument);
}
export const FinalizeCalendarEventOrderDocument = gql`
    mutation FinalizeCalendarEventOrder($p: FinalizeCalendarEventOrderInput!) {
        finalizeCalendarEventOrder(p: $p) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragmentFragmentDoc}
`;

export function useFinalizeCalendarEventOrderMutation() {
    return Urql.useMutation<
        FinalizeCalendarEventOrderMutation,
        FinalizeCalendarEventOrderMutationVariables
    >(FinalizeCalendarEventOrderDocument);
}
export const MoreRepairsbookedDocument = gql`
    mutation MoreRepairsbooked($orderId: Float!) {
        moreRepairsbooked(orderID: $orderId)
    }
`;

export function useMoreRepairsbookedMutation() {
    return Urql.useMutation<
        MoreRepairsbookedMutation,
        MoreRepairsbookedMutationVariables
    >(MoreRepairsbookedDocument);
}
export const WorkshopCancelOrderDocument = gql`
    mutation WorkshopCancelOrder($p: WorkshopCancelOrder!) {
        workshopCancelOrder(p: $p) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragmentFragmentDoc}
`;

export function useWorkshopCancelOrderMutation() {
    return Urql.useMutation<
        WorkshopCancelOrderMutation,
        WorkshopCancelOrderMutationVariables
    >(WorkshopCancelOrderDocument);
}
export const OrderSearchDocument = gql`
    mutation OrderSearch($p: OrderSearchInput!) {
        orderSearch(p: $p) {
            ...orderFragment
        }
    }
    ${OrderFragmentFragmentDoc}
`;

export function useOrderSearchMutation() {
    return Urql.useMutation<OrderSearchMutation, OrderSearchMutationVariables>(
        OrderSearchDocument
    );
}
export const GetWorkshopCalendarEventsBetweenDatesDocument = gql`
    query GetWorkshopCalendarEventsBetweenDates(
        $p: GetWorkshopCalendarEventsInput!
    ) {
        getWorkshopCalendarEventsBetweenDates(p: $p) {
            ...calendarEventFragment
        }
        pendingInvitations {
            ID
            expiresAt
            createdAt
            status
            primary
            order {
                ...orderFragment
            }
        }
    }
    ${CalendarEventFragmentFragmentDoc}
    ${OrderFragmentFragmentDoc}
`;

export function useGetWorkshopCalendarEventsBetweenDatesQuery(
    options: Omit<
        Urql.UseQueryArgs<GetWorkshopCalendarEventsBetweenDatesQueryVariables>,
        "query"
    >
) {
    return Urql.useQuery<
        GetWorkshopCalendarEventsBetweenDatesQuery,
        GetWorkshopCalendarEventsBetweenDatesQueryVariables
    >({ query: GetWorkshopCalendarEventsBetweenDatesDocument, ...options });
}
export const UpdateCalendarEventDocument = gql`
    mutation UpdateCalendarEvent($event: UpdateCalendarEventInput!) {
        updateCalendarEvent(event: $event) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragmentFragmentDoc}
`;

export function useUpdateCalendarEventMutation() {
    return Urql.useMutation<
        UpdateCalendarEventMutation,
        UpdateCalendarEventMutationVariables
    >(UpdateCalendarEventDocument);
}
export const CreateCalendarEventDocument = gql`
    mutation CreateCalendarEvent(
        $event: CreateCalendarEventInput!
        $sendMessages: Boolean
    ) {
        createCalendarEvent(event: $event, sendMessages: $sendMessages) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragmentFragmentDoc}
`;

export function useCreateCalendarEventMutation() {
    return Urql.useMutation<
        CreateCalendarEventMutation,
        CreateCalendarEventMutationVariables
    >(CreateCalendarEventDocument);
}
export const DeleteCalendarEventDocument = gql`
    mutation DeleteCalendarEvent($eventId: Float!) {
        deleteCalendarEvent(eventID: $eventId)
    }
`;

export function useDeleteCalendarEventMutation() {
    return Urql.useMutation<
        DeleteCalendarEventMutation,
        DeleteCalendarEventMutationVariables
    >(DeleteCalendarEventDocument);
}
export const CreateEventsDocument = gql`
    mutation CreateEvents($events: [CreateCalendarEventInput!]!) {
        createEvents(events: $events) {
            ...calendarEventFragment
        }
    }
    ${CalendarEventFragmentFragmentDoc}
`;

export function useCreateEventsMutation() {
    return Urql.useMutation<
        CreateEventsMutation,
        CreateEventsMutationVariables
    >(CreateEventsDocument);
}
export const AvailabilityUpdatedDocument = gql`
    mutation availabilityUpdated {
        availabilityUpdated
    }
`;

export function useAvailabilityUpdatedMutation() {
    return Urql.useMutation<
        AvailabilityUpdatedMutation,
        AvailabilityUpdatedMutationVariables
    >(AvailabilityUpdatedDocument);
}
export const DashboardStatsDocument = gql`
    query DashboardStats {
        dashboardStats {
            stats {
                ordersSum
                externalOrdersSum
                feePercentage
                feeSum
                avgOrderSize
                expiredClients
                acceptedClients
                avgOrdersRating
                reviewsCount
                ordersCount
                billableOrdersSum
                reviewsCount
                dynamicFee
                bookNowOrdersSum
                declinedClients
                declinedPunishableClients
                returningOrdersCount
                externalOrdersCount
            }
            date
        }
    }
`;

export function useDashboardStatsQuery(
    options?: Omit<Urql.UseQueryArgs<DashboardStatsQueryVariables>, "query">
) {
    return Urql.useQuery<DashboardStatsQuery, DashboardStatsQueryVariables>({
        query: DashboardStatsDocument,
        ...options,
    });
}
export const WorkshopLoginDocument = gql`
    mutation WorkshopLogin($email: String!) {
        workshopLogin(email: $email)
    }
`;

export function useWorkshopLoginMutation() {
    return Urql.useMutation<
        WorkshopLoginMutation,
        WorkshopLoginMutationVariables
    >(WorkshopLoginDocument);
}
export const UpsertInvoiceDocument = gql`
    mutation UpsertInvoice($invoice: InvoiceUpsert!) {
        upsertInvoice(invoice: $invoice) {
            ...InvoiceFrag
        }
    }
    ${InvoiceFragFragmentDoc}
`;

export function useUpsertInvoiceMutation() {
    return Urql.useMutation<
        UpsertInvoiceMutation,
        UpsertInvoiceMutationVariables
    >(UpsertInvoiceDocument);
}
export const SendInvoiceToEmailDocument = gql`
    mutation sendInvoiceToEmail($p: SendInvoiceToEmailInput!) {
        sendInvoiceToEmail(p: $p)
    }
`;

export function useSendInvoiceToEmailMutation() {
    return Urql.useMutation<
        SendInvoiceToEmailMutation,
        SendInvoiceToEmailMutationVariables
    >(SendInvoiceToEmailDocument);
}
export const DownloadInvoiceDocument = gql`
    mutation downloadInvoice($p: DownloadInvoiceInput!) {
        downloadInvoice(p: $p)
    }
`;

export function useDownloadInvoiceMutation() {
    return Urql.useMutation<
        DownloadInvoiceMutation,
        DownloadInvoiceMutationVariables
    >(DownloadInvoiceDocument);
}
export const GetNextSeriesIdDocument = gql`
    query getNextSeriesID {
        getNextSeriesID
    }
`;

export function useGetNextSeriesIdQuery(
    options?: Omit<Urql.UseQueryArgs<GetNextSeriesIdQueryVariables>, "query">
) {
    return Urql.useQuery<GetNextSeriesIdQuery, GetNextSeriesIdQueryVariables>({
        query: GetNextSeriesIdDocument,
        ...options,
    });
}
export const AuthedWorkshopOrdersDocument = gql`
    query AuthedWorkshopOrders {
        authedWorkshopOrders {
            ID
            createdAt
            fullName
            email
            phoneNumber
            vehicleBrand
            vehicleModel
            vehicleYear
            vehicleVIN
            vehicleBody
            createdByWorkshop
            vehicleLicensePlate
            clientArrived
            status
            categories {
                ID
                serviceName {
                    ...TranslatableFrag
                }
                durationMinsPerUnit
                defaultUnitCount
            }
            arrivalDate
            customerComment
            workshopOrder {
                vehicleYear
                vehicleModel
                vehicleLicensePlate
                vehicleBrand
                phoneNumber
                fullName
                email
            }
            calendarEvents {
                comments
                assignedEmployees {
                    fullName
                }
                status
                type
                items {
                    ID
                    name
                    price
                    serviceDefinition
                    type
                    belongsToItem
                    partsPrice
                }
            }
            invoice {
                ...InvoiceFrag
            }
        }
    }
    ${TranslatableFragFragmentDoc}
    ${InvoiceFragFragmentDoc}
`;

export function useAuthedWorkshopOrdersQuery(
    options?: Omit<
        Urql.UseQueryArgs<AuthedWorkshopOrdersQueryVariables>,
        "query"
    >
) {
    return Urql.useQuery<
        AuthedWorkshopOrdersQuery,
        AuthedWorkshopOrdersQueryVariables
    >({ query: AuthedWorkshopOrdersDocument, ...options });
}
export const DeleteOrderDocument = gql`
    mutation DeleteOrder($orderId: Float!) {
        deleteOrder(orderID: $orderId)
    }
`;

export function useDeleteOrderMutation() {
    return Urql.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(
        DeleteOrderDocument
    );
}
export const DuplicateWorkshopDocument = gql`
    mutation duplicateWorkshop {
        duplicateWorkshop
    }
`;

export function useDuplicateWorkshopMutation() {
    return Urql.useMutation<
        DuplicateWorkshopMutation,
        DuplicateWorkshopMutationVariables
    >(DuplicateWorkshopDocument);
}
export const OptOutOfServicesDocument = gql`
    mutation OptOutOfServices {
        optOutOfServices
    }
`;

export function useOptOutOfServicesMutation() {
    return Urql.useMutation<
        OptOutOfServicesMutation,
        OptOutOfServicesMutationVariables
    >(OptOutOfServicesDocument);
}
export const UpdateWorkshopDocument = gql`
    mutation UpdateWorkshop($p: UpdateWorkshopInput!) {
        updateWorkshop(p: $p) {
            ...authedWorkshop
        }
    }
    ${AuthedWorkshopFragmentDoc}
`;

export function useUpdateWorkshopMutation() {
    return Urql.useMutation<
        UpdateWorkshopMutation,
        UpdateWorkshopMutationVariables
    >(UpdateWorkshopDocument);
}
export const FindEvenInactiveServicesDocument = gql`
    query findEvenInactiveServices(
        $findAllCategoriesParams: FindAllCategoriesInput!
    ) {
        findAllCategories(params: $findAllCategoriesParams) {
            serviceName {
                ...TranslatableFrag
            }
            ID
            partCategory {
                ...TranslatableFrag
            }
            durationMinsPerUnit
            defaultUnit {
                ...TranslatableFrag
            }
            defaultUnitCount
            important
            sortOrder
            priceType
        }
    }
    ${TranslatableFragFragmentDoc}
`;

export function useFindEvenInactiveServicesQuery(
    options: Omit<
        Urql.UseQueryArgs<FindEvenInactiveServicesQueryVariables>,
        "query"
    >
) {
    return Urql.useQuery<
        FindEvenInactiveServicesQuery,
        FindEvenInactiveServicesQueryVariables
    >({ query: FindEvenInactiveServicesDocument, ...options });
}
export const SignUpWorkshopDocument = gql`
    mutation SignUpWorkshop($input: WorkshopSignupInput!) {
        signUpWorkshop(input: $input) {
            magicLink
        }
    }
`;

export function useSignUpWorkshopMutation() {
    return Urql.useMutation<
        SignUpWorkshopMutation,
        SignUpWorkshopMutationVariables
    >(SignUpWorkshopDocument);
}
