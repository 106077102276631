import React from "react";
import * as S from "./WarrantyContent.styled";

const WarrantyContent_LT = () => {
    return (
        <S.WarrantyContent>
            <S.Section>
                <S.Title>
                    Visuose nevaziuoja.lt autoservisuose teikiamos garantijos:
                </S.Title>
                <ul>
                    <li>
                        Šeši mėnesiai arba iki 10 000 km, neįskaitant elektros
                        prietaisų remonto.
                    </li>
                    <li>
                        Vienas mėnuo techninio aptarnavimo ir priežiūros darbams
                    </li>
                    <li>Dalims taikoma gamintojo garantija.</li>
                    <li>Garantija netaikoma elektros prietaisams.</li>
                </ul>
            </S.Section>
            <S.Section>
                <S.Title>Techninis advokatas</S.Title>
                <div>
                    Kiekvieną nevaziuoja.lt klientą konflikto atvėju su
                    autoservisu atstovauja techninis advokatas. Jo paskirtis yra
                    objektyviai įvertinti autoserviso atliktą remontą kai
                    klientas turi nusiskundimų dėl atliktų darbų kokybės, kurių
                    individualiai su autoservisu išspręsti nepavyksta.
                    Transporto priemonių žalų vertinimas reikalauja ne tik
                    žinių, bet ir patirties, todėl bendradarbiaujame tik su
                    profesionaliais šios srities specialistais. Esame čia tam,
                    kad užtikrintume kokybiškas automobilių remonto paslaugas ir
                    padėtume sprendžiant bet kokias iškilusias problemas.
                </div>
            </S.Section>
            <S.Section>
                <S.Title>Nevaziuoja.lt kokybės garantas</S.Title>
                <div>
                    Klientų atsiliepimų pagalba atrinkome ir bendradarbiaujame
                    tik su lyderiais automobilių remonto sektoriuje. Absoliučiai
                    visus klientų nusiskundimus tiriame, taip užtikrindami, kad
                    mūsų platformoje būtų tik kokybiškas paslaugas teikiantys
                    autoservisai.
                </div>
            </S.Section>
            <S.Section>
                <S.Title>
                    Garantiniai terminai ir sąlygos atitinka Lietuvoje
                    galiojančius įstatymus:
                </S.Title>
                <ul>
                    <li>
                        LIETUVOS STANDARTAS LST 1438:2005 Automobiliai. Techninė
                        priežiūra ir remontas;
                    </li>
                    <li>
                        Valstybinės kelių transporto inspekcijos prie
                        Susisiekimo ministerijos viršininko 2016 m. vasario 1 d.
                        įsakymas Nr.{" "}
                        <a
                            target="_blank"
                            href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/d1ba6770c92711e5a141fecd4d43d786?jfwid=ck9gyapfk"
                            rel="noreferrer"
                        >
                            2BE-45 TRANSPORTO PRIEMONIŲ TECHNINIO APTARNAVIMO IR
                            REMONTO PASLAUGŲ TEIKIMO TVARKOS APRAŠAS;
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="https://www.e-tar.lt/portal/lt/legalAct/TAR.D790096B17EE"
                            rel="noreferrer"
                        >
                            VARTOTOJŲ TEISIŲ GYNIMO ĮSTATYMAS
                        </a>
                        ;
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="https://www.e-tar.lt/portal/lt/legalAct/TAR.5DC1759E42CB"
                            rel="noreferrer"
                        >
                            SAUGAUS EISMO AUTOMOBILIŲ KELIAIS ĮSTATYMAS.
                        </a>
                    </li>
                </ul>
            </S.Section>
            <S.Section>
                <S.Title>
                    Visi defektai, kuriems taikoma garantija, bus pataisyti
                    nemokamai, o sugedę komponentai pakeičiami. Garantijos
                    galiojimo sąlygos:
                </S.Title>
                <ul>
                    <li>
                        Kad garantija galiotų, klientas turi laikytis transporto
                        priemonės eksploatavimo taisyklių.
                    </li>
                    <li>
                        Garantuotas automobilis ar automobilio dalis turi būti
                        naudojami tinkamai ir nepažeisti dėl netinkamo
                        naudojimo, pvz., automobilio ar jo komponentų
                        eksploatavimo ne gamintojo numatytu būdu, mechaninių
                        pažeidimų, perkaitimo ar veikimo be pakankamo kiekio
                        skysčių ar tepalų.
                    </li>
                    <li>
                        Klientas turi vadovautis autoserviso nurodymais,
                        įskaitant savalaikį dalių keitimą bei atlikti visus
                        būtinus veiksmus ar procedūras, nurodytas
                        technologiniuose procesuose.
                    </li>
                </ul>
            </S.Section>
        </S.WarrantyContent>
    );
};

export default WarrantyContent_LT;
