import { SharedConfig } from "./shared-config";
import Domain from "./types/enums/Domain";

const sharedConfig: Partial<SharedConfig> = {
	oneSignalAppId: {
		[Domain.REPAIR_PUNK_COM]: "7c808c62-411b-450c-abd1-b6bea7c89b51",
		[Domain.NEVAZIUOJA_LT]: "5ff4bbd9-d7c8-4614-87e9-24485f56a7f3",
	},
};

export default sharedConfig;
