import styled from "styled-components";
import Button from "../Button/Button";

export const MenuButton = styled(Button)`
    .icon-arrow-down {
        font-size: 8px;
    }
`;

export const LeftIcon = styled("i")`
    margin-right: 8px;
`;
