import styled from "styled-components";

export const Title = styled("div")`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 16px;
`;

export const WarrantyContent = styled("div")`
    a {
        color: black;
        text-decoration: underline;
    }
`;

export const Section = styled("div")`
    margin-bottom: 24px;
`;
