import { Typography } from "@mui/material";
import styled from "styled-components";
import { ContentContainer } from "../../../commonComps/ContentContainer/ContentContainer.styled";
import Box from "@FEShared/components/UI/Box/Box";
import CAR_FIXER_IMG from "./assets/fix-car.webp";
import CAR_FIXER_MOB_IMG from "./assets/fix-car-mob.webp";
import SPLASH_NEW_DESKTOP from "./assets/splash-new-desktop.webp";
import SPLASH_NEW_MOB from "./assets/splash-new-mob.webp";

export const SplashIcon = styled("span")`
    width: 32px;
    font-size: 24px;
    margin-right: 8px;
    text-align: center;
    display: inline-block;
`;

export const SplashTexts = styled("div")`
    position: relative;
    z-index: 1;
    text-align: left;
    flex: 1;
`;

export const LeftSideWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    padding-bottom: 56px;
    margin-right: 32px;
    max-width: 900px;
    flex: 1;
    align-self: flex-start;
`;

export const SplashContent = styled(ContentContainer)`
    display: flex;
    align-items: stretch;
`;

export const SplashTitle = styled(Typography)`
    font-weight: 700;
    font-size: 42px;
    color: white;
    filter: drop-shadow(1px 0 10px rgba(0, 0, 0, 0.75));
`;

export const SplashSubtitle = styled(Typography)`
    font-weight: 300;
    font-size: 20px;
    color: white;
    margin-top: 16px;
`;

export const SplashFeatures = styled("div")`
    display: flex;
    margin-top: 32px;
`;

export const SplashImg = styled("img")`
    width: 100%;
    margin-left: auto;
`;

export const WarrantyFeatureWrapper = styled("div")``;

export const FeatureImg = styled("img")`
    margin-right: 12px;
    width: 36px;
`;

export const FeatureTitle = styled("div")`
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 0px;
`;

export const FeatureDescr = styled("div")`
    font-size: 18px;
    color: white;
`;

export const H2Line = styled("div")`
    color: white;
    margin-bottom: 8px;
`;

export const ImgWrapper = styled(Box)`
    margin-left: auto;
    align-items: flex-end;
`;

export const SplashContainer = styled("div")<{ $newBg?: boolean }>`
    background: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    min-height: 530px;
    background-image: ${(p) =>
        p.$newBg ? `url(${SPLASH_NEW_DESKTOP})` : `url(${CAR_FIXER_IMG})`};
    padding-top: 64px;
    padding-bottom: 125px;

    ${(p) => p.theme.breakpoints.down("md")} {
        padding-top: 48px;
        padding-bottom: 32px;
        min-height: unset;
        height: unset;
        border-radius: unset;
        /* background: black; */
        background-size: 100% auto;
        background-position: top center;
        background-image: ${(p) =>
            p.$newBg ? `url(${SPLASH_NEW_MOB})` : `url(${CAR_FIXER_MOB_IMG})`};
        ${LeftSideWrapper} {
            padding-bottom: 0;
            margin-right: 0;
            max-width: 100%;
            width: 100%;
            align-items: center;
        }

        ${ImgWrapper} {
            margin-left: unset;
            width: 100%;
            justify-content: center;
        }

        ${SplashContent} {
            flex-direction: column;
            align-items: flex-start;
        }

        ${SplashTitle} {
            font-size: 29px;
            text-align: center;
        }
        ${SplashSubtitle} {
            font-size: 16px;
        }

        ${SplashFeatures} {
            margin-top: 24px;
            flex-direction: column;
        }

        ${FeatureDescr} {
            font-size: 14px;
        }

        ${SplashImg} {
            margin-left: unset;
        }
    }

    /* ${(p) => p.theme.breakpoints.down(425)} {
        ${SplashTitle} {
            font-size: 28px;
        }
    } */
`;
