import Box from "@FEShared/components/UI/Box/Box";
import styled, { css } from "styled-components";

export const ShieldImg = styled("img")`
    margin-right: 12px;
    width: 48px;
    height: 48px;
`;

export const Title = styled("div")`
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
    margin-bottom: 4px;
`;

export const ListText = styled("div")`
    font-size: 12px;
    &:not(:last-child) {
        margin-right: 12px;
    }
`;

export const Icon = styled("i")`
    color: #41b46f;
    margin-right: 4px;
`;

export const PointsWrapper = styled(Box)`
    display: flex;
`;

const ContainerMobStyles = css`
    ${PointsWrapper} {
        flex-direction: column;
    }
`;

export const WarrantyBoxContainer = styled(Box)<{ $forceMobileFlow?: boolean }>`
    display: flex;
    align-self: flex-start;
    cursor: pointer;

    ${(p) => p.$forceMobileFlow && ContainerMobStyles}

    ${(p) => p.theme.breakpoints.down("md")} {
        ${ContainerMobStyles}
    }
`;
