import { Modal } from "@mui/material";
import styled, { css } from "styled-components";
import BackButton from "../BackButton/BackButton";
import Button from "../Button/Button";

const SIDE_PADDING = 16;

export const ModalContent = styled("div")<{
    $maxWidth?: string;
    $fixedHeight?: string;
}>`
    outline: unset;
    display: flex;
    flex-direction: column;
    background: white;
    max-width: ${(p) => p.$maxWidth || "1120px"};
    ${(p) =>
        p.$fixedHeight &&
        css`
            height: ${p.$fixedHeight};
        `}
    width: 100%;
    max-height: 95vh;
    min-width: 500px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    overflow: auto;
`;

export const Head = styled("div")`
    padding: 8px ${SIDE_PADDING}px;
    margin-bottom: 8px;
    display: flex;
`;

export const Body = styled("div")`
    padding: 0 ${SIDE_PADDING}px;
    padding-bottom: ${SIDE_PADDING}px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const HeadBackButton = styled(BackButton)`
    height: 40px;
    width: 40px;
    text-align: center;
    min-width: unset;
    padding: 0;
`;

export const CloseIcon = styled("i")`
    height: 20px;
    width: 28px;
    border-radius: 50px;
    border: 1px solid #c5c5c5;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
`;

// export const Footer = styled("div")`
//     min-height: 64px;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-top: auto;
//     padding: 8px ${SIDE_PADDING}px;
// `;

export const FooterBtn = styled(Button)`
    margin-left: auto;
    height: 40px;
`;

export const MUIModalContainer = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;

    ${(p) => p.theme.breakpoints.down("md")} {
        ${ModalContent} {
            max-width: unset;
            height: 100%;
            width: 100%;
        }
    }
`;
