import styled from "styled-components";

export const MapPoint = styled("i")`
    font-size: 42px;
    color: ${(p) => p.theme.palette.primary.main};
`;

export const GMapWrapper = styled("div")<{ $className?: string }>`
    width: 100%;
    height: 188px;
    border-radius: 15px;
`;
