import sendPixelEvent from "./sendPixelEvent";

const EXPERIMENT_KEYS_LS_KEY = "EXPERIMENT_KEYS";

const sentEvents: string[] = [];

export default function pickABTest(p: {
    newComponentPercentage?: number;
    experimentKey: string;
}): string {
    const defaultCaseKey = `DEFAULT_${p.experimentKey}`;
    const lsExperimentKeys = localStorage.getItem(EXPERIMENT_KEYS_LS_KEY);
    const existingKeys = lsExperimentKeys
        ? (JSON.parse(lsExperimentKeys) as string[])
        : undefined;

    let keyToSet: string;
    if (
        existingKeys &&
        (existingKeys.includes(p.experimentKey) ||
            existingKeys.includes(defaultCaseKey))
    ) {
        keyToSet = existingKeys.includes(p.experimentKey)
            ? p.experimentKey
            : defaultCaseKey;
    } else {
        const chance = Math.random();
        const drawnExperimentKey =
            chance <= (p.newComponentPercentage || 0.5)
                ? p.experimentKey
                : defaultCaseKey;

        keyToSet = drawnExperimentKey;

        const newKeysArr = [keyToSet].concat(existingKeys || []);
        localStorage.setItem(
            EXPERIMENT_KEYS_LS_KEY,
            JSON.stringify(newKeysArr)
        );
    }

    const pixelEventName = `EXPERIMENT_${keyToSet}`;
    if (!sentEvents.includes(pixelEventName)) {
        sendPixelEvent({ eventName: pixelEventName });
        sentEvents.push(pixelEventName);
    }

    return keyToSet;
}
