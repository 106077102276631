import { Accordion } from "@mui/material";
import styled from "styled-components";

export const AccordionContainer = styled(Accordion)`
    box-shadow: unset;
    margin-top: unset !important;
    margin-bottom: 8px !important;
    border-radius: 15px !important;
    background: white;
    color: black;

    .MuiAccordionSummary-root {
        min-height: 50px;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }

    &::before,
    &::after {
        content: unset !important;
    }
` as typeof Accordion;
