import useStore from "@FEClient/logic/store";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import * as S from "./NavItem.styled";

const NavItem: React.FCC<{
    url: string;
    name: string;
    disabled?: boolean;
}> = observer((p) => {
    const GS = useStore();

    return (
        <S.NavItem
            $disabled={p.disabled}
            onClick={(e) => {
                if (p.disabled) {
                    e.stopPropagation();
                    e.preventDefault();
                } else {
                    runInAction(() => {
                        GS.showSidebar = false;
                    });
                }
            }}
            $isActive={location.pathname === p.url}
            to={p.url}
        >
            {p.name}
        </S.NavItem>
    );
});

export default NavItem;
