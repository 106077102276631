import styled, { css } from "styled-components";
import { Pagination as MUIPagination } from "@mui/material";
import Dropdown from "@FEShared/components/UI/Dropdown/Dropdown";
import Button from "@FEShared/components/UI/Button/Button";
import Text from "@FEShared/components/UI/Text/Text";

export const DRAWER_MARGIN_TOP = -25;
export const CLOSED_DRAWER_HEIGHT = 60;

export const TopWorkshops = {
    Wrapper: styled("div")`
        display: flex;
        align-items: flex-end;
        margin-bottom: 16px;
    `,
    Icon: styled("i").attrs({ className: "icon-star-full" })`
        color: #e96c6e;
        margin-right: 4px;
    `,
    Title: styled("h3")`
        margin-right: 4px;
        font-size: 16px;
        color: #e96c6e;
        font-weight: 500;
    `,
    Description: styled("span")`
        color: #727272;
        font-size: 12px;
    `,
};

export const DraggableWrapper = styled("div")`
    order: 2;
    z-index: 10;
    background: #eff0f3;
    min-height: 400px;
    margin-top: -25px;

    overflow: hidden;
    border-top-left-radius: ${(p) => p.theme.shape.borderRadius}px;
    border-top-right-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

export const MobileHeader = styled("div")`
    text-align: center;
    cursor: grab;
    padding-top: 8px;
    height: ${CLOSED_DRAWER_HEIGHT}px;
`;

export const MobileHeaderDash = styled("div")`
    background: #c4c4c4;
    border-radius: 5px;
    width: 36px;
    margin: 0 auto;
    height: 3px;
    border-radius: 5px;
`;

export const ListWrapper = styled("div")`
    display: flex;
    flex-direction: column;
`;

export const ListWrapperHead = styled("div")`
    display: flex;
    margin-bottom: 16px;
`;

export const ListWrapperFooter = styled("div")`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ListWrapperFooterText = styled("span")`
    color: #2e313f;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
`;

export const ListWrapperFooterButton = styled(Button)`
    max-width: 263px;
    width: 100%;
    padding: 12px;
    background-color: #dfe3ef;
    color: #2e313f;
`;

export const ListTitle = styled("div")`
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 12px;
    margin-right: 8px;
    margin-top: 0;
    margin-bottom: 0;
`;

export const ListTitleDescription = styled(Text).attrs({
    variant: "subtitle1",
    component: "div",
})<{ $noOverflow?: boolean }>`
    ${(p) =>
        !p.$noOverflow &&
        css`
            /* white-space: nowrap; */
            overflow: hidden;
            text-overflow: ellipsis;
        `}

    max-width: 100%;
`;
export const ListTitleMake = styled("span")`
    color: #222222;
`;

export const PaginationWrapper = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const Pagination = styled(MUIPagination)`
    .Mui-selected {
        background: black;
        &:hover {
            background: black;
        }
        color: white;
    }
`;

export const DropdownWrapper = styled("div")`
    display: flex;
    margin-left: auto;
    align-items: flex-end;
`;

export const OrderDropdown = styled(Dropdown)`
    width: 190px;

    .MuiInputBase-root {
        background: white;
    }

    .MuiSelect-select {
        font-size: 14px;
    }

    .icon-transfer {
        transform: rotate(90deg);
    }
` as typeof Dropdown;

export const SearchSidebarContainer = styled("div")`
    width: 50vw;
    max-width: 850px;
    padding: 24px 24px;
    background: ${(p) => p.theme.palette.greyish.main};
    position: relative;
    height: 100%;
    border-right: 1px solid #e0e0e0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    ${(p) => p.theme.breakpoints.down("lg")} {
        ${ListWrapperHead} {
            margin-bottom: 8px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    ${(p) => p.theme.breakpoints.down(1200)} {
        ${DropdownWrapper} {
            margin-left: 0;
            order: -1;
            margin-bottom: 8px;
        }
        ${TopWorkshops.Wrapper} {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        padding-left: 16px;
        padding-right: 16px;
        overflow-y: initial;
        padding-top: 0px;
        border-radius: 20px 20px 0px 0px;
        z-index: 2;
        background-image: unset;
        width: 100%;
        min-width: unset;
        background: unset;
        border: unset;

        // this is needed, so that in mobile, our &::before img is relative to the drawer and not to the SearchSidebar;
        position: static;

        ${TopWorkshops.Wrapper} {
            flex-direction: column;
            align-items: flex-start;
        }

        ${ListWrapper} {
            max-height: calc(100% - 75px);
        }
    }
`;

export const CancelIcon = styled("i")`
    font-size: 12px;
    margin-right: 5px;
    color: black;
    display: inline-block;
`;

export const ClosedDashText = styled(Text)`
    margin-top: 15px;
    & > * {
        vertical-align: text-bottom;
    }
`;

export const OtherWorkshops = {
    Wrapper: styled("div")`
        margin-top: 16px;
        margin-bottom: 16px;
    `,
    Icon: styled("i").attrs({ className: "icon-magnifier" })`
        margin-right: 8px;
        color: #727272;
    `,
    Text: styled("span")`
        margin-right: 4px;
    `,
};

export const CheckmarkImg = styled("img")`
    width: 14px;
    height: 14px;
    margin-right: 4px;
    vertical-align: middle;
    display: inline;
`;
