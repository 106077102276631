import { Radio as MUIRadio } from "@mui/material";
import styled from "styled-components";

export const Radio = styled(MUIRadio)`
    .Mui-error & {
        color: ${(p) => p.theme.palette.error.main};
    }
    color: ${(p) => p.theme.typography.subtitle2.color};
    &.Mui-checked {
        color: ${(p) => p.theme.palette.primary.main};
    }
`;
