import { InputAdornment, StandardTextFieldProps } from "@mui/material";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import * as S from "./Input.styled";

/*
 * TBD Idea: currently datepicker & autocompletes uses additional wrapper around input, when we set it as disabled,
 *     because some web engines (firefox/iOS) dont register "click" events on disabled inputs. Could add automatic
 *     addition of wrapper around the input inside this component to reduce repetition.
 */

/*
 * leftIconClass & rightIcon props are just for ease of use, to avoid all the boilerplate of <InputAdornment>...</>
 * TBD: make leftIconClass into "string" | JSX.Element if there is ever need for custom icon on the left side.
 */
export interface InputP extends StandardTextFieldProps {
    leftIconClass?: string;
    rightIconClass?: string;
    iconColor?: string;
    noBorder?: boolean;
    // withError?: boolean;
    rightIcon?: JSX.Element;
    leftIcon?: JSX.Element;
    /** atm used only by autocompletes when you know that you might need an increase in height dynamically depending on the content. */
    dynamicHeight?: boolean;
    /** When we want only clickable inputs, but dont want them to look disabled. */
    removeDisabledStyles?: boolean;
    /** When we want to bypass internal input error checks for wasFocused/isFocused */
    forceError?: boolean;
    transparentBg?: boolean;
    bgcolor?: string;
}
const Input = observer<InputP, HTMLDivElement | null>(
    (p, ref) => {
        const {
            leftIconClass,
            dynamicHeight,
            rightIconClass,
            removeDisabledStyles,
            rightIcon,
            leftIcon,
            transparentBg,
            forceError,
            // withError,
            noBorder,
            ...textFieldMUIProps
        } = p;

        const LS = useLocalObservable(() => ({
            wasFocusedOnce: false,
            isFocused: false,
        }));

        const isErr = React.useMemo(() => {
            return (
                !LS.isFocused && (forceError || LS.wasFocusedOnce) && p.error
            );
        }, [LS.isFocused, forceError, LS.wasFocusedOnce, p.error]);

        return (
            <S.Input
                $bgcolor={p.bgcolor}
                onFocus={() => {
                    runInAction(() => {
                        LS.wasFocusedOnce = true;
                        LS.isFocused = true;
                    });
                }}
                onBlur={() => {
                    runInAction(() => {
                        LS.isFocused = false;
                    });
                }}
                ref={ref}
                $noBorder={noBorder}
                $transparentBg={transparentBg}
                $dynamicHeight={dynamicHeight}
                $removeDisabledStyles={removeDisabledStyles}
                {...textFieldMUIProps}
                inputProps={{
                    ...textFieldMUIProps.inputProps,
                    ["data-hj-allow"]: true,
                }}
                value={p.value === undefined || p.value === null ? "" : p.value}
                className={p.className}
                InputProps={{
                    ...p.InputProps,
                    startAdornment: (
                        <>
                            {(leftIconClass || leftIcon) && (
                                <>
                                    <InputAdornment
                                        position="start"
                                        sx={
                                            leftIcon && {
                                                minWidth: "unset",
                                                maxWidth: "unset",
                                            }
                                        }
                                    >
                                        {leftIcon ? (
                                            leftIcon
                                        ) : (
                                            <S.InputIcon
                                                className={`${leftIconClass} left-icon`}
                                                color={p.iconColor}
                                            />
                                        )}
                                    </InputAdornment>
                                </>
                            )}
                            {p.InputProps?.startAdornment}
                        </>
                    ),
                    endAdornment: (
                        <>
                            {(rightIconClass || rightIcon) && (
                                <>
                                    <InputAdornment
                                        position="end"
                                        sx={
                                            rightIcon && {
                                                minWidth: "unset",
                                                maxWidth: "unset",
                                            }
                                        }
                                    >
                                        {rightIcon ? (
                                            rightIcon
                                        ) : (
                                            <S.InputIcon
                                                className={`${rightIconClass} right-icon`}
                                                color={p.iconColor}
                                            />
                                        )}
                                    </InputAdornment>
                                </>
                            )}
                            {p.InputProps?.endAdornment}
                        </>
                    ),
                }}
                error={isErr}
                helperText={isErr ? p.helperText : undefined}
            />
        );
    },
    { forwardRef: true }
);
Input.displayName = "Input";

export default Input;
