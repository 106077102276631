import React from "react";
import Input, { InputP } from "../Input/Input";

const NumberInput: React.FC<
    InputP & {
        intOnly?: boolean;
        onNumChange?: (num: number | undefined) => void;
    }
> = (p) => {
    const { intOnly: _, ...inputProps } = p;
    return (
        <Input
            {...inputProps}
            type={p.intOnly ? "text" : "number"}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: 0 }}
            value={p.value === undefined || p.value === null ? "" : p.value}
            onChange={(e) => {
                let parsedVal: number | undefined;

                if (e.target.value) {
                    parsedVal = p.intOnly
                        ? parseInt(e.target.value)
                        : parseFloat(e.target.value);
                } else {
                    parsedVal = undefined;
                }

                const parsedEvent = {
                    ...e,
                    target: {
                        ...e.target,
                        value: parsedVal ? parsedVal.toString() : "",
                    },
                };
                p.onChange?.(parsedEvent);
                p.onNumChange?.(parsedVal);
            }}
        />
    );
};

export default NumberInput;
