import {
    Untranslated,
    TranslatableI18nData,
    Translatable,
    ILocale,
    TranslatableStringified,
    TranslatableOrStringified,
} from "./i18n.types";
import { evaluateGemlMarkupStringToString } from "./geml-evaluation";
import { tryMapAndReturnFirst } from "./utils";

/** Creates a message that can be translated on the client (also known as a lazy translation). */
export function translatable(
    defaultTranslation: string,
    options: {
        data?: TranslatableI18nData;
        /**
         * A static description of this translatable for the translator. Is not used at runtime. Only set a description
         * if `default` needs more context.
         */
        description?: string;
        /** The static id of this translatable. */
        id: string;
        /**
         * An optional static debug information to be passed on to client. Not used while rendering but can be seen from
         * the received translatable message content (from socket, HTTP response etc.)
         */
        debugData?: string;
    }
): Translatable {
    return {
        defaultTranslation,
        data: options.data,
        id: options.id,
        debugData: options.debugData,
    };
}

/**
 * Use this if you don't want to make a string translatable. Use it for all admin interactions, and errors that don't or
 * almost never appear for normal users (e.g. in case of race condition between multiple clients or when user tries to
 * abuse our APIs)
 *
 * This is preferred over Translatable|string since it forces the dev to actively decide to not make the text
 * translatable.
 */
export function staticText(text: string): Untranslated {
    return {
        defaultTranslation: "{text}",
        data: {
            text,
        },
    };
}

export function isTranslatable(
    x: unknown | string | Translatable | React.ComponentType
): x is Translatable {
    return !!x && typeof (x as Translatable).defaultTranslation === "string";
}

export function isTranslatableStringified(
    x: unknown
): x is TranslatableStringified {
    return typeof x === "string" && x.startsWith("T|{");
}

export function isTranslatableOrStringified(
    x: unknown
): x is TranslatableOrStringified {
    return isTranslatable(x) || isTranslatableStringified(x);
}

/** Sometimes we need to store a localized message in a plain string */
export function translatableToStringified(
    l: Translatable
): TranslatableStringified {
    const translatableStringified = `T|${JSON.stringify(
        l
    )}` as TranslatableStringified;
    return translatableStringified;
}

export function translatableOrStringFromString(
    l: TranslatableStringified | string
): Translatable | string {
    if (isTranslatableStringified(l)) return JSON.parse(l.substring(2));
    return l;
}

/** Convenience function, to process messages from back-end and return a string */
export function transStringFunctional(
    stringifiedTranslatableOrString:
        | string
        | TranslatableStringified
        | Translatable,
    locale: ILocale
): string {
    const translatableOrString =
        typeof stringifiedTranslatableOrString === "string"
            ? translatableOrStringFromString(stringifiedTranslatableOrString)
            : stringifiedTranslatableOrString;

    if (isTranslatable(translatableOrString)) {
        return evaluateTranslatableToString(
            {
                defaultTranslation: translatableOrString.defaultTranslation,
                data: {
                    ...translatableOrString.data,
                    LocalDate: LocalDateFunc(
                        (translatableOrString.data?.expDate as string) ||
                            (translatableOrString.data
                                ?.expirationDate as string) ||
                            new Date().toISOString()
                    ),
                },
                id: translatableOrString.id,
            },
            locale
        );
    } else {
        return translatableOrString as string;
    }
}

export function evaluateTranslatableToString(
    translatable: Translatable,
    locale: ILocale
): string {
    const format = translatable.id
        ? locale.getTranslatedFormat(
              translatable.id,
              translatable.defaultTranslation
          )
        : translatable.defaultTranslation;

    // We use `tryMapAndReturnFirst` so that invalid translations (syntactically or semantically) don't let the UI crash.
    // The default translation is used as fallback.
    return (
        tryMapAndReturnFirst(
            [format, translatable.defaultTranslation],
            (format) =>
                evaluateGemlMarkupStringToString(
                    format,
                    translatable.data || {},
                    locale
                )
        ) || ""
    );
}

const LocalDateFunc: (arg: string) => string = (date: string) => {
    return new Date(date).toLocaleDateString();
};
