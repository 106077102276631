import { Popper } from "@mui/material";
import styled from "styled-components";

export const ExpansivePopperContainer = styled(Popper)`
    border: 1px solid #000000;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    overflow: hidden;
    z-index: 10000;
    background: white;
    display: flex;
    flex-direction: column;
    overscroll-behavior: contain;
    border-bottom: 0;

    ${(p) => p.theme.breakpoints.down("md")} {
        .MuiAutocomplete-listbox {
            max-height: unset;
        }

        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        box-shadow: 0px -200px 0px 0px rgb(0 0 0 / 70%);
        position: fixed !important;
        left: 0 !important;
        right: 0 !important;
        width: 100% !important;
        border: unset;
        /* border-radius: unset; */
        bottom: 0 !important;
        top: ${(p) => p.theme.sizes.spaceBetweenDrawerAndTop} !important;
        background: white;
        transform: unset !important;
    }
`;
