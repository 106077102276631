import Country from "@Shared/types/enums/Country";
import parsePhoneNumber from "libphonenumber-js";

export default function isValidPhoneNr(
    phoneNr: string,
    country: Country,
    allLocales?: boolean
): boolean {
    const trimmedPhoneNr = phoneNr.trim().replace(/ /g, "");
    if (allLocales) {
        // first check if its a local phone number in this country, if not, then check if its a matching international number of any locale.
        return Boolean(
            parsePhoneNumber(trimmedPhoneNr, country)?.isValid() ||
                parsePhoneNumber(trimmedPhoneNr)?.isValid()
        );
    } else {
        return Boolean(parsePhoneNumber(trimmedPhoneNr, country)?.isValid());
    }
}
