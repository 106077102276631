import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import pickABTest from "@FEShared/utils/pickABTest";

const ABTest: React.FC<{
    newComponentPercentage?: number;
    experimentKey: string;
    newComponent: JSX.Element;
    defaultComponent: JSX.Element;
}> = observer((p) => {
    const LS = useLocalObservable(() => ({
        key: undefined as undefined | string,
    }));

    React.useEffect(() => {
        const keyToSet = pickABTest({
            experimentKey: p.experimentKey,
            newComponentPercentage: p.newComponentPercentage,
        });

        runInAction(() => {
            LS.key = keyToSet;
        });
    }, [LS, p.experimentKey, p.newComponentPercentage]);

    if (!LS.key) return null; // prevent flicker until key is set;

    return (
        <>{LS.key === p.experimentKey ? p.newComponent : p.defaultComponent}</>
    );
});

export default ABTest;
