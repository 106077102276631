import { styled } from "@mui/material";

export const HomePageContainer = styled("div")`
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;

    ${(p) => p.theme.breakpoints.down("md")} {
        padding-bottom: unset;
    }
`;
