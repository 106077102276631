import ModalDrawer from "@FEShared/components/UI/ModalDrawer/ModalDrawer";
import React from "react";
import * as S from "./WarrantyModal.styled";
import { TransMsg } from "@FEShared/i18n";
import WarrantyContent from "../WarrantyContent/WarrantyContent";

const WarrantyModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = (p) => {
    return (
        <ModalDrawer isOpen={p.isOpen} onClose={p.onClose}>
            <S.Header>
                <S.Title>
                    <TransMsg default={"Techninė garantija"} id="vNKMeFBp" />
                </S.Title>
            </S.Header>
            <WarrantyContent />
        </ModalDrawer>
    );
};

export default WarrantyModal;
