import React from "react";
import ContentContainer from "../../commonComps/ContentContainer/ContentContainer";

const SeoServices_EN: React.FC = () => {
    return (
        <ContentContainer
            sx={{
                a: {
                    textDecoration: "underline",
                },
            }}
        >
            <h1>Car repair services</h1>
            <p>
                Car broke down? Need periodic service? Find your next mechanic
                here!
            </p>
            <p>
                It doesn’t matter if you need car repair, periodical service,
                seasonal tire change or AC recharge, you can find a mechanic for
                all repairs for your car on Repair Punk.
            </p>
            <h3>Services offered by mechanics & auto shops</h3>
            <p>
                Repair Punk has attracted mechanics, technicians and auto repair
                shops of various fields to our portal, who can carry out any
                needed car repair.
            </p>
            <p>Most popular repairs & diagnostics are:</p>
            <ul>
                <li>
                    <a href="/oil-change">Oil change</a>
                </li>
                <li>
                    <a href="/tire-change">Tire change</a>
                </li>
                <li>
                    <a href="/suspension-check">Suspension check</a>
                </li>
                <li>
                    <a href="/suspension-repair">Suspension repair</a>
                </li>
                <li>
                    <a href="/brake-repair">Brake repair</a>
                </li>
                <li>
                    <a href="/brake-pads-replacement">Brake pads replacement</a>
                </li>
                <li>
                    <a href="/brake-disc-replacement">Brake disc replacement</a>
                </li>
                <li>
                    <a href="/roadworthiness-test">Roadworthiness test</a>
                </li>
                <li>
                    <a href="/ac-repair">AC Repair</a>
                </li>
                <li>
                    <a href="/ac-recharge">AC Recharge</a>
                </li>
                <li>
                    <a href="/automatic-transmission-oil-replacement">
                        Automatic transmission oil replacement
                    </a>
                </li>
                <li>
                    <a href="/headlight-adjustment">Headlight adjustment</a>
                </li>
                <li>
                    <a href="/headlight-repair">Headlight repair</a>
                </li>
                <li>
                    <a href="/engine-diagnostics">Engine diagnostics</a>
                </li>
                <li>
                    <a href="/engine-repair">Engine repair</a>
                </li>
            </ul>
            <h3>It&apos;s important to get quality repairs</h3>
            <p>
                Regular technical inspection and repairs are necessary to keep
                your vehicle running smoothly and to feel safe on the road.
            </p>
            <p>
                Nevertheless, finding a reliable mechanic is not always so easy.
                It’s easy to get confused when there are so many auto repair
                shops offering various services for your car. Repair Punk aims
                to make this task easier and collects all auto repair shops that
                provide quality services and have good reviews in one place.
                This way, you can easily choose a mechanic & book an
                appointment.
            </p>
            <h3>Regular inspection</h3>
            <p>
                With regular car maintenance & periodic inspections, you
                won&apos;t have to be afraid of unexpected breakdowns and
                constant vehicle repairs, which will reduce unnecessary costs.
                Carry out periodical maintenance, change your oil regularly,
                check the coolant, brakes, seasonally change tires and balance
                the wheels.
            </p>
            <p>
                Pay attention to tire pressure, especially when the weather
                changes. Make sure the pressure is correct as it is essential
                for safe driving, fuel economy and tire life.
            </p>
            <p>
                Make sure to have these services performed regularly by a
                professional technician. This will cost you less than repairing
                the car without doing this work. In addition, periodic technical
                inspection and diagnostics of the car is not only economical,
                but also an easy task, so it does not take up much of your
                precious time.
            </p>
        </ContentContainer>
    );
};

export default SeoServices_EN;
