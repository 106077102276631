import React from "react";
import * as S from "./SpecializedBadge.styled";

const SpecializedBadge: React.FCC<{ className?: string }> = (p) => {
    return (
        <S.Specialized className={p.className}>
            <S.WrenchIcon className="icon-wrench-full" />
            {p.children}
        </S.Specialized>
    );
};

export default SpecializedBadge;
