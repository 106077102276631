import React from "react";
import * as S from "./Drawer.styled";
import { TransMsg } from "@FEShared/i18n";
import { SxProps } from "@mui/material";

const Drawer: React.FCC<{
    isOpen: boolean;
    className?: string;
    onClose: () => void;
    /* set to null if you don't want the default header */
    header?: JSX.Element | null;
    goBack?: () => void;
    onChooseClick?: () => void;
    showChooseBtn?: boolean;
    sx?: SxProps;
    disableSwipeToClose?: boolean;
}> = (p) => {
    return (
        <S.Drawer
            open={p.isOpen}
            swipeAreaWidth={0}
            disableSwipeToOpen={false}
            disableDiscovery={p.disableSwipeToClose}
            onClose={() => p.onClose()}
            onOpen={() => {}}
            anchor="bottom"
            onTouchStart={(event) => {
                if (p.disableSwipeToClose) {
                    // https://github.com/mui/material-ui/issues/18884
                    (event as any).nativeEvent.defaultMuiPrevented = true;
                }
            }}
            ModalProps={{
                keepMounted: false,
            }}
            className={p.className}
            container={window.document.body}
            sx={p.sx}
        >
            {p.header !== undefined ? (
                p.header
            ) : (
                <S.Head>
                    <S.HeadBackButton
                        onClick={() => {
                            if (p.goBack) {
                                p.goBack();
                            } else {
                                p.onClose();
                            }
                        }}
                        circle
                    />
                    {p.onChooseClick && p.showChooseBtn && (
                        <S.ConfirmBtn onClick={p.onChooseClick}>
                            <TransMsg default={"Pasirinkti"} id="oFHlp0ad" />
                        </S.ConfirmBtn>
                    )}
                </S.Head>
            )}
            <S.DrawerContent>{p.children}</S.DrawerContent>
        </S.Drawer>
    );
};

export default Drawer;
