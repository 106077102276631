import React from "react";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import Rating from "@FEShared/components/UI/Rating/Rating";
import FollowSocialButtons from "@FEShared/components/UI/FollowSocialButtons/FollowSocialButtons";
import ContentContainer from "@FEClient/views/commonComps/ContentContainer/ContentContainer";
import WorkshopDetailsHeader from "../WorkshopDetailsHeader/WorkshopDetailsHeader";
import { dateToMonthDayHour } from "@Shared/util/dateFormat";
import { AlignToBottomOnMobile } from "../ReviewPage.styled";
import { getTitleAndEmoji } from "./SavedReviews.utils";

const SavedReview: React.FC<{
    workshopName: string;
    workshopAddress: string;
    serviceName: string;
    rating: number;
    date: Date;
    reviewText?: string | null;
    customerName?: string | null;
}> = (p) => {
    const { title, emoji } = getTitleAndEmoji(p.rating);
    return (
        <>
            <WorkshopDetailsHeader
                name={p.workshopName}
                address={p.workshopAddress}
            />
            <ContentContainer>
                <Box
                    vertical
                    maxWidth="611px"
                    margin="0 auto"
                    padding="32px 0px"
                >
                    <Box vertical align="center">
                        <Text fontSize={18}>{title}</Text>
                        <Text fontSize={64} mt="32px">
                            {emoji}
                        </Text>
                    </Box>
                    <Box mt="32px">
                        <Box align="space-between" alignVertical="center">
                            <Text fontSize={16} lineHeight="20px">
                                {p.customerName}
                            </Text>
                            <Rating readOnly size="small" value={p.rating} />
                        </Box>
                        <Box align="space-between" alignVertical="center">
                            <Text
                                fontSize={12}
                                lineHeight="20px"
                                color="#727272"
                            >
                                {p.serviceName}
                            </Text>
                            <Text
                                fontSize={12}
                                lineHeight="20px"
                                color="#727272"
                            >
                                {dateToMonthDayHour(p.date)}
                            </Text>
                        </Box>
                        <Text
                            mt="4px"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight="20px"
                        >
                            {p.reviewText}
                        </Text>
                    </Box>
                    {p.rating >= 3 && (
                        <AlignToBottomOnMobile>
                            <Box mt="32px" mb="24px">
                                <FollowSocialButtons />
                            </Box>
                        </AlignToBottomOnMobile>
                    )}
                </Box>
            </ContentContainer>
        </>
    );
};

export default SavedReview;
