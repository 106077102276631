import Button from "@FEShared/components/UI/Button/Button";
import { DATE_PICKER_CLASSNAME_IDENTIFIER } from "@FEShared/components/UI/DatePicker/DatePicker.consts";
import styled, { css } from "styled-components";
import { CITY_DROPDOWN_WRAPPER_CLASSNAME } from "../../Home/SearchForm/SearchInputs/SearchInputs";
import { SEARCH_INPUTS_MOBILE_MODE_FROM_PX } from "./TopSearchBar.utils";
import Text from "@FEShared/components/UI/Text/Text";
import Box from "@FEShared/components/UI/Box/Box";

export const SelectionsWrapper = styled("div")`
    background: white;
    color: white;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    padding: 8px 24px;
    color: black;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const TextIconWrapper = styled(Box)`
    display: flex;
    align-items: center;
`;

export const SearchH2 = styled(Text).attrs({
    mr: 0.5,
    component: "h2",
    lineHeight: 1,
    display: "block",
    fontSize: 12,
})`
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const SelectionIcon = styled("i")`
    font-size: 18px;
    margin-right: 24px;
    color: #7b7f93;
`;

export const InfoWrapper = styled("div")`
    overflow: hidden;
    width: 100%;
`;

export const EllipsisText = styled(Text).attrs({ variant: "inherit" })`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const SelectedServices = styled(Text)`
    font-size: 16px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
`;

export const InfoBottomRow = styled("div")`
    color: #727272;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-wrap: nowrap;
        & > * {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 33%;
        }
    }
`;

export const BottomRow = styled("div")`
    margin-top: 16px;
`;

export const PopularServicesTitle = styled("div")`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    color: white;
`;

export const PopularServicesWrapper = styled("div")`
    display: flex;
`;

export const PopularServiceIcon = styled("i")`
    font-size: 18px;
    margin-right: 16px;
`;

export const PopularServiceBtn = styled(Button)<{ $isSelected: boolean }>`
    margin-right: 8px;
    background: white;
    align-items: center;
    padding: 8px px;
    display: flex;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    cursor: pointer;
    border: 2px solid transparent;
    font-size: 16px;
    color: black;

    &:hover {
        background: white;
    }

    ${(p) =>
        p.$isSelected &&
        css`
            border-color: ${(p) => p.theme.palette.primary.main};
        `}
`;

export const TopSearchBarContainer = styled("div")<{ $open?: boolean }>`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    ${(p) => p.theme.breakpoints.up(SEARCH_INPUTS_MOBILE_MODE_FROM_PX)} {
        .MuiInputBase-root {
            height: ${(p) => p.theme.sizes.inputHeight}px;
            overflow: hidden;
            flex-wrap: nowrap;
        }

        .MuiAutocomplete-popupIndicatorOpen {
            display: none;
        }

        ${`.${DATE_PICKER_CLASSNAME_IDENTIFIER}`} {
            max-width: 220px;
        }
        ${`.${CITY_DROPDOWN_WRAPPER_CLASSNAME}`} {
            max-width: 150px;
        }
    }

    ${(p) => p.theme.breakpoints.down(SEARCH_INPUTS_MOBILE_MODE_FROM_PX)} {
        max-width: unset;
        overflow: hidden;

        ${(p) =>
            p.$open &&
            css`
                background: ${(p) => p.theme.palette.primary.main};
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                padding: 8px 16px;
            `}

        ${SelectionsWrapper} {
            padding: 8px;
        }

        ${SelectionIcon} {
            margin-right: 12px;
        }
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        ${InfoWrapper} {
            width: 100%;
        }
    }
`;
