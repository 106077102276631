import { Order } from "@FEShared/graphql/generated/graphql";
import { DONT_KNOW_YEAR } from "./CarAutocomplete.consts";
import { TransCarDataObj, CarDataArr } from "./CarAutocomplete.types";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";
import COMMON_TRANSLATABLES from "@Shared/consts/COMMON_TRANSLATABLES";

/* Will return CarDataObj, where vehicleBrand, vehicleModel, vehicleYear is translated. We convert DONT_KNOW_MODEL, DONT_KNOW_YEAR, OTHER_MODEL_OR_YEAR, OTHER_BRAND to translated via `transCommonTranslatable` */
export function carDataArrToTransObj(carDataArr: CarDataArr): TransCarDataObj {
    return {
        vehicleBrand: transCommonTranslatable(
            carDataArr[0] as keyof typeof COMMON_TRANSLATABLES
        ),
        vehicleModel: transCommonTranslatable(
            carDataArr[1] as keyof typeof COMMON_TRANSLATABLES
        ),
        vehicleYear: transCommonTranslatable(
            carDataArr[2] as keyof typeof COMMON_TRANSLATABLES
        ),
    } as TransCarDataObj;
}

export function carDataArrToGQLObj(
    carDataArr: CarDataArr
): Pick<Order, "vehicleBrand" | "vehicleModel" | "vehicleYear"> {
    // NULL stands for "DONT_KNOW_YEAR" option. If it's undefined, it means that it wasn't chosen at all.
    const yearArrElement = carDataArr[2];
    const convertedYear = Number.isInteger(+yearArrElement)
        ? +yearArrElement
        : yearArrElement === DONT_KNOW_YEAR
        ? null
        : undefined;
    return {
        vehicleBrand: carDataArr[0],
        vehicleModel: carDataArr[1],
        vehicleYear: convertedYear,
    };
}

export function carDataObjToArr(
    carDataObj: Pick<Order, "vehicleBrand" | "vehicleModel" | "vehicleYear">
): CarDataArr {
    const carDataArr = [carDataObj.vehicleBrand, carDataObj.vehicleModel];

    if (carDataObj.vehicleBrand && carDataObj.vehicleModel) {
        if (carDataObj.vehicleYear && +carDataObj.vehicleYear) {
            carDataArr.push(carDataObj.vehicleYear.toString());
        } else if (carDataObj.vehicleYear === null) {
            carDataArr.push(DONT_KNOW_YEAR);
        }
    }

    return carDataArr.filter(Boolean) as CarDataArr;
}
