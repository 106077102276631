import { SEARCH_HEADER_HEIGHT } from "@FEClient/views/commonComps/Header/SearchHeader/SearchHeader.styled";
import styled from "styled-components";

export const SearchContainer = styled("div")`
    height: calc(100vh - ${SEARCH_HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    scroll-behavior: smooth;
    ${(p) => p.theme.breakpoints.down("md")} {
        min-height: unset;
        height: auto;
    }
`;

export const SidebarNMapWrapper = styled("div")`
    display: flex;
    flex: 1;
    overflow: hidden;
    background: #f7f7f7;
    ${(p) => p.theme.breakpoints.down("md")} {
        overflow: initial;
        flex-direction: column;
        background: transparent;
    }
`;
