import React from "react";
import { observer } from "mobx-react-lite";
import * as S from "./SearchForm.styled";
import useStore, { history } from "@FEClient/logic/store";
import useInitSearchFormInfo from "@FEClient/logic/hooks/useInitSearchFormInto/useInitSearchFormInfo";
import { UrlPathnameParser } from "../../Search/Search.logic";
import Box from "@FEShared/components/UI/Box/Box";
import Button from "@FEShared/components/UI/Button/Button";
import { TransMsg } from "@FEShared/i18n";

const SearchForm: React.FC<{}> = observer((_p) => {
    const GS = useStore();

    useInitSearchFormInfo(false);

    const onSearchBtnClick = async () => {
        history.push(
            UrlPathnameParser.paramsToPathname({
                city: GS.searchState.city,
            })
        );
    };

    return (
        <Box
            mt={3}
            maxWidth={"400px"}
            width={1}
            borderRadius={"15px"}
            overflow="hidden"
        >
            <Box pl={1} pr={1} pt={1} pb={1} bgcolor="white.main">
                <S.SearchFormSearchInputs
                    visibleInputs={{
                        carPicker: true,
                        cityPicker: true,
                        servicesPicker: true,
                        datePicker: false,
                    }}
                />
            </Box>
            <Button
                sx={{
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                }}
                fullWidth
                onClick={onSearchBtnClick}
                leftIconClass="icon-magnifier"
            >
                <TransMsg default={"Ieškoti"} id="3dxj9G1O" />
            </Button>
        </Box>
    );
});

export default SearchForm;
