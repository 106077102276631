import { InternalLink } from "@FEShared/components/InternalLink/InternalLink.styled";
import styled, { css } from "styled-components";

const NavItemActiveStyle = css`
    color: ${(p) => p.theme.palette.primary.main};
    background: rgba(94, 57, 254, 0.1);
    border-radius: 15px;
`;

export const NavItem = styled(InternalLink)<{
    $isActive?: boolean;
    $disabled?: boolean;
}>`
    font-size: 16px;
    margin-bottom: 8px;
    display: flex;
    padding: 16px 24px;
    width: 100%;
    color: #000000; // move color to theme
    cursor: pointer;

    ${(p) => p.$isActive && NavItemActiveStyle}

    ${(p) =>
        p.$disabled &&
        css`
            opacity: 0.5;
        `}

    &:hover {
        ${NavItemActiveStyle}
    }
`;
