import styled from "styled-components";

export const Title = styled("div")`
    font-size: 18px;
    font-weight: 500;
`;

export const Header = styled("div")`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`;

export const CloseIcon = styled("i")`
    font-size: 14px;
`;
