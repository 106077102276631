import { WorkshopDTO } from "@FEClient/views/pages/Workshop/Workshop.types";
import { transStringFunctionalFE } from "@FEShared/i18n";
import { transStr } from "@FEShared/i18n";

export default function isWorkshopSpecialized(
    workshop: Pick<WorkshopDTO, "specialized"> & {
        services: {
            type: Pick<
                WorkshopDTO["services"][0]["type"],
                "ID" | "serviceName"
            >;
        }[];
    },
    userChosen: { vehicleBrand: string; servicesIDs: string[] }
): {
    isSpecializedForUser: boolean;
    carBrand?: string;
    servicesIDs: string[];
    displayString?: string;
} {
    const res = {
        carBrand: workshop.specialized.vehicleBrands.includes(
            userChosen.vehicleBrand
        )
            ? userChosen.vehicleBrand
            : undefined,
        servicesIDs: userChosen.servicesIDs.filter((userChosenServiceID) =>
            workshop.specialized.servicesIDs.includes(userChosenServiceID)
        ),
    };
    const isSpecializedForUser = !!res.carBrand || res.servicesIDs.length > 0;

    let displayString: string | undefined;
    if (isSpecializedForUser) {
        if (res.carBrand) {
            displayString = transStr("Specializuojasi {carBrand} remonte", {
                data: { carBrand: res.carBrand },
                id: "0gkQENEn",
            });
        } else if (res.servicesIDs.length === 1) {
            const service = workshop.services.find(
                (s) => s.type.ID === res.servicesIDs[0]
            );
            if (service) {
                displayString = transStr("Specializuojasi {serviceName}", {
                    data: {
                        serviceName: transStringFunctionalFE(
                            service.type.serviceName
                        ),
                    },
                    id: "XcT8eOpm",
                });
            }
        } else if (res.servicesIDs.length > 1) {
            displayString = transStr(
                "Specializuojasi pasirinktose paslaugose",
                { id: "K5lLFF4Q" }
            );
        }
    }

    return {
        ...res,
        isSpecializedForUser,
        displayString,
    };
}
