import styled from "styled-components";

export const Img = styled("img")`
    height: 40px;
    opacity: 0.8;
    filter: grayscale(100%);

    ${(p) => p.theme.breakpoints.down("md")} {
        height: 25px;
    }

    ${(p) => p.theme.breakpoints.down("sm")} {
        height: 20px;
    }
`;
