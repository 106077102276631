import {
    InitSearchFormInfoDocument,
    InitSearchFormInfoQuery,
} from "@FEShared/graphql/generated/graphql";
import { runInAction } from "mobx";
import useStore, { urqlClient } from "../../store";
import React from "react";
import gqlResHandler from "@FEShared/utils/gqlResHandler";

export default function useInitSearchFormInfo(withTime = true) {
    const GS = useStore();

    React.useEffect(() => {
        async function fetch() {
            urqlClient
                .query<InitSearchFormInfoQuery>(
                    InitSearchFormInfoDocument,
                    {
                        getEarliestAvailableTimeParams: {},
                        findAllCategoriesParams: {},
                        includeGetEarliestAvailableTime: withTime,
                    },
                    {
                        requestPolicy: "network-only",
                    }
                )
                .toPromise()
                .then((res) => {
                    gqlResHandler(res, (data) => {
                        runInAction(() => {
                            if (data.findAllCategories) {
                                GS.searchState.searchServicesOptions =
                                    data.findAllCategories;
                            }
                            if (data.getEarliestAvailableTime) {
                                GS.searchState.earliestAvailableTime = new Date(
                                    data.getEarliestAvailableTime.earliestAvailableTime
                                );
                            }
                            if (data.findAllServicePackages) {
                                GS.searchState.servicePackages =
                                    data.findAllServicePackages;
                            }
                        });
                    });
                });
        }
        fetch();
    }, [withTime]); // eslint-disable-line react-hooks/exhaustive-deps
}
