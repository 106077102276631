const DESIGN_SIZE = 1920;

export default function responsiveSize(
    min: number,
    res1920Size: number,
    max: number,
    customBaseSize?: number
): string {
    return `clamp(${min}px, ${
        (res1920Size / (customBaseSize ?? DESIGN_SIZE)) * 100
    }vw, ${max}px)`;
}
