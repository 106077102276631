import useStore from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import React from "react";
import ReactDOM from "react-dom";

export const OVERLAY_CONTAINER_Z_INDEX = 999;

// base function for creating DOM div node
function createPositionContainerEl() {
    const el = document.createElement("div");
    el.classList.add("OverlayContainer");
    el.style.position = "absolute";
    el.style.display = "inline-block";
    return el;
}

// Our OverlayComponent will recieve map, postion and children props - position is coords, map is google.map object and children is a component that will render in overlay
interface P {
    map: google.maps.Map | null;
    position: { lat: number; lng: number };
    workshopID?: number;
}
const OverlayContainer: React.FCC<P> = observer((p) => {
    const GS = useStore();
    const overlayRef = React.useRef<google.maps.OverlayView | null>(null);
    const positionContainerElRef = React.useRef<HTMLDivElement>(
        createPositionContainerEl()
    );

    React.useEffect(() => {
        if (p.workshopID === GS.searchPageState.selectedServiceId) {
            positionContainerElRef.current.style.zIndex = "9999";
        } else if (positionContainerElRef.current && p.workshopID) {
            positionContainerElRef.current.style.zIndex =
                GS.searchPageState.sortedTopWorkshopIDs.includes(p.workshopID)
                    ? String(
                          GS.searchPageState.sortedTopWorkshopIDs.length -
                              GS.searchPageState.sortedTopWorkshopIDs.indexOf(
                                  p.workshopID
                              )
                      )
                    : "-1";
        }
    }, [
        GS.searchPageState.sortedTopWorkshopIDs,
        p.workshopID,
        GS.searchPageState.selectedServiceId,
    ]);

    React.useEffect(() => {
        return () => {
            if (overlayRef.current) overlayRef.current.setMap(null);
        };
    }, []);

    React.useEffect(() => {
        if (!p.map) return;
        // This can't be refactored into seperate file, because we can only use the extension, when window.google object is initialized.
        // modified OverlayView from google.maps [https://developers.google.com/maps/documentation/javascript/reference/3.44/overlay-view?hl=en]
        class GMapOverlayView extends window.google.maps.OverlayView {
            position: google.maps.LatLng | null = null;
            content: HTMLDivElement;

            constructor(props: {
                content: HTMLDivElement;
                position: google.maps.LatLng;
            }) {
                super();
                this.position = props.position;
                this.content = props.content;
            }

            onAdd = () => {
                if (!this.getPanes()) throw new Error("no getPanes()!");
                if (this.content)
                    this.getPanes()?.floatPane.appendChild(this.content);
            };

            onRemove = () => {
                if (this.content?.parentElement) {
                    this.content.parentElement.removeChild(this.content);
                }
            };

            draw = () => {
                if (this.position) {
                    const divPosition =
                        this.getProjection().fromLatLngToDivPixel(
                            this.position
                        );

                    if (!divPosition) throw new Error("divPosition is null");

                    this.content.style.left =
                        divPosition.x - this.content.clientWidth * 0.5 + "px";
                    this.content.style.top =
                        divPosition.y - this.content.clientHeight + "px";
                }
            };
        }

        overlayRef.current =
            overlayRef.current ||
            new GMapOverlayView({
                position: new google.maps.LatLng(
                    p.position.lat,
                    p.position.lng
                ),
                content: positionContainerElRef.current,
            });
        overlayRef.current.setMap(p.map);
    }, [p.map, p.position]);

    if (p.map && positionContainerElRef.current) {
        return ReactDOM.createPortal(
            p.children,
            positionContainerElRef.current
        );
    }
    return null;
});

export default OverlayContainer;
