import styled, { css } from "styled-components";
import ContentContainer from "../ContentContainer/ContentContainer";
import BG_LINES_FOOTER from "../assets/bg-lines-footer.webp";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";

export const FooterContentContainer = styled(ContentContainer)`
    padding-top: unset;
    padding-bottom: unset;
    position: relative;
    z-index: 1;
`;

export const SocialMediaRow = styled("div")`
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
`;

export const IconLink = styled("a")`
    &:not(:last-child) {
        margin-right: 20px;
    }
`;

export const SocialMediaIcon = styled("i")`
    font-size: 25px;
    color: white;
`;

export const FooterLogo = styled("div")`
    font-size: 29px;
    color: white;
    margin-bottom: 31px;
`;

export const ColumnWrapper = styled("div")`
    display: flex;
    font-weight: 500;
    flex-direction: column;
    &:not(:last-child) {
        margin-right: 16px;
    }
    ${(p) => p.theme.breakpoints.down("md")} {
        margin-bottom: 16px;
    }
`;

export const LinkStyles = css`
    margin-bottom: 8px;
    color: white;
    font-size: 16px;

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-right: unset;
    }
`;

export const ImportantLink = styled("a")`
    font-weight: 500;
    ${LinkStyles}
`;

export const ExternalLink = styled("a")`
    ${LinkStyles}
    font-weight: 300;
`;

export const UnimportantLink = styled("a")`
    ${LinkStyles}
    font-weight: 300;
`;

export const UnimportantLinksWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    font-weight: 300;
`;

export const FooterLinksWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    & > * {
        flex: 1;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: column;
    }
`;

export const ContactsWrapper = styled("div")`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
`;

export const ContactRow = styled(Box)`
    ${LinkStyles}

    display: flex;
    align-items: center;
    font-weight: 300;
    margin-bottom: 8px;
    a {
        color: white;
    }
`;

export const ContactIcon = styled("i")`
    margin-right: 5px;
`;

export const ColumnTitle = styled(Text).attrs({
    mb: 1,
    color: "rgba(255,255,255,0.6)",
    semiBold: true,
    fontSize: 14,
})`` as typeof Text;

export const FooterContainer = styled("footer")`
    background-image: url(${BG_LINES_FOOTER});
    background-size: cover;
    background: #161616;
    min-height: 374px;
    width: 100%;
    padding-top: 66px;
    padding-bottom: 80px;
    color: white;
    position: relative;
    margin-top: auto;
    flex: 1;

    &::before {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        pointer-events: none;
        background-position: 0 100%;
        z-index: 0;
    }

    &::after {
        content: " ";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 100% 0%;
        background-size: contain;
        pointer-events: none;
        z-index: 0;

        ${(p) => p.theme.breakpoints.down("md")} {
            display: none;
        }
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        padding-top: 26px;

        ${SocialMediaRow} {
            margin-bottom: 20px;
        }

        ${FooterLogo} {
            margin-bottom: 20px;
        }

        ${ContactsWrapper} {
            flex-direction: column;
        }

        ${UnimportantLinksWrapper} {
            text-align: left;
        }
    }
`;
