import styled, { css } from "styled-components";

interface ConfirmationDetailsBoxP {
    $marginBottom?: number;
    $background?: string;
}

export const ConfirmationDetailsBox = styled("div")<ConfirmationDetailsBoxP>`
    display: flex;
    flex-direction: column;
    background: #f0f2f8;
    padding: 16px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    background: white;

    ${(p) =>
        p.$marginBottom &&
        css`
            margin-bottom: ${p.$marginBottom}px;
        `}
    ${(p) =>
        p.$background &&
        css`
            background: ${p.$background};
        `};
`;
