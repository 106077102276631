import React from "react";
import OverlayContainer from "./OverlayContainer/OverlayContainer";
import MapPoint from "./MapPoint/MapPoint";
import { observer } from "mobx-react-lite";
import useStore from "@FEClient/logic/store";
import { runInAction } from "mobx";
import { LIST_ITEM_CLASSNAME_IDENTIFIER } from "../../SearchSidebar/ListItem/ListItem";
import { MOBILE_SERVICE_SELECTION_CLASS_IDENTIFIER } from "../MobileServiceSelection/MobileServiceSelection";
import { COLLAPSED_MAP_POINT_CLASSNAME_IDENTIFIER } from "./MapPoint/CollapsedMapPoint/CollapsedMapPoint";
import { EXPANDED_MAP_POINT_CLASSNAME_IDENTIFIER } from "./MapPoint/ExpandedMapPoint/ExpandedMapPoint";
import { DollarRanges } from "./GoogleMap.types";
import _mean from "lodash/mean";
import { WorkshopOmitted } from "../../Search.types";
import * as S from "./GoogleMap.styled";
import lDebounce from "lodash/debounce";
import Box from "@FEShared/components/UI/Box/Box";
import lRemove from "lodash/remove";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import createAdjustedBounds from "./GoogleMap.utils";
import prodRealUsersOnly from "@FEClient/logic/utils/prodRealUsersOnly";

const debounced = lDebounce((callbackFn: () => void) => {
    callbackFn();
}, 200);

let searchTimeout: NodeJS.Timeout;

function calcPriceRanges(hourCostArray: number[]): DollarRanges {
    const hourPriceAvg = _mean(hourCostArray);

    return {
        oneDollarRange: hourPriceAvg * 0.66,
        twoDollarRange: hourPriceAvg * 1.33,
        threeDollarRange: hourPriceAvg * 1.33 * 2,
    };
}

interface P {
    center: google.maps.LatLngLiteral;
    zoom: number;
    mapItems: WorkshopOmitted[];
    disabled?: boolean;
}
const GoogleMap: React.FC<P> = observer((p) => {
    const mapRef = React.useRef<HTMLDivElement | null>(null);
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const initAdjustmentDone = React.useRef(false);
    const GS = useStore();
    const SPS = GS.searchPageState;
    const isMobile = useIsMobile();

    const activeMapItem = p.mapItems.find(
        (i) => i.ID === GS.searchPageState.selectedServiceId
    );

    React.useEffect(() => {
        if (
            SPS.currentDevicePos.latitude &&
            SPS.currentDevicePos.longitude &&
            map
        ) {
            map?.panTo({
                lat: SPS.currentDevicePos.latitude,
                lng: SPS.currentDevicePos.longitude,
            });
        }
    }, [map, SPS.currentDevicePos]);

    const handleClickOutside = React.useCallback(
        (event: MouseEvent | TouchEvent) => {
            const eventTarget = event.target as HTMLElement;

            if (
                eventTarget.closest(
                    `.${COLLAPSED_MAP_POINT_CLASSNAME_IDENTIFIER}`
                ) ||
                eventTarget.closest(`.${LIST_ITEM_CLASSNAME_IDENTIFIER}`) ||
                eventTarget.closest(
                    `.${EXPANDED_MAP_POINT_CLASSNAME_IDENTIFIER}`
                ) ||
                eventTarget.closest(
                    `.${MOBILE_SERVICE_SELECTION_CLASS_IDENTIFIER}`
                )
            ) {
                return;
            }

            runInAction(() => {
                GS.searchPageState.selectedServiceId = undefined;
                GS.searchPageState.hoveringOnServiceId = undefined;
            });
        },
        [GS.searchPageState]
    );

    const priceRanges = React.useMemo(
        () => calcPriceRanges(p.mapItems.map((i) => i.hourCost)),
        [p.mapItems]
    );

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [handleClickOutside]);

    // TBD we probably dont need mapRef and map in seperates here.
    React.useEffect(() => {
        if (mapRef.current && !map) {
            const createdMap = new google.maps.Map(mapRef.current, {
                center: p.center,
                zoom: p.zoom,
                gestureHandling: "greedy",
                disableDefaultUI: isMobile,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                },
                fullscreenControl: false,
                streetViewControl: false,
                clickableIcons: false,
                mapId: "d8195c8f688a13d",
                mapTypeControl: false,
            });

            console.log("map load in search");
            prodRealUsersOnly(() => {
                const url = window.location.origin + window.location.pathname;
                window.gtag?.("event", "google_map_loaded", {
                    event_label: url,
                });
            });

            // tbd this is probably useless
            setTimeout(() => {
                const gmapWrapper = mapRef.current;
                gmapWrapper?.setAttribute("data-hj-allow-iframe", "true");
                (window as any).gmapWrapper = gmapWrapper;

                const iframe = gmapWrapper?.querySelector("iframe");
                iframe?.setAttribute("data-hj-allow-iframe", "true");
            }, 100);

            createdMap.addListener("idle", () => {
                const currentPos = createdMap.getCenter();
                const currentZoom = createdMap.getZoom();

                runInAction(() => {
                    if (!currentPos) return;
                    GS.searchPageState.posBackup = {
                        city: GS.searchState.city,
                        position: currentPos,
                        zoom: currentZoom,
                    };
                });
            });
            setMap(createdMap);
        }
    }, [p.center, p.zoom, map, GS.searchPageState, GS.searchState, isMobile]);

    const adjustVisibility = React.useCallback(
        (noTimeout?: boolean) => {
            debounced(() => {
                if (SPS.altFlow.isAlt) {
                    GS.searchPageState.workshopIDsInMapBounds = p.mapItems.map(
                        (i) => i.ID
                    );
                    return;
                }

                const bounds = map?.getBounds();
                if (!bounds) {
                    console.error("map", map);
                    console.error("map?.getBounds", map?.getBounds());
                    return console.error("Bounds not found!");
                }
                const adjustedBounds = createAdjustedBounds(bounds, 0.9);

                const mapItemsInBounds = p.mapItems.filter((mapItem) => {
                    const position = new google.maps.LatLng(
                        mapItem.posX,
                        mapItem.posY
                    );

                    return adjustedBounds.contains(position);
                });

                if (searchTimeout) clearTimeout(searchTimeout);

                const update = () => {
                    runInAction(() => {
                        GS.searchPageState.workshopIDsInMapBounds =
                            mapItemsInBounds.map((i) => i.ID);

                        if (
                            GS.searchPageState.workshopIDsInMapBounds.length > 0
                        ) {
                            initAdjustmentDone.current = true;
                        }

                        if (
                            !GS.searchPageState.loaders.includes(
                                "SEARCH_LOADER"
                            )
                        ) {
                            // This if exists for not straightforward reasons.
                            // We only want to remove FAKE_LOADER when graphql search request finished loading, because there is the following flow:
                            // 1. We fetch results and put them to store
                            // 2. React checks workshops positions against current map bounds
                            // 3. Visible map items are rendered
                            // Without this iF, there is "loader" back/forth addition/removal
                            lRemove(
                                GS.searchPageState.loaders,
                                (v) => v === "FAKE_LOADER"
                            );
                            GS.searchPageState.loaders = [
                                ...GS.searchPageState.loaders,
                            ];
                        }
                    });
                };

                if (noTimeout) {
                    update();
                } else {
                    runInAction(() => {
                        if (
                            !GS.searchPageState.loaders.includes("FAKE_LOADER")
                        ) {
                            GS.searchPageState.loaders = [
                                ...GS.searchPageState.loaders,
                                "FAKE_LOADER" as const,
                            ];
                        }
                    });
                    searchTimeout = setTimeout(() => {
                        update();
                    }, 400);
                }
            });
        },
        [GS.searchPageState, map, p.mapItems, SPS.altFlow.isAlt]
    );

    React.useEffect(() => {
        if (!map) return;

        // better would be using "idle" or "bounds_change" event, instead of those 2, but both events has a bug of retriggering on iOS when page is dragged to the bottom
        const dragendListener = window.google.maps.event.addListener(
            map,
            "dragend",
            () => {
                adjustVisibility();
            }
        );
        const zoomChangedListener = window.google.maps.event.addListener(
            map,
            "zoom_changed",
            () => {
                adjustVisibility();
            }
        );
        const idleListener = window.google.maps.event.addListener(
            map,
            "idle",
            () => {
                if (initAdjustmentDone.current) return;
                adjustVisibility(true);
            }
        );
        adjustVisibility(true);
        return () => {
            window.google.maps.event.removeListener(dragendListener);
            window.google.maps.event.removeListener(zoomChangedListener);
            window.google.maps.event.removeListener(idleListener);
        };
    }, [adjustVisibility, map]);

    React.useEffect(() => {
        if (!mapRef.current || !activeMapItem || !map) return;
        map.panTo({
            lat: activeMapItem.posX, //+ (isMobile ? 0 : 0.003), TBD figure this out, this is broken depending on zoom level.
            lng: activeMapItem.posY,
        });
        adjustVisibility(true);
    }, [activeMapItem]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <S.MapContainer
            ref={mapRef}
            $disabled={p.disabled}
            data-hj-allow-iframe="true"
        >
            {SPS.currentDevicePos.latitude &&
                SPS.currentDevicePos.longitude && (
                    <OverlayContainer
                        workshopID={-1337}
                        map={map}
                        position={{
                            lat: SPS.currentDevicePos.latitude,
                            lng: SPS.currentDevicePos.longitude,
                        }}
                    >
                        <Box
                            sx={{
                                border: "3px solid white",
                                backgroundColor: "#1B84FF",
                                borderRadius: "100%",
                                width: "18px",
                                height: "18px",
                                boxShadow:
                                    "0px 0px 0px 10px rgba(27,132,255,0.2)",
                            }}
                        />
                    </OverlayContainer>
                )}
            {!GS.searchPageState.loaders.includes("SEARCH_LOADER") &&
                p.mapItems
                    .filter(
                        (i) =>
                            GS.searchPageState.workshopIDsInMapBounds.includes(
                                i.ID
                            ) &&
                            GS.searchPageState.visibleWorkshopIDs.includes(i.ID)
                    )
                    .map((mapItem) => {
                        return (
                            <OverlayContainer
                                workshopID={mapItem.ID}
                                map={map}
                                position={{
                                    lat: mapItem.posX,
                                    lng: mapItem.posY,
                                }}
                                key={mapItem.ID}
                            >
                                <MapPoint
                                    mapItem={mapItem}
                                    priceRanges={priceRanges}
                                />
                            </OverlayContainer>
                        );
                    })}
        </S.MapContainer>
    );
});

export default GoogleMap;
