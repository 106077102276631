import React from "react";
import ContentContainer from "@FEClient/views/commonComps/ContentContainer/ContentContainer";
import * as S from "./WorkshopDetailsHeader.styled";

const WorkshopDetailsHeader: React.FC<{
    name: string;
    address: string;
}> = (p) => {
    return (
        <S.Container>
            <ContentContainer>
                <S.Title>{p.name}</S.Title>
                <S.Subtitle>{p.address}</S.Subtitle>
            </ContentContainer>
        </S.Container>
    );
};

export default WorkshopDetailsHeader;
