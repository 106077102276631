/*
 * It's just like `mapGroupBy/lodash.groupBy` except, you can provide multiple keys by which you can group and one value can be grouped into multiple keys.
 * It will avoid duplications in the same group. E.g if you provide multiple key generators that return same key, only the first key will be used.
 */
export default function mapMultiGroupBy<T, K>(
    valuesArr: T[],
    getKeys: ((t: T) => K[])[]
): Map<K, T[]> {
    return valuesArr.reduce((accumulator, currentValue) => {
        const usedArrKeys: K[] = [];
        getKeys.forEach((getKey) => {
            const keysArr = getKey(currentValue);

            keysArr.forEach((key) => {
                if (usedArrKeys.includes(key)) return;
                const group = accumulator.get(key) || [];
                group.push(currentValue);
                usedArrKeys.push(key);
                accumulator.set(key, group);
            });
        });
        return accumulator;
    }, new Map<K, T[]>());
}
