import React from "react";
import { Fade } from "@mui/material";
import Box from "../UI/Box/Box";

interface LoadOnViewProps {
    children: React.ReactNode;
    threshold?: number;
    timeout?: number;
}

const LoadOnView: React.FC<LoadOnViewProps> = (p) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const elementRef = React.useRef<HTMLDivElement>(null);
    const alreadySetRef = React.useRef<boolean>(false);

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!alreadySetRef.current && entry.isIntersecting) {
                    console.log("setIsVisible");
                    setIsVisible(entry.isIntersecting);
                    alreadySetRef.current = true;
                }
            },
            { threshold: p.threshold }
        );

        const currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [p.threshold]);

    return (
        <Box ref={elementRef} height={1}>
            <Fade in={isVisible} timeout={p.timeout}>
                <Box height={1}>{isVisible && p.children}</Box>
            </Fade>
        </Box>
    );
};

export default LoadOnView;
