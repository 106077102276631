export default function capFirst(s: string, lowerCaseRest = true): string {
    if (!s) return "";

    let newString: string;
    if (lowerCaseRest) {
        newString = s[0].toUpperCase() + s.slice(1).toLowerCase();
    } else {
        newString = s[0].toUpperCase() + s.slice(1);
    }
    return newString;
}
