import styled, { css } from "styled-components";

export const MapContainer = styled("div")<{ $disabled?: boolean }>`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    .gm-style {
        // disable blue outline that sometimes appears
        & > * {
            border: unset !important;
        }
    }
`;
