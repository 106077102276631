import styled from "styled-components";
import ContentContainer from "../../commonComps/ContentContainer/ContentContainer";

export const LeftPanel = styled("div")`
    display: flex;
    flex-direction: column;
    max-width: 615px;
    margin-right: 32px;
`;

export const SuccessContainer = styled(ContentContainer)`
    display: flex;
    padding: 32px 0;

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: column;

        ${LeftPanel} {
            max-width: unset;
            width: 100%;
        }
    }
`;
