import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    ClientPage,
    pageRedirects,
    countryClientPageToMeta,
    clientUrlToPage,
} from "@Shared/util/clientPagesMeta";
import MetaWrapper from "FEShared/utils/MetaWrapper";
import Home from "@FEClient/views/pages/Home/Home";
import Search from "../../pages/Search/Search";
import ReservationConfirmation from "../../pages/ReservationConfirmation/ReservationConfirmation";
import ReservationSuccess from "../../pages/ReservationSuccess/ReservationSuccess";
import AlternativesSearch from "../../pages/AlternativesSearch/AlternativesSearch";
import PrivacyPolicy_LT from "../../pages/PrivacyPolicy/PrivacyPolicy_LT";
import TermsOfService_LT from "../../pages/TermsOfService/TermsOfService_LT";
import AboutUs from "../../pages/AboutUs/AboutUs";
import SeoServices from "../../pages/SeoServices/SeoServices";
import ReviewPage from "../../pages/ReviewPage/ReviewPage";
import BecomePartner from "@FEClient/views/pages/BecomePartner/BecomePartner";
import Workshop from "@FEClient/views/pages/Workshop/Workshop";
import OnRouteChange from "../../../../../FEShared/components/OnRouteChange/OnRouteChange";
import AlternativeReservationConfirmation from "@FEClient/views/pages/AlternativeReservationConfirmation/AlternativeReservationConfirmation";
import { runInAction } from "mobx";
import useStore from "@FEClient/logic/store";
import Warranty from "@FEClient/views/pages/Warranty/Warranty";
import ClientOnly from "@FEClient/views/pages/ClientOnly/ClientOnly";
import WarrantyActivation from "@FEClient/views/pages/WarrantyActivation/WarrantyActivation";
import Sitemap from "@FEClient/views/pages/Sitemap/Sitemap";
import Partners from "@FEClient/views/pages/Partners/Partners";
import AdminPromo from "@FEClient/views/pages/AdminPromo/AdminPromo";
import Country from "@Shared/types/enums/Country";
import TermsOfService_EN from "@FEClient/views/pages/TermsOfService/TermsOfService_EN";
import PrivacyPolicy_EN from "@FEClient/views/pages/PrivacyPolicy/PrivacyPolicy_EN";
import { transStringFunctionalFE } from "@FEShared/i18n";

const PAGE_TO_COMPONENT: Record<
    ClientPage,
    React.ReactElement | Record<Country, React.ReactElement>
> = {
    [ClientPage.HOME]: <Home />,
    [ClientPage.SEO_PAGES]: <Home />,
    [ClientPage.SEARCH]: <Search />,
    [ClientPage.ALTERNATIVES_SEARCH]: <AlternativesSearch />,
    [ClientPage.WORKSHOP]: <Workshop />,
    [ClientPage.RESERVATION_CONFIRM]: <ReservationConfirmation />,
    [ClientPage.RESERVATION_SUCCESS]: <ReservationSuccess />,
    [ClientPage.TERMS_OF_SERVICE]: {
        [Country.LT]: <TermsOfService_LT />,
        [Country.CA]: <TermsOfService_EN />,
    },
    [ClientPage.PRIVACY_POLICY]: {
        [Country.LT]: <PrivacyPolicy_LT />,
        [Country.CA]: <PrivacyPolicy_EN />,
    },
    [ClientPage.ABOUT_US]: <AboutUs />,
    [ClientPage.SEO_SERVICES]: <SeoServices />,
    [ClientPage.INTERNAL_ORDER_REVIEW]: <ReviewPage />,
    [ClientPage.BECOME_PARTNER]: <BecomePartner />,
    [ClientPage.WARRANTY]: {
        [Country.LT]: <Warranty />,
        [Country.CA]: <Warranty />,
    },
    [ClientPage.ALTERNATIVE_RESERVATION_CONFIRMATION]: (
        <AlternativeReservationConfirmation />
    ),
    [ClientPage.CLIENT_ONLY]: <ClientOnly />,
    [ClientPage.WARRANTY_ACTIVATION]: <WarrantyActivation />,
    [ClientPage.WARRANTY_N_REVIEW]: <WarrantyActivation />,
    [ClientPage.SITEMAP]: <Sitemap />,
    [ClientPage.PARTNERS]: <Partners />,
    [ClientPage.ADMIN_PROMO]: <AdminPromo />,
};

const clientPageMeta = countryClientPageToMeta(window._COUNTRY);

const PagesRoutes: React.FC = () => {
    const GS = useStore();

    return (
        <Switch>
            {Object.entries(clientPageMeta).map(([page, meta]) => {
                const pageToComponent = PAGE_TO_COMPONENT[page];
                let path: string | string[];

                if (meta.genI18nPath) {
                    path = meta.genI18nPath(
                        transStringFunctionalFE,
                        window._COUNTRY
                    );
                } else {
                    path = meta.path || meta.url;
                }

                return (
                    <Route
                        exact={!meta.notExactMatch}
                        path={path}
                        key={meta.url}
                    >
                        <>
                            <OnRouteChange
                                onRouteChange={(matchedRoute) => {
                                    const pair = Object.entries(
                                        clientUrlToPage(
                                            window._COUNTRY,
                                            transStringFunctionalFE
                                        )
                                    ).find(([pathNamesString, _page]) => {
                                        return pathNamesString
                                            .split(",")
                                            .some(
                                                (pathName) =>
                                                    pathName ===
                                                    matchedRoute.path
                                            );
                                    });

                                    runInAction(() => {
                                        if (!pair) {
                                            return console.error(
                                                `Active page not found for matchedRoute: ${JSON.stringify(
                                                    matchedRoute
                                                )}`
                                            );
                                        }
                                        const [_pathNamesString, page] = pair;
                                        GS.activePage = page;
                                    });
                                }}
                            />
                            <MetaWrapper
                                title={meta.title}
                                descr={meta.description}
                            >
                                {React.isValidElement(pageToComponent)
                                    ? pageToComponent
                                    : pageToComponent[window._COUNTRY]}
                            </MetaWrapper>
                        </>
                    </Route>
                );
            })}
            {pageRedirects.map((r) => {
                return (
                    <Route path={r.fromPath} key={r.fromPath}>
                        <Redirect to={r.toPath} />
                    </Route>
                );
            })}
        </Switch>
    );
};
export default PagesRoutes;
