import React from "react";
import Text from "@FEShared/components/UI/Text/Text";
import Box from "@FEShared/components/UI/Box/Box";
import { TransMsg } from "@FEShared/i18n";

const urls: { name: string; url: string }[] = [
    {
        name: "Flixauto",
        url: "https://flixauto.lt",
    },
    {
        name: "Česma",
        url: "https://cesma.lt",
    },
    {
        name: "AurenAuto",
        url: "https://aurenauto.lt",
    },
    {
        name: "Carmann",
        url: "https://carmann.lt",
    },
    {
        name: "Smetonos",
        url: "https://smetonos.lt",
    },
    {
        name: "Aservis",
        url: "https://aservis.lt",
    },
    {
        name: "Monkesbiznis",
        url: "https://monkesbiznis.lt",
    },
    {
        name: "Danjan",
        url: "https://danjan.lt",
    },
    {
        name: "Edler",
        url: "https://edler.lt",
    },
    {
        name: "Autodina",
        url: "https://autodina.lt",
    },
    {
        name: "Inrada",
        url: "https://inrada.lt",
    },
    {
        name: "Acon1",
        url: "https://acon1.lt",
    },
    {
        name: "Edservis",
        url: "https://edservis.lt",
    },
    {
        name: "Deremontas",
        url: "https://deremontas.lt",
    },
    {
        name: "Fassauga",
        url: "https://fassauga.lt",
    },
    {
        name: "Autoglass Service",
        url: "https://autoglass-service.lt",
    },
    {
        name: "Autoefka",
        url: "https://autoefka.lt",
    },
    {
        name: "Megasola",
        url: "https://megasola.lt",
    },
    {
        name: "Elegantservice",
        url: "https://elegantservice.lt",
    },
    {
        name: "Automeškos",
        url: "https://automeskos.lt",
    },
    {
        name: "DSC Off",
        url: "https://www.dscoff.lt",
    },
    {
        name: "WheelSpot ",
        url: "https://www.wheelspot.lt",
    },
    {
        name: "3GDetailing ",
        url: "https://3gdetailing.lt",
    },
    {
        name: "Berataus",
        url: "https://berautus.lt",
    },
    {
        name: "TempaDrive",
        url: "https://tempadrive.com",
    },
    {
        name: "Clavis Auto",
        url: "https://clavisauto.lt",
    },
    {
        name: "TurboFortas ",
        url: "https://turbofortas.lt",
    },
    {
        name: "Remonto Centras",
        url: "https://www.remontocentras.lt",
    },
    {
        name: "Baltic Diagnostic Service",
        url: "https://bds.lt",
    },
    { name: "Bam Servisas", url: "https://bamservisas.lt/" },
    { name: "JL Autoservisas", url: "https://jlservisas.lt/" },
    { name: "Autokunce", url: "https://autokunce.lt/" },
    { name: "Autoringas", url: "http://autoringas.lt/" },
    { name: "TurboBaltic", url: "https://turbobaltic.lt/" },
    { name: "Automagija", url: "https://www.automagija.lt/" },
    { name: "Kils Dirbtuves", url: "https://kilsauto.lt/" },
    { name: "Tomada", url: "http://www.tomada.lt/" },
    { name: "Fixikai", url: "https://fixikai.com/" },
];

const Partners = () => {
    return (
        <Box padding={4} textAlign="center">
            <Text fontSize={30} semiBold mb={3}>
                <TransMsg default={"Partneriai"} id="ufyHhP4v" />
            </Text>
            <div>
                {urls.map((url) => (
                    <div key={url.url}>
                        <a
                            href={url.url}
                            style={{
                                color: "blue",
                                textDecoration: "underline",
                            }}
                        >
                            {url.name}
                        </a>
                    </div>
                ))}
            </div>
        </Box>
    );
};

export default Partners;
