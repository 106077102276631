import { css } from "@mui/material/styles";
import styled from "styled-components";
import ContentContainer from "../ContentContainer/ContentContainer";
import { ClientPage } from "@Shared/util/clientPagesMeta";

// maybe should be added to theme. I think inside theme this value could be made responsive and automatically adjusting to width.
interface HeaderContainerP {
    $page?: ClientPage;
    $notSticky?: boolean;
}
export const HeaderContainer = styled("header")<HeaderContainerP>`
    height: ${(p) => p.theme.sizes.headerHeight}px;
    position: sticky;
    background: white;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    z-index: ${(p) => p.theme.zIndex.appBar};

    ${(p) =>
        p.$notSticky &&
        css`
            position: static;
        `}
`;

export const HeaderContent = styled(ContentContainer)<{ $page?: ClientPage }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    ${(p) =>
        p.$page &&
        [ClientPage.SEARCH, ClientPage.ALTERNATIVES_SEARCH].includes(p.$page) &&
        css`
            margin: 0 16px;
            max-width: unset;
        `}
`;
