import styled from "styled-components";
import { css } from "@mui/material/styles";
import * as AWMS from "@FEShared/components/UI/AutocompleteWithModal/AutocompleteWithModal.styled";
import AutocompleteWithModal from "../AutocompleteWithModal/AutocompleteWithModal";

const DESKTOP_DROPDOWN_SIDE_PADDING = "20px";

export const CarAutocomplete = styled(
    AutocompleteWithModal
)`` as typeof AutocompleteWithModal;

export const DropdownContainer = styled(AWMS.DropdownContainer)`
    flex: 1;
    background: white;
    height: 100%;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .MuiAutocomplete-listbox {
        max-height: initial !important;
        overflow-y: auto;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        max-height: unset;
    }
`;

export const DropdownGroupTitle = styled("div")`
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    padding: 0 ${DESKTOP_DROPDOWN_SIDE_PADDING} 5px
        ${DESKTOP_DROPDOWN_SIDE_PADDING}!important;
`;

export const DropdownOption = styled("li")`
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 0;
    padding: 5px ${DESKTOP_DROPDOWN_SIDE_PADDING}!important;

    ${(p) => p.theme.breakpoints.down("md")} {
        padding-left: 0 !important;
    }
`;

export const StateWrapper = styled("div")`
    display: flex;
    align-items: center;
    padding: 5px ${DESKTOP_DROPDOWN_SIDE_PADDING} 0
        ${DESKTOP_DROPDOWN_SIDE_PADDING};

    ${(p) => p.theme.breakpoints.down("md")} {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const CurrentState = styled("div")<{ $active?: boolean }>`
    color: ${(p) => p.theme.palette.text.secondary};

    ${(p) =>
        p.$active &&
        css`
            color: ${p.theme.palette.primary.main};
        `}
`;

export const CurrentStateTitle = styled(CurrentState)`
    font-size: 20px;
    white-space: nowrap;
`;

export const StateSeperator = styled("span")`
    color: ${(p) => p.theme.palette.text.secondary};
    font-size: 20px;
    margin: 0 5px;
`;

export const CarOpt = styled("span")`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-width: 40px;
    text-overflow: ellipsis;
    max-width: 31%;
    display: inline;
    text-align: center;
`;
