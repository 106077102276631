import { ABOVE_WORKSHOPS_COUNT } from "@FEClient/consts/consts";
import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import React from "react";
import * as S from "./AboutUs.styled";
import DEVS_IMG from "./assets/devs.webp";
import MACHINERY_IMG from "./assets/machinery.webp";
import { TransMsg } from "@FEShared/i18n";

const AboutUs: React.FC = () => {
    return (
        <S.AboutUsContainer>
            <S.PicBanner $backgroundImgUrl={MACHINERY_IMG}>
                <ContentContainer>
                    <S.Title>
                        <TransMsg default={"Apie Mus"} id="yxeKbeql" />
                    </S.Title>
                    <S.Descr>
                        <TransMsg
                            default={
                                "Esame pasiryžę pakeisti senamadišką požiūrį į automobilių remontą. Siūlome patogų ir greitą būdą registruotis į tavo pasirinktą autoservisą. Jokių skambučių, 4 mygtuko paspaudimai ir baigta."
                            }
                            id="LHrwcsiu"
                        />
                    </S.Descr>
                </ContentContainer>
            </S.PicBanner>
            <S.TransparentSection>
                <ContentContainer>
                    <S.BoxFeaturesWrapper>
                        <S.BoxFeature>
                            <S.BoxFeatureTitle>
                                <TransMsg
                                    default={"Kaina ir kokybė"}
                                    id="uceOzRqA"
                                />
                            </S.BoxFeatureTitle>
                            <S.BoxFeatureDescr>
                                <TransMsg
                                    default={
                                        "Dirbame su patikimiausiais paslaugų teikėjais, siekdami užtikrinti kokybiškas paslaugas už prieinamą kainą."
                                    }
                                    id="0dMuyflB"
                                />
                            </S.BoxFeatureDescr>
                        </S.BoxFeature>
                        <S.BoxFeature>
                            <S.BoxFeatureTitle>
                                <TransMsg
                                    default={"Greitis ir patogumas"}
                                    id="UY01GSY6"
                                />
                            </S.BoxFeatureTitle>
                            <S.BoxFeatureDescr>
                                <TransMsg
                                    default={
                                        "Palygink autoservisus vienoje vietoje. Lengvai rask ir užsisakyk tau reikalingą paslaugą, neišeinant iš namų."
                                    }
                                    id="dtKnWEeu"
                                />
                            </S.BoxFeatureDescr>
                        </S.BoxFeature>
                        <S.BoxFeature>
                            <S.BoxFeatureTitle>
                                <TransMsg
                                    default={"Kokybės garantija"}
                                    id="X4BDD3rw"
                                />
                            </S.BoxFeatureTitle>
                            <S.BoxFeatureDescr>
                                <TransMsg
                                    default={
                                        "Nesklandumus nemokamai padės išspręsti mūsų techninis advokatas."
                                    }
                                    id="WL4363Bo"
                                />
                            </S.BoxFeatureDescr>
                        </S.BoxFeature>
                    </S.BoxFeaturesWrapper>
                </ContentContainer>
            </S.TransparentSection>
            <S.GreySection>
                <S.GreySectionTitle>
                    <TransMsg
                        default={"Rinkis iš mūsų partnerių"}
                        id="XeZf7aAv"
                    />
                </S.GreySectionTitle>
                <S.GreySectionFeaturesWrapper>
                    <S.GreySectionFeature>
                        <S.GreySectionFeatureEmphasis>
                            2
                        </S.GreySectionFeatureEmphasis>
                        <S.GreySectionFeatureDescr>
                            <TransMsg default={"Šalys"} id="tBsI20Av" />
                        </S.GreySectionFeatureDescr>
                    </S.GreySectionFeature>
                    <S.GreySectionFeature>
                        <S.GreySectionFeatureEmphasis>
                            {ABOVE_WORKSHOPS_COUNT + 150}+
                        </S.GreySectionFeatureEmphasis>
                        <S.GreySectionFeatureDescr>
                            <TransMsg default={"partneriai"} id="iiovysyk" />
                        </S.GreySectionFeatureDescr>
                    </S.GreySectionFeature>
                    <S.GreySectionFeature>
                        <S.GreySectionFeatureEmphasis>
                            8
                        </S.GreySectionFeatureEmphasis>
                        <S.GreySectionFeatureDescr>
                            <TransMsg default={"miestai"} id="dEZpkFx3" />
                        </S.GreySectionFeatureDescr>
                    </S.GreySectionFeature>
                    <S.GreySectionFeature>
                        <S.GreySectionFeatureEmphasis>
                            100+
                        </S.GreySectionFeatureEmphasis>
                        <S.GreySectionFeatureDescr>
                            <TransMsg default={"rajonai"} id="GNMgR22I" />
                        </S.GreySectionFeatureDescr>
                    </S.GreySectionFeature>
                </S.GreySectionFeaturesWrapper>
            </S.GreySection>
            <S.OurStorySection>
                <S.OurStoryContentContainer>
                    <S.OurStoryWrapper>
                        <S.OurStoryTitle>
                            <TransMsg default={"Mūsų istorija"} id="Fhuy6XOU" />
                        </S.OurStoryTitle>
                        <S.OurStoryDescr>
                            <TransMsg
                                default={
                                    "Pastebėjome, kad daugeliui žmonių sunku rasti laiko ir jėgų planuoti rezervacijas į autoservisus. Nevažiuoja - moderni aplikacija leidžianti užsiregistruoti vizitui į autoservisą vos kelių mygtukų paspaudimu. Rinkis pagal kokybę, kainą ir laisvą laiką. Viskuo kitu pasirūpins ilgametę patirtį turintys meistrai."
                                }
                                id="x8CcifcO"
                            />
                            <S.OurStoryEndingLine>
                                <TransMsg
                                    default={
                                        "Išbandyk mūsų aplikaciją šiandien ir nusimesk rūpesčius."
                                    }
                                    id="plSwGNXW"
                                />
                            </S.OurStoryEndingLine>
                        </S.OurStoryDescr>
                    </S.OurStoryWrapper>
                    <S.OurStoryImg src={DEVS_IMG} />
                </S.OurStoryContentContainer>
            </S.OurStorySection>
        </S.AboutUsContainer>
    );
};

export default AboutUs;
