const languageMeta = {
    en: { flag: "gb", name: "English" },
    lt: { flag: "lt", name: "Lietuvių" },
    fr: { flag: "fr", name: "French" },
    es: { flag: "es", name: "Spanish" },
    fil: { flag: "ph", name: "Filipino" },
    hi: { flag: "in", name: "Hindi" },
    nl: { flag: "nl", name: "Dutch" },
    pl: { flag: "pl", name: "Polish" },
    pt: { flag: "pt", name: "Portuguese" },
    ru: { flag: "ru", name: "Russian" },
    sr: { flag: "rs", name: "Serbian" },
    sv: { flag: "se", name: "Swedish" },
    tr: { flag: "tr", name: "Turkish" },
    zh: { flag: "cn", name: "Chinese" },
    de: { flag: "de", name: "German" },
    fi: { flag: "fi", name: "Finnish" },
    it: { flag: "it", name: "Italian" },
    ja: { flag: "jp", name: "Japanese" },
    ko: { flag: "kr", name: "Korean" },
    bg: { flag: "bg", name: "Bulgarian" },
    cs: { flag: "cz", name: "Czech" },
    el: { flag: "gr", name: "Greek" },
    th: { flag: "th", name: "Thai" },
    vi: { flag: "vn", name: "Vietnamese" },
    no: { flag: "no", name: "Norwegian" },
    id: { flag: "id", name: "Indonesian" },
    ka: { flag: "ge", name: "Georgian" },
};

function getFlagUrl(flag: string) {
    return `https://cdn.jsdelivr.net/npm/flag-icon-css@2.8.0/flags/4x3/${flag}.svg`;
}

export function getLanguageInfo(languageCode: string): LanguageInfo {
    const info = languageMeta[languageCode as keyof typeof languageMeta];
    if (info) {
        return {
            languageCode,
            name: info.name,
            flagUrl: getFlagUrl(info.flag),
        };
    }

    return {
        languageCode,
        name: languageCode,
    };
}

interface LanguageInfo {
    languageCode: string;
    flagUrl?: string;
    name: string;
}
