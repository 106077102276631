import styled from "styled-components";
import MultiCarousel from "react-multi-carousel";
import Box from "@FEShared/components/UI/Box/Box";

export const Img = styled("img")`
    height: 48px;
    width: 48px;
    margin-right: 16px;
    border-radius: 100%;
`;

export const CarouselWrapper = styled(Box)``;

export const Carousel = styled(MultiCarousel)`
    &:after {
        content: "";
        background: linear-gradient(
            90deg,
            rgba(229, 231, 240, 0) 0%,
            rgba(229, 231, 240, 1) 90%
        );

        position: absolute;
        right: 0;
        height: 100%;
        width: 5%;
    }
`;

export const ReviewServiceTag = styled("span")`
    font-weight: 400;
    font-size: 16px;
    padding: 4px 8px;
    display: inline-flex;
    color: black;
    background: #f0f2f8;
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 8px;

    &:last-child {
        margin-right: 0;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-bottom: 8px;
    }
`;

export const CheckmarkImg = styled("img")`
    width: 14px;
    height: 14px;
    margin-right: 4px;
    vertical-align: middle;
`;
