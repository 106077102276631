import {
    DONT_KNOW_MODEL,
    DONT_KNOW_YEAR,
} from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.consts";
import { translatable } from "@Shared/i18n/i18n";
import { Translatable } from "@Shared/i18n/i18n.types";
import { OrderCarData } from "@Shared/types/types";

export default function formatVehicleDescription(
    order: OrderCarData
): Translatable {
    const vehicleModel =
        order.vehicleModel && order.vehicleModel !== DONT_KNOW_MODEL
            ? `${order.vehicleModel}`
            : undefined;

    const vehicleYear =
        order.vehicleYear && order.vehicleYear !== DONT_KNOW_YEAR
            ? translatable("{vehicleYear}m.", {
                  data: { vehicleYear: order.vehicleYear },
                  id: "zqee9oKe",
              })
            : undefined;

    // basically all this is needed just to translate the `m.` part near year.
    return translatable(
        "{vehicleLicensePlate}{vehicleBrand}{vehicleModel}{vehicleYear}",
        {
            id: "cyrLGzId",
            data: {
                vehicleLicensePlate: order.vehicleLicensePlate
                    ? `${order.vehicleLicensePlate}, `
                    : "",
                vehicleBrand: order.vehicleBrand
                    ? `${order.vehicleBrand} `
                    : "",
                vehicleModel: vehicleModel ? `${vehicleModel} ` : "",
                vehicleYear: vehicleYear || "",
            },
            description:
                "This doesnt need any translation really. Just copy paste in all languages. It exists for peculiar dev reasons.",
        }
    );
}
