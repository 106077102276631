import { SeoPages } from "@FEShared/types/common";

// since this is included in
const FECLIENT_SERVICES_SEO_PAGES_CA: SeoPages = {
    "/oil-change": {
        seoServiceName: "Oil change",
        serviceDefinitionID: "1_1",
        footer: true,
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics & auto shops that provide {carModel} {serviceName} near you.",
            },
            {
                question: "How much does {carModel} {serviceName} cost?",
                answer: "{carModel} {serviceName} price can vary, but on average it costs $50 to $100. Prices vary depending on the oil chosen and specific auto shop's hourly rate. Accurate pricing for different mechanics & auto shops can be found on our Search Page.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question:
                    "Where can I find a fast {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find an auto shop that can change your {carModel} oil the soonest.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "{carModel} {serviceNameEN} typically takes about 30 minutes to an hour to complete. This can vary depending on the auto shop's workload and the specific requirements of your vehicle.",
            },
            {
                question:
                    "How often do you need to change your {carModel} oil?",
                answer: "It is generally recommended to get a {carModel} {serviceNameEN} every 7,500 to 10,000 miles, depending on your car's make and model, as well as the type of oil used. Always refer to your vehicle's owner manual for specific guidelines.",
            },
            {
                question:
                    "What are the signs I need to change my {carModel} oil?",
                answer: "Common signs that your car needs {carModel} {serviceName} include: the oil change light on your dashboard, dark and dirty oil, engine noise, and a noticeable decrease in fuel efficiency. Regularly checking your oil level and quality can help you identify when it's time for a {carModel} {serviceName}.",
            },
            {
                question:
                    "Where can I find & book {carModel} {serviceName} on Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} on Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/tire-change": {
        footer: true,
        seoServiceName: "Tire change",
        serviceDefinitionID: "12_0",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "{carModel} {serviceName} prices can vary, but on average it costs $20 - $40 per tire. Prices vary depending on the specific auto shop's hourly rate. Accurate pricing for different mechanics & auto shops can be found on our Search Page.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question:
                    "Where can I find a fast {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find mechanics that can change your {carModel} tires the soonest.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 30 minutes to an hour, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "How often do you need to change your {carModel} tire?",
                answer: "It is recommended to do a {carModel} {serviceName} every 25,000 to 50,000 miles, or as specified in your vehicle's owner's manual. Regular tire inspections can help determine if a change is needed sooner.",
            },
            {
                question:
                    "What are the signs I need to change my {carModel} tires?",
                answer: "Common signs that your car needs a {carModel} {serviceName} include: uneven tire wear, visible tire damage such as cracks or bulges, reduced tread depth, vibrations while driving, and the car pulling to one side. Regularly checking for these signs can help ensure your safety and maintain optimal vehicle performance.",
            },
            {
                question:
                    "Where can I find & book {carModel} {serviceName} on Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/suspension-check": {
        footer: true,

        seoServiceName: "Suspension check",
        serviceDefinitionID: "29_78",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "On average, the cost of a {carModel} {serviceName} ranges from $50 to $150. You can find the best deal based on pricing or reviews on our Search Page.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question:
                    "Where can I find a fast {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find mechanics that can provide a {carModel} {serviceName} the soonest.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 30 minutes to an hour, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "How often do you need to check your {carModel} suspension?",
                answer: "It is recommended to do {carModel} {serviceName} at least once a year or every 12,000 miles, whichever comes first. Regular inspections can help identify and address issues early, ensuring a smooth and safe driving experience.",
            },
            {
                question:
                    "What are the signs I need to check my {carModel} suspension?",
                answer: "Common signs that your car needs a {carModel} {serviceName} include: a rough or bumpy ride, unusual noises such as clunking or squeaking when driving over bumps, uneven tire wear, the car pulling to one side, and difficulty steering. These symptoms indicate potential issues with your suspension system that should be addressed promptly.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/suspension-repair": {
        footer: true,

        seoServiceName: "Suspension repair",
        serviceDefinitionID: "11_0",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary widely depending on the specific components that need to be repaired or replaced. On average, the cost ranges from $200 to $500.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 2 to 4 hours, depending on the extent of the repairs needed and the specific requirements of your vehicle. More complex repairs may take longer.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include a rough or bumpy ride, unusual noises such as clunking or squeaking when driving over bumps, uneven tire wear, the car pulling to one side, and difficulty steering. These symptoms indicate potential issues with your suspension system that should be addressed promptly.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/brake-repair": {
        footer: true,

        seoServiceName: "Brake repair",
        serviceDefinitionID: "13_0",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the specific components that need to be repaired or replaced. On average, the cost ranges from $250 to $500.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 3 hours, depending on the extent of the repairs needed and the specific requirements of your vehicle. More complex repairs may take longer.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include squeaking or grinding noises when braking, a spongy or soft brake pedal, the car pulling to one side when braking, longer stopping distances, and a brake warning light on the dashboard. These symptoms indicate potential issues with your braking system that should be addressed promptly.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/brake-pads-replacement": {
        footer: true,
        seoServiceName: "Brake pads replacement",
        serviceDefinitionID: "29_63",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the type of brake pads and the service provider. On average, the cost ranges from $150 to $300, including labor.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include squeaking or squealing noises when braking, a grinding sound when the brakes are applied, reduced braking performance, a brake warning light on the dashboard, and a noticeable decrease in brake pad thickness. These symptoms indicate that your brake pads are worn and need to be replaced promptly to ensure safe braking performance.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/brake-disc-replacement": {
        footer: true,
        seoServiceName: "Brake disc replacement",
        serviceDefinitionID: "29_62",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the type of brake discs and the service provider. On average, the cost ranges from $300 to $600, including labor.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include a pulsating or vibrating brake pedal, a grinding noise when braking, visible grooves or scoring on the brake discs, reduced braking performance, and a brake warning light on the dashboard. These symptoms indicate that your brake discs are worn or damaged and need to be replaced to ensure safe braking performance.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/roadworthiness-test": {
        footer: true,
        seoServiceName: "Roadworthiness test",
        serviceDefinitionID: "1_0",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the service provider and the specific requirements of your vehicle. On average, the cost ranges from $100 to $200.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include upcoming registration renewal, selling or transferring ownership of the vehicle, or if you notice any issues that could affect the safety and performance of your car, such as unusual noises, poor handling, or warning lights on the dashboard. Regular roadworthiness tests help ensure your vehicle meets safety standards and is safe to drive.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/ac-repair": {
        footer: true,
        seoServiceName: "AC Repair",
        serviceDefinitionID: "2_8",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the specific issue and the service provider. On average, the cost ranges about $200, depending on whether it's a more complex repair like replacing the compressor.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 3 hours, depending on the extent of the repairs needed and the specific requirements of your vehicle. More complex repairs may take longer.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include weak or no airflow from the vents, unusual noises when the AC is running, unpleasant odors coming from the vents, the AC blowing warm air instead of cold, and visible leaks or moisture around the AC components. These symptoms indicate potential issues with your AC system that should be addressed promptly to ensure comfort and proper functioning.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/ac-recharge": {
        footer: true,
        seoServiceName: "AC Recharge",
        serviceDefinitionID: "29-53",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the service provider and the type of refrigerant used. On average, the cost starts from $100.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 30 minutes to 1 hour, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include the AC blowing warm or less cold air than usual, the AC system cycling on and off frequently, visible refrigerant leaks, and unusual noises when the AC is running. These symptoms indicate that your AC system may be low on refrigerant and needs a recharge to restore optimal cooling performance.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/automatic-transmission-oil-replacement": {
        footer: true,
        seoServiceName: "Automatic transmission oil replacement",
        serviceDefinitionID: "29_36",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the service provider and the type of transmission fluid used. On average, the cost ranges from $150 to $300.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include delayed or rough shifting, unusual noises such as whining or grinding from the transmission, slipping gears, a burning smell, and a transmission warning light on the dashboard. These symptoms indicate that your transmission fluid may be degraded or contaminated and needs to be replaced to ensure smooth and efficient transmission performance.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/manual-transmission-oil-replacement": {
        seoServiceName: "Manual transmission oil replacement",
        serviceDefinitionID: "29_35",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the service provider and the type of transmission fluid used. On average, the cost ranges from $100 to $200.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include delayed or rough shifting, unusual noises such as whining or grinding from the transmission, slipping gears, a burning smell, and a transmission warning light on the dashboard. These symptoms indicate that your transmission fluid may be degraded or contaminated and needs to be replaced to ensure smooth and efficient transmission performance.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/semi-automatic-transmission-oil-replacement": {
        seoServiceName: "Semi-automatic transmission oil replacement",
        serviceDefinitionID: "29_36",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the service provider and the type of transmission fluid used. On average, the cost ranges from $100 to $200.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include delayed or rough shifting, unusual noises such as whining or grinding from the transmission, slipping gears, a burning smell, and a transmission warning light on the dashboard. These symptoms indicate that your transmission fluid may be degraded or contaminated and needs to be replaced to ensure smooth and efficient transmission performance.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/headlight-adjustment": {
        footer: true,
        seoServiceName: "Headlight adjustment",
        serviceDefinitionID: "2_10",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of {carModel} {serviceName} can vary depending on the service provider. On average, the cost ranges from $50 to $100.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 30 minutes to 1 hour, depending on the service provider and the specific requirements of your vehicle. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include headlights that appear misaligned, uneven light distribution on the road, one headlight appearing higher or lower than the other, and reduced visibility while driving at night. These symptoms indicate that your headlights are not properly aligned and need adjustment to ensure optimal visibility and safety.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/headlight-repair": {
        footer: true,
        seoServiceName: "Headlight repair",
        serviceDefinitionID: "2_6",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of {carModel} {serviceName} can vary depending on the specific issue and the service provider. On average, the cost ranges from $100 to $300, depending on whether it's a simple bulb replacement or a more complex repair like fixing the headlight assembly.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours, depending on the extent of the repairs needed and the specific requirements of your vehicle. More complex repairs may take longer.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include dim or flickering headlights, one or both headlights not working, visible damage to the headlight assembly, moisture or condensation inside the headlight, and a headlight warning light on the dashboard. These symptoms indicate potential issues with your headlights that should be addressed promptly to ensure optimal visibility and safety.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/engine-diagnostics": {
        footer: true,
        seoServiceName: "Engine diagnostics",
        serviceDefinitionID: "29_86",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the service provider and the complexity of the diagnostics. On average, the cost ranges from $100 to $200.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours, depending on the service provider and the specific requirements of your vehicle. More complex diagnostics may take longer.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include the check engine light being illuminated on the dashboard, unusual noises from the engine, reduced fuel efficiency, poor engine performance, and frequent stalling or misfiring. These symptoms indicate potential issues with your engine that should be diagnosed and addressed promptly to ensure optimal vehicle performance and prevent further damage.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/engine-repair": {
        footer: true,
        seoServiceName: "Engine repair",
        serviceDefinitionID: "0_0",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary widely depending on the specific issue and the service provider. On average, the cost start from $300, depending on whether it's a minor repair or a major overhaul.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 5 days, depending on the extent of the repairs needed and the specific requirements of your vehicle. More complex repairs may take longer.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs a {carModel} {serviceName} include the check engine light being illuminated on the dashboard, unusual noises such as knocking or tapping from the engine, reduced fuel efficiency, poor engine performance, frequent stalling or misfiring, and visible leaks or smoke from the engine. These symptoms indicate potential issues with your engine that should be addressed promptly to ensure optimal vehicle performance and prevent further damage.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/engine-rebuild": {
        footer: true,
        seoServiceName: "Engine rebuild",
        serviceDefinitionID: "6_2",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary significantly depending on the make and model of the vehicle, the extent of the rebuild, and the service provider. On average, the cost ranges from $2,500 to $5,000.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 weeks, depending on the extent of the rebuild and the specific requirements of your vehicle. More complex rebuilds may take longer.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include persistent engine knocking or tapping noises, excessive oil consumption, low oil pressure, significant loss of power, frequent overheating, and visible smoke from the exhaust. These symptoms indicate severe engine wear or damage that may require a comprehensive rebuild to restore optimal performance and reliability.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/timing-chain-replacement": {
        footer: true,
        seoServiceName: "Timing chain replacement",
        serviceDefinitionID: "6_4",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the make and model of the vehicle and the service provider. On average, the cost starts from $500, including parts and labor.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 4 to 8 hours, depending on the specific requirements of your vehicle and the complexity of the job. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include rattling or ticking noises from the engine, especially during startup or idling, the engine misfiring or running rough, metal shavings in the oil, a check engine light on the dashboard, and poor engine performance. These symptoms indicate potential issues with the timing chain that should be addressed promptly to prevent severe engine damage.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/timing-belt-replacement": {
        footer: true,
        seoServiceName: "Timing belt replacement",
        serviceDefinitionID: "1_7",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of {carModel} {serviceName} can vary depending on the make and model of the vehicle and the service provider. On average, the cost starting from $500, including parts and labor.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 3 to 5 hours, depending on the specific requirements of your vehicle and the complexity of the job. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include a ticking noise coming from the engine, the engine misfiring or running rough, oil leaking from the front of the motor, difficulty starting the car, and a check engine light on the dashboard. These symptoms indicate potential issues with the timing belt that should be addressed promptly to prevent severe engine damage.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/spark-plug-replacement": {
        footer: true,
        seoServiceName: "Spark plug replacement",
        serviceDefinitionID: "3_4",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the make and model of the vehicle and the service provider. On average, the cost ranges from $100 to $300, including parts and labor.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours, depending on the specific requirements of your vehicle and the accessibility of the spark plugs. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include difficulty starting the engine, rough idling, poor acceleration, reduced fuel efficiency, engine misfires, and a check engine light on the dashboard. These symptoms indicate that your spark plugs may be worn or fouled and need to be replaced to ensure optimal engine performance.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/glow-plug-replacement": {
        footer: true,
        seoServiceName: "Glow plug replacement",
        serviceDefinitionID: "56_2",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of {carModel} {serviceName} can vary depending on the make and model of the vehicle and the service provider. On average, the cost ranges from $200 to $500, including parts and labor.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 3 hours, depending on the specific requirements of your vehicle and the accessibility of the glow plugs. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include difficulty starting the engine, especially in cold weather, rough idling, excessive white smoke from the exhaust during startup, reduced engine performance, and a glow plug warning light on the dashboard. These symptoms indicate that your glow plugs may be worn or faulty and need to be replaced to ensure optimal engine performance, particularly in diesel engines.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/broken-spark-plug-removal": {
        seoServiceName: "Broken spark plug removal",
        serviceDefinitionID: "6_8",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the make and model of the vehicle and the service provider. On average, the cost ranges from $300 to $600, including parts and labor, as removing a broken spark plug can be more complex and time-consuming than a standard replacement.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 2 to 4 hours, depending on the specific requirements of your vehicle and the difficulty of removing the broken spark plug. More complex cases may take longer.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include difficulty starting the engine, rough idling, poor acceleration, reduced fuel efficiency, engine misfires, and a check engine light on the dashboard. Additionally, if you experience a sudden loss of power or hear unusual noises from the engine, it may indicate a broken spark plug that needs immediate attention.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/wheel-alignment": {
        footer: true,
        seoServiceName: "Wheel alignment",
        serviceDefinitionID: "11_9",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the service provider and the type of alignment required (e.g., front-end or four-wheel alignment). On average, the cost ranges around $100.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 hour, depending on the specific requirements of your vehicle and the service provider's equipment. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include uneven or rapid tire wear, the car pulling to one side while driving, a crooked steering wheel when driving straight, and vibrations in the steering wheel. These symptoms indicate that your wheels may be misaligned and need adjustment to ensure optimal handling, tire longevity, and safety.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
    "/rato-guolio-keitimas": {
        footer: true,
        seoServiceName: "Rato guolio keitimas",
        serviceDefinitionID: "11_10",
        faq: [
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} near me?",
                answer: "Pick your car model, required repairs, preferred time of arrival and location to see top mechanics that provide a {carModel} {serviceName} near you.",
            },
            {
                question: "How much does a {carModel} {serviceName} cost?",
                answer: "The cost of a {carModel} {serviceName} can vary depending on the make and model of the vehicle and the service provider. On average, the cost ranges around $200, including parts and labor.",
            },
            {
                question:
                    "Where can I find a cheap {carModel} {serviceName} near me?",
                answer: "Select your car brand, model and year, as well as the repairs needed and use the sort button on the Search Page to sort mechanics based on their pricing and find the cheapest {carModel} {serviceName} near you.",
            },
            {
                question: "How long does a {carModel} {serviceName} take?",
                answer: "A {carModel} {serviceName} typically takes about 1 to 2 hours per wheel, depending on the specific requirements of your vehicle and the complexity of the job. Scheduling an appointment can help minimize wait times.",
            },
            {
                question:
                    "What are the signs I need to get a {carModel} {serviceName}?",
                answer: "The signs that your car needs {carModel} {serviceName} include a grinding or humming noise coming from the wheel area, uneven tire wear, the car pulling to one side, vibrations in the steering wheel, and a loose or wobbly wheel. These symptoms indicate potential issues with your wheel bearings that should be addressed promptly to ensure safe and smooth driving.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} on a Saturday or Sunday?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide a {carModel} {serviceName} on a Saturday or Sunday.",
            },
            {
                question:
                    "Where can I find & book a {carModel} {serviceName} open late?",
                answer: "Select your car brand, model and year, as well as the repairs needed and the preferred time of arrival to find a mechanic that can provide {carModel} {serviceName} open late.",
            },
        ],
    },
};
export default FECLIENT_SERVICES_SEO_PAGES_CA;
