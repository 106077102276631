import styled from "styled-components";
import { absoluteHorizontalCenter } from "@FEShared/styled/util.styled";
import {
    CLOSED_DRAWER_HEIGHT,
    DRAWER_MARGIN_TOP,
} from "../SearchSidebar/SearchSidebar.styled";
import Box from "@FEShared/components/UI/Box/Box";
import { SEARCH_HEADER_HEIGHT } from "@FEClient/views/commonComps/Header/SearchHeader/SearchHeader.styled";
import Icon from "@FEShared/components/UI/Icon/Icon";

export const LagBanner = styled("div")`
    background: ${(p) => p.theme.palette.primary.main};
    color: white;
    font-size: 18px;
    padding: 16px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;

    ${(p) => p.theme.breakpoints.down("md")} {
        font-size: 14px;
        padding: 8px;
    }
`;

export const CloseIcon = styled("i")`
    color: white;
    margin-left: auto;
    cursor: pointer;
`;

export const LoaderWrapper = styled(Box).attrs(() => ({
    boxShadow: 4,
}))`
    position: absolute;
    z-index: 2;
    ${absoluteHorizontalCenter}
    padding: 16px 24px;
    top: 32px;
    border-radius: 15px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CircularLoader = styled("div")`
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: #222222;
    margin: 0 2px 0 2px;
    border-radius: 100%;
    animation-name: animate-map-fancy-loader-dot;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
    animation-fill-mode: both;

    &:nth-child(1) {
        animation-delay: -0.3s;
    }
    &:nth-child(2) {
        animation-delay: -0.15s;
    }

    @keyframes animate-map-fancy-loader-dot {
        0%,
        80%,
        100% {
            transform: scale(0);
        }
        30%,
        50% {
            transform: scale(1);
        }
    }
`;

export const MapControlIcon = styled(Icon).attrs({
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;",
})`
    /* matching shadow from google maps control btn */
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: white;
    font-size: 20;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #f7f7f7;
    }
`;

export const SearchMapContainer = styled("div")`
    flex-grow: 1;
    height: 100%;
    outline: 0;
    position: relative;

    ${(p) => p.theme.breakpoints.down("md")} {
        height: calc(
            100dvh -
                ${SEARCH_HEADER_HEIGHT +
                (CLOSED_DRAWER_HEIGHT + DRAWER_MARGIN_TOP)}px
        );
    }
`;
