import styled from "styled-components";
import Button from "../../../../../../FEShared/components/UI/Button/Button";
import Input from "@FEShared/components/UI/Input/Input";
import Box from "@FEShared/components/UI/Box/Box";
import ServicesAutocomplete from "@FEShared/components/UI/ServicesAutocomplete/ServicesAutocomplete";
import Dropdown from "@FEShared/components/UI/Dropdown/Dropdown";
import { AUTOCOMPLETE_MOBILE_INPUT_CLASS_NAME } from "@FEShared/components/UI/AutocompleteWithModal/AutocompleteWithModal";
import SearchInputs from "./SearchInputs/SearchInputs";
import { CITY_DROPDOWN_WRAPPER_CLASSNAME } from "./SearchInputs/SearchInputs";

export const SearchFormSearchInputs = styled(SearchInputs)`
    .MuiOutlinedInput-root {
        background-color: transparent;
        /* border-bottom: 1px solid #7b7f93; */
        /* &:hover {
            border-color: transparent
        } */
    }
    .MuiFormControl-root {
        margin-bottom: 0;
    }

    .${CITY_DROPDOWN_WRAPPER_CLASSNAME} {
        .MuiSvgIcon-root {
            right: 8px;
            ${(p) => p.theme.breakpoints.down("md")} {
                right: 15px;
                color: black;
            }
        }
    }
    // tbd: this is not nice, need to even out all paddings/icon colors theme-level on all autocompletes/dropdowns, but w.e for now
`;

export const SearchFormInput = styled(Input)`
    width: 100%;
`;

export const SearchFormButton = styled(Button)`
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    max-width: 60px;
    max-height: 100%;
    margin-left: auto;
    height: auto;
`;

export const ServicesAutocompleteStyled = styled(ServicesAutocomplete)`
    width: 100%;

    .MuiInputBase-root {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: 16px !important;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }
    .MuiInputAdornment-root {
        display: none;
    }
    .MuiAutocomplete-endAdornment {
        display: none;
    }
`;

export const CityDropdown = styled(Dropdown)`
    max-width: 95px;
    min-width: 85px;
    max-height: 100%;
    border-color: transparent !important;

    .MuiInputBase-input {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: unset;
        display: block;
        line-height: 51px;
        padding-left: 4px;
        max-width: calc(100% - 22px);
        padding-right: 2px !important;
        text-align: center;
    }

    .MuiInputBase-root {
        font-size: 14px;
        text-decoration: underline;
        border-radius: 0;
        color: #7b7f93;
        margin: 0;
        max-height: 100%;
        height: 100%;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }
` as typeof Dropdown;

export const SearchFormWrapper = styled(Box)`
    margin-top: 16px;
    display: flex;
    max-width: 420px;

    .${AUTOCOMPLETE_MOBILE_INPUT_CLASS_NAME} {
        overflow: hidden;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        max-width: 100%;

        ${CityDropdown as any} {
            max-width: 82px;

            .MuiSelect-icon {
                right: 2px;
                font-size: 20px;
            }

            // fix this any some time
            .MuiInputBase-input {
                font-size: 12px;
                max-width: calc(100% - 15px);
            }
        }
    }
`;
