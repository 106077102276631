import { PopperProps } from "@mui/material";
import { runInAction } from "mobx";
import React from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import * as S from "./ExpansivePopper.styled";
import ExpansivePopperHead from "./ExpansivePopperHead/ExpansivePopperHead";
import cs from "classnames";

export const EXPANSIVE_POPPER_CLASSNAME_IDENTIFIER = "ExpansivePopper";

export interface ExpansivePopperP {
    popperProps: PopperProps;
    onCloseClick: () => void;
    canFinalizeChoice: () => boolean;
    className?: string;
    togglePageScroll?: (isScrollEnabled: boolean) => void;
}

/**
 * It's called ExpansivePopper because on desktop it looks like a simple "dropdown" but on mobile it transforms into
 * full drawer UI.
 */
const ExpansivePopper = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<ExpansivePopperP>
>((p, ref) => {
    const isMobile = useIsMobile();
    const { onCloseClick, canFinalizeChoice } = p;
    React.useEffect(() => {
        if (!p.togglePageScroll) return;

        if (isMobile && p.popperProps.open) {
            runInAction(() => {
                p.togglePageScroll?.(false);
            });
            return () => {
                runInAction(() => {
                    p.togglePageScroll?.(true);
                });
            };
        }
    }, [p, isMobile]);

    const onChooseClick = React.useCallback(() => {
        if (!canFinalizeChoice()) return;
        onCloseClick();
    }, [onCloseClick, canFinalizeChoice]);

    return (
        <S.ExpansivePopperContainer
            {...p.popperProps}
            className={cs(p.className, EXPANSIVE_POPPER_CLASSNAME_IDENTIFIER)}
            ref={ref}
            sx={{
                boxShadow: 4,
            }}
        >
            <>
                {isMobile && (
                    <ExpansivePopperHead
                        onCloseClick={p.onCloseClick}
                        onChooseClick={onChooseClick}
                    />
                )}
                {p.children}
                {p.popperProps.children}
            </>
        </S.ExpansivePopperContainer>
    );
});
ExpansivePopper.displayName = "ExpansivePopper";
export default ExpansivePopper;
