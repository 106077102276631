export const TEST_WORKSHOPS_IDS = [
    752, 751, 740, 739, 700, 666, 665, 664, 663, 662, 661, 660, 659, 658, 657,
    656, 655, 654, 653, 652, 651, 650, 649, 648, 647, 646, 645, 644, 643, 642,
    641, 640, 639, 638, 637, 636, 635, 634, 633, 632, 631, 630, 629, 628, 627,
    626, 625, 624, 623, 595, 594, 593, 592, 591, 590, 589, 588, 587, 586, 585,
    584, 583, 582, 581, 580, 579, 578, 577, 576, 575, 574, 573, 572, 571, 570,
    569, 568, 567, 566, 565, 564, 563, 562, 561, 560, 559, 558, 557, 556, 554,
    553, 551, 550, 549, 548, 547, 546, 545, 544, 543, 542, 541, 540, 539, 538,
    537, 536, 535, 534, 533, 532, 531, 530, 529, 528, 527, 525, 523, 522, 521,
    520, 519, 518, 517, 516, 515, 513, 512, 511, 510, 509, 508, 507, 506, 505,
    504, 503, 501, 500, 499, 498, 497, 484, 483, 482, 481, 480, 479, 477, 475,
    473, 471, 466, 453, 452,
];
