import React from "react";
import Link, { LinkProps } from "@mui/material/Link";
import { Link as ReactLink } from "react-router-dom";

interface P extends LinkProps {
    className?: string;
    to: string;
}
const InternalLink: React.FCC<P> = (p) => {
    const { children, ...otherProps } = p;
    return (
        <Link {...otherProps} component={ReactLink} underline="none">
            {children}
        </Link>
    );
};
export default InternalLink;
