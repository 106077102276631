import InternalLink from "@FEShared/components/InternalLink/InternalLink";
import Button from "@FEShared/components/UI/Button/Button";
import styled from "styled-components";

export const BtnLink = styled(InternalLink)`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const BecomePartnerBtn = styled(Button)`
    margin-top: 16px;
    min-width: 300px;
    background: ${(p) => p.theme.palette.primary.main}
        ${(p) => p.theme.breakpoints.down("md")} {
        width: 100%;
    }
`;
