import styled from "styled-components";

export const WrenchIcon = styled("i")`
    margin-right: 4px;
`;

export const Specialized = styled("div")`
    color: ${(p) => p.theme.palette.primary.main};
    font-weight: 400;
    width: auto;
    font-size: 11px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    height: auto;
    align-self: flex-start;
`;
