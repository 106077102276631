import { OperationResult, UseQueryState } from "urql";
import showToast from "./showToast";
import { transStr, transStringFunctionalFE } from "@FEShared/i18n";
import { MaybePromise } from "@Shared/types/utils";

export default function gqlResHandler<
    OperationResultT extends OperationResult | UseQueryState,
    SuccessHandlerRes
>(
    gqlResponse: OperationResultT,
    successHandler: (
        data: NonNullable<OperationResultT["data"]>
    ) => MaybePromise<SuccessHandlerRes | void>,
    onError?: () => void,
    muteErrors?: boolean
): MaybePromise<SuccessHandlerRes | void> {
    if ("fetching" in gqlResponse && gqlResponse.fetching) return;
    if (!gqlResponse.data && !gqlResponse.error && !gqlResponse.operation)
        return;

    if (gqlResponse.error?.graphQLErrors[0]?.message) {
        if (muteErrors) return;
        const allErrors = gqlResponse.error.graphQLErrors
            .map((e) => transStringFunctionalFE(e.message))
            .join(" --- ");
        console.error(allErrors);
        showToast.error(
            transStringFunctionalFE(gqlResponse.error.graphQLErrors[0].message),
            gqlResponse.error.graphQLErrors.length > 1
        ); // if there is more than 1 error, then it is most likely a breaking error.
        if (onError) onError();
        return;
    }
    if (!gqlResponse.data) {
        if (muteErrors) return;
        console.error(`Iškilo netikėta klaida. Klaidos kodas: #BABA`);
        showToast.error(
            transStr("Iškilo netikėta klaida. Klaidos kodas: #BABA", {
                id: "uefFEBkP",
            }),
            true
        );
        return;
    }

    return successHandler(gqlResponse.data);
}
