import SUBCATEGORIES_ORDER from "@FEShared/consts/SUBCATEGORIES_ORDER";
import {
    TranslatableCustomID_PartCategories,
    TranslatableCustomID_SubCategories,
} from "@Shared/types/enums/TranslatableCustomID";

export default function getSubcategSortOrder(p: {
    partCategory: TranslatableCustomID_PartCategories;
    subCategory: TranslatableCustomID_SubCategories;
}): number {
    return SUBCATEGORIES_ORDER[p.partCategory]?.[p.subCategory] || 1;
}
