import "@FEShared/utils/setupGlobals";

import React from "react";
import { createRoot } from "react-dom/client";
import WebContainer from "./App";
import * as serviceWorker from "@FEShared/utils/serviceWorker";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";

if (window._IS_PROD) {
    Sentry.init({
        dsn: "https://2a9a19e907a3c3a4f04573fce05aaf87@o4506270073421824.ingest.sentry.io/4506270074601472",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [window._COUNTRY_META.baseUrl],
            }),
            new Sentry.Replay(),
            new CaptureConsole({
                levels: ["error"],
            }),
        ],
        environment: window._IS_PROD ? "production" : "development",
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.25, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const root = createRoot(document.getElementById("body"));
root.render(<WebContainer />);
serviceWorker.unregister();
