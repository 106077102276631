import styled from "styled-components";

export const Title = styled("div")``;

export const TitleIcon = styled("i")`
    font-weight: 500;
    font-size: 16px;
    margin-right: 8px;
`;

export const TitleText = styled("span")`
    font-weight: 500;
    margin-right: 16px;
`;

export const Subtitle = styled("div")`
    font-weight: 500;
    font-size: 18px;
    margin-top: 8px;
    max-width: 485px;
`;

export const TitleWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
`;
