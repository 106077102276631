import latinize from "latinize";

export default function urlifyString(s: string): string {
    return latinize(s)
        .toLowerCase()
        .trim()
        .replace(/\//g, "-")
        .replace(/ /g, "-")
        .replace(/&/g, "-")
        .replace(/'/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/,/g, "")
        .replace(/,/g, "")
        .replace(/\./g, "")
        .replace(/---/g, "-") // This is needed in case there is a scenario like "a & b workshop" which would urlify into "a---b workshop"
        .replace(/--/g, "-"); // This is needed in case there is a scenario like "a& b workshop" which would urlify into "a--b workshop"
}
