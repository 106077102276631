import { isTranslatable, evaluateTranslatableToString } from "./i18n";
import {
    GemlNode,
    GemlMarkupStringDocument,
    parseGemlMarkupString,
} from "@hediet/geml";
import { ILocale, TranslatableI18nData } from "./i18n.types";

export function evaluateGemlMarkupStringToString(
    gemlSrc: string,
    data: TranslatableI18nData,
    locale: ILocale
): string {
    const doc = CachingGemlParser.global.parseGemlMarkupString(gemlSrc);
    function toString(node: GemlNode): string {
        switch (node.kind) {
            case "markupString":
            case "markupStringDocument": {
                return node.content.nodes.map((n) => toString(n)).join("");
            }
            case "markupStringPart": {
                return node.value;
            }
            case "object": {
                if (!node.type) {
                    throw new Error();
                }
                const key = node.type.text;
                if (!(key in data)) {
                    throw new Error(`No data for key "${key}" available!`);
                }
                const d = data[node.type.text];
                if (isTranslatable(d)) {
                    return evaluateTranslatableToString(d, locale);
                }
                return `${d}`;
            }
        }
        throw new Error(`Unsupported geml node of kind "${node.kind}"`);
    }
    return toString(doc);
}

export class CachingGemlParser {
    public static readonly global = new CachingGemlParser();

    private readonly documentsBySrc = new Map<
        string,
        GemlMarkupStringDocument
    >();

    public parseGemlMarkupString(gemlSrc: string): GemlMarkupStringDocument {
        let doc = this.documentsBySrc.get(gemlSrc);
        if (!doc) {
            doc = parseGemlMarkupString(gemlSrc);
            this.documentsBySrc.set(gemlSrc, doc);
        }
        return doc;
    }
}
