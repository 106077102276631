export default function openToCloseTimeString(
    day?: {
        open: { hour: number; minute: number };
        close: { hour: number; minute: number };
    } | null
): string | undefined {
    if (!day) return;
    const { open, close } = day;

    if (open.hour || open.minute || close.hour || close.minute) {
        return `${open.hour}:${open.minute === 0 ? "00" : open.minute} - ${
            close.hour
        }:${close.minute === 0 ? "00" : close.minute}`;
    }
}
