import React from "react";
import * as PS from "../Header.styled";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import InternalLink from "@FEShared/components/InternalLink/InternalLink";
import Logo from "@FEShared/components/UI/Logo/Logo";
import { observer } from "mobx-react-lite";
import useStore from "@FEClient/logic/store";
import { Cross as Hamburger } from "hamburger-react";
import { runInAction } from "mobx";
import Box from "@FEShared/components/UI/Box/Box";
import TopSearchBar from "@FEClient/views/pages/Search/TopSearchBar/TopSearchBar";
import * as S from "./SearchHeader.styled";
import { useSearchInputsMobileMode } from "@FEClient/views/pages/Search/TopSearchBar/TopSearchBar.utils";
import { TransMsg, transStr } from "@FEShared/i18n";

const Header: React.FC<{}> = observer((_p) => {
    const GS = useStore();
    const isSearchInputsMobileMode = useSearchInputsMobileMode();

    return (
        <S.SearchHeaderContainer $page={GS.activePage}>
            <PS.HeaderContent $page={GS.activePage}>
                {!isSearchInputsMobileMode && (
                    <InternalLink
                        to={countryClientPageToMeta(window._COUNTRY).HOME.url}
                        aria-label="nevaziuoja-logo"
                        mr={2}
                    >
                        <Logo white />
                    </InternalLink>
                )}
                {<TopSearchBar />}
                {/* {!isMobile && (
                    <div
                        id="lang-switcher"
                        style={{ display: "inline-block" }}
                    />
                )} */}
                {
                    <Box
                        displayFlex
                        alignVertical="center"
                        ml={1}
                        mr={isSearchInputsMobileMode ? -1 : undefined} // to compensate for hamburger btn padding
                    >
                        {!isSearchInputsMobileMode && (
                            <InternalLink
                                to={
                                    countryClientPageToMeta(window._COUNTRY)
                                        .WARRANTY.url
                                }
                                style={{
                                    marginRight: 16,
                                    fontSize: 14,
                                    textAlign: "center",
                                    color: "white",
                                }}
                            >
                                <TransMsg
                                    default={"100% Darbų garantija"}
                                    id="ZjapYCpU"
                                />
                            </InternalLink>
                        )}

                        {/* <div
                            id="lang-switcher"
                            style={{ display: "inline-block" }}
                        /> */}
                        {!GS.refFrom && (
                            <Hamburger
                                color={"white"}
                                toggle={() =>
                                    runInAction(() => {
                                        GS.showSidebar = true;
                                    })
                                }
                                toggled={GS.showSidebar}
                                direction="right"
                            />
                        )}
                    </Box>
                }
            </PS.HeaderContent>
        </S.SearchHeaderContainer>
    );
});

export default Header;
