import React from "react";
import * as S from "./TermsOfService.styled";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import { useHistory, useLocation } from "react-router-dom";

const TermsOfService: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const isMobile = useIsMobile();

    return (
        <S.TOSContentContainer>
            {isMobile && (
                <S.BackBtn
                    onClick={() => {
                        if (location.key) {
                            history.goBack();
                        } else {
                            history.push(
                                countryClientPageToMeta(window._COUNTRY).HOME
                                    .url
                            );
                        }
                    }}
                >
                    {transCommonTranslatable("BACK")}
                </S.BackBtn>
            )}
            <div>
                <h1>Terms of Service (Customers)</h1>

                <h2>1. Introduction</h2>
                <p>
                    Welcome to{" "}
                    <a href="https://repairpunk.com">https://repairpunk.com</a>,
                    the website and online service (hereinafter referred to as
                    &ldquo;we&ldquo;, &ldquo;us&ldquo;, &ldquo;our&ldquo;, or
                    &ldquo;RepairPunk&ldquo;). These Terms of Service
                    (&ldquo;Agreement&ldquo;) govern your access to and use of
                    our website, services, and any related software
                    (collectively the &ldquo;Service&ldquo;). By accessing or
                    using the Service, you agree to be bound by this Agreement,
                    whether or not you have registered an account with us.
                </p>

                <h2>2. Use of Our Service</h2>
                <h3>2.1 Scope of Service</h3>
                <p>
                    RepairPunk provides an online platform that connects users
                    (&ldquo;Users&ldquo;) with car repair shops
                    (&ldquo;Partners&ldquo;) for vehicle repair and maintenance
                    services. Additionally, we offer customer support and a
                    warranty on repairs made through our platform.
                </p>

                <h3>2.2 Eligibility</h3>
                <p>
                    The Service is intended for Users who are 18 years of age or
                    older. By using our Service, you confirm that you are at
                    least 18 years old. Users under the age of 18 may only use
                    the Service with the consent of a parent or legal guardian.
                </p>

                <h3>2.3 Account Registration</h3>
                <p>
                    While Users can browse our platform without registration,
                    certain features such as posting reviews may require account
                    registration. Users must provide accurate and complete
                    information when creating an account and are responsible for
                    maintaining the security of their account credentials.
                </p>

                <h3>2.4 Prohibited Activities</h3>
                <p>
                    Users agree not to engage in any activities that interfere
                    with or disrupt the Service, including but not limited to:
                    <ul>
                        <li>
                            Posting or transmitting harmful or malicious content
                            such as viruses or spam.
                        </li>
                        <li>
                            Impersonating any person or entity or falsely
                            representing your affiliation with any person or
                            entity.
                        </li>
                        <li>
                            Attempting to access unauthorized areas of the
                            Service or bypassing security measures.
                        </li>
                        <li>
                            Engaging in any form of harassment, abusive
                            behavior, or fraudulent activities.
                        </li>
                    </ul>
                </p>

                <h3>2.5 User Responsibilities</h3>
                <p>
                    Users are responsible for providing accurate information
                    about their vehicles and for ensuring that any content they
                    post, including reviews, complies with these Terms and
                    applicable laws. Misuse of the review system, such as
                    posting false information, may result in the removal of
                    content or termination of the User’s account.
                </p>

                <h2>3. User Content and Reviews</h2>
                <h3>3.1 Posting Reviews</h3>
                <p>
                    After using the services of a Partner through our platform,
                    Users may be invited to post reviews and ratings
                    (&ldquo;User Content&ldquo;). User Content must be accurate,
                    respectful, and comply with all applicable laws.
                </p>

                <h3>3.2 Dispute Resolution</h3>
                <p>
                    If a Partner disputes a review, RepairPunk will mediate
                    between the User and the Partner to ensure that only
                    accurate and fair reviews are published. RepairPunk reserves
                    the right to remove or modify any User Content that violates
                    these terms.
                </p>

                <h3>3.3 License Grant</h3>
                <p>
                    By posting User Content, you grant RepairPunk a
                    royalty-free, sublicensable, transferable, perpetual,
                    irrevocable, non-exclusive, worldwide license to use,
                    reproduce, modify, publish, and distribute your User Content
                    in connection with the Service.
                </p>

                <h2>4. Payments and Refunds</h2>
                <h3>4.1 Payments</h3>
                <p>
                    Payments for services are made directly to the Partner
                    repair shops. RepairPunk is not involved in the payment
                    process and does not handle or store payment information.
                </p>

                <h3>4.2 Refunds and Cancellations</h3>
                <p>
                    Refunds and cancellations are managed by the Partner repair
                    shops. RepairPunk does not provide refunds or process
                    cancellations directly. Users are advised to review the
                    refund and cancellation policies of the respective Partner
                    before making a booking.
                </p>

                <h2>5. Warranty and Customer Support</h2>
                <h3>5.1 Repair Warranty</h3>
                <p>
                    RepairPunk offers a warranty on repairs made through our
                    platform at Partner repair shops. If there are issues with a
                    repair, Users can contact our customer support within 30
                    days of the service for assistance. RepairPunk will work
                    with the Partner to ensure the issue is resolved and
                    warranty repairs are provided at no additional cost.
                </p>

                <h3>5.2 Warranty Claim Process</h3>
                <p>
                    To claim a warranty, Users must provide the original service
                    receipt, a description of the issue, and any supporting
                    documentation. Claims must be submitted within 30 days of
                    the repair. RepairPunk will coordinate with the Partner
                    repair shop to address the issue promptly.
                </p>

                <h3>5.3 Customer Support</h3>
                <p>
                    For any questions or issues related to our Service, Users
                    can contact our customer support team via the contact
                    details provided on our website.
                </p>

                <h2>6. Data Security and Privacy</h2>
                <h3>6.1 Data Security</h3>
                <p>
                    RepairPunk implements industry-standard security measures,
                    including SSL encryption, to protect User data from
                    unauthorized access, use, or disclosure. While we strive to
                    safeguard your information, we cannot guarantee absolute
                    security, and Users provide their personal information at
                    their own risk.
                </p>

                <h3>6.2 Privacy Policy</h3>
                <p>
                    Your use of the Service is also governed by our Privacy
                    Policy, which details how we collect, use, and protect your
                    personal information.
                </p>

                <h3>6.3 Data Breach Notification</h3>
                <p>
                    In the event of a data breach that poses a significant risk
                    of harm, RepairPunk will notify affected Users and the
                    relevant authorities in accordance with applicable laws.
                </p>

                <h2>7. Limitation of Liability</h2>
                <h3>7.1 Service Quality</h3>
                <p>
                    RepairPunk is not directly responsible for the quality of
                    services provided by Partner repair shops. However, we
                    provide a warranty as outlined in Section 5.
                </p>

                <h3>7.2 General Limitation</h3>
                <p>
                    To the fullest extent permitted by law, RepairPunk shall not
                    be liable for any indirect, incidental, special,
                    consequential, or punitive damages, including loss of
                    profits, data, or goodwill, arising out of or related to
                    your use of the Service.
                </p>

                <h3>7.3 Exclusion of Liability</h3>
                <p>
                    RepairPunk is not liable for any damages resulting from:
                    <ul>
                        <li>Errors or inaccuracies in content.</li>
                        <li>
                            Personal injury or property damage arising from the
                            use of our Service.
                        </li>
                        <li>
                            Unauthorized access to or use of our secure servers
                            and/or any personal information stored therein.
                        </li>
                        <li>
                            Interruption or cessation of transmission to or from
                            the Service.
                        </li>
                        <li>
                            Bugs, viruses, or other harmful components
                            transmitted through our Service by third parties.
                        </li>
                    </ul>
                </p>

                <h2>8. Opt-In/Opt-Out for Marketing Communications</h2>
                <h3>8.1 Consent for Marketing</h3>
                <p>
                    By using our Service, you agree to receive marketing
                    communications from RepairPunk. However, you can opt out of
                    receiving such communications at any time by following the
                    unsubscribe link in our emails or by adjusting your account
                    settings.
                </p>

                <h3>8.2 Opt-Out Mechanism</h3>
                <p>
                    Users can opt out of marketing communications by contacting
                    our customer support or by following the instructions
                    provided in the communication. Opting out will not affect
                    your ability to use the Service.
                </p>

                <h2>9. Third-Party Websites and Services</h2>
                <h3>9.1 Links to Third-Party Sites</h3>
                <p>
                    The Service may contain links to third-party websites or
                    services. RepairPunk is not responsible for the content,
                    privacy policies, or practices of any third-party websites
                    or services. Users access third-party sites at their own
                    risk.
                </p>

                <h3>9.2 Third-Party Partnerships</h3>
                <p>
                    While RepairPunk currently does not have third-party
                    integrations, we may introduce partnerships or integrations
                    in the future. Users will be informed of any such
                    partnerships and how they may affect the use of the Service.
                </p>

                <h2>10. International Use and Compliance</h2>
                <h3>10.1 Global Use</h3>
                <p>
                    While RepairPunk is based in Ontario, Canada, and operates
                    under Canadian law, our Service may be accessed by Users in
                    other jurisdictions. Users who access the Service from
                    outside Canada do so at their own risk and are responsible
                    for complying with local laws and regulations.
                </p>

                <h3>10.2 Export Compliance</h3>
                <p>
                    Users must comply with all applicable export and import laws
                    and regulations in their use of the Service. RepairPunk
                    makes no representation that the Service is appropriate or
                    available for use in other locations.
                </p>

                <h2>11. Force Majeure</h2>
                <p>
                    RepairPunk shall not be liable for any failure to perform
                    its obligations under this Agreement due to circumstances
                    beyond its reasonable control, including but not limited to
                    acts of God, natural disasters, war, terrorism, labor
                    disputes, governmental actions, or interruptions in internet
                    service. In the event of such a force majeure, RepairPunk’s
                    obligations under this Agreement will be suspended for the
                    duration of the event.
                </p>

                <h2>12. Termination of Service</h2>
                <h3>12.1 Termination by User</h3>
                <p>
                    Users may terminate their account at any time by following
                    the instructions on our website.
                </p>

                <h3>12.2 Termination by RepairPunk</h3>
                <p>
                    RepairPunk may terminate or suspend access to the Service at
                    its sole discretion, particularly in cases of User
                    misconduct, such as malicious activities, fraudulent
                    behavior, or violation of this Agreement.
                </p>

                <h2>13. Dispute Resolution and Governing Law</h2>
                <h3>13.1 Governing Law</h3>
                <p>
                    This Agreement shall be governed by and construed in
                    accordance with the laws of Ontario, Canada, without regard
                    to its conflict of law principles.
                </p>

                <h3>13.2 Dispute Resolution</h3>
                <p>
                    Any disputes arising out of or relating to this Agreement or
                    the Service shall be resolved through negotiation between
                    the parties. If a resolution cannot be reached, the dispute
                    shall be subject to the exclusive jurisdiction of the courts
                    in Ontario.
                </p>

                <h2>14. Changes to the Terms of Service</h2>
                <p>
                    RepairPunk reserves the right to modify or update this
                    Agreement at any time. Users will be notified of significant
                    changes through the Service or by other appropriate means.
                    Continued use of the Service after any such changes shall
                    constitute your consent to such changes.
                </p>

                <h2>15. Contact Information</h2>
                <p>
                    For any questions or concerns regarding this Agreement or
                    the Service, please contact us at:
                </p>
                <p>
                    <strong>Email:</strong> hey@repairpunk.com
                </p>
            </div>
        </S.TOSContentContainer>
    );
};

export default TermsOfService;
