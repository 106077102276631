const consonants = ["A", "E", "I", "O", "U"];
const vowels = [
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "R",
    "S",
    "T",
    "V",
    "Z",
];
import _sample from "lodash/sample";

export default function niceIdGen(): string {
    const letters = [
        _sample(consonants),
        _sample(vowels),
        _sample(consonants),
        _sample(vowels),
    ];
    return letters.join("");
}
