import Box from "@FEShared/components/UI/Box/Box";
import ModalDrawer from "@FEShared/components/UI/ModalDrawer/ModalDrawer";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import { runInAction } from "mobx";
import CancelReasonStep from "./CancelReasonStep/CancelReasonStep";
import PromoOfferStep from "./PromoOfferStep/PromoOfferStep";
import PromoActivatedStep from "./PromoActivatedStep/PromoActivatedStep";
import useStore, { history } from "@FEClient/logic/store";
import {
    CustomerOrderCancelPromoType,
    OrderStatus,
    useActivateCancelDiscountMutation,
    useCancelOrderMutation,
} from "@FEShared/graphql/generated/graphql";
import showToast from "@FEShared/utils/showToast";
import {
    CancelReason,
    OtherWorkshopFindReason,
} from "./ReservationCancelModal.const";
import { PickPartial } from "@Shared/types/utils";
import { UrlPathnameParser } from "../../Search/Search.logic";
import { ModalStep } from "./ReservationCancelModal.types";
import gqlResHandler from "@FEShared/utils/gqlResHandler";
import OrderCancelConfirmStep from "./OrderCancelConfirmStep/OrderCancelConfirmStep";
import { transStr } from "@FEShared/i18n";

const STEPS_WITH_BACK_BTN: ModalStep[] = [
    "2_NO_PROMO",
    "2_PROMO_OFFER",
    "3_NO_CANCEL",
    "3_CHANGE_DATE",
];

const CancelOrderModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    changeReservationDate: () => void;
}> = observer((p) => {
    const LS = useLocalObservable(() => ({
        step: "1_CANCEL_REASON" as ModalStep,
        selectedReason: undefined as CancelReason | undefined,
        otherWorkshopFindReason: undefined as
            | OtherWorkshopFindReason
            | undefined,
        otherReason: undefined as undefined | string,
        isLoading: undefined as undefined | ModalStep,
        get formattedReason() {
            return [
                LS.selectedReason,
                LS.otherWorkshopFindReason &&
                    `Other workshop find reason: ${LS.otherWorkshopFindReason}`,
                LS.otherReason && `Reason: ${LS.otherReason}`,
            ]
                .filter(Boolean)
                .join(" || ");
        },
    }));
    const GS = useStore();
    const [_cancelOrderRes, cancelOrderReq] = useCancelOrderMutation();
    const [_cancelDiscountRes, cancelDiscountActivate] =
        useActivateCancelDiscountMutation();

    const order = GS.reservationSuccessPageState.completedOrder;

    React.useEffect(() => {
        if (p.isOpen) {
            runInAction(() => {
                LS.step = "1_CANCEL_REASON";
            });
        }
    }, [p.isOpen, LS]);

    const editReasons = React.useCallback(
        (
            reasons: PickPartial<
                typeof LS,
                "selectedReason" | "otherWorkshopFindReason" | "otherReason"
            >
        ) => {
            runInAction(() => {
                Object.keys(reasons).forEach((key) => {
                    LS[key] = reasons[key];
                });
            });
        },
        [LS]
    );

    const cancelOrder = React.useCallback(
        async (showNotif?: boolean) => {
            if (!order) {
                return showToast.error(
                    transStr(
                        "Nepavyko atšaukti užsakymo. Klaidos kodas: #NAU",
                        { id: "y8k0qGT6" }
                    ),
                    true
                );
            }

            runInAction(() => {
                if (!LS.isLoading) {
                    LS.isLoading = LS.step;
                }
            });

            try {
                const res = await cancelOrderReq({
                    p: {
                        accessToken: order.userAccessToken,
                        orderID: order.ID,
                        reason: LS.formattedReason,
                    },
                });
                gqlResHandler(res, () => {
                    if (showNotif) {
                        showToast.success(
                            transStr(
                                "Užsakymas sėkmingai atšauktas. Autoservisas informuotas, jog neatvyksite į šią rezervaciją.",
                                { id: "D9RkpSVW" }
                            )
                        );
                        p.onClose(); // TBD: Show modal with actionable buttons instead of toast.
                        runInAction(() => {
                            if (
                                !GS.reservationSuccessPageState.completedOrder
                            ) {
                                return console.error(
                                    "GS.reservationSuccessPageState.completedOrder is undefined"
                                );
                            }

                            GS.reservationSuccessPageState.completedOrder.status =
                                OrderStatus.ClientCancelled;
                        });
                    }
                });
                return res;
            } finally {
                runInAction(() => {
                    LS.isLoading = undefined;
                });
            }
        },
        [cancelOrderReq, order, LS, GS.reservationSuccessPageState, p]
    );

    const reReg = React.useCallback(async () => {
        if (!order) return;

        if (order.vehicleBrand) {
            GS.searchState.carDataArr = [order.vehicleBrand];
        }
        GS.searchState.selectedServicesDefinitionsIDs = order.categories.map(
            (c) => c.ID
        );

        const url = UrlPathnameParser.paramsToPathname({
            city: order.city,
        });

        history.push(url);
    }, [order, GS.searchState]);

    const nextStep = React.useCallback(
        async (step: typeof LS.step) => {
            runInAction(async () => {
                if (step.toString().includes("3")) {
                    if (!order) {
                        return showToast.error(
                            transStr("Netikėta klaida. Klaidos kodas: #PP", {
                                id: "qzOHvG1q",
                            }),
                            true
                        );
                    }

                    LS.isLoading = step;
                    try {
                        const res = await cancelDiscountActivate({
                            p: {
                                orderID: order.ID,
                                accessToken: order.userAccessToken,
                                reason: LS.formattedReason,
                                cancelPromoType: step
                                    .toString()
                                    .replace(
                                        "3_",
                                        ""
                                    ) as CustomerOrderCancelPromoType,
                            },
                        });

                        gqlResHandler(res, async () => {
                            if (["3_CANCEL", "3_RE_REG"].includes(step)) {
                                const res = await cancelOrder();
                                if (res) {
                                    gqlResHandler(res, () => {
                                        LS.step = step;
                                    });
                                }
                            } else {
                                LS.step = step;
                            }
                        });
                    } finally {
                        runInAction(() => {
                            LS.isLoading = undefined;
                        });
                    }
                } else {
                    runInAction(() => {
                        LS.step = step;
                    });
                }
            });
        },
        [LS, order, cancelDiscountActivate, cancelOrder]
    );

    const stepComponent = React.useMemo(() => {
        if (LS.step === "1_CANCEL_REASON") {
            return (
                <CancelReasonStep
                    editReasons={editReasons}
                    otherWorkshopFindReason={LS.otherWorkshopFindReason}
                    otherReason={LS.otherReason}
                    selectedCancelReason={LS.selectedReason}
                    onClose={p.onClose}
                    nextStep={nextStep}
                />
            );
        } else if (LS.step === "2_PROMO_OFFER") {
            return (
                <PromoOfferStep
                    onClose={p.onClose}
                    nextStep={nextStep}
                    isLoading={LS.isLoading}
                />
            );
        } else if (LS.step === "2_NO_PROMO") {
            return (
                <OrderCancelConfirmStep
                    cancelOrder={async (showNotif?: boolean) => {
                        await cancelOrder(showNotif);
                    }}
                    onClose={p.onClose}
                    reReg={reReg}
                    changeReservationDate={p.changeReservationDate}
                />
            );
        } else if (LS.step.includes("3")) {
            return (
                <PromoActivatedStep
                    onClose={p.onClose}
                    type={LS.step}
                    reReg={reReg}
                    changeReservationDate={p.changeReservationDate}
                    orderID={
                        GS.reservationSuccessPageState.completedOrder?.ID ||
                        1337 /* shouldnt happen */
                    }
                />
            );
        }
    }, [
        LS.step,
        p.onClose,
        nextStep,
        p.changeReservationDate,
        LS.isLoading,
        GS.reservationSuccessPageState.completedOrder,
        cancelOrder,
        LS.otherWorkshopFindReason,
        LS.otherReason,
        LS.selectedReason,
        editReasons,
        reReg,
    ]);

    return (
        <ModalDrawer
            isOpen={p.isOpen}
            onClose={p.onClose}
            maxWidth="700px"
            goBack={
                STEPS_WITH_BACK_BTN.includes(LS.step)
                    ? () => {
                          let backStep: ModalStep;
                          switch (LS.step) {
                              case "2_NO_PROMO": {
                                  backStep = "1_CANCEL_REASON";
                                  break;
                              }
                              case "2_PROMO_OFFER": {
                                  backStep = "1_CANCEL_REASON";
                                  break;
                              }
                              case "3_CHANGE_DATE": {
                                  backStep = "2_PROMO_OFFER";
                                  break;
                              }
                              case "3_NO_CANCEL": {
                                  backStep = "2_PROMO_OFFER";
                                  break;
                              }
                          }

                          runInAction(() => {
                              LS.step = backStep;
                          });
                      }
                    : undefined
            }
        >
            <Box>{stepComponent}</Box>
        </ModalDrawer>
    );
});

export default CancelOrderModal;
