import ConfirmationSidePanel from "@FEClient/views/commonComps/ConfirmationSidePanel/ConfirmationSidePanel";
import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import styled from "styled-components";

export const ConfirmationContainer = styled("div")`
    position: relative;
    background: #f0f2f8;
    min-height: 100svh;
`;

export const ConfirmationContentContainer = styled(ContentContainer)`
    padding: 32px 0;
    max-width: 430px;
`;

export const ConfirmationPanel = styled(ConfirmationSidePanel)`
    margin-left: unset;
    max-width: unset;
`;
