import styled from "styled-components";
import { ContentContainer } from "../../commonComps/ContentContainer/ContentContainer.styled";

export const PicBanner = styled("div")<{ $backgroundImgUrl?: string }>`
    background-image: url(${(p) => p.$backgroundImgUrl});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 128px 0;
`;

export const Title = styled("h1")`
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 24px;
`;

export const Descr = styled("h2")`
    font-weight: 400;
    font-size: 18px;
    max-width: 630px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 150%;
`;

export const GreySection = styled("div")`
    background: #f2f3f4;
    color: black;
    text-align: center;
    padding: 96px 0;
`;

export const GreySectionTitle = styled("h3")`
    margin-bottom: 64px;
    margin-top: unset;
    font-size: 48px;
    font-weight: 700;
`;

export const GreySectionFeaturesWrapper = styled("div")`
    display: flex;
    justify-content: center;
`;

export const GreySectionFeature = styled("div")`
    &:not(:last-child) {
        margin-right: 128px; // gonna nede something more dynamic
    }
    display: flex;
    align-items: center;
`;

export const GreySectionFeatureEmphasis = styled("span")`
    font-size: 48px;
    font-weight: 700;
    margin-right: 12px;
`;

export const GreySectionFeatureDescr = styled("span")`
    color: #7a7a7a;
    font-size: 24px;
`;

export const TransparentSection = styled("div")`
    padding-top: 128px;
    padding-bottom: 128px;
    color: black;
    display: flex;
    justify-items: center;
    align-items: center;
`;

export const BoxFeature = styled("div")`
    border: 1px solid #dddddd;
    color: black;
    padding: 32px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    flex: 1;
    &:not(:last-child) {
        margin-right: 32px;
    }
`;

export const BoxFeatureTitle = styled("div")`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
`;

export const BoxFeatureDescr = styled("div")`
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
`;

export const OurStorySection = styled(TransparentSection)``;

export const OurStoryContentContainer = styled(ContentContainer)`
    display: flex;
`;

export const OurStoryWrapper = styled("div")`
    margin-right: 84px;
    display: flex;
    flex-direction: column;
`;

export const OurStoryTitle = styled("div")`
    font-size: 48px;
    margin-bottom: 32px;
`;

export const OurStoryDescr = styled("div")`
    font-size: 18px;
`;

// TBD in the future make into background img;
export const OurStoryImg = styled("img")`
    height: 100%;
    width: auto;
    max-width: 100%;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

export const OurStoryEndingLine = styled("div")`
    margin-top: 16px;
`;

export const AboutUsContainer = styled("div")`
    background: white;

    ${(p) => p.theme.breakpoints.down("md")} {
        ${PicBanner} {
            padding-top: 64px;
            padding-bottom: 64px;
        }
        ${TransparentSection} {
            padding-top: 64px;
            padding-bottom: 64px;
        }
        ${GreySectionFeaturesWrapper} {
            flex-direction: column;
            align-items: center;
        }

        ${GreySectionFeature} {
            margin-bottom: 24px;
            margin-right: unset;
        }

        ${OurStoryContentContainer} {
            flex-direction: column;
        }

        ${OurStoryWrapper} {
            flex-direction: column;
            margin-right: unset;
        }

        ${OurStoryImg} {
            margin-top: 24px;
        }
    }
`;

export const BoxFeaturesWrapper = styled("div")`
    display: flex;

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: column; // small tbd - can be moved down, if wont be used in home page anymore.

        ${BoxFeature} {
            margin-right: unset;
            margin-bottom: 24px;
            min-height: 170;
        }

        ${BoxFeatureTitle} {
            font-size: 18px;
            margin-bottom: 16px;
        }

        ${BoxFeatureDescr} {
            font-size: 16px;
        }
    }
`;

// export const AboutUsContentContainer = styled(ContentContainer)`
//     background: white;
//     border-radius: 20px;
// `;

// export const Section = styled("div")`
//     margin-bottom: 25px;
// `;

// export const HeaderText = styled(Typography)`
//     margin-bottom: 5px;
// `;
