import Domain from "./types/enums/Domain";

export type SharedConfig = {
    i18n: {
        timeoutInMs: number;
        backend: {
            serverUrl: string;
            org: string;
            project: string;
            // Create a new version online after each release.
            // This ensures that i18n changes don't affect current deployments.
            // Enter the new name here.
            version: string;
        }; //  as HostedI18nBackendConfig
    };
    oneSignalAppId: Partial<Record<Domain, string>>;
};

// create shared-config.local.ts if you want to modify the config
const sharedConfigDefault: SharedConfig = {
    i18n: {
        timeoutInMs: 6000,
        backend: {
            serverUrl: "https://i18n.virtuozo.dev",
            org: "l33t",
            project: "app",
            // Create a new version online after each release.
            // This ensures that i18n changes don't affect current deployments.
            // Enter the new name here.
            version: "main-1",
        }, //  as HostedI18nBackendConfig
    },
    oneSignalAppId: {
        // This is development app id
        [Domain.NEVAZIUOJA_LT]: "ac4088f9-26d8-4b58-8593-8854499c1b98",
    },
};

let localCfg: undefined | object;

try {
    localCfg = require(`./shared-config.local.ts`).default;
} catch (e: any) {
    if (!e || e.code !== "MODULE_NOT_FOUND") {
        throw e;
    }
}

if (!localCfg) {
    // This is only needed for BE code.
    try {
        localCfg = require(`./shared-config.local.js`).default;
    } catch (e: any) {
        if (!e || e.code !== "MODULE_NOT_FOUND") {
            throw e;
        }
    }
}

const finalCfg = Object.assign(
    {},
    sharedConfigDefault,
    localCfg
) as SharedConfig;

export default finalCfg;
