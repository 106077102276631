import { SeoPageDataGen, SeoPages } from "@FEShared/types/common";
import capFirst from "@Shared/util/capFirst";
import urlifyString from "@FEShared/utils/urlifyString";
import FECLIENT_SERVICES_SEO_PAGES_LT from "./FECLIENT_SERVICES_SEO_PAGES";
import FECLIENT_SERVICES_SEO_PAGES_EN from "./FECLIENT_SERVICES_SEO_PAGES_EN";
import { translatable } from "@Shared/i18n/i18n";
import { SERVICES_TRANSLATABLES } from "./SHARED_TRANSLATABLES";
import pickByCountry from "@Shared/util/pickByCountry";
import Country from "@Shared/types/enums/Country";

export const MOST_POPULAR_SERVICES_LANDINGS = (country: Country): SeoPages =>
    pickByCountry(country, {
        LT: FECLIENT_SERVICES_SEO_PAGES_LT,
        CA: FECLIENT_SERVICES_SEO_PAGES_EN,
    });

const TEMPLATE_FAQ = [
    {
        question: translatable(
            "Kur atliekama _serviceName_ Vilnius, Kaunas, Klaipėda, Panevėžys, Šiauliai ar Alytus miestuose?",
            {
                id: "17h9oLi3",
                description: "FECLIENT_ALL_SERVICES_SEO_PAGES_QUESTION",
            }
        ),
        answer: translatable(
            "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kuris atlieka _serviceName_ Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            {
                id: "g9uQt0vj",
                description: "FECLIENT_ALL_SERVICES_SEO_PAGES_ANSWER",
            }
        ),
    },
    {
        question: translatable(
            "Kur atliekama _serviceName_ savaitgalį (šeštadienį, sekmadienį)?",
            {
                id: "O9yLhSeF",
                description: "FECLIENT_ALL_SERVICES_SEO_PAGES_QUESTION",
            }
        ),
        answer: translatable(
            "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama _serviceName_ savaitgalį (šeštadienį/sekmadienį).",
            {
                id: "agAiU6Mu",
                description: "FECLIENT_ALL_SERVICES_SEO_PAGES_ANSWER",
            }
        ),
    },
    {
        question: translatable(
            "Kur atliekama _serviceName_ po darbo valandų?",
            {
                id: "0OIsi0zz",
                description: "FECLIENT_ALL_SERVICES_SEO_PAGES_QUESTION",
            }
        ),
        answer: translatable(
            "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama _serviceName_ po darbo valandų.",
            {
                id: "3a9TGcvE",
                description: "FECLIENT_ALL_SERVICES_SEO_PAGES_ANSWER",
            }
        ),
    },
    {
        question: translatable("Kur atliekama pigus _serviceName_?", {
            id: "7WFUy5Yr",
            description: "FECLIENT_ALL_SERVICES_SEO_PAGES_QUESTION",
        }),
        answer: translatable(
            "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus _serviceName_.",
            {
                id: "uLGdAmOF",
                description: "FECLIENT_ALL_SERVICES_SEO_PAGES_ANSWER",
            }
        ),
    },
];

const FECLIENT_ALL_SERVICES_SEO_PAGES: SeoPageDataGen = (
    translate,
    country
) => {
    const customServicesLandings = Object.values(
        MOST_POPULAR_SERVICES_LANDINGS(country)
    ).map((page) => page.serviceDefinitionID);

    const filteredServices = SERVICES_TRANSLATABLES.filter(
        (s) => !customServicesLandings.includes(s.ID)
    );

    return filteredServices.reduce((acc, service) => {
        const transServiceName = translate(service.serviceName);

        acc["/" + urlifyString(transServiceName)] = {
            seoServiceName: transServiceName,
            serviceDefinitionID: service.ID,
            faq: TEMPLATE_FAQ.map((f) => {
                return {
                    question: capFirst(
                        translate(f.question).replace(
                            /_serviceName_/g,
                            transServiceName
                        ),
                        false
                    ),
                    answer: capFirst(
                        translate(f.answer).replace(
                            /_serviceName_/g,
                            transServiceName
                        ),
                        false
                    ),
                };
            }),
        };
        return acc;
    }, {} as SeoPages);
};

export default FECLIENT_ALL_SERVICES_SEO_PAGES;
