import React from "react";
import MuiRating, { RatingProps } from "@mui/material/Rating";
import styled from "styled-components";

export interface RatingP extends RatingProps {
    fillColor?: string;
    emptyColor?: string;
}

const StyledRating = styled<React.ComponentType<RatingP>>(MuiRating)`
    &.MuiRating-sizeSmall .MuiRating-icon {
        padding: 0 2px;
        font-size: 14px;
    }

    &.MuiRating-sizeLarge .MuiRating-icon {
        padding: 0 8px;
        font-size: 32px;
    }

    .MuiRating-iconFilled {
        color: ${(p) => p.fillColor || "#FFB600"};
    }

    .MuiRating-iconEmpty {
        color: ${(p) => p.emptyColor || "#E0E0E0"};
    }
`;

const Rating: React.FC<RatingP> = (p) => {
    return (
        <StyledRating
            {...p}
            icon={<i className="icon-star-full" />}
            emptyIcon={<i className="icon-star-full" />}
        />
    );
};

export default Rating;
