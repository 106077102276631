import { GqlCountry } from "@FEShared/graphql/generated/graphql";
import Country from "../types/enums/Country";
import DOMAINS from "@Shared/consts/DOMAINS";

// this should be moved into config in the future
const EXTERNAL_TEST = false;
export const devHost = EXTERNAL_TEST ? "88.223.110.194" : "localhost";

export default function getUrlHost(
    type: "BACKEND" | "FE_WORKSHOP" | "FE_CLIENT",
    country: Country | GqlCountry
): string {
    let URL_HOST: string;

    if (type === "BACKEND") {
        URL_HOST =
            process.env.NODE_ENV === "production"
                ? // this should be https://api.nevaziuoja.lt, but with nginx we redirect from nevaziuoja.lt to api.nevaziuoja.lt
                  `https://${DOMAINS.FE_CLIENT[country]}`
                : `http://${devHost}:3000`;
    } else if (type === "FE_WORKSHOP") {
        URL_HOST =
            process.env.NODE_ENV === "production"
                ? `https://${DOMAINS.FE_WORKSHOP[country]}`
                : `http://${devHost}:8081`;
    } else if (type === "FE_CLIENT") {
        URL_HOST =
            process.env.NODE_ENV === "production"
                ? `https://${DOMAINS.FE_CLIENT[country]}`
                : `http://${devHost}:8080`;
    } else {
        throw new Error("Invalid type");
    }
    return URL_HOST;
}
