import styled from "styled-components";
import BackButton from "../../BackButton/BackButton";
import Button from "../../Button/Button";

export const Head = styled("div")`
    padding: 8px 16px;
    display: flex;
`;

export const HeadBackButton = styled(BackButton)`
    height: 40px;
    width: 40px;
    min-width: unset;
`;

export const CloseIcon = styled("i")`
    height: 20px;
    width: 28px;
    border-radius: 50px;
    border: 1px solid #c5c5c5;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
`;

export const SubmitBtn = styled(Button)`
    margin-left: auto;
    height: 40px;
`;
