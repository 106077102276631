import React from "react";
import * as S from "./Home.styled";
import Splash from "./Splash/Splash";
// import WorkshopJoinSection from "./WorkshopJoinSection/WorkshopJoinSection";
import NewBenefitsSection from "./NewBenefitsSection/NewBenefitsSection";
// import ServicesList from "./ServicesList/ServicesList";
// import RecommendedWorkshops from "./RecommendedWorkshops/RecommendedWorkshops";
// import PopularServices from "./PopularServices/PopularServices";
import ReviewsSection from "./ReviewsSection/ReviewsSection";
import { Helmet } from "react-helmet";
import NewsPortals from "./NewsPortals/NewsPortals";
import WorkshopReviewsSection from "./WorkshopsReviewsSection/WorkshopReviewsSection";
import { observer } from "mobx-react-lite";
import FAQ from "./FAQ/FAQ";
import { getSeoPageData } from "./FAQ/FAQ.util";
import { runInAction } from "mobx";
import useStore from "@FEClient/logic/store";
import { transStr } from "@FEShared/i18n";
import genDynamicHomeMeta from "./Home.util";

const Home: React.FC = observer(() => {
    const faq = getSeoPageData();
    const GS = useStore();

    const seoPageData = React.useMemo(() => {
        const seoPageData = getSeoPageData();
        return seoPageData;
    }, []);

    React.useEffect(() => {
        if (seoPageData) {
            runInAction(() => {
                if (seoPageData.carBrand) {
                    GS.searchState.carDataArr = [seoPageData.carBrand];
                } else {
                    GS.searchState.carDataArr = [];
                }

                GS.searchState.selectedServicesDefinitionsIDs =
                    seoPageData.serviceDefinitionID
                        ? [seoPageData.serviceDefinitionID]
                        : [];
            });
        }
    }, [seoPageData]); // eslint-disable-line react-hooks/exhaustive-deps

    const meta = genDynamicHomeMeta({
        seoPageData,
    });

    return (
        <S.HomePageContainer id="home-page-container">
            <Helmet>
                <link
                    rel="canonical"
                    href={`https://${window._COUNTRY_META.domain}${
                        seoPageData ? window.location.pathname : ""
                    }`}
                />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "url": "https://${window._COUNTRY_META.domain}/",
                        "description": "${meta.descr}",
                        "name": "${window._COUNTRY_META.domain}",
                        "foundingDate": "2023",
                        "sameAs": [
                          ${Object.values(window._COUNTRY_META.socials)
                              .map((link) => `"${link}"`)
                              .join(", ")}
                        ]
                    }
                `}
                </script>
            </Helmet>
            {seoPageData && (
                <Helmet>
                    <title>{meta.title}</title>
                    <meta name="description" content={meta.descr} />
                    <meta property="og:title" content={meta.title} />
                    <meta property="og:description" content={meta.descr} />
                </Helmet>
            )}
            <Splash />
            {window._COUNTRY === "LT" && <NewsPortals />}
            {/* <PopularServices /> */}
            {/* <RecommendedWorkshops /> */}
            <NewBenefitsSection />
            {faq && (
                <FAQ
                    title={transStr("{seoServiceName} - dažni klausimai", {
                        data: { seoServiceName: faq.seoServiceName },
                        id: "aEoA5QNJ",
                    })}
                    faq={faq.faq}
                />
            )}
            <ReviewsSection />
            <WorkshopReviewsSection />
            {/* <WorkshopJoinSection /> */}
            {/* <ServicesList /> */}
            {/* <FAQ /> */}
        </S.HomePageContainer>
    );
});

export default Home;
