import { translatable } from "@Shared/i18n/i18n";
import { Translatable } from "@Shared/i18n/i18n.types";

export enum CancelReason {
    BOOKED_FOR_NEW_TIME_SAME_WORKSHOP = "BOOKED_FOR_NEW_TIME_SAME_WORKSHOP",
    WILL_BOOK_FOR_NEW_TIME_SAME_WORKSHOP = "WILL_BOOK_FOR_NEW_TIME_SAME_WORKSHOP",
    BOOKED_OTHER_PLATFORM_WORKSHOP = "BOOKED_OTHER_PLATFORM_WORKSHOP",
    WANT_BOOK_OTHER_PLATFORM_WORKSHOP = "WANT_BOOK_OTHER_PLATFORM_WORKSHOP",
    FOUND_NON_PLATFORM_WORKSHOP = "FOUND_NON_PLATFORM_WORKSHOP",
    WORKSHOP_CANT_ACCEPT_ME = "WORKSHOP_CANT_ACCEPT_ME",
    OTHER = "OTHER",
}

export enum OtherWorkshopFindReason {
    "EXPENSIVE_PRICES" = "EXPENSIVE_PRICES",
    "NEEDED_EARLIER_TIME" = "NEEDED_EARLIER_TIME",
    "DIDNT_FIND_CONVENIENT_TIME" = "DIDNT_FIND_CONVENIENT_TIME",
    "DIDNT_FIND_CONVENIENT_LOCATION" = "DIDNT_FIND_CONVENIENT_LOCATION",
    "NOT_ENOUGH_TRUST" = "NOT_ENOUGH_TRUST",
    "OTHER" = "OTHER",
}

export const REASON_TO_TEXT: Record<CancelReason, Translatable> = {
    [CancelReason.FOUND_NON_PLATFORM_WORKSHOP]: translatable(
        "Radau kitą autoservisą ne jūsų platformoje",
        { id: "MggWcJv9" }
    ),
    [CancelReason.WORKSHOP_CANT_ACCEPT_ME]: translatable(
        "Autoservisas informavo, kad negalės priimti",
        { id: "FcXfqRIB" }
    ),
    [CancelReason.BOOKED_FOR_NEW_TIME_SAME_WORKSHOP]: translatable(
        "Užsiregistravau kitu laiku tam pačiam autoservise",
        { id: "60ZDC5AI" }
    ),
    // split this step into know/dont-know. If know, offer new new-time flow
    [CancelReason.WILL_BOOK_FOR_NEW_TIME_SAME_WORKSHOP]: translatable(
        "Noriu registruotis kitu laiku tam pačiam autoservise",
        { id: "EY0Gms8y" }
    ),
    [CancelReason.BOOKED_OTHER_PLATFORM_WORKSHOP]: translatable(
        "Užsiregistravau į kitą autoservisą Nevažiuoja platformoje",
        { id: "uNQONZLQ" }
    ),
    [CancelReason.WANT_BOOK_OTHER_PLATFORM_WORKSHOP]: translatable(
        "Noriu registruotis į kitą autoservisą Nevažiuoja platformoje",
        { id: "4DiNcb5X" }
    ),
    [CancelReason.OTHER]: translatable("Kita", { id: "wuw8Jjl8" }),
};

export const OTHER_WORKSHOP_FIND_REASON_TO_TEXT: Record<
    OtherWorkshopFindReason,
    Translatable
> = {
    [OtherWorkshopFindReason.NOT_ENOUGH_TRUST]: translatable(
        "Nepasitikėjau nevažiuoja platforma ir atlikta registracija",
        { id: "SW4DruAK" }
    ),
    [OtherWorkshopFindReason.DIDNT_FIND_CONVENIENT_LOCATION]: translatable(
        "Dėl patogesnės vietos",
        { id: "jiaXMHUo" }
    ),
    [OtherWorkshopFindReason.EXPENSIVE_PRICES]: translatable(
        "Dėl pigesnės kainos",
        { id: "jFlU1MIM" }
    ),
    [OtherWorkshopFindReason.NEEDED_EARLIER_TIME]: translatable(
        "Galėjo priimti greičiau",
        { id: "AIGsFmT0" }
    ),
    [OtherWorkshopFindReason.DIDNT_FIND_CONVENIENT_TIME]: translatable(
        "Galėjo priimti patogesniu laiku",
        { id: "Ut8DHx5b" }
    ),
    [OtherWorkshopFindReason.OTHER]: translatable("Kita", { id: "A6KkmRaG" }),
};
