import React from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import ExpansivePopper, {
    ExpansivePopperP,
} from "../ExpansivePopper/ExpansivePopper";
import * as S from "./ExpansivePopperWithValue.styled";
import { InputP } from "../Input/Input";

export const EXPANSION_INPUT_CLASSNAME = "EXPANSION_INPUT";

interface P extends ExpansivePopperP {
    value: string | string[];
    inputProps?: InputP;
    /** Used to show current search value that user has typed */
    temporaryValue?: string;
    className?: string;
    onClick?: () => void;
    renderTags?: (values: unknown[]) => React.ReactNode[];
    reserveSpaceForOptions?: boolean;
}
const ExpansivePopperWithValue = React.forwardRef<HTMLDivElement, P>(
    (p, ref) => {
        const isChipsInput = Array.isArray(p.value);
        const isMobile = useIsMobile();
        const inpValue = isChipsInput ? p.temporaryValue : p.value;

        return (
            <ExpansivePopper {...p} ref={ref}>
                {isMobile && (
                    <S.InputWrapper
                        className={EXPANSION_INPUT_CLASSNAME}
                        $reserveMoreSpace={p.reserveSpaceForOptions}
                    >
                        <S.ExpansionInput
                            size="small"
                            fullWidth
                            disabled
                            $fakePlaceholder={!inpValue}
                            dynamicHeight={isChipsInput}
                            $isChipsInput={isChipsInput}
                            onClick={p.onClick}
                            InputProps={
                                isChipsInput
                                    ? {
                                          startAdornment: p.renderTags
                                              ? p.renderTags(
                                                    p.value as string[]
                                                )
                                              : (p.value as string[]).map(
                                                    (v) => (
                                                        <S.ExpansionValueChip
                                                            key={v}
                                                            label={v}
                                                        />
                                                    )
                                                ),
                                      }
                                    : undefined
                            }
                            {...p.inputProps}
                            helperText={undefined} // expansive popper input should never have helperText
                            value={inpValue}
                        />
                    </S.InputWrapper>
                )}
            </ExpansivePopper>
        );
    }
);
ExpansivePopperWithValue.displayName = "ExpansivePopperWithValue";
export default ExpansivePopperWithValue;
