import capFirst from "@Shared/util/capFirst";
import transCommonTranslatable from "./transCommonTranslatable";
import isValidDate from "@Shared/util/isValidDate";
import LangCode from "@Shared/types/enums/LangCode";

const shortLTMonths = [
    "saus.",
    "vas.",
    "kov.",
    "bal.",
    "geg.",
    "birž.",
    "liep.",
    "rugp.",
    "rugs.",
    "spal.",
    "lapkr.",
    "gruod.",
];

function getShortMonth(date: Date): string {
    return capFirst(
        window._COUNTRY_META.langCode === LangCode.LT
            ? shortLTMonths[date.getMonth()]
            : date.toLocaleString(window._COUNTRY_META.locale, {
                  month: "short",
              })
    );
}

export function dateToMonthHourPrettyText(
    date: Date,
    withHours = true,
    monthAlwaysVisible = true
): string {
    const shortMonth = getShortMonth(date);
    const shortDayOfTheWeek = date.toLocaleString(window._COUNTRY_META.locale, {
        weekday: "short",
    });
    const day = date.getDate();

    const HH = date.getHours() as number;
    const MM = date.getMinutes() as number;

    let prettyText: string | undefined;
    const dateToCheckAgainst = new Date(date);
    const today = new Date();
    const tomorrow = new Date();
    const yesterday = new Date();

    tomorrow.setDate(tomorrow.getDate() + 1);
    yesterday.setDate(yesterday.getDate() - 1);

    // Resetting the hours, minutes, seconds, and milliseconds for comparison
    dateToCheckAgainst.setHours(0, 0, 0, 0);
    tomorrow.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);

    if (dateToCheckAgainst.getTime() === today.getTime()) {
        prettyText = transCommonTranslatable("TODAY");
    } else if (dateToCheckAgainst.getTime() === tomorrow.getTime()) {
        prettyText = transCommonTranslatable("TOMMOROW");
    } else if (dateToCheckAgainst.getTime() === yesterday.getTime()) {
        prettyText = transCommonTranslatable("YESTERDAY");
    } else {
        prettyText = capFirst(shortDayOfTheWeek);
    }

    // Adding leading zero to hours and minutes if less than 10
    const paddedHH = HH < 10 ? "0" + HH : HH;
    const paddedMM = MM < 10 ? "0" + MM : MM;

    return [
        (!prettyText || monthAlwaysVisible) && shortMonth,
        " ",
        day,
        ", ",
        prettyText && `${prettyText}`,
        withHours && `, ${paddedHH}:${paddedMM}`,
    ]
        .filter(Boolean)
        .join("");
}

// /* Return MM-DD / HH:MM */
// export function dateToShortFormatWithHour(date: Date): string {
//     const parsedDate = typeof date === "string" ? new Date(date) : date;
//     if (!parsedDate || !isValidDate(parsedDate)) {
//         console.error(`Received invalid date: ${date}`);
//         return "";
//     }
//     const shortMonth = getShortMonth(parsedDate);

//     const dateToCheckAgainst = new Date(parsedDate);
//     const today = new Date();
//     const tomorrow = new Date();
//     const yesterday = new Date();
//     tomorrow.setDate(tomorrow.getDate() + 1);
//     yesterday.setDate(yesterday.getDate() - 1);
//     // Resetting the hours, minutes, seconds, and milliseconds for comparison
//     dateToCheckAgainst.setHours(0, 0, 0, 0);
//     tomorrow.setHours(0, 0, 0, 0);
//     today.setHours(0, 0, 0, 0);
//     yesterday.setHours(0, 0, 0, 0);

//     const day = String(parsedDate.getDate()).padStart(2, "0");
//     const hours = String(parsedDate.getHours()).padStart(2, "0");
//     const minutes = String(parsedDate.getMinutes()).padStart(2, "0");

//     let prettyText: string | undefined;
//     if (dateToCheckAgainst.getTime() === today.getTime()) {
//         prettyText = transCommonTranslatable("TODAY");
//     } else if (dateToCheckAgainst.getTime() === tomorrow.getTime()) {
//         prettyText = transCommonTranslatable("TOMMOROW");
//     } else if (dateToCheckAgainst.getTime() === yesterday.getTime()) {
//         prettyText = transCommonTranslatable("YESTERDAY");
//     }

//     return `${
//         prettyText ? `${prettyText}, ` : ``
//     }${shortMonth} ${day}, ${hours}:${minutes}`;
// }
