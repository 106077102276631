import useIsMobile from "@FEShared/hooks/useIsMobile";
import React from "react";
import { observer } from "mobx-react-lite";
import * as S from "./AutocompleteWithModal.styled";
import AutocompleteModal from "./AutocompleteModal/AutocompleteModal";
import Input, { InputP } from "../Input/Input";
import { AutocompleteProps, AutocompleteValue } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import cs from "classnames";

export const AUTOCOMPLETE_MOBILE_INPUT_CLASS_NAME = "AutocompleteMobileInput";

// TBD: After some changes, it seems that seperate state for modal handling might be redundant overhead. Before we wanted to be able to handle it seperately, but now it might make sense to delete it and just use Autocomplete open state for modal state.
export const AUTOCOMPLETE_WITH_MODAL_CLASS_NAME = "AutocompleteWithModal";
interface P<T, Multiple extends boolean>
    extends Omit<
        AutocompleteProps<T, Multiple, false, false>,
        "onChange" | "renderInput" | "onClose" | "onOpen"
    > {
    canFinalizeChoice: () => boolean;
    /** Props that will be applied to the `TextField` inside `renderInput` */
    inputProps: InputP;
    /** Props that will be applied to the input that is shown on mobiles which is not part of Autocomplete and is outside the modal */
    mobileInputProps?: InputP;
    modal: {
        showChooseBtn?: boolean;
        isOpen: boolean;
        toggle: (open: boolean) => void;
    };
    dropdownContainerEl?: JSX.Element;
    // Below props that we rewrite for simpler usage of API and less boilerplate.
    // Could be removed, if there would be any incoveniences
    onChange: (val: AutocompleteValue<T, Multiple, false, false>) => void;
    onClose: () => void;
    onOpen: () => void;
    goBack?: () => void;
    value: AutocompleteValue<T, Multiple, false, false>;
}
const AutocompleteWithModal = observer(
    <T, Multiple extends boolean>(p: P<T, Multiple>) => {
        const {
            canFinalizeChoice: _1,
            inputProps: _4,
            mobileInputProps: _5,
            goBack: _7,
            modal: _6,
            PopperComponent,
            ...autocompleteProps
        } = p;
        const isMobile = useIsMobile();
        const autocompleteRef = React.useRef<HTMLDivElement>(null);
        const { onOpen, onChange, onClose } = p;
        const { toggle: modalToggle } = p.modal;

        // React.useEffect(() => {
        //     if (p.modal.isOpen) {
        //         setTimeout(() => {
        //             // force autocomplete keyboard focus on phones
        //             autocompleteRef.current?.click();
        //             autocompleteRef.current?.focus();
        //         }, 1000);
        //     }
        // }, [p.modal.isOpen]);

        React.useEffect(() => {
            return () => {
                if (p.open && onClose) {
                    onClose();
                }
            };
        }, [p.open, onClose]);

        const onFocusCb = React.useCallback(() => {
            if (!p.open) {
                onOpen();
            }
        }, [onOpen, p.open]);

        const onChangeCb = React.useCallback(
            (e, val) => {
                if (e.key === "Enter") {
                    return;
                }
                onChange(val);
            },
            [onChange]
        );

        const onCloseCb = React.useCallback(
            (e: React.FocusEvent | React.SyntheticEvent, reason) => {
                const eventTarget =
                    "relatedTarget" in e ? e.relatedTarget : e.currentTarget;
                if (reason === "toggleInput" && e.type === "click") {
                    onClose();
                    return;
                }
                if (
                    eventTarget &&
                    eventTarget.closest(
                        `.${AUTOCOMPLETE_WITH_MODAL_CLASS_NAME}`
                    )
                ) {
                    return;
                }

                if (
                    (isMobile && reason === "blur") ||
                    /* for some reason onClose is called when the input inside modal is toggled on*/
                    reason === "toggleInput"
                ) {
                    return;
                }

                onClose();
                if (isMobile) {
                    modalToggle(false);
                }
            },
            [onClose, modalToggle, isMobile]
        );

        const PopperComponentCb = React.useCallback(
            (props: any) => {
                const FinalPopperComp = PopperComponent || S.PopperComponent;

                return isMobile ? (
                    <FinalPopperComp {...props} />
                ) : (
                    <S.Backdrop open={props.open}>
                        <FinalPopperComp
                            {...props}
                            onMouseDown={(e) => {
                                // prevent Autocomplete Desktop Input from losing focus. If the input loses focus, the `onClose` handler breaks, because it seems like it is dependant on input focus state.
                                e.preventDefault();
                            }}
                        />
                    </S.Backdrop>
                );
            },
            [PopperComponent, isMobile]
        );

        const autocomplete = (
            <S.Autocomplete
                ref={autocompleteRef}
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        (document.activeElement as any)?.blur();
                    }
                }}
                disablePortal={isMobile}
                disableCloseOnSelect={p.multiple}
                filterOptions={(o) => o /*disable filtering from MUI side.*/}
                // ListboxComponent={ListboxComponent}
                PaperComponent={S.DropdownContainer}
                // renderOption={(props, option, state) =>
                //     ({
                //         props,
                //         option: p.getOptionLabel
                //             ? p.getOptionLabel(option)
                //             : option,
                //         state,
                //     } as unknown as React.ReactNode)
                // }
                // renderOption and renderGroup is handled inside ListboxComponent.
                // renderGroup={(params) => params as unknown as React.ReactNode}
                renderInput={(params) => {
                    return (
                        <Input
                            dynamicHeight
                            {...params}
                            {...p.inputProps}
                            size="medium"
                            // the input that appears on modals, should have no helperTexts to gain more space, since helperText creates an extra margin by the bottom of input.
                            helperText={
                                isMobile ? undefined : p.inputProps.helperText
                            }
                            error={isMobile ? undefined : p.inputProps.error}
                            forceError={
                                isMobile ? undefined : p.inputProps.forceError
                            }
                            InputProps={{
                                ...params.InputProps,
                                ...p.inputProps.InputProps,
                                sx: isMobile
                                    ? {
                                          borderRadius: "15px!important",
                                      }
                                    : undefined,
                                ...(isMobile
                                    ? {
                                          endAdornment: null,
                                      }
                                    : {}),
                            }}
                            style={
                                isMobile
                                    ? {
                                          marginTop: 1 /** Android (Xiaomi) border bug fix */,
                                      }
                                    : p.open
                                    ? {
                                          position: "relative",
                                          zIndex: 3000,
                                      }
                                    : undefined
                            }
                            inputProps={{
                                ...params.inputProps,
                                // try to disable grammarly stuff if user has it, because it was reported that delete btn doesnt work with grammarly.
                                // from ggl it seemed that this should be the proper solution, but it didn't really solve the problem for user who reported it.
                                autoCorrect: "off",
                                ["data-gramm"]: "false",
                                ["data-gramm_editor"]: "false",
                                ["data-enable-grammarly"]: "false",
                                // autoFocus: isMobile,
                                ["data-hj-allow"]: true,
                            }}
                        />
                    );
                }}
                {...autocompleteProps}
                className={p.className}
                // below props shouldn't be rewritten by props
                PopperComponent={PopperComponentCb}
                open={p.modal.isOpen || p.open}
                onChange={onChangeCb}
                onClose={onCloseCb}
                onFocus={onFocusCb}
            />
        );

        const openModal = React.useCallback(() => {
            if (p.modal.isOpen) return;
            modalToggle(true);
        }, [p.modal.isOpen, modalToggle]);

        const closeModal = React.useCallback(() => {
            modalToggle(false);
        }, [modalToggle]);

        const onClick = React.useCallback(() => {
            openModal();
        }, [openModal]);

        return isMobile ? (
            <>
                <S.MobileInputWrapper
                    onClick={onClick}
                    className={cs(
                        p.className,
                        AUTOCOMPLETE_MOBILE_INPUT_CLASS_NAME
                    )}
                >
                    <S.MobileInput
                        rightIcon={<ArrowDropDownIcon />}
                        {...p.mobileInputProps}
                        {...p.inputProps}
                        inputProps={{
                            ["data-hj-allow"]: true,
                        }}
                        forceError={p.inputProps.error}
                        fullWidth
                        disabled
                        removeDisabledStyles
                    />
                </S.MobileInputWrapper>
                <AutocompleteModal
                    showChooseBtn={p.modal.showChooseBtn}
                    className={AUTOCOMPLETE_WITH_MODAL_CLASS_NAME}
                    isOpen={p.modal.isOpen}
                    onClose={closeModal}
                    canFinalizeChoice={p.canFinalizeChoice}
                    goBack={p.goBack}
                >
                    {autocomplete}
                </AutocompleteModal>
            </>
        ) : (
            autocomplete
        );
    }
);

export default AutocompleteWithModal;
