import styled from "styled-components";
import { default as LogoShared } from "@FEShared/components/UI/Logo/Logo";
import Drawer from "@mui/material/Drawer";

export const SideBarContainer = styled(Drawer)`
    .MuiDrawer-paper {
        width: 256px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        padding: 0px 8px;
    }
`;

export const Logo = styled(LogoShared).attrs({ desktop: true })`
    padding: 32px 24px;
`;
