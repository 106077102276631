import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import Button from "@FEShared/components/UI/Button/Button";
import styled, { css } from "styled-components";
import * as IFS from "../../commonComps/InfoPage/InfoPage.styled";
import BLACK_BG_IMG from "./assets/black-bg.webp";
/** delete below 2 assets after home page landing upgraded */
import CAN_YOU_ACCEPT_IMG from "./assets/can-you-accept.webp";
import CAN_YOU_ACCEPT_MOBILE_IMG from "./assets/can-you-accept-mobile.webp";
import responsiveSize from "@FEShared/utils/responsiveSize";
import { emphasize } from "@mui/material/styles";

export const TOP_LANDING_BREAKPOINT = 1200;

export const TopBanner = styled("div")`
    color: white;
    padding-top: 64px;
    padding-bottom: 120px;
    background: #462bbe;
    position: relative;
    display: flex;
    overflow: hidden;
`;

export const Title = styled("h1")`
    font-weight: 600;
    font-size: 38px;
    margin: 0;
    max-width: 500px;
    margin-bottom: 32px;
    width: 120%;
`;

export const Left = styled("div")`
    display: flex;
    flex-direction: column;
    max-width: 464px;
    position: relative;
    z-index: 1;

    ${(p) => p.theme.breakpoints.down(TOP_LANDING_BREAKPOINT)} {
        margin-left: 0;
        max-width: unset;
    }
`;

export const RightImg = styled("img")`
    position: absolute;
    right: 0;
    bottom: 0;
    object-fit: contain;
    height: 100%;
    object-position: bottom right;
    width: ${responsiveSize(1400, 1728, 2000, 1728)};
`;

export const CircleImg = styled("img")`
    position: absolute;
    right: 0;
    top: -120px;
    width: ${responsiveSize(750, 923, 1500, 1728)};
    bottom: -77%;
    z-index: 0;
`;

export const PercentageBubble = styled("div")`
    font-weight: 600;
    font-size: 15px;
    position: relative;
    text-align: left;
    width: 90%;
    margin: 0 auto;
    margin-bottom: -70px;
    background: ${(p) => p.theme.palette.primary.main};
    padding: 16px 24px;
    color: white;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

export const MobileLandingImg = styled("img")`
    object-fit: contain;
    max-width: 100%;
    align-self: center;
    max-height: 500px;
    align-self: start;
    margin-top: 32px;
`;

export const BannerEmphasisText = styled("span")`
    color: ${(p) => p.theme.palette.success.main};
`;

export const Descr = styled("h2")`
    font-size: 18px;
    font-weight: 400;
    margin: 0;
`;

export const FeaturesWrapper = styled("div")`
    display: flex;
    align-items: space-between;
    margin-top: 56px;
    margin-bottom: 56px;
`;

export const FeatureBox = styled("div")`
    font-weight: 400;
    &:not(:last-child) {
        margin-right: 48px;
    }
`;

export const FeatureTitle = styled("div")`
    font-size: 30px;
    font-weight: 500;
`;

export const FeatureDescr = styled("span")`
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
`;

export const BecomePartnerBtn = styled(Button)``;

export const SectionContentWrapper = styled("div")<{
    $type: "left" | "right";
}>`
    max-width: 570px;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;

    ${(p) =>
        p.$type === "left" &&
        css`
            margin-right: auto;
        `}
    ${(p) =>
        p.$type === "right" &&
        css`
            margin-left: auto;
        `}
`;

export const SectionTitle = styled("h3")`
    font-size: 30px;
    margin: unset;
    margin-bottom: 32px;
    margin-top: 0;
`;

export const SectionDescr = styled("div")`
    font-size: 16px;
    display: flex;
    flex-direction: column;
`;

export const VideoSection = styled(IFS.TransparentSection)`
    background-image: url(${BLACK_BG_IMG});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    overflow: visible;
    padding-top: 48px;
    padding-bottom: 48px;
`;

export const VideoTitle = styled(SectionTitle)`
    margin-bottom: 32px;
    margin-top: 0;
    color: white;
    font-size: 24px;
`;

export const VideoIframe = styled("iframe")`
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    overflow: hidden;
`;

export const FormTitle = styled("h3")`
    margin-top: unset;
    font-size: 24px;
`;

export const FormDescr = styled("div")`
    margin-top: 8px;
    margin-bottom: 16px;
`;

export const EmphasisText = styled("span")`
    color: ${(p) => p.theme.palette.primary.main};
`;

export const FormIframeWrapper = styled("div")`
    overflow: hidden;
    height: 400px;
`;

export const FormIframe = styled("iframe")`
    margin-top: -60px;
    width: 100%;
    height: 600px;
    border: 0;
`;

export const FormWrapper = styled("div")`
    border: 1px solid #e0e0e0;
    background: #f2f3f4;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    padding: 32px;
    max-width: 580px;
`;

export const BecomePartnerContainer = styled("div")`
    background: white;

    ${(p) => p.theme.breakpoints.down("md")} {
        ${TopBanner} {
            padding-top: 32px;
            padding-bottom: 48px;
        }

        ${Title} {
            width: 100%;
        }

        ${FeaturesWrapper} {
            margin-bottom: 0;
        }

        ${BecomePartnerBtn} {
            margin-top: 32px;
            width: 100%;
        }

        ${FeatureDescr} {
            font-size: 16px;
            margin-bottom: 12px;
        }

        ${FeatureBox} {
            &:not(:last-child) {
                margin-right: 16px;
            }
        }

        ${VideoSection} {
            background: black;
            background-image: none;
            width: 100%;
            justify-content: center;
            display: flex;
            text-align: center;
        }

        ${VideoIframe} {
            margin: 0 auto;
            width: calc(100% - 48px);
        }

        ${FormTitle} {
            margin-bottom: 24px;
            font-size: 20px;
            text-align: center;
        }

        ${FormIframeWrapper} {
            height: 420px;
        }
    }
`;

export const SectionTitleEmphasis = styled("span")``;

export const SectionFeature = styled("div")`
    display: flex;
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
`;

export const FeatureCheckmark = styled("i")`
    font-size: 16px;
    color: #13c7d0;
    margin-right: 16px;
`;

export const ColorSectionMobileImg = styled("img")`
    width: 100%;
    max-height: 500px;
    object-fit: contain;
    margin-bottom: 32px;
`;

export const ColorSplash = styled("div")<{
    $bgImgUrl?: string;
    $type: "left" | "right";
    $multiplier?: number;
}>`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    background-image: url(${(p) => p.$bgImgUrl});
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;

    ${(p) => {
        const multipliedSize = 900 * (p.$multiplier || 1);
        return (
            p.$type === "left" &&
            css`
                background-position-x: 0%;
                right: max(
                    0px,
                    calc(${multipliedSize}px - ${multipliedSize} * 100vw / 2560)
                );
            `
        );
    }}

    ${(p) => {
        const multipliedSize = 900 * (p.$multiplier || 1);
        return (
            p.$type === "right" &&
            css`
                background-position-x: 100%;
                left: max(
                    0px,
                    calc(${multipliedSize}px - ${multipliedSize} * 100vw / 2560)
                );
            `
        );
    }}
`;

interface ColorSectionP {
    $color: string;
}
export const ColorSection = styled("div")<ColorSectionP>`
    height: 540px;
    width: 100%;
    color: black;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;

    ${SectionTitleEmphasis} {
        color: ${(p) => p.$color};
    }

    .MuiButton-root {
        color: white;
        background: ${(p) => p.$color};
        /* border-color: ${(p) => p.$color}; */
        /* &:hover {
            background: ${(p) => emphasize(p.$color, 0.9)};
        } */
        margin-top: 32px;
        max-width: 270px;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        height: auto;
        padding-bottom: 32px;
        text-align: center;

        .MuiButton-root {
            max-width: unset;
        }

        ${SectionTitle} {
            margin-top: 0;
        }

        ${SectionContentWrapper} {
            max-width: unset;
        }

        ${SectionDescr} {
            text-align: left;
        }
    }
`;

export const BottomSection = styled("div")`
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: ${(p) => p.theme.padding.contentPadding};
    padding-right: ${(p) => p.theme.padding.contentPadding};

    color: black;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background: ${(p) => p.theme.palette.primary.main};
`;

/** old. Delete below, when it's not used in home page anymore. And soon it wont be. */

export const PurpleSectionClientAcceptImg = styled("div")`
    background-image: url(${CAN_YOU_ACCEPT_IMG});
    height: 100%;
    width: 506px;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const ColorSectionContentContainer = styled(ContentContainer)`
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
`;

export const ColorSectionOld = styled("div")<{ $backgroundColor: string }>`
    height: 405px;
    width: 100%;
    color: white;
    background: ${(p) => p.$backgroundColor};

    ${(p) => p.theme.breakpoints.down("md")} {
        height: 553px;
        padding-bottom: 64px;
        text-align: center;

        ${SectionTitle} {
            margin-top: 0px;
            margin-bottom: 24px;
        }

        ${ColorSectionContentContainer} {
            flex-direction: column;
        }

        ${SectionContentWrapper} {
            margin: unset;
        }

        ${PurpleSectionClientAcceptImg} {
            order: -1;
            background-position: center bottom;
            background-size: auto 100%;
            background-image: url(${CAN_YOU_ACCEPT_MOBILE_IMG});
            height: 340px;
            width: 100%;
        }
    }
`;
