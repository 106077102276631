import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

const ScrollToTop: React.FC<{
    history: History;
}> = (p) => {
    useEffect(() => {
        const unlisten = p.history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, []);

    return null;
};

export default withRouter(ScrollToTop);
