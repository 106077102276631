import { WorkshopService } from "@FEShared/graphql/generated/graphql";

export default function getServiceDurationMins(
    service: Pick<WorkshopService, "durationFromMins" | "durationToMins"> & {
        type: Pick<
            WorkshopService["type"],
            "durationMinsPerUnit" | "defaultUnitCount"
        >;
    }
): number {
    if (
        service.durationFromMins &&
        service.durationToMins &&
        service.durationFromMins === service.durationToMins
    ) {
        return service.durationFromMins * service.type.defaultUnitCount;
    } else if (service.durationFromMins && service.durationToMins) {
        return service.durationFromMins * service.type.defaultUnitCount;
    } else if (service.durationFromMins) {
        return service.durationFromMins * service.type.defaultUnitCount;
    } else if (service.durationToMins) {
        return service.durationToMins * service.type.defaultUnitCount;
    } else {
        return service.type.durationMinsPerUnit * service.type.defaultUnitCount;
    }
}
