import React from "react";
import { useTheme } from "styled-components";
import Rating from "FEShared/components/UI/Rating/Rating";
import InputsGroup from "@FEShared/components/UI/InputsGroup/InputsGroup";
import * as S from "./ReviewStep.styled";
import Input from "@FEShared/components/UI/Input/Input";
import {
    OrderReviewSummaryQuery,
    useCreateReviewMutation,
} from "@FEShared/graphql/generated/graphql";
import Box from "@FEShared/components/UI/Box/Box";
import { AlignToBottomOnMobile } from "@FEClient/views/pages/ReviewPage/ReviewPage.styled";
import Text from "@FEShared/components/UI/Text/Text";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import useToken from "@FEClient/logic/hooks/useToken/useToken";
import gqlResHandler from "@FEShared/utils/gqlResHandler";
import { Review } from "../../WarrantyActivation.types";
import { TransMsg, transStr } from "@FEShared/i18n";

const ReviewStep: React.FC<{
    order: OrderReviewSummaryQuery["order"];
    onBtnClick: () => Promise<void>;
    reviewOnly?: boolean;
    onReviewChange: (review: Review) => void;
}> = observer((p) => {
    const RATING_LABELS = {
        1: transStr("Labai blogai", { id: "hZGX7kYp" }),
        2: transStr("Blogai", { id: "gtse6eUn" }),
        3: transStr("Patenkinamai", { id: "5NepxKfS" }),
        4: transStr("Gerai", { id: "CBPv4uIM" }),
        5: transStr("Puikiai", { id: "4QygROff" }),
    };

    const theme = useTheme();
    const token = useToken();

    const [_, createReview] = useCreateReviewMutation();

    const LS = useLocalObservable(() => ({
        forceError: false,
        rating: 5,
        name: "",
        reviewText: "",
        loading: false as false | "PRIMARY" | "SECONDARY",
    }));

    const save = async () => {
        if (!LS.rating) return;

        if (!LS.name || !LS.reviewText) {
            runInAction(() => {
                LS.forceError = true;
            });
            return;
        }

        try {
            runInAction(() => {
                LS.loading = "PRIMARY";
            });
            const reviewRes = await createReview({
                accessToken: token,
                orderId: +p.order.ID,
                params: {
                    rating: LS.rating,
                    text: LS.reviewText || null,
                    authorName: LS.name || null,
                },
            });
            gqlResHandler(reviewRes, (data) => {
                p.onReviewChange(data.createReview);
            });
            await p.onBtnClick();
        } finally {
            LS.loading = false;
        }

        p.onBtnClick();
    };

    const onChangeRating = (_: React.SyntheticEvent, value: number | null) => {
        if (value !== null) {
            runInAction(() => {
                LS.rating = value;
            });
        }
    };

    if (!p.order.acceptedService) {
        console.error("No accepted service in ReviewForm", p.order);
        return null;
    }

    return (
        <Box>
            <S.RatingContainer>
                <Text mb={3}>
                    <TransMsg
                        default={
                            "Padėk autoservisui tobulėti ir kitiems žmonėms priimti teisingus sprendimus ❤️"
                        }
                        id="vNVtHlPV"
                    />
                </Text>
                <S.RatingTitle>
                    <TransMsg
                        default={"Kaip vertinate savo patirtį autoservise"}
                        id="sKeKnJAu"
                    />{" "}
                    <S.RatingTitleWorkshopName>
                        {p.order.acceptedService?.name}
                    </S.RatingTitleWorkshopName>
                    ?
                </S.RatingTitle>
                <Rating
                    size="large"
                    value={LS.rating}
                    fillColor={theme.palette.primary.main}
                    onChange={onChangeRating}
                />
                <S.RatingLabel>
                    {LS.rating && RATING_LABELS[LS.rating]}
                </S.RatingLabel>
            </S.RatingContainer>
            <AlignToBottomOnMobile>
                <Box>
                    <InputsGroup>
                        <Input
                            fullWidth
                            placeholder={transStr("Vardas", { id: "RBjx4dpI" })}
                            autoFocus
                            value={LS.name}
                            onChange={(e) =>
                                runInAction(() => (LS.name = e.target.value))
                            }
                        />
                        <S.CommentInput
                            fullWidth
                            multiline
                            placeholder={transStr(
                                "Net ir trumpas atsiliepimas padės autoservisui tobulėti ❤️",
                                { id: "bsQ7y4vx" }
                            )}
                            value={LS.reviewText}
                            onChange={(e) =>
                                runInAction(
                                    () => (LS.reviewText = e.target.value)
                                )
                            }
                        />
                    </InputsGroup>
                    {LS.forceError && (
                        <Text color={"error"}>
                            <TransMsg
                                default={"Parašykite vardą ir atsiliepimą"}
                                id="c9j5BxZg"
                            />
                        </Text>
                    )}
                    <S.ConfirmBtn
                        onClick={save}
                        isLoading={LS.loading === "PRIMARY"}
                    >
                        <TransMsg default={"Baigti"} id="9RSRvybQ" />
                    </S.ConfirmBtn>
                    {!p.reviewOnly && (
                        <Box>
                            <S.ConfirmBtn
                                isLoading={LS.loading === "SECONDARY"}
                                variant="text"
                                onClick={async () => {
                                    try {
                                        runInAction(() => {
                                            LS.loading = "SECONDARY";
                                        });
                                        await p.onBtnClick();
                                    } finally {
                                        LS.loading = false;
                                    }
                                }}
                                sx={{
                                    ml: "auto",
                                    mt: 3,
                                    color: "text.secondary",
                                    textDecoration: "underline",
                                    fontWeight: 400,
                                }}
                            >
                                <TransMsg
                                    default={"Baigti nepalikus atsiliepimo"}
                                    id="QDcfYyLt"
                                />
                            </S.ConfirmBtn>
                        </Box>
                    )}
                </Box>
            </AlignToBottomOnMobile>
        </Box>
    );
});

export default ReviewStep;
