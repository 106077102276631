import { WorkshopService } from "@FEShared/graphql/generated/graphql";
import { WantedService } from "./BusinessDateHandler";

export default function serviceCategoriesToWantedServices(
    s: Pick<WorkshopService, "durationFromMins"> & {
        type: Pick<WorkshopService["type"], "ID" | "durationMinsPerUnit">;
    }
): WantedService {
    return {
        ID: s.type.ID,
        durationMins: s.durationFromMins || s.type.durationMinsPerUnit,
    };
}
