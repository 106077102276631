import React from "react";
import { useRouteMatch, match } from "react-router-dom";

/**
 * Run all side effects that should happen on route change here.
 * In order to use `useRouteMatch` the component must be under <Route> parent.
 * */
const OnRouteChange: React.FC<{
    onRouteChange: (currentRouteMatch: match) => void;
}> = (p) => {
    const currentRouteMatch = useRouteMatch();
    React.useEffect(() => {
        p.onRouteChange(currentRouteMatch);
    }, [p, currentRouteMatch]);
    return null;
};

export default OnRouteChange;
