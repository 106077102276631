import useIsMobile from "@FEShared/hooks/useIsMobile";
import React from "react";
import Modal from "../Modal/Modal";
import Drawer from "../Drawer/Drawer";

/* On desktop works as a modal, on mobile works as a drawer.
 */

const ModalDrawer: React.FCC<{
    isOpen: boolean;
    onClose: () => void;
    className?: string;
    onChooseClick?: () => void;
    showChooseBtn?: boolean;
    goBack?: () => void;
    /** pass null or empty fragment if you don't want header */
    header?: JSX.Element | null;
    maxWidth?: string;
    fixedHeight?: string;
    disableSwipeToClose?: boolean;
}> = (p) => {
    const isMobile = useIsMobile();

    return isMobile ? (
        <Drawer
            disableSwipeToClose={p.disableSwipeToClose}
            isOpen={p.isOpen}
            header={p.header}
            className={p.className}
            onClose={p.onClose}
            goBack={p.goBack}
        >
            {p.children}
        </Drawer>
    ) : (
        <Modal
            goBack={p.goBack}
            isOpen={p.isOpen}
            onClose={p.onClose}
            className={p.className}
            header={p.header}
            maxWidth={p.maxWidth}
            fixedHeight={p.fixedHeight}
        >
            {p.children}
        </Modal>
    );
};

ModalDrawer.defaultProps = {
    showChooseBtn: true,
};

export default ModalDrawer;
