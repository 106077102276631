import Select from "@mui/material/Select";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";

export const MUISelect = styled(Select)`
    margin: 1px 0; // fix border bug. Without this, on mobile, the top/bottom borders become invisible

    .MuiSelect-select {
        display: flex;
        align-items: center;
        padding-left: 3px;
    }
` as typeof Select;

export const MUIMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        word-break: break-word;
        white-space: unset;
    }
`;

export const Placeholder = styled("span")`
    opacity: 0.5;
`;

export const LeftIcon = styled("i")`
    font-size: 18px;
    margin-right: 8px;
`;

export const LeftIconImg = styled("img")`
    width: 18px;
    max-height: 100%;
    margin-right: 8px;
`;
