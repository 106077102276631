import React from "react";
import Dropdown from "../UI/Dropdown/Dropdown";
import { I18nService } from "@FEShared/i18n/I18nService";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import { getLanguageInfo } from "@FEShared/i18n/languageInfo";

const LanguageSelector: React.FC<{
    i18nService: I18nService;
}> = observer((p) => {
    const LS = useLocalObservable(() => ({
        languages: [] as string[],
    }));

    React.useEffect(() => {
        async function getLangs() {
            const langs = await p.i18nService.getSupportedLanguages();

            runInAction(() => {
                LS.languages = langs.map((lang) => lang.languageCode);
            });
        }
        getLangs();
    }, []);

    const currentLanguageCode = p.i18nService.currentLocale.languageCode;

    const options =
        LS.languages.length > 0
            ? LS.languages.map((lang) => ({
                  text: getLanguageInfo(lang).name,
                  value: lang,
                  iconSrc: getLanguageInfo(lang).flagUrl,
              }))
            : [
                  {
                      text: "en",
                      value: currentLanguageCode,
                  },
              ];

    return (
        <Dropdown
            sx={{ maxWidth: 200 }}
            fullWidth
            value={currentLanguageCode}
            options={options}
            onChange={async (e) => {
                if (!e.target?.value) return;

                const languageCode = e.target.value;
                try {
                    await p.i18nService.setLanguage({ languageCode });
                } catch (err) {
                    console.error(err);
                }
            }}
        />
    );
});

export default LanguageSelector;
