import { CALENDAR_FULL_DAY_SEARCH_TIME } from "@Shared/consts/commonConsts";
import { DateTime } from "luxon";

export default function isFullDaySearch(dateTime: DateTime | Date): boolean {
    const dt =
        dateTime instanceof Date ? DateTime.fromJSDate(dateTime) : dateTime;

    return (
        dt.hour === CALENDAR_FULL_DAY_SEARCH_TIME.hour &&
        dt.minute === CALENDAR_FULL_DAY_SEARCH_TIME.minute &&
        dt.second === CALENDAR_FULL_DAY_SEARCH_TIME.second &&
        dt.millisecond === CALENDAR_FULL_DAY_SEARCH_TIME.ms
    );
}
