import React from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { TransMsg, transStr } from "@FEShared/i18n";
import showToast from "@FEShared/utils/showToast";

const ErrorBoundary: React.FCC = (p) => {
    return (
        <SentryErrorBoundary
            fallback={() => {
                return (
                    <TransMsg
                        default={
                            "Įvyko netikėta klaida. Jeigu problema kartosis, susisiekite su klientų aptarnavimu. Puslapis netrukus persikraus."
                        }
                        id="PkDkPZ9c"
                    />
                );
            }}
            onError={() => {
                if (!window._IS_PROD) return;

                showToast.error(
                    transStr(
                        "Įvyko nenumatyta klaida #CR. Jeigu problema kartosis, susisiekite su klientų aptarnavimu.",
                        { id: "Z9re9CLO" }
                    )
                );
                window.location.reload(); // Not entirely sure if it's good idea to reload - maybe would make more sense to redirect user to home page. But lets see if this brings any problems and change then, since this should be better UX
            }}
        >
            {p.children}
        </SentryErrorBoundary>
    );
};

export default ErrorBoundary;
