import { SeoPageDataGen, SeoPages } from "@FEShared/types/common";
import DISTRICTS from "./DISTRICTS";
import capFirst from "@Shared/util/capFirst";
import urlifyString from "@FEShared/utils/urlifyString";
import { translatable } from "@Shared/i18n/i18n";
import Country from "@Shared/types/enums/Country";
import pickByCountry from "@Shared/util/pickByCountry";

export const DISTRICTS_URL_PREFIX = (country: Country) =>
    pickByCountry(country, {
        LT: "autoservisas",
        CA: "auto-repair",
    });

const TEMPLATE_FAQ = [
    {
        question: translatable(
            "Autoservisas rajone _districtName_ - kaip rasti geriausią?",
            { id: "5RS12XKw", description: "FECLIENT_DISTRICT_SEO_QUESTION" }
        ),
        answer: translatable(
            "Nevažiuoja naršyk geriausius autoservisus rajone _districtName_. Pasirink automobilio modelį, reikiamas paslaugas, norimą atvykimo laiką ir rezervuok vizitą pas patikimus meistrus savo automobilio remontui.",
            { id: "7sVf2sd6", description: "FECLIENT_DISTRICT_SEO_ANSWER" }
        ),
    },
    {
        question: translatable(
            "Kur atliekama tepalų keitimas rajone _districtName_?",
            { id: "ghi0xaYk", description: "FECLIENT_DISTRICT_SEO_QUESTION" }
        ),
        answer: translatable(
            "Tepalų keitimas rajone _districtName_. Pasirink tepalų keitimo paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą tepalų keitimui sau patogioje vietoje.",
            { id: "bI1hqtkP", description: "FECLIENT_DISTRICT_SEO_ANSWER" }
        ),
    },
    {
        question: translatable(
            "Kur atliekama padangų montavimas rajone _districtName_?",
            { id: "W3mjZ5xB", description: "FECLIENT_DISTRICT_SEO_QUESTION" }
        ),
        answer: translatable(
            "Padangų montavimas rajone _districtName_. Pasirink padangų montavimo paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą padangų montavimui sau patogioje vietoje.",
            { id: "qaMFhqNG", description: "FECLIENT_DISTRICT_SEO_ANSWER" }
        ),
    },
    {
        question: translatable(
            "Kur atliekama pakabos patikra/remontas rajone _districtName_?",
            { id: "K5xEhOlP", description: "FECLIENT_DISTRICT_SEO_QUESTION" }
        ),
        answer: translatable(
            "Pakabos patikra/remontas rajone _districtName_. Pasirink pakabos patikros/remonto paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą pakabos patikrai/remontui sau patogioje vietoje.",
            { id: "8CW2hhuu", description: "FECLIENT_DISTRICT_SEO_ANSWER" }
        ),
    },
    {
        question: translatable(
            "Kur atliekama stabdžių remontas rajone _districtName_?",
            { id: "DsZ3NPmU", description: "FECLIENT_DISTRICT_SEO_QUESTION" }
        ),
        answer: translatable(
            "Stabdžių remontas rajone _districtName_. Pasirink stabdžių remonto paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą stabdžių remontui sau patogioje vietoje.",
            { id: "blKhk5nG", description: "FECLIENT_DISTRICT_SEO_ANSWER" }
        ),
    },
    {
        question: translatable(
            "Kur atliekama variklio remontas rajone _districtName_?",
            { id: "eViPW6Rf", description: "FECLIENT_DISTRICT_SEO_QUESTION" }
        ),
        answer: translatable(
            "Variklio remontas rajone _districtName_. Pasirink variklio remonto paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą variklio remontui sau patogioje vietoje.",
            { id: "mCRr0wkg", description: "FECLIENT_DISTRICT_SEO_ANSWER" }
        ),
    },
    {
        question: translatable(
            "Kur atliekama automobilio patikra rajone _districtName_?",
            { id: "tCnekwGG", description: "FECLIENT_DISTRICT_SEO_QUESTION" }
        ),
        answer: translatable(
            "Automobilio patikra rajone _districtName_. Pasirink automobilio patikros paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą automobilio patikrai sau patogioje vietoje.",
            { id: "KInIMJMD", description: "FECLIENT_DISTRICT_SEO_ANSWER" }
        ),
    },
];

const FECLIENT_DISTRICT_SEO_PAGES: SeoPageDataGen = (translate, country) => {
    const t = Object.entries(DISTRICTS[country]).reduce(
        (acc, [city, districts]) => {
            districts.forEach((district) => {
                const districtTranslatedPrefix = DISTRICTS_URL_PREFIX(country);

                acc[
                    `/${districtTranslatedPrefix}-${urlifyString(
                        district
                    )}-${city}`.toLowerCase()
                ] = {
                    seoServiceName: translate(
                        translatable("Autoservisas {district}", {
                            data: { district },
                            id: "WRqt2Rbk",
                            description:
                                "FECLIENT_DISTRICT_SEO_PAGES seoServiceName",
                        })
                    ),
                    faq: TEMPLATE_FAQ.map((f) => {
                        return {
                            question: capFirst(
                                translate(f.question).replace(
                                    /_districtName_/g,
                                    district
                                ),
                                false
                            ),
                            answer: capFirst(
                                translate(f.answer).replace(
                                    /_districtName_/g,
                                    district
                                ),
                                false
                            ),
                        };
                    }),
                };
            });
            return acc;
        },
        {} as SeoPages
    );

    return t;
};

export default FECLIENT_DISTRICT_SEO_PAGES;
