import { createTheme, lighten } from "@mui/material/styles";
import { ClientCustomTheme } from "./theme.types";
import "./index.d.ts";
import Domain from "@Shared/types/enums/Domain";

const clientCustomTheme: ClientCustomTheme = {
    sizes: {
        headerHeight: 63,
        inputHeight: 51,
        inputHeightSmall: 40,
        pageContentWidth: "1120px",
        lowestMobileWidth: "375px",
        spaceBetweenDrawerAndTop: "10px",
    },
    padding: {
        contentPadding: "16px",
    },
};

const muiTheme = createTheme({
    palette: {
        common: {
            black: "#171717",
            white: "#ffffff",
        },
        primary:
            window._COUNTRY_META.domain === Domain.NEVAZIUOJA_LT
                ? {
                      main: "#5E39FE",
                  }
                : {
                      main: "#EE364C",
                  },
        secondary: {
            main: "#ff63a4",
        },
        tertiary:
            window._COUNTRY_META.domain === Domain.NEVAZIUOJA_LT
                ? {
                      main: "#d8d5f3",
                      contrastText: "#5E39FE",
                  }
                : {
                      main: "#f8d7da",
                      contrastText: "#EE364C",
                  },
        background: {
            default: "#F0F2F8",
        },
        text: {
            primary: "#000000",
            secondary: "#828282",
        },
        error: {
            main: "#EE364C",
            contastText: "white",
        },
        success: {
            main: "#51D385",
        },
        white: {
            main: "#",
        },
        greyish: {
            main: "#",
        },
    } as any,
    typography: {
        fontFamily: "Rubik, sans-serif",
        h1: {
            fontWeight: 500,
            fontSize: 44,
            margin: 0,
        },
        h2: {
            fontWeight: 500,
            fontSize: 28,
            margin: 0,
        },
        h3: {
            fontSize: 30,
            fontWeight: 500,
            margin: 0,
        },
        subtitle1: {
            color: "#7B7F93",
            fontSize: 14,
            lineHeight: "normal",
            fontWeight: 400,
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: 12,
            color: "#7B7F93",
        },
    },
    shape: {
        borderRadius: 15,
    },
    components: {
        MuiTooltip: {
            defaultProps: {
                enterTouchDelay: 0,
                leaveTouchDelay: 4000,
            },
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#EFF0F3",
                    color: "black",
                    fontWeight: 400,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: (p) => ({
                    lineHeight: 1.5,
                    height:
                        p.ownerState.size === "medium"
                            ? p.theme.sizes.inputHeight
                            : p.theme.sizes.inputHeightSmall,
                    textTransform: "none",
                    ...(p.ownerState.variant === "outlined" && {
                        border: "1px solid black",
                        color: "black",
                        background: "transparent",
                        "&:hover": {
                            color: p.theme.palette.primary.main,
                        },
                    }),
                    boxShadow: "unset!important",
                }),
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    color: "black",
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: (p) => ({
                    fontSize: "16px",
                }),
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    height: "unset",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: (p) => ({
                    height:
                        p.ownerState.size === "medium"
                            ? p.theme.sizes.inputHeight
                            : p.theme.sizes.inputHeightSmall,
                    paddingTop: "0!important",
                    paddingBottom: "0!important",
                    boxSizing: "border-box",
                    background: "white",
                }),
                input: (p) => ({
                    height:
                        p.ownerState.size === "medium"
                            ? p.theme.sizes.inputHeight
                            : p.theme.sizes.inputHeightSmall,
                    paddingTop: "0!important",
                    paddingBottom: "0!important",
                    boxSizing: "border-box",
                }),
                multiline: (p) => ({
                    height: "unset",
                    minHeight:
                        p.ownerState.size === "medium"
                            ? p.theme.sizes.inputHeight
                            : p.theme.sizes.inputHeightSmall,
                    paddingTop: "8px!important",
                    paddingBottom: "8px!important",
                }),
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: (p) => {
                    return {
                        color: "inherit", // so it inherits the color from the Input and follows disabled state.
                    };
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "&.Mui-checked": {
                        "& .MuiSvgIcon-root": {
                            color: "#171717",
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "#F0F2F8",
                },
                notchedOutline: {
                    borderColor: "transparent",
                },
            },
        },
    },
    ...clientCustomTheme,
});

// this is the recommended way to augment MUI Theme. https://mui.com/material-ui/customization/theming/#createtheme-options-args-theme
const appTheme = createTheme(muiTheme, {
    palette: {
        white: {
            ...muiTheme.palette.augmentColor({
                color: {
                    main: "#ffffff",
                    contrastText: muiTheme.palette.common.black,
                },
                name: "white",
            }),
        },
        greyish: {
            ...muiTheme.palette.augmentColor({
                color: {
                    main: "#F0F2F8",
                    contrastText: "black",
                },
                name: "greyish",
            }),
        },
    },
});

export default appTheme;
