import { transStringFunctionalFE } from "@FEShared/i18n";
import COMMON_TRANSLATABLES, {
    CommonTranslatableKey,
} from "@Shared/consts/COMMON_TRANSLATABLES";

/*
 * return translated text for the translatable KEY. If it's not found it will just return the key.
 */
export default function transCommonTranslatable(
    commonTranslatable: CommonTranslatableKey
): string {
    const translatable =
        COMMON_TRANSLATABLES[commonTranslatable] || commonTranslatable;
    return transStringFunctionalFE(translatable);
}
