import Box from "@FEShared/components/UI/Box/Box";
import React from "react";
import { WorkshopDTO } from "../../Workshop.types";
import Text from "@FEShared/components/UI/Text/Text";
import { observer } from "mobx-react-lite";
import ReservationBoxContent from "./ReservationBoxContent/ReservationBoxContent";
import * as WS from "../../Workshop.styled";
import { TransMsg } from "@FEShared/i18n";

const ReservationBox: React.FCC<{
    selectedWorkshop: WorkshopDTO;
}> = observer((p) => {
    return (
        <Box
            sx={{
                position: "sticky",
                width: 0.39,
                top: "8px",
                alignSelf: "start",
            }}
        >
            <WS.ServicesTitleWrap>
                <WS.PurpleVerticalBar />
                <WS.ServicesTitleSubWrap>
                    {/* <WS.ServicesSubTitle>
                                Ieškai daugiau?
                            </WS.ServicesSubTitle> */}
                    <Text variant="h2">
                        <TransMsg default={"Rezervacija"} id="wspTIuXQ" />
                    </Text>
                </WS.ServicesTitleSubWrap>
            </WS.ServicesTitleWrap>

            <Box
                rounded
                sx={{
                    backgroundColor: "white",
                    border: "1px solid rgb(221, 221, 221)",
                    boxShadow: "0px 0px 25px 0px rgba(69, 84, 123, 0.25);",
                    alignSelf: "flex-start",
                }}
                padding={3}
            >
                <ReservationBoxContent selectedWorkshop={p.selectedWorkshop} />
            </Box>
        </Box>
    );
});

export default ReservationBox;
