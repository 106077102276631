import ContentContainer from "@FEClient/views/commonComps/ContentContainer/ContentContainer";
import styled from "styled-components";

export const LogosContentContainer = styled(ContentContainer)`
    align-items: space-between;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
`;

export const Logo = styled("img")`
    filter: grayscale(100%);
    object-fit: contain;
    margin-right: 24px;
    margin-bottom: 24px;
    max-width: 100px;
`;
