import React from "react";

import PartnerImgDesk0 from "./assets/partner_ds0.webp";
import PartnerImgDesk1 from "./assets/partner_ds1.webp";
import PartnerImgDesk2 from "./assets/partner_ds2.webp";
import PartnerImgDesk3 from "./assets/partner_ds3.webp";
import PartnerImgMob0 from "./assets/partner_s0.webp";
import PartnerImgMob1 from "./assets/partner_s1.webp";
import PartnerImgMob2 from "./assets/partner_s2.webp";
import PartnerImgMob3 from "./assets/partner_s3.webp";
import { Review } from "./PartnerRecomendationCards.styled";
import { TransMsg } from "@FEShared/i18n";
import { translatable } from "@Shared/i18n/i18n";

export const reviews = [
    {
        id: 0,
        review: (
            <Review>
                “
                <TransMsg
                    default={
                        "{B <Daugiau klientų>}, spartesnis administracijos darbas."
                    }
                    data={{ B: (body) => <b>{body}</b> }}
                    id="tu5hQcnc"
                />
                ”
            </Review>
        ),
        companyName: translatable("EDLER", { id: "zLtExWDr" }),
        personName: translatable("Edvard Namirskij", { id: "Vm7YrX2Z" }),
        bgColor: "#281382",
        bgImg: {
            desktop: PartnerImgDesk0,
            mobile: PartnerImgMob0,
        },
    },
    {
        id: 1,
        review: (
            <Review>
                “
                <TransMsg
                    default={"{B <Visiškai atnaujintas>} autoserviso įvaizdis."}
                    data={{ B: (body) => <b>{body}</b> }}
                    id="rDDqEcbz"
                />
                ”
            </Review>
        ),
        companyName: translatable("ExpressAuto", { id: "RzwWlGbk" }),
        personName: translatable("Arvydas Krakauskas", { id: "3idO7QVI" }),
        bgColor: "#E83C3F",
        bgImg: {
            desktop: PartnerImgDesk1,
            mobile: PartnerImgMob1,
        },
    },
    {
        id: 2,
        review: (
            <Review>
                “
                <TransMsg
                    default={
                        "{B <Klientai registruojasi>} kada jiems patogu, {B <visą parą>}."
                    }
                    data={{ B: (body) => <b>{body}</b> }}
                    id="S3OyvwYG"
                />
                ”
            </Review>
        ),
        companyName: translatable("FlixAuto", { id: "qIQiGU8s" }),
        personName: translatable("Mantas Grinkevičius", { id: "FygmH10z" }),
        bgColor: "#FF9900",
        bgImg: {
            desktop: PartnerImgDesk2,
            mobile: PartnerImgMob2,
        },
    },
    {
        id: 3,
        review: (
            <Review>
                “
                <TransMsg
                    default={
                        "Autoservisą valdau iš telefono, {B <dirbam daug efektyviau>}."
                    }
                    data={{ B: (body) => <b>{body}</b> }}
                    id="x7plRnMp"
                />
                ”
            </Review>
        ),
        companyName: translatable("EDauta", { id: "IyiZsdZ5" }),
        personName: translatable("Edvinas Juodis", { id: "fVmKNTj9" }),
        bgColor: "#1BCC77",
        bgImg: {
            desktop: PartnerImgDesk3,
            mobile: PartnerImgMob3,
        },
    },
];
