import React from "react";
import * as S from "./ConfirmationTitle.styled";
import WarrantyBox from "../WarrantyBox/WarrantyBox";
import Text from "@FEShared/components/UI/Text/Text";

interface P {
    title: string;
    iconClassName: string;
    subtitle?: string;
    showWarranty?: boolean;
    color?: string;
}

const ConfirmationTitle: React.FC<P> = (p) => (
    <S.TitleWrapper>
        {p.showWarranty && (
            <WarrantyBox
                sx={{
                    mb: 2,
                }}
            />
        )}
        <Text color={p.color}>
            <S.TitleIcon className={p.iconClassName} />
            <S.TitleText>{p.title}</S.TitleText>
        </Text>
        <S.Subtitle>{p.subtitle}</S.Subtitle>
    </S.TitleWrapper>
);

ConfirmationTitle.defaultProps = {
    showWarranty: true,
};

export default ConfirmationTitle;
