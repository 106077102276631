import React from "react";
import * as S from "./AutocompleteModal.styled";

const AutocompleteModal: React.FCC<{
    isOpen: boolean;
    onClose: () => void;
    showChooseBtn?: boolean;
    canFinalizeChoice: () => boolean;
    className?: string;
    goBack?: () => void;
}> = (p) => {
    const { onClose } = p;
    React.useEffect(() => {
        return () => {
            onClose();
        };
    }, [onClose]);

    return (
        <S.ModalDrawerStyled
            className={p.className}
            isOpen={p.isOpen}
            onClose={() => {
                p.onClose();
            }}
            showChooseBtn={p.showChooseBtn}
            goBack={p.goBack}
            onChooseClick={() => {
                if (p.canFinalizeChoice()) {
                    p.onClose();
                }
            }}
        >
            {p.children}
        </S.ModalDrawerStyled>
    );
};

export default AutocompleteModal;
