import Text from "@FEShared/components/UI/Text/Text";
import React from "react";
import { PROC } from "../PromoOfferStep/PromoOfferStep";
import { DateTime } from "luxon";
import { dateFormatFull } from "@Shared/util/dateFormat";
import Button from "@FEShared/components/UI/Button/Button";
import DISCOUNT_IMG from "./assets/discount.webp";
import Img from "@FEShared/components/UI/Img/Img";
import Box from "@FEShared/components/UI/Box/Box";
import { PromoActivatedStep } from "../ReservationCancelModal.types";
import { TransMsg, transStr } from "@FEShared/i18n";

const EXPIRATION_DAYS = 3;

const OrderCancelledStep: React.FC<{
    orderID: number;
    type: PromoActivatedStep;
    reReg: () => void;
    changeReservationDate: () => void;
    onClose: () => void;
    isLoading?: boolean;
}> = (p) => {
    const stepData = React.useMemo(() => {
        let title: string;
        let btnText: string;
        let contentText: string | undefined;
        let customContent: JSX.Element | undefined;
        let secondaryBtn: { text: string; onClick: () => void } | undefined;

        if (p.type === "3_NO_CANCEL") {
            title = transStr("Nuolaida aktyvuota!", { id: "qc0D1Wxr" });
            customContent = (
                <Text>
                    <TransMsg
                        default={
                            "Autoservisas laukia jūsų atvykstant. Jums bus pritaikyta 10% nuolaida užsakymo sumai."
                        }
                        id="ry9jOvdl"
                    />
                </Text>
            );
            btnText = transStr("Supratau", { id: "clRbOLbD" });
        } else if (p.type === "3_CANCEL") {
            title = transStr("Rezervacija atšaukta. Nuolaida kitam vizitui", {
                id: "6qlEYEln",
            });
            contentText = transStr("Naujos registracijos metu", {
                id: "UZq8aiPj",
            });
            btnText = transStr("Registruotis iš naujo", { id: "OkAn9bA5" });
        } else if (p.type === "3_CHANGE_DATE") {
            title = transStr(
                "Pakeisk rezervacijos laiką ir aktyvuok {PROC} nuolaida",
                { data: { PROC }, id: "YagbKnft" }
            );
            btnText = transStr("Keisti rezervacijos laiką", { id: "msLqNQm1" });
            contentText = transStr("Keisdamas vizito laiką", {
                id: "va6jnyOw",
            });
            secondaryBtn = {
                text: transStr("Nauja registracija", { id: "MiKGIa5a" }),
                onClick: p.reReg,
            };
        } else if (p.type === "3_RE_REG") {
            title = transStr(
                "Rezervacija atšaukta. Atlik naują registraciją ir aktyvuok {PROC} nuolaida",
                { data: { PROC }, id: "GiAiYAdh" }
            );
            contentText = transStr("Atlikdamas registraciją", {
                id: "EPBYKJVV",
            });
            btnText = transStr("Nauja registracija", { id: "bSoD5hou" });
            secondaryBtn = {
                text: transStr("Keisti rezervacijos laiką", { id: "i2sWa718" }),
                onClick: p.changeReservationDate,
            };
        } else {
            // shouldn't ever happen.
            console.error("Unknown type in PromoActivatedStep: ", p.type);
            title = transStr("Nuolaida aktyvuota", { id: "QCOWv4Ie" });
            btnText = transStr("Uždaryto", { id: "eymIn47i" });
        }

        const content = customContent || (
            <>
                <Text>
                    <TransMsg
                        default={
                            "{contentText} panaudok promo kodą: {Text <NUOLAIDA{orderID}>} ir gauk {Text <{PROC}>} nuolaidą."
                        }
                        data={{
                            contentText,
                            orderID: p.orderID,
                            Text: (body) => (
                                <Text span semiBold>
                                    {body}
                                </Text>
                            ),
                            PROC,
                        }}
                        id="GGcgfpDn"
                    />
                    <Text mt={1}>
                        <TransMsg
                            default={
                                "Kodas galioja {EXPIRATION_DAYS} dienas, atlikus registracija iki:"
                            }
                            data={{ EXPIRATION_DAYS }}
                            id="Gn0ilTrR"
                        />{" "}
                        <Text span semiBold>
                            {dateFormatFull(
                                DateTime.now()
                                    .plus({ days: EXPIRATION_DAYS })
                                    .toJSDate()
                            )}
                        </Text>
                    </Text>
                </Text>
            </>
        );

        return {
            title,
            btnText,
            content,
            secondaryBtn,
        };
    }, [p.type, p.orderID, p.changeReservationDate, p.reReg]);

    return (
        <Box>
            <Box pb={2}>
                <Text
                    mb={2}
                    semiBold
                    center
                    sx={(theme) => ({
                        fontSize: 30,
                        [theme.breakpoints.down("md")]: {
                            fontSize: 18,
                        },
                    })}
                >
                    {stepData.title}
                </Text>
                <Box textAlign="center">
                    <Img
                        src={DISCOUNT_IMG}
                        mt={3}
                        mb={3}
                        ml="auto"
                        mr="auto"
                        height={225}
                        sx={{ textAlign: "center" }}
                    />
                </Box>
                <Box textAlign="center">
                    {stepData.content}{" "}
                    {/* <Text mt={1}>
                    Po sėkmingo vizito, Nevažiuoja komanda su tavim susisieks ir
                    grąžins į tavo banko sąskaitą {PROC} remonto sumos.
                </Text> */}
                    <Text variant="subtitle2">
                        <TransMsg
                            default={
                                "* Nuolaida galioja tik kai dalys sudaro mažiau nei 75% remonto sumos"
                            }
                            id="EAHuyXTg"
                        />
                    </Text>
                </Box>
                <Button
                    isLoading={Boolean(p.isLoading)}
                    fullWidth
                    onClick={() => {
                        if (p.type === "3_CHANGE_DATE") {
                            p.changeReservationDate();
                        } else if (
                            p.type === "3_RE_REG" ||
                            p.type === "3_CANCEL"
                        ) {
                            p.reReg();
                        } else {
                            p.onClose();
                        }
                    }}
                    sx={{ mt: 4 }}
                >
                    {stepData.btnText}
                </Button>
                {/* {stepData.secondaryBtn && (
                <Button
                    fullWidth
                    onClick={stepData.secondaryBtn?.onClick}
                    color="greyish"
                    sx={{ mt: 2 }}
                >
                    {stepData.secondaryBtn?.text}
                </Button>
            )} */}
            </Box>
        </Box>
    );
};

export default OrderCancelledStep;
