import Box from "@FEShared/components/UI/Box/Box";
import Button from "@FEShared/components/UI/Button/Button";
import styled, { css } from "styled-components";
import { SUBCATEG_WRAPPER_CLASS } from "../ServiceOrSubcategRow/ServiceOrSubcategRow";

export const VISIBLE_WORKSHOP_SERVICE_ROW_CLASSNAME =
    "VISIBLE_WORKSHOP_SERVICE_ROW";

const BORDER_RADIUS_PX = 10;

export const WorkshopServiceTitlesWrap = styled("div")`
    display: flex;
    flex-direction: column;
    margin-right: 8px;
`;

export const WorkshopServiceTitle = styled("span")`
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 2px;
`;

export const WorkshopServiceSubtitle = styled("span")<{
    $packageExplanation?: boolean;
}>`
    font-weight: 400;
    font-size: 16px;
    color: #727272;

    ${(p) =>
        p.$packageExplanation &&
        css`
            font-size: 12px;
        `}
`;

export const WorkshopServiceRowItemWrap = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-left: auto;
`;

export const Price = styled("div")`
    font-size: 16px;
    display: flex;
    text-wrap: nowrap;
    justify-content: center;

    ${(p) => p.theme.breakpoints.down("md")} {
        font-size: 16px;
    }
`;

export const Duration = styled("div")`
    margin-left: 8px;
    font-weight: 400;
    font-size: 12px;
    color: black;
    padding: 4px;
    border-radius: 5px;
    background: #dddfe8;
    white-space: nowrap;
`;

export const SelectButton = styled(Button)<{ $selected: boolean }>`
    min-height: 35px;
    border-color: ${(p) => p.theme.palette.primary.main};
    min-width: 110px;
    max-width: 110px;
    flex: 1;
    align-self: flex-end;

    ${(p) =>
        p.$selected &&
        css`
            background: #d7eddf !important;
            color: #3c8b56 !important;
            border-color: transparent !important;
        `}
`;

export const MobileBtnIcon = styled("i")`
    font-size: 16px;
`;

export const RecommendationText = styled("div")`
    color: white;
    background: #e5514a;
    font-size: 12px;
    padding: 4px;
    text-align: center;
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 8px;
    align-self: start;
`;

export const ServiceImg = styled("img")`
    width: 56px;
    height: 56px;
    margin-right: 16px;
    align-self: flex-start;
`;

export const AdditionalText = styled("div")`
    padding-left: 4px;
    font-size: 14px;
`;

export const Specialized = styled("i")`
    background: ${(p) => p.theme.palette.primary.main};
    color: white;
    padding: 6px;
    font-weight: 500;
    margin-left: 8px;
    font-size: 12px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

export const WorkshopServiceRow = styled("div")<{
    $hidden?: boolean;
    $bulky?: boolean;
    $last?: boolean;
}>`
    display: flex;
    align-items: flex-start;
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #f0f2f8;
    border-bottom: 1px solid #e0e0e0;

    // set radius for element before subcateg
    &:has(+ .${SUBCATEG_WRAPPER_CLASS}) {
        border-bottom-left-radius: ${BORDER_RADIUS_PX}px;
        border-bottom-right-radius: ${BORDER_RADIUS_PX}px;
        border-bottom: 0;
    }

    // set radius for element after subcateg
    .${SUBCATEG_WRAPPER_CLASS} + & {
        border-top-left-radius: ${BORDER_RADIUS_PX}px;
        border-top-right-radius: ${BORDER_RADIUS_PX}px;
    }

    // if you are using vscode-styled-components extension, you will see errors in below 2 blocks, doesn't detect syntax;

    // set radius for the first visible element
    :nth-child(1 of .${VISIBLE_WORKSHOP_SERVICE_ROW_CLASSNAME}) {
        border-top-left-radius: ${BORDER_RADIUS_PX}px;
        border-top-right-radius: ${BORDER_RADIUS_PX}px;
    }

    // set radius & remove border for the last visible element
    :nth-last-child(1 of .${VISIBLE_WORKSHOP_SERVICE_ROW_CLASSNAME}) {
        border-bottom-left-radius: ${BORDER_RADIUS_PX}px;
        border-bottom-right-radius: ${BORDER_RADIUS_PX}px;
        margin-bottom: 4px;
        border-bottom: 0;
    }

    /* 
    ${(p) =>
        p.$last &&
        css`
            border-bottom: 0;
            border-bottom-left-radius: ${BORDER_RADIUS_PX}px;
            border-bottom-right-radius: ${BORDER_RADIUS_PX}px;
        `} */

    /* .VISIBLE_WORKSHOP_SERVICE_ROW:nth-last-of-type {
        border-top-left-radius: ${BORDER_RADIUS_PX}px;
        border-top-right-radius: ${BORDER_RADIUS_PX}px;
    } */

    &:last-child {
        border-bottom: 0;
    }

    ${(p) =>
        p.$hidden &&
        css`
            display: none;
        `}

    ${(p) =>
        p.$bulky &&
        css`
            padding: 16px;
            background: ${(p) => p.theme.palette.greyish.main};
            border-bottom: 0;
            margin-bottom: 8px;
            border-radius: 15px;

            ${WorkshopServiceTitle} {
                font-weight: 500;
                font-size: 16px;
            }
        `}


    ${(p) => p.theme.breakpoints.down("md")} {
        border-color: transparent;

        ${WorkshopServiceRowItemWrap} {
            align-items: end;
        }

        ${SelectButton} {
            width: 48px;
            min-width: 48px;
            min-height: 32px;
            padding: unset;
        }

        ${ServiceImg} {
            margin-right: 8px;
            width: 40px;
            height: 40px;
        }

        ${RecommendationText} {
            font-size: 10px;
        }

        ${Price} {
            justify-content: end;
        }

        ${(p) =>
            !p.$bulky &&
            css`
                padding: 8px;
            `}

        ${(p) =>
            p.$bulky &&
            css`
                padding: 16px 8px;
                margin-bottom: 8px;
            `}
    }

    ${(p) => p.theme.breakpoints.down(350)} {
        ${WorkshopServiceTitle}, ${WorkshopServiceSubtitle} {
            font-size: 14px;
        }
        ${Price} {
            font-size: 14px;
            text-wrap: wrap;
        }

        ${SelectButton} {
            width: 32px;
            min-width: 32px;
        }
    }
`;
