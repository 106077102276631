import {
    PriceObj,
    ServiceDataForWorkshopPriceNDuration,
} from "@Shared/types/types";
import {
    HourCosts,
    ServicePriceType,
} from "@FEShared/graphql/generated/graphql";
import priceObjToPriceStringObj from "./priceObjToPriceStringObj";
import { transStringFunctionalFE } from "@FEShared/i18n";
import transCommonTranslatable from "./transCommonTranslatable";
import getServicePriceObj from "./getServicePriceObj";
import getServiceDurationMins from "../../Shared/util/getServiceDurationMins";
import pickHourCost from "@Shared/util/pickHourCost";
import lMean from "lodash/mean";

const PRICE_TYPES_ORDER = [
    ServicePriceType.Fixed,
    ServicePriceType.FromTo,
    ServicePriceType.From,
    ServicePriceType.Hourly,
];

export type ServicesPriceNDuration = {
    servicesPrice: {
        value: number;
        text: string;
    };
    duration: {
        mins: number;
        text: string;
    };
    partsPrice: {
        value: number;
        text: string;
    };
    totalPrice: {
        value: number;
        text: string;
    };
    priceObj: PriceObj;
};

function minsToString(mins: number, isFromDuration?: boolean): string {
    const hours = Math.max(Math.round(mins / 30) / 2, 0.5);
    return `${hours}${isFromDuration ? "+" : ""} ${transCommonTranslatable(
        "HOUR_SHORT"
    )}`;
}

export default function getServicesPriceNDuration(
    services: ServiceDataForWorkshopPriceNDuration[],
    hourCost: number,
    hourCosts: HourCosts | undefined | null,
    /* showAverage for from-to prices */
    showAverage?: boolean,
    noUnits?: boolean
): ServicesPriceNDuration {
    const allServicesPriceObj: PriceObj = {
        price: 0,
        fixedPrice: 0,
        fromPrice: 0,
        toPrice: 0,
        type: ServicePriceType.Fixed,
        hourlyPrice: 0,
        defaultUnit: undefined,
        partsPrice: 0,
    };
    let allServicesDurationMins = 0;

    const hourlyPrices = services.map((s) =>
        pickHourCost({
            workshop: {
                hourCost,
                hourCosts,
            },
            service: s,
        })
    );

    allServicesPriceObj.hourlyPrice = lMean(hourlyPrices);

    services.forEach((service) => {
        const thisServiceHourCost = pickHourCost({
            workshop: {
                hourCost,
                hourCosts,
            },
            service,
        });

        const servicePriceObj = getServicePriceObj(
            service,
            thisServiceHourCost,
            showAverage
        );
        const serviceDurationMins = getServiceDurationMins(service);

        // all price picking logic is in getServicePriceObj. Allow this to just sum everything.
        allServicesPriceObj.price += servicePriceObj.price;
        allServicesPriceObj.fixedPrice += servicePriceObj.fixedPrice;
        allServicesPriceObj.fromPrice += servicePriceObj.fromPrice;
        allServicesPriceObj.toPrice += servicePriceObj.toPrice;
        allServicesPriceObj.partsPrice += servicePriceObj.partsPrice || 0;

        if (
            PRICE_TYPES_ORDER.indexOf(servicePriceObj.type) >
            PRICE_TYPES_ORDER.indexOf(allServicesPriceObj.type)
        ) {
            // price type can only worsen. It can't get better;
            allServicesPriceObj.type = servicePriceObj.type;
        }

        allServicesDurationMins += serviceDurationMins;
    });

    allServicesPriceObj.defaultUnit =
        services.length === 1 && services[0].type.defaultUnit
            ? transStringFunctionalFE(services[0].type.defaultUnit)
            : undefined;

    const priceStrings = priceObjToPriceStringObj({
        priceObj: allServicesPriceObj,
        showAverage,
        noUnits,
    });

    return {
        servicesPrice: {
            value: allServicesPriceObj.price,
            text: priceStrings.servicesPriceString,
        },
        partsPrice: {
            value: allServicesPriceObj.partsPrice,
            text:
                allServicesPriceObj.partsPrice > 0
                    ? `±${window._COUNTRY_META.currencySymbol}${allServicesPriceObj.partsPrice}`
                    : ``,
        },
        totalPrice: {
            value: allServicesPriceObj.partsPrice + allServicesPriceObj.price,
            text: priceStrings.totalPriceString,
        },
        duration: {
            mins: allServicesDurationMins,
            text: minsToString(
                allServicesDurationMins,
                allServicesPriceObj.type !== ServicePriceType.Fixed
            ),
        },
        priceObj: allServicesPriceObj,
    };
}
