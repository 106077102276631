export const countriesMeta = {
    af: { flag: "af", name: "Afghanistan" },
    al: { flag: "al", name: "Albania" },
    dz: { flag: "dz", name: "Algeria" },
    as: { flag: "as", name: "American Samoa" },
    ad: { flag: "ad", name: "Andorra" },
    ao: { flag: "ao", name: "Angola" },
    ai: { flag: "ai", name: "Anguilla" },
    aq: { flag: "aq", name: "Antarctica" },
    ag: { flag: "ag", name: "Antigua and Barbuda" },
    ar: { flag: "ar", name: "Argentina" },
    am: { flag: "am", name: "Armenia" },
    aw: { flag: "aw", name: "Aruba" },
    au: { flag: "au", name: "Australia" },
    at: { flag: "at", name: "Austria" },
    az: { flag: "az", name: "Azerbaijan" },
    bs: { flag: "bs", name: "Bahamas" },
    bh: { flag: "bh", name: "Bahrain" },
    bd: { flag: "bd", name: "Bangladesh" },
    bb: { flag: "bb", name: "Barbados" },
    by: { flag: "by", name: "Belarus" },
    be: { flag: "be", name: "Belgium" },
    bz: { flag: "bz", name: "Belize" },
    bj: { flag: "bj", name: "Benin" },
    bm: { flag: "bm", name: "Bermuda" },
    bt: { flag: "bt", name: "Bhutan" },
    bo: { flag: "bo", name: "Bolivia (Plurinational State of)" },
    bq: { flag: "bq", name: "Bonaire, Sint Eustatius and Saba" },
    ba: { flag: "ba", name: "Bosnia and Herzegovina" },
    bw: { flag: "bw", name: "Botswana" },
    bv: { flag: "bv", name: "Bouvet Island" },
    br: { flag: "br", name: "Brazil" },
    io: { flag: "io", name: "British Indian Ocean Territory" },
    bn: { flag: "bn", name: "Brunei Darussalam" },
    bg: { flag: "bg", name: "Bulgaria" },
    bf: { flag: "bf", name: "Burkina Faso" },
    bi: { flag: "bi", name: "Burundi" },
    cv: { flag: "cv", name: "Cabo Verde" },
    kh: { flag: "kh", name: "Cambodia" },
    cm: { flag: "cm", name: "Cameroon" },
    ca: { flag: "ca", name: "Canada" },
    ky: { flag: "ky", name: "Cayman Islands" },
    cf: { flag: "cf", name: "Central African Republic" },
    td: { flag: "td", name: "Chad" },
    cl: { flag: "cl", name: "Chile" },
    cn: { flag: "cn", name: "China" },
    cx: { flag: "cx", name: "Christmas Island" },
    cc: { flag: "cc", name: "Cocos (Keeling) Islands" },
    co: { flag: "co", name: "Colombia" },
    km: { flag: "km", name: "Comoros" },
    cd: { flag: "cd", name: "Congo (the Democratic Republic)" },
    cg: { flag: "cg", name: "Congo" },
    ck: { flag: "ck", name: "Cook Islands" },
    cr: { flag: "cr", name: "Costa Rica" },
    hr: { flag: "hr", name: "Croatia" },
    cu: { flag: "cu", name: "Cuba" },
    cw: { flag: "cw", name: "Curaçao" },
    cy: { flag: "cy", name: "Cyprus" },
    cz: { flag: "cz", name: "Czechia" },
    ci: { flag: "ci", name: "Côte d'Ivoire" },
    dk: { flag: "dk", name: "Denmark" },
    dj: { flag: "dj", name: "Djibouti" },
    dm: { flag: "dm", name: "Dominica" },
    do: { flag: "do", name: "Dominican Republic" },
    ec: { flag: "ec", name: "Ecuador" },
    eg: { flag: "eg", name: "Egypt" },
    sv: { flag: "sv", name: "El Salvador" },
    gq: { flag: "gq", name: "Equatorial Guinea" },
    er: { flag: "er", name: "Eritrea" },
    ee: { flag: "ee", name: "Estonia" },
    sz: { flag: "sz", name: "Eswatini" },
    et: { flag: "et", name: "Ethiopia" },
    fk: { flag: "fk", name: "Falkland Islands" },
    fo: { flag: "fo", name: "Faroe Islands" },
    fj: { flag: "fj", name: "Fiji" },
    fi: { flag: "fi", name: "Finland" },
    fr: { flag: "fr", name: "France" },
    gf: { flag: "gf", name: "French Guiana" },
    pf: { flag: "pf", name: "French Polynesia" },
    tf: { flag: "tf", name: "French Southern Territories" },
    ga: { flag: "ga", name: "Gabon" },
    gm: { flag: "gm", name: "Gambia" },
    ge: { flag: "ge", name: "Georgia" },
    de: { flag: "de", name: "Germany" },
    gh: { flag: "gh", name: "Ghana" },
    gi: { flag: "gi", name: "Gibraltar" },
    gr: { flag: "gr", name: "Greece" },
    gl: { flag: "gl", name: "Greenland" },
    gd: { flag: "gd", name: "Grenada" },
    gp: { flag: "gp", name: "Guadeloupe" },
    gu: { flag: "gu", name: "Guam" },
    gt: { flag: "gt", name: "Guatemala" },
    gg: { flag: "gg", name: "Guernsey" },
    gn: { flag: "gn", name: "Guinea" },
    gw: { flag: "gw", name: "Guinea-Bissau" },
    gy: { flag: "gy", name: "Guyana" },
    ht: { flag: "ht", name: "Haiti" },
    hm: { flag: "hm", name: "Heard Island and McDonald Islands" },
    va: { flag: "va", name: "Holy See" },
    hn: { flag: "hn", name: "Honduras" },
    hk: { flag: "hk", name: "Hong Kong" },
    hu: { flag: "hu", name: "Hungary" },
    is: { flag: "is", name: "Iceland" },
    in: { flag: "in", name: "India" },
    id: { flag: "id", name: "Indonesia" },
    ir: { flag: "ir", name: "Iran" },
    iq: { flag: "iq", name: "Iraq" },
    ie: { flag: "ie", name: "Ireland" },
    im: { flag: "im", name: "Isle of Man" },
    il: { flag: "il", name: "Israel" },
    it: { flag: "it", name: "Italy" },
    jm: { flag: "jm", name: "Jamaica" },
    jp: { flag: "jp", name: "Japan" },
    je: { flag: "je", name: "Jersey" },
    jo: { flag: "jo", name: "Jordan" },
    kz: { flag: "kz", name: "Kazakhstan" },
    ke: { flag: "ke", name: "Kenya" },
    ki: { flag: "ki", name: "Kiribati" },
    kp: { flag: "kp", name: "North Korea" },
    kr: { flag: "kr", name: "South Korea" },
    kw: { flag: "kw", name: "Kuwait" },
    kg: { flag: "kg", name: "Kyrgyzstan" },
    la: { flag: "la", name: "Lao People's Democratic Republic" },
    lv: { flag: "lv", name: "Latvia" },
    lb: { flag: "lb", name: "Lebanon" },
    ls: { flag: "ls", name: "Lesotho" },
    lr: { flag: "lr", name: "Liberia" },
    ly: { flag: "ly", name: "Libya" },
    li: { flag: "li", name: "Liechtenstein" },
    lt: { flag: "lt", name: "Lithuania" },
    lu: { flag: "lu", name: "Luxembourg" },
    mo: { flag: "mo", name: "Macao" },
    mg: { flag: "mg", name: "Madagascar" },
    mw: { flag: "mw", name: "Malawi" },
    my: { flag: "my", name: "Malaysia" },
    mv: { flag: "mv", name: "Maldives" },
    ml: { flag: "ml", name: "Mali" },
    mt: { flag: "mt", name: "Malta" },
    mh: { flag: "mh", name: "Marshall Islands" },
    mq: { flag: "mq", name: "Martinique" },
    mr: { flag: "mr", name: "Mauritania" },
    mu: { flag: "mu", name: "Mauritius" },
    yt: { flag: "yt", name: "Mayotte" },
    mx: { flag: "mx", name: "Mexico" },
    fm: { flag: "fm", name: "Micronesia" },
    md: { flag: "md", name: "Moldova" },
    mc: { flag: "mc", name: "Monaco" },
    mn: { flag: "mn", name: "Mongolia" },
    me: { flag: "me", name: "Montenegro" },
    ms: { flag: "ms", name: "Montserrat" },
    ma: { flag: "ma", name: "Morocco" },
    mz: { flag: "mz", name: "Mozambique" },
    mm: { flag: "mm", name: "Myanmar" },
    na: { flag: "na", name: "Namibia" },
    nr: { flag: "nr", name: "Nauru" },
    np: { flag: "np", name: "Nepal" },
    nl: { flag: "nl", name: "Netherlands" },
    nc: { flag: "nc", name: "New Caledonia" },
    nz: { flag: "nz", name: "New Zealand" },
    ni: { flag: "ni", name: "Nicaragua" },
    ne: { flag: "ne", name: "Niger" },
    ng: { flag: "ng", name: "Nigeria" },
    nu: { flag: "nu", name: "Niue" },
    nf: { flag: "nf", name: "Norfolk Island" },
    mp: { flag: "mp", name: "Northern Mariana Islands" },
    no: { flag: "no", name: "Norway" },
    om: { flag: "om", name: "Oman" },
    pk: { flag: "pk", name: "Pakistan" },
    pw: { flag: "pw", name: "Palau" },
    ps: { flag: "ps", name: "Palestine, State of" },
    pa: { flag: "pa", name: "Panama" },
    pg: { flag: "pg", name: "Papua New Guinea" },
    py: { flag: "py", name: "Paraguay" },
    pe: { flag: "pe", name: "Peru" },
    ph: { flag: "ph", name: "Philippines" },
    pn: { flag: "pn", name: "Pitcairn" },
    pl: { flag: "pl", name: "Poland" },
    pt: { flag: "pt", name: "Portugal" },
    pr: { flag: "pr", name: "Puerto Rico" },
    qa: { flag: "qa", name: "Qatar" },
    mk: { flag: "mk", name: "Republic of North Macedonia" },
    ro: { flag: "ro", name: "Romania" },
    ru: { flag: "ru", name: "Russian Federation" },
    rw: { flag: "rw", name: "Rwanda" },
    re: { flag: "re", name: "Réunion" },
    bl: { flag: "bl", name: "Saint Barthélemy" },
    sh: { flag: "sh", name: "Saint Helena, Ascension and Tristan da Cunha" },
    kn: { flag: "kn", name: "Saint Kitts and Nevis" },
    lc: { flag: "lc", name: "Saint Lucia" },
    mf: { flag: "mf", name: "Saint Martin" },
    pm: { flag: "pm", name: "Saint Pierre and Miquelon" },
    vc: { flag: "vc", name: "Saint Vincent and the Grenadines" },
    ws: { flag: "ws", name: "Samoa" },
    sm: { flag: "sm", name: "San Marino" },
    st: { flag: "st", name: "Sao Tome and Principe" },
    sa: { flag: "sa", name: "Saudi Arabia" },
    sn: { flag: "sn", name: "Senegal" },
    rs: { flag: "rs", name: "Serbia" },
    sc: { flag: "sc", name: "Seychelles" },
    sl: { flag: "sl", name: "Sierra Leone" },
    sg: { flag: "sg", name: "Singapore" },
    sx: { flag: "sx", name: "Sint Maarten" },
    sk: { flag: "sk", name: "Slovakia" },
    si: { flag: "si", name: "Slovenia" },
    sb: { flag: "sb", name: "Solomon Islands" },
    so: { flag: "so", name: "Somalia" },
    za: { flag: "za", name: "South Africa" },
    gs: { flag: "gs", name: "South Georgia and the South Sandwich Islands" },
    ss: { flag: "ss", name: "South Sudan" },
    es: { flag: "es", name: "Spain" },
    lk: { flag: "lk", name: "Sri Lanka" },
    sd: { flag: "sd", name: "Sudan" },
    sr: { flag: "sr", name: "Suriname" },
    sj: { flag: "sj", name: "Svalbard and Jan Mayen" },
    se: { flag: "se", name: "Sweden" },
    ch: { flag: "ch", name: "Switzerland" },
    sy: { flag: "sy", name: "Syrian Arab Republic" },
    tw: { flag: "tw", name: "Taiwan" },
    tj: { flag: "tj", name: "Tajikistan" },
    tz: { flag: "tz", name: "Tanzania, United Republic of" },
    th: { flag: "th", name: "Thailand" },
    tl: { flag: "tl", name: "Timor-Leste" },
    tg: { flag: "tg", name: "Togo" },
    tk: { flag: "tk", name: "Tokelau" },
    to: { flag: "to", name: "Tonga" },
    tt: { flag: "tt", name: "Trinidad and Tobago" },
    tn: { flag: "tn", name: "Tunisia" },
    tr: { flag: "tr", name: "Turkey" },
    tm: { flag: "tm", name: "Turkmenistan" },
    tc: { flag: "tc", name: "Turks and Caicos Islands" },
    tv: { flag: "tv", name: "Tuvalu" },
    ug: { flag: "ug", name: "Uganda" },
    ua: { flag: "ua", name: "Ukraine" },
    ae: { flag: "ae", name: "United Arab Emirates" },
    gb: { flag: "gb", name: "United Kingdom" },
    // um: { flag: "um", name: "United States Minor Outlying Islands" },
    // us: { flag: "us", name: "United States of America" },
    uy: { flag: "uy", name: "Uruguay" },
    uz: { flag: "uz", name: "Uzbekistan" },
    vu: { flag: "vu", name: "Vanuatu" },
    ve: { flag: "ve", name: "Venezuela" },
    vn: { flag: "vn", name: "Vietnam " },
    vg: { flag: "vg", name: "Virgin Islands (British)" },
    vi: { flag: "vi", name: "Virgin Islands (U.S.)" },
    wf: { flag: "wf", name: "Wallis and Futuna" },
    eh: { flag: "eh", name: "Western Sahara" },
    ye: { flag: "ye", name: "Yemen" },
    zm: { flag: "zm", name: "Zambia" },
    zw: { flag: "zw", name: "Zimbabwe" },
    ax: { flag: "ax", name: "Åland Islands" },
};

export type countries = keyof typeof countriesMeta;

export const countries = Object.keys(countriesMeta) as countries[];
