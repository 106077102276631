import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";

/**
 * Our mobile resolution starts at 900px resolution, default MUI "md" breakpoint
 */
const useIsMobile = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return isMobile;
};
export default useIsMobile;
