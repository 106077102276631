import React from "react";
import { Router } from "react-router-dom";
import * as S from "./App.styled";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import muiTheme from "@FEClient/styled/theme";
import ScrollToTop from "FEShared/utils/ScrollToTop";
import Header from "./views/commonComps/Header/Header";
import PagesRoutes from "./views/commonComps/PagesRoutes/PagesRoutes";
import Footer from "./views/commonComps/Footer/Footer";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "FEShared/styled/global.styled";
import { Provider as UrqlProvider } from "urql";
import { ClientPage } from "@Shared/util/clientPagesMeta";
import useStore, { history, urqlClient } from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import UseCookieConsent from "./logic/hooks/useCookieConsent";
import showToast from "@FEShared/utils/showToast";
import LoaderScreen from "@FEShared/components/LoaderScreen/LoaderScreen";
import Sidebar from "./views/commonComps/Sidebar/Sidebar";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import { runInAction } from "mobx";
import {
    dateFormatFullReadableBuildable,
    dateObjToString,
} from "@Shared/util/dateFormat";
import getCookie from "@FEShared/utils/getCookie";
import formatVehicleDescriptionFE from "@FEShared/utils/formatVehicleDescriptionFE";
import { UrlPathnameParser } from "./views/pages/Search/Search.logic";
import { I18nLoader, i18nService } from "@FEShared/i18n";
import Country from "@Shared/types/enums/Country";
import ErrorBoundary from "@FEShared/components/ErrorBoundary/ErrorBoundary";

let chatlioInited = false;
let searchPagePropsResetted = false;

const WebContent: React.FC = observer(() => {
    const GS = useStore();
    const chatlioWidgetRef = React.useRef<HTMLDivElement>(null);

    // tbd should just move showFooter/showHeader into clientPageMeta as a type of "ALL" | "MOBILE" | "DESKTOP"
    const showFooter =
        !GS.refFrom &&
        GS.activePage &&
        ![
            ClientPage.SEARCH,
            ClientPage.ALTERNATIVES_SEARCH,
            ClientPage.RESERVATION_CONFIRM,
            ClientPage.ALTERNATIVE_RESERVATION_CONFIRMATION,
            ClientPage.INTERNAL_ORDER_REVIEW,
            ClientPage.ADMIN_PROMO,
        ].includes(GS.activePage);

    React.useEffect(() => {
        // this hook must be placed here, instead of `Search` page, to make sure its executed only once and only then the search page was visited directly via link and not via react router.
        // this is mostly needed to avoid google bot/organic traffic, that visits our "saved" search pages (e.g /autoservisai/vilnius/visos-paslaugos/bmw) to prevent showing services or car brands that were saved in localStorage earlier. Otherwise it might be bad UX or google bot will find our pages confusing/duplicate content, etc.
        // If this was instead put into Search.tsx page root, this would run everytime user vists Search page for the first time, which would reset his selected search props from the home page, which is not what we want.

        if (searchPagePropsResetted) return;
        if (!GS.activePage) return;
        searchPagePropsResetted = true;
        if (GS.activePage !== ClientPage.SEARCH) return;

        const pathname = location.pathname;
        const parsedParams = UrlPathnameParser.pathnameToParams(pathname);

        runInAction(() => {
            if (!parsedParams.serviceDefinitionID) {
                GS.searchState.selectedServicesDefinitionsIDs = [];
            }
            if (!parsedParams.carBrand) {
                GS.searchState.carDataArr = [];
            }
        });
    }, [GS.activePage]); //eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (
            window.location.search.includes(
                QUERY_PARAMS.TEST_MODE_ENABLE_QUERY_PARAM
            )
        ) {
            showToast.success("Test workshops enabled");
            localStorage.setItem(
                QUERY_PARAMS.TEST_MODE_ENABLE_QUERY_PARAM,
                "true"
            );
        }

        if (window.location.search.toLowerCase().includes("kurohudas")) {
            runInAction(() => {
                GS.refFrom = "KUROHUDAS";
            });
        }

        if (window.location.search.toLowerCase().includes("lang-en")) {
            i18nService.setLanguage({ languageCode: "en" });
        }
    }, []);

    React.useEffect(() => {
        const clid = window.location.search;

        runInAction(() => {
            const currentReferrer = document.referrer;

            if (
                GS.visitHistory.some(([dateString, referrerUrl, clid]) => {
                    const date = new Date(dateString);
                    if (!date || isNaN(date.getTime())) {
                        return console.warn(`invalid date: ${date}`);
                    }

                    const HOUR_IN_MS = 1 * 60 * 60 * 1000;
                    if (
                        referrerUrl === currentReferrer &&
                        clid === clid &&
                        date.getTime() >= new Date().getTime() - HOUR_IN_MS
                    ) {
                        return true;
                    }
                })
            ) {
                return;
            }

            GS.visitHistory = [
                ...GS.visitHistory,
                [
                    dateFormatFullReadableBuildable(new Date()),
                    document.referrer,
                    clid,
                ],
            ];

            // limit logs to 100 size.
            if (GS.visitHistory.length >= 10) GS.visitHistory.shift();
        });
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (i18nService.initialized.state === "pending" || chatlioInited)
            return;
        chatlioInited = true;

        const chatlioWidget = document.createElement("chatlio-widget");

        chatlioWidget.setAttribute(
            "widgetid",
            window._COUNTRY === Country.LT
                ? "c4a9e3df-17e0-4ce5-70f4-e370f2dfaa6d"
                : "7ba8dd49-b728-4c0a-74f9-495c6c51e577"
        );

        // Append it to the ref'd div
        if (chatlioWidgetRef.current) {
            chatlioWidgetRef.current.appendChild(chatlioWidget);
        }

        document.addEventListener("chatlio.expanded", (_e) => {
            window._chatlio?.identify?.(getCookie("_ga") || Math.random(), {
                email: GS.reservationConfirmationPageState.emailInputVal,
                phoneNumber: GS.reservationConfirmationPageState.phoneInputVal,
                city: GS.searchState.city,
                car: formatVehicleDescriptionFE(GS.searchState.carData),
                arrivalDate: dateObjToString(GS.searchState.date.obj),
                selectedServices:
                    GS.searchState.selectedServicesTransNames.join(", "),
                selectedWorkshop: [
                    GS.reservationConfirmationPageState.selectedWorkshop?.ID,
                    GS.reservationConfirmationPageState.selectedWorkshop?.name,
                    GS.reservationConfirmationPageState.selectedWorkshop
                        ?.address,
                ]
                    .filter(Boolean)
                    .join(", "),
            });
        });
    }, [i18nService.initialized.state]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <I18nLoader>
            <UrqlProvider value={urqlClient}>
                <MUIThemeProvider theme={muiTheme}>
                    <ThemeProvider theme={muiTheme}>
                        <CssBaseline />
                        <GlobalStyles $disableScroll={!GS.bodyScrollEnabled} />
                        <ToastContainer />
                        <ErrorBoundary>
                            <LoaderScreen show={GS.showLoadingScreen} />
                            <S.AllContentContainer>
                                <Header />
                                <Sidebar />
                                <S.PageContainer>
                                    <PagesRoutes />
                                </S.PageContainer>
                                {showFooter && <Footer />}
                                <div ref={chatlioWidgetRef}></div>
                            </S.AllContentContainer>
                        </ErrorBoundary>
                    </ThemeProvider>
                </MUIThemeProvider>
            </UrqlProvider>
        </I18nLoader>
    );
});

const WebContainer: React.FC<{}> = () => {
    return (
        <>
            <UseCookieConsent />
            <Router history={history}>
                <WebContent />
                <ScrollToTop />
            </Router>
        </>
    );
};

export default WebContainer;
