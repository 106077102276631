import { useMediaQuery } from "@mui/material";

export const SEARCH_INPUTS_MOBILE_MODE_FROM_PX = 1230;

export const useSearchInputsMobileMode = () => {
    const isMobile = useMediaQuery(
        `(max-width: ${SEARCH_INPUTS_MOBILE_MODE_FROM_PX}px)`
    );
    return isMobile;
};

/** Mostly you should use useIsMobile hook in react components. This should only be used in non react code. */
export function getIsSearchInputsMobileMode(): boolean {
    return (
        (window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth) < SEARCH_INPUTS_MOBILE_MODE_FROM_PX
    );
}
