import React from "react";
import * as S from "./Logo.styled";
import { observer } from "mobx-react-lite";
import Country from "@Shared/types/enums/Country";
import Icon from "../Icon/Icon";

// maybe add white prop if will be reused everywhere
const Logo = observer<{
    className?: string;
    white?: boolean;
    fontSize?: number;
}>((p) => {
    return (
        <S.LogoWrapper className={p.className} $white={p.white}>
            {window._COUNTRY === Country.LT ? (
                <>
                    <S.LogoMark className="icon-logo-symbol"></S.LogoMark>
                    <Icon className="icon-text-logo" />
                </>
            ) : (
                <Icon
                    className="icon-repairpunk-logo"
                    fontSize={p.fontSize || 48}
                />
            )}
        </S.LogoWrapper>
    );
    // return <S.Logo className="icon-full-logo" />;
});

export default Logo;
