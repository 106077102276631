import Country from "@Shared/types/enums/Country";
import countryToMeta from "@Shared/util/countryToMeta";
import { DateTime } from "luxon";

export default function dateTimeWithTimeZone(
    p:
        | {
              country: Country;
              dateOrDateTime?: DateTime | Date;
          }
        | {
              /* America/Toronto */
              timeZone: string;
              dateOrDateTime?: DateTime | Date;
          }
): DateTime {
    const existingDateOrDT =
        p.dateOrDateTime instanceof Date
            ? DateTime.fromJSDate(p.dateOrDateTime)
            : p.dateOrDateTime;
    const DT: DateTime = existingDateOrDT || DateTime.now();
    let timeZone: string;

    if ("country" in p) {
        const countryMeta = countryToMeta(p.country);
        timeZone = countryMeta.defaultTimezone;
    } else if ("timeZone" in p) {
        timeZone = p.timeZone;
    } else {
        throw new Error(`Invalid p: ${JSON.stringify(p)}`);
    }

    const dtWithTz = DT.setZone(timeZone);
    return dtWithTz;
}
