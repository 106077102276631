import React from "react";
import * as S from "./ExpandedMapPoint.styled";
import useStore from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import { WorkshopOmitted } from "@FEClient/views/pages/Search/Search.types";
import SpecializedBadge from "@FEClient/views/pages/Search/SpecializedBadge/SpecializedBadge";
import Text from "@FEShared/components/UI/Text/Text";
import Box from "@FEShared/components/UI/Box/Box";
import Icon from "@FEShared/components/UI/Icon/Icon";
import { TransMsg } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";
import { dateToMonthHourPrettyText } from "@FEShared/utils/dateFormatFE";

export const EXPANDED_MAP_POINT_CLASSNAME_IDENTIFIER = "ExpandedMapPoint";

interface P {
    mapItem: WorkshopOmitted;
    handleClose: () => void;
    onClick?: () => void;
    specializedString?: string;
}
const ExpandedMapPoint: React.FC<P> = observer((p) => {
    const GS = useStore();
    const SPS = GS.searchPageState;

    const handleContainerClick = () => {
        GS.searchPageState.onWorkshopChoose({
            ID: p.mapItem.ID,
            name: p.mapItem.name,
        });
    };

    const priceNDurationString =
        GS.getSelectedServicesPriceNDurationForWorkshop(p.mapItem, true);

    const newArrivalTime = GS.searchPageState.altFlow.newWorkshopSuggestedTime(
        p.mapItem.ID
    );

    return (
        <S.ExpandedMapPointContainer
            className={EXPANDED_MAP_POINT_CLASSNAME_IDENTIFIER}
            onClick={() => handleContainerClick()}
        >
            <S.Img src={p.mapItem.logoURL || "#"} />
            <S.Wrapper>
                <S.Header>
                    <S.Title>{p.mapItem.name}</S.Title>
                    <S.StarsWrapper>
                        <S.ReviewScore>
                            {(p.mapItem.reviewRatingGmap || 5).toFixed(1)}
                        </S.ReviewScore>
                        <S.StarIcon className="icon-star-full" />(
                        {p.mapItem.reviewCountGmap || 1})
                    </S.StarsWrapper>
                </S.Header>
                <S.Address>
                    {[p.mapItem.microdistrict, p.mapItem.address]
                        .filter(Boolean)
                        .join(", ")}
                </S.Address>
                {p.specializedString && (
                    <SpecializedBadge>{p.specializedString}</SpecializedBadge>
                )}
                {newArrivalTime && (
                    <Text
                        color={newArrivalTime.new ? "warning.main" : "grey"}
                        fontSize={12}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <Text
                            mr={0.5}
                            component="i"
                            className="icon-clock"
                            fontSize="inherit"
                        />
                        {newArrivalTime.text}
                    </Text>
                )}
                {p.mapItem.earliestAvailableDate &&
                    !GS.searchPageState.altFlow.isAlt && (
                        <Text fontSize={12}>
                            <Icon
                                mr={0.5}
                                className="icon-clock-rotate"
                                fontSize={"10px!important"}
                            />
                            <TransMsg
                                default={"Artimiausias laikas:"}
                                id="5kahjxYw"
                            />{" "}
                            <Text semiBold span variant="inherit">
                                {dateToMonthHourPrettyText(
                                    new Date(p.mapItem.earliestAvailableDate)
                                )}
                            </Text>
                        </Text>
                    )}
                {p.mapItem.badgeOrdersCount ? (
                    <Box
                        padding={0.5}
                        bgcolor={"#f0f2f9"}
                        fontSize={12}
                        borderRadius={"5px"}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "flex-start",
                        }}
                    >
                        <Icon
                            color={"#40BD81"}
                            mr={0.5}
                            className="icon-chart-up"
                            fontSize={"10px!important"}
                        />
                        <TransMsg
                            default={"{ordersCount}+ užsakymų per 30d."}
                            data={{ ordersCount: p.mapItem.badgeOrdersCount }}
                            id="LZIQhgQK"
                        />
                    </Box>
                ) : undefined}
                <S.Price>
                    {priceNDurationString ? (
                        <S.PriceLabel>
                            {priceNDurationString.servicesPrice.text}
                        </S.PriceLabel>
                    ) : (
                        <>
                            <S.PriceLabel>
                                {window._COUNTRY_META.currencySymbol}
                                {p.mapItem.hourCost}
                            </S.PriceLabel>
                            <S.PriceText>
                                {"/"}
                                {transCommonTranslatable("HOUR_SHORT")}
                            </S.PriceText>
                        </>
                    )}
                </S.Price>
            </S.Wrapper>
            <S.BottomBtn fullWidth>{SPS.workshopBtnText()}</S.BottomBtn>
        </S.ExpandedMapPointContainer>
    );
});

export default ExpandedMapPoint;
