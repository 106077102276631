import React from "react";
import SHIELD_IMG from "./assets/shield.png";
import { runInAction } from "mobx";
import useStore from "@FEClient/logic/store";
import * as S from "./WarrantyBox.styled";
import { SxProps } from "@mui/material";
import WarrantyModal from "@FEClient/views/pages/Warranty/WarrantyModal/WarrantyModal";
import { observer } from "mobx-react-lite";
import Box from "@FEShared/components/UI/Box/Box";
import { TransMsg } from "@FEShared/i18n";

const WarrantyBox: React.FC<{
    sx?: SxProps;
    forceMobileFlow?: boolean;
}> = observer((p) => {
    const GS = useStore();

    return (
        <>
            <WarrantyModal
                isOpen={GS.workshopPageState.warrantyModalOpen}
                onClose={() =>
                    runInAction(() => {
                        GS.workshopPageState.warrantyModalOpen = false;
                    })
                }
            />
            <S.WarrantyBoxContainer
                $forceMobileFlow={p.forceMobileFlow}
                sx={p.sx}
                onClick={() =>
                    runInAction(() => {
                        GS.workshopPageState.warrantyModalOpen = true;
                    })
                }
            >
                <S.ShieldImg src={SHIELD_IMG} />
                <Box alignVertical="center" vertical>
                    <S.Title>
                        <TransMsg
                            default={"100% Darbų garantija"}
                            id="jXoUkcPk"
                        />
                    </S.Title>
                    <S.PointsWrapper>
                        <S.ListText>
                            <S.Icon className="icon-checkmark-circle" />
                            <TransMsg
                                default={"{months} mėnesiai arba {mileage}"}
                                data={{
                                    months: window._COUNTRY_META.warranty
                                        .durationMonths,
                                    mileage:
                                        window._COUNTRY_META.warranty.mileage,
                                }}
                                id="qvwp5eyV"
                            />
                        </S.ListText>
                        <S.ListText>
                            <S.Icon className="icon-checkmark-circle" />
                            <TransMsg
                                default={"Techninio advokato apsauga"}
                                id="mV79oJkA"
                            />
                        </S.ListText>
                    </S.PointsWrapper>
                </Box>
            </S.WarrantyBoxContainer>
        </>
    );
});

export default WarrantyBox;
