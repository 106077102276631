import { Workshop } from "@FEClient/types/types";
import { ServicePriceType } from "@FEShared/graphql/generated/graphql";

const MULTIPLIERS_FOR_PRICE_TYPES = {
    [ServicePriceType.Fixed]: 1.15,
    [ServicePriceType.FromTo]: 1.1,
    [ServicePriceType.From]: 1,
    [ServicePriceType.Hourly]: 1,
};

const MINIMUM_REVIEWS_REQUIRED = 50;

// bayesian average
export function calcBayesianAverage(
    workshop: Pick<Workshop, "reviewCountGmap" | "reviewRatingGmap" | "name">,
    avgWorkshopRating: number
): number {
    const rating = workshop.reviewRatingGmap || 0;
    const ratingCount = workshop.reviewCountGmap || 0;
    if (!rating) return 0;

    // Calculate and return the weighted rating
    const bayAvg =
        (ratingCount * rating + MINIMUM_REVIEWS_REQUIRED * avgWorkshopRating) /
        (ratingCount + MINIMUM_REVIEWS_REQUIRED);
    return bayAvg;
}

export function calcBestBangForPriceRating(
    workshop: Pick<Workshop, "reviewCountGmap" | "reviewRatingGmap" | "name">,
    avgWorkshopsRating: number,
    priceObj: {
        price: number;
        type: ServicePriceType;
    }
): number {
    const bayesianRating = calcBayesianAverage(workshop, avgWorkshopsRating);
    const bestPriceForRating = bayesianRating / priceObj.price;
    return bestPriceForRating * MULTIPLIERS_FOR_PRICE_TYPES[priceObj.type];
}
