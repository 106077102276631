import { InternalLink } from "@FEShared/components/InternalLink/InternalLink.styled";
import Button from "@FEShared/components/UI/Button/Button";
import { absoluteHorizontalCenter } from "FEShared/styled/util.styled";
import { Typography } from "@mui/material";
import styled from "styled-components";
import Box from "@mui/material/Box/Box";
import { OVERLAY_CONTAINER_Z_INDEX } from "../../OverlayContainer/OverlayContainer";

export const Wrapper = styled("div")`
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
`;

export const Img = styled("img")`
    width: 100%;
    height: 130px;
    object-fit: cover;
`;

export const Address = styled(Typography)`
    font-size: 14px;
    margin-bottom: 4px;
    color: #727272;
`;

export const Price = styled("div")`
    display: flex;
    margin-top: 8px;
`;

export const PriceLabel = styled("span")`
    font-weight: 500;
    font-size: 18px;
    margin-right: 0px;
`;

export const PriceText = styled("span")`
    font-weight: 400;
    font-size: 18px;
`;

export const BtnLinkWrapper = styled(InternalLink)`
    width: 100%;
    padding: 0 15px;
`;

export const ReserveBtn = styled(Button).attrs({ size: "small" })`
    width: 100%;
`;

export const Header = styled("div")`
    display: flex;
    justify-content: space-between;
`;

export const Title = styled("span")`
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    display: flex;
`;

export const ReviewScore = styled("span")`
    font-weight: 500;
    font-size: 18px;
    margin-right: 4px;
`;

export const ExpandedMapPointContainer = styled(Box).attrs({
    sx: {
        boxShadow: 4,
    },
})`
    width: 335px;
    border-radius: 25px;
    background: white;
    overflow: hidden;
    z-index: ${OVERLAY_CONTAINER_Z_INDEX + 1};
    position: relative;
    display: block;
    color: inherit;
    position: absolute;
    bottom: calc(100% + 10px);
    cursor: pointer;

    ${absoluteHorizontalCenter}
`;

export const StarsWrapper = styled("div")`
    display: flex;
    align-items: center;
`;

export const StarIcon = styled("i")`
    margin: 0 2px;
    margin-top: -4px;
    font-size: 16px;
    color: #edb117;
`;

export const BottomBtn = styled(Button)`
    margin-top: 0;
    border-radius: unset;
    height: 43px;
`;
