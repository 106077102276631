export default function removeUndefinedOrNull<
    T extends Record<string, unknown>
>(obj: T): T {
    const result: Partial<T> = {};

    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            if (obj[prop] !== undefined && obj[prop] !== null) {
                result[prop] = obj[prop];
            }
        }
    }

    return result as T;
}
