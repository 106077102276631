import React from "react";
import { Box as MuiBox } from "@mui/material";
import { BoxProps } from "@mui/material/Box";
import { DefaultTheme, useTheme } from "styled-components";

const alignMap = {
    left: "flex-start",
    center: "center",
    right: "flex-end",
    "space-between": "space-between",
};

const alignVerticalMap = {
    top: "flex-start",
    center: "center",
    bottom: "flex-end",
};

export interface BoxP extends BoxProps {
    displayFlex?: boolean;
    vertical?: boolean;
    align?: keyof typeof alignMap;
    alignVertical?: keyof typeof alignVerticalMap;
    rounded?: boolean;
    href?: string;
    src?: string;
    target?: string;
    pointer?: boolean;
    rel?: string;
}
const Box = React.forwardRef<unknown, BoxP>(function Box(p, ref) {
    const {
        displayFlex: _1,
        vertical: _2,
        align: _3,
        alignVertical: _4,
        rounded: _5,
        pointer: _6,
        ...restBoxProps
    } = p;
    const { children: _c, ...propsWithoutChildren } = p;
    const theme = useTheme();
    const propModifiers = React.useMemo(
        () => getPropModifiers(propsWithoutChildren, theme),
        [propsWithoutChildren, theme]
    );

    return (
        <MuiBox
            {...restBoxProps}
            {...propModifiers}
            sx={[
                // according to https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop sx can be array
                ...(Array.isArray(p.sx) ? p.sx : [p.sx]),
                {
                    ...(propModifiers.sx || {}),
                },
            ]}
            ref={ref}
        />
    );
});

const getPropModifiers = (p: BoxP, theme: DefaultTheme): BoxProps => {
    const flexModifiers = ["displayFlex", "vertical", "align", "alignVertical"];
    const isFlex = Object.keys(p).some((key) => flexModifiers.includes(key));

    const modifiers: BoxProps = {};

    if (isFlex) {
        modifiers.display = "flex";
    }

    const flexDirection = p.vertical ? "column" : "row";
    modifiers.flexDirection = flexDirection;

    if (p.align) {
        modifiers[flexDirection === "row" ? "justifyContent" : "alignItems"] =
            alignMap[p.align];
    }

    if (p.alignVertical) {
        modifiers[
            flexDirection === "column" ? "justifyContent" : "alignItems"
        ] = alignVerticalMap[p.alignVertical];
    }

    if (p.rounded) {
        modifiers.borderRadius = `${theme.shape.borderRadius}px`;
    }

    if (p.pointer) {
        modifiers.sx = {
            cursor: "pointer",
        };
    }

    return modifiers;
};

export default Box;
