import { ServicePriceType } from "@FEShared/graphql/generated/graphql";
import { PriceObj } from "@Shared/types/types";
import transCommonTranslatable from "./transCommonTranslatable";

function defaultUnit(unit?: string | null, noUnits?: boolean): string {
    if (noUnits) return "";
    return unit ? ` / ${unit}` : "";
}

export type PriceStringObj = {
    servicesPriceString: string;
    partsPriceString?: string;
    totalPriceString: string;
};

export default function priceObjToPriceStringObj(p: {
    priceObj: PriceObj;
    /** Show average for from-to prices */
    showAverage?: boolean;
    noUnits?: boolean;
}): PriceStringObj {
    const currencySymbol = window._COUNTRY_META.currencySymbol;

    const partsPriceString = p.priceObj.partsPrice
        ? `±${window._COUNTRY_META.currencySymbol}${p.priceObj.partsPrice}`
        : undefined;

    if (p.priceObj.type === ServicePriceType.Fixed) {
        return {
            servicesPriceString: `${currencySymbol}${
                p.priceObj.price
            }${defaultUnit(p.priceObj.defaultUnit, p.noUnits)}`,
            partsPriceString,
            totalPriceString: p.priceObj.partsPrice
                ? `±${currencySymbol}${
                      p.priceObj.price + p.priceObj.partsPrice
                  }`
                : `${currencySymbol}${p.priceObj.price}`,
        };
    } else if (p.priceObj.type === ServicePriceType.FromTo) {
        const servicesPriceString = p.showAverage
            ? `±${currencySymbol}${
                  p.priceObj.fixedPrice +
                  Math.floor((p.priceObj.fromPrice + p.priceObj.toPrice) / 2)
              }${defaultUnit(p.priceObj.defaultUnit, p.noUnits)}`
            : `${currencySymbol}${
                  p.priceObj.fromPrice + p.priceObj.fixedPrice
              } - ${currencySymbol}${
                  p.priceObj.toPrice + p.priceObj.fixedPrice
              }${defaultUnit(p.priceObj.defaultUnit, p.noUnits)}`;

        const totalPriceString = p.showAverage
            ? `±${currencySymbol}${
                  p.priceObj.fixedPrice +
                  p.priceObj.partsPrice +
                  Math.floor((p.priceObj.fromPrice + p.priceObj.toPrice) / 2)
              }${defaultUnit(p.priceObj.defaultUnit, p.noUnits)}`
            : `${currencySymbol}${
                  p.priceObj.fromPrice +
                  p.priceObj.fixedPrice +
                  p.priceObj.partsPrice
              } - ${currencySymbol}${
                  p.priceObj.toPrice +
                  p.priceObj.fixedPrice +
                  p.priceObj.partsPrice
              }${defaultUnit(p.priceObj.defaultUnit, p.noUnits)}`;

        return {
            servicesPriceString,
            partsPriceString,
            totalPriceString,
        };
    } else if (p.priceObj.type === ServicePriceType.From) {
        const servicesPriceString = `${currencySymbol}${
            p.priceObj.fromPrice + p.priceObj.fixedPrice
        }+${defaultUnit(p.priceObj.defaultUnit, p.noUnits)}`;

        return {
            servicesPriceString,
            totalPriceString: `${currencySymbol}${
                p.priceObj.fromPrice +
                p.priceObj.fixedPrice +
                p.priceObj.partsPrice
            }+${defaultUnit(p.priceObj.defaultUnit, p.noUnits)}`,
            partsPriceString,
        };
    } else if (p.priceObj.type === ServicePriceType.Hourly) {
        const priceSum = p.priceObj.fromPrice + p.priceObj.fixedPrice;

        if (priceSum > 0 || p.priceObj.partsPrice > 0) {
            return {
                servicesPriceString: `${currencySymbol}${p.priceObj.price}+`,
                totalPriceString: `${currencySymbol}${
                    p.priceObj.price + p.priceObj.partsPrice
                }+`,
                partsPriceString: partsPriceString,
            };
        } else {
            return {
                servicesPriceString: `${currencySymbol}${
                    p.priceObj.hourlyPrice
                }/${transCommonTranslatable("HOUR_SHORT")}`,
                totalPriceString: `${currencySymbol}${
                    p.priceObj.hourlyPrice
                }/${transCommonTranslatable("HOUR_SHORT")}`,
                partsPriceString: partsPriceString,
            };
        }
    } else {
        console.error(`Invalid price type for priceObjToString`);
        return {
            servicesPriceString: "",
            totalPriceString: "",
            partsPriceString: "",
        };
    }
}
