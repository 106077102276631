import React from "react";
import { ButtonP } from "../Button/Button";
import * as S from "./BackButton.styled";

const BackButton: React.FCC<
    ButtonP & {
        circle?: boolean;
    }
> = (p) => {
    const { circle: _, ...btnProps } = p;
    return (
        <S.BackButton variant="outlined" {...btnProps} $circle={p.circle}>
            <S.BackArrow
                className="icon-arrow-left"
                style={{ marginRight: p.children ? "8px" : 0 }}
            />
            {p.children}
        </S.BackButton>
    );
};

export default BackButton;
