import Country from "@Shared/types/enums/Country";
import { Cities } from "./CITIES";

const DISTRICTS: {
    [country in Country]: Record<Cities[country][number], string[]>;
} = {
    [Country.LT]: {
        Šiauliai: [],
        Vilnius: [
            "Antakalnis",
            "Antavilis",
            "Aukštieji Paneriai",
            "Bajorai",
            "Balsiai",
            "Baltupiai",
            "Belmontas",
            "Bireliai",
            "Buivydiškės",
            "Bukčiai",
            "Burbiškės",
            "Centras",
            "Didieji Gulbinai",
            "Dvarčionys",
            "Fabijoniškės",
            "Filaretai",
            "Galgiai",
            "Gariūnai",
            "Gineitiškės",
            "Grigiškės",
            "Gudeliai",
            "Guriai",
            "Jeruzalė",
            "Justiniškės",
            "Kairėnai",
            "Kalnėnai",
            "Karoliniškės",
            "Kirtimai",
            "Kryžiokai",
            "Kuprijoniškės",
            "Lazdynai",
            "Lazdynėliai",
            "Liepkalnis",
            "Markučiai",
            "Mažieji Gulbinai",
            "Medžiakalnis",
            "Naujamiestis",
            "Naujaneriai",
            "Naujininkai",
            "Naujoji Vilnia",
            "Ožkiniai",
            "Pagiriai",
            "Paneriai",
            "Pašilaičiai",
            "Paupys",
            "Pavilnys",
            "Pilaitė",
            "Rasos",
            "Salininkai",
            "Santariškės",
            "Saulėtekis",
            "Senamiestis",
            "Šeškinė",
            "Šiaurės miestelis",
            "Šnipiškės",
            "Tarandė",
            "Trakų Vokė",
            "Turniškės",
            "Užupis",
            "Užusienis",
            "Vaidotai",
            "Valakampiai",
            "Valakupiai",
            "Verkiai",
            "Vilkpėdė",
            "Viršuliškės",
            "Visoriai",
            "Zujūnai",
            "Žemieji Paneriai",
            "Žirmūnai",
            "Žvėrynas",
        ],
        Kaunas: [
            "Aleksotas",
            "Amaliai",
            "Aukštieji Šančiai",
            "Aukštutiniai Kaniūkai",
            "Birutė",
            "Centras",
            "Dainava",
            "Domeikava",
            "Eiguliai",
            "Freda",
            "Garliava",
            "Gričiupis",
            "Kalniečiai",
            "Kazlinskai",
            "Kleboniškis",
            "Lampėdžiai",
            "Marvelė",
            "Murava",
            "Naujasodis",
            "Naugardiškė",
            "Noreikiškės",
            "Palemonas",
            "Panemunė",
            "Panerys",
            "Petrašiūnai",
            "Raudondvaris",
            "Rokai",
            "Romainiai",
            "Sargėnai",
            "Senamiestis",
            "Smėliai",
            "Šančiai",
            "Šilainiai",
            "Vaišvydava",
            "Versvai",
            "Vičiūnai",
            "Vilijampolė",
            "Vytėnai",
            "Žaliakalnis",
            "Žemieji Šančiai",
            "Žemutiniai Kaniūkai",
        ],
        Klaipėda: [
            "Alksnynė",
            "Baltija",
            "Bandužiai",
            "Birutė",
            "Centras",
            "Debrecenas",
            "Gandrališkės",
            "Gedminai",
            "Giruliai",
            "Kaunas",
            "Jūrininkai",
            "Labrenciškės",
            "Laukininkai",
            "Liepoja",
            "Lypkiai",
            "Luizė",
            "Mažasis kaimelis",
            "Melnragė",
            "Miškas",
            "Mokykla",
            "Naujakiemis",
            "Naujamiestis",
            "Paupiai",
            "Plytinė",
            "Poilsis",
            "Pušynas",
            "Rimkai",
            "Rumpiškės",
            "Senamiestis",
            "Sendvaris",
            "Smeltė",
            "Smiltynė",
            "Sportininkai",
            "Šauliai",
            "Tauralaukis",
            "Trekas",
            "Varpai",
            "Vėtrungė",
            "Vingis",
            "Žardininkai",
            "Žardė",
            "Žvejybos uostas",
        ],
        Panevėžys: [
            "Centras",
            "Klaipėdos",
            "Kniaudiškiai",
            "Molainiai",
            "Pilėnai",
            "Pramonės",
            "Rožės",
            "Skaistakalnis",
            "Stetiškiai",
            "Tulpės",
            "Vaivados",
            "Žemaičiai",
        ],
        Alytus: [
            "Dainava",
            "Likiškėliai",
            "Likiškiai",
            "Pirmas Alytus",
            "Putinai",
            "Senamiestis",
            "Vidzgiris",
        ],
    },
    [Country.CA]: {
        // Montreal: ["t"],
        Toronto: [
            "Downtown",
            "Alexandra Park",
            "Annex",
            "Baldwin Village",
            "Cabbagetown",
            "CityPlace",
            "Chinatown",
            "Church and Wellesley",
            "Corktown",
            "Discovery",
            "Distillery",
            "Entertainment",
            "East Bayfront",
            "Fashion",
            "Financial",
            "Garden",
            "Grange Park",
            "Harbord Village",
            "Harbourfront",
            "Kensington Market",
            "Little Japan",
            "Moss Park",
            "Old Town",
            "Quayside",
            "Queen Street West",
            "Regent Park",
            "South Core",
            "St. James Town",
            "St. Lawrence",
            "Toronto Islands",
            "Trefann Court",
            "University",
            "West Don Lands",
            "Yorkville",
            "East End",
            "Beaches",
            "East Chinatown",
            "East Danforth",
            "Gerrard Street East",
            "Greektown",
            "Leslieville",
            "Main Square",
            "Playter Estates",
            "Port Lands",
            "Riverdale",
            "Riverside",
            "Upper Beaches",
            "Villiers Island",
            "North End",
            "Bedford Park",
            "Casa Loma",
            "Chaplin Estates",
            "Davisville Village",
            "Deer Park",
            "Forest Hill",
            "Lawrence Park",
            "Lytton Park",
            "Midtown",
            "Moore Park",
            "North Toronto",
            "Rosedale",
            "South Hill",
            "Summerhill",
            "Uptown",
            "Wanless Park",
            "Wychwood Park",
            "Yonge–Eglinton",
            "West End",
            "Beaconsfield Village",
            "Bloor West Village",
            "Bloorcourt Village",
            "Bracondale Hill",
            "Brockton Village",
            "Carleton Village",
            "Corso Italia",
            "Davenport",
            "Dovercourt Park",
            "Dufferin Grove",
            "Earlscourt",
            "Fort York",
            "High Park",
            "Junction",
            "Junction Triangle",
            "Koreatown",
            "Liberty Village",
            "Little Italy",
            "Little Portugal",
            "Little Tibet",
            "Mirvish Village",
            "Niagara",
            "Ordinance Triangle",
            "Palmerston",
            "Parkdale",
            "Queen Street West",
            "Regal Heights",
            "Roncesvalles",
            "Runnymede",
            "Seaton Village",
            "Swansea",
            "Trinity–Bellwoods",
            "Wallace Emerson",
            "East York",
            "Old East York",
            "Broadview North",
            "Crescent Town",
            "East Danforth",
            "Pape Village",
            "Woodbine Heights",
            "Bermondsey",
            "Governor's Bridge",
            "Leaside",
            "O'Connor–Parkview",
            "Thorncliffe Park",
            "Etobicoke",
            "Alderwood",
            "Centennial Park",
            "Claireville",
            "Eatonville",
            "Elms",
            "Etobicoke City Centre",
            "Eringate-Centennial-West Deane",
            "Humber Bay",
            "Humber Heights-Westmount",
            "Humber Valley Village",
            "Humberwood",
            "Islington",
            "Kingsview Village",
            "Kingsway",
            "Long Branch",
            "Markland Wood",
            "Mimico",
            "New Toronto",
            "Princess Gardens",
            "Rexdale",
            "Richview",
            "Smithfield",
            "Stonegate-Queensway",
            "Sunnylea",
            "Thistletown",
            "Thorncrest Village",
            "West Humber-Claireville",
            "West Deane Park",
            "Willowridge",
            "North York",
            "Amesbury",
            "Armour Heights",
            "Bathurst Manor",
            "Bayview Village",
            "Bayview Woods-Steeles",
            "Bermondsey",
            "Black Creek",
            "Bridle Path",
            "Clanton Park",
            "Don Mills",
            "Don Valley Village",
            "Downsview",
            "Flemingdon Park",
            "Glen Park",
            "Henry Farm",
            "Hillcrest Village",
            "Hoggs Hollow",
            "Humber Summit",
            "Humbermede",
            "Jane and Finch",
            "Lansing",
            "Lawrence Heights",
            "Lawrence Manor",
            "Ledbury Park",
            "Maple Leaf",
            "Newtonbrook",
            "North York City Centre",
            "Oakdale–Beverley Heights",
            "Parkway Forest",
            "Parkwoods",
            "Pelmo Park-Humberlea",
            "Pleasant View",
            "Uptown Toronto",
            "Victoria Village",
            "Westminster–Branson",
            "Willowdale",
            "York Mills",
            "York University Heights",
            "Scarborough",
            "Agincourt",
            "Armadale",
            "Bendale",
            "Birch Cliff",
            "Brown's Corners",
            "Clairlea",
            "Cliffside",
            "Cliffcrest",
            "Dorset Park",
            "Eglinton East",
            "Golden Mile",
            "Guildwood",
            "Highland Creek",
            "Ionview",
            "L'Amoreaux",
            "Malvern",
            "Maryvale",
            "Milliken",
            "Morningside",
            "Morningside Heights",
            "Oakridge",
            "Port Union",
            "Rouge",
            "Scarborough City Centre",
            "Scarborough Junction",
            "Scarborough Village",
            "Steeles",
            "Tam O'Shanter-Sullivan",
            "West Hill",
            "West Rouge",
            "Wexford",
            "Woburn",
            "York",
            "Briar Hill–Belgravia",
            "Fairbank",
            "Humewood–Cedarvale",
            "Lambton–Baby Point",
            "Little Jamaica",
            "Mount Dennis",
            "Oakwood Village",
            "Old Mill",
            "Rockcliffe–Smythe",
            "Silverthorn",
            "Tichester",
            "Weston",
        ],
        Ottawa: [
            "Centretown",
            "ByWard Market",
            "The Glebe",
            "Sandy Hill",
            "Lower Town",
            "New Edinburgh",
            "Old Ottawa East",
            "Old Ottawa South",
            "Dow's Lake",
            "Golden Triangle",
            "Westboro",
            "Hintonburg",
            "Carlington",
            "Mechanicsville",
            "Civic Hospital",
            "Wellington Village",
            "Woodroffe North",
            "McKellar Heights",
            "Bayshore",
            "Carlingwood",
            "Vanier",
            "Beacon Hill North",
            "Beacon Hill South",
            "Manor Park",
            "Rockcliffe Park",
            "Cyrville",
            "Overbrook",
            "Rothwell Heights",
            "Riverside South",
            "Blossom Park",
            "Hunt Club",
            "Alta Vista",
            "Elmvale Acres",
            "Greenboro",
            "Heron Gate",
            "South Keys",
            "Gloucester",
            "Findlay Creek",
            "Barrhaven",
            "Kanata",
            "Orléans",
            "Stittsville",
            "Nepean",
            "Manotick",
            "Greely",
            "Marlborough",
            "Cumberland",
            "Carp",
            "Dunrobin",
            "Fitzroy Harbour",
            "North Gower",
            "Richmond",
            "Osgoode",
            "Metcalfe",
        ],
        Vaughan: [
            "Concord",
            "Kleinburg",
            "Maple",
            "Pine Valley Village",
            "Vellore Village",
            "Woodbridge",
            "Thornhill",
            "Weston Downs",
            "East Woodbridge",
            "Islington Woods",
            "Sonoma Heights",
            "Vaughan Metropolitan Centre",
        ],
        Mississauga: [
            "Applewood",
            "Central Erin Mills",
            "Churchill Meadows",
            "City Centre",
            "Clarkson",
            "Cooksville",
            "Creditview",
            "Dixie",
            "East Credit",
            "Erin Mills",
            "Erindale",
            "Fairview",
            "Hurontario",
            "Lakeview",
            "Lisgar",
            "Lorne Park",
            "Malton",
            "Meadowvale",
            "Meadowvale Village",
            "Mineola",
            "Mississauga Valleys",
            "Port Credit",
            "Rathwood",
            "Sheridan",
            "Streetsville",
        ],
        Brampton: [
            "Avondale",
            "Bramalea",
            "Castlemore",
            "Central Park",
            "Churchill Meadows",
            "Clairville",
            "Credit Valley",
            "Downtown Brampton",
            "Eldorado Park",
            "Fletcher's Creek South",
            "Fletcher's Creek Village",
            "Fletcher's Meadow",
            "Gore Meadows",
            "Heart Lake",
            "Huttonville",
            "Lorne Park Estates",
            "Madoc",
            "Mount Pleasant",
            "Northwood Park",
            "Peel Village",
            "Professor's Lake",
            "Snelgrove",
            "Southgate",
            "Springdale",
            "Steeles Industrial",
            "Toronto Gore",
            "Vales of Castlemore",
            "Valleywood",
        ],
        "Richmond Hill": [
            "Bayview Hill",
            "Beverley Acres",
            "Crosby",
            "Devonsleigh",
            "Doncrest",
            "Elgin Mills",
            "Headford",
            "Jefferson",
            "Langstaff",
            "Mill Pond",
            "North Richvale",
            "Oak Ridges",
            "Oak Ridges Lake Wilcox",
            "Observatory",
            "Rouge Woods",
            "South Richvale",
            "Westbrook",
        ],
        Markham: [
            "Angus Glen",
            "Berczy Village",
            "Box Grove",
            "Cachet",
            "Cedar Grove",
            "Cornell",
            "Devil's Elbow",
            "Greensborough",
            "Legacy",
            "Markham Village",
            "Milliken Mills",
            "Sherwood-Amberglen",
            "Thornhill",
            "Unionville",
            "Victoria Square",
            "Wismer Commons",
        ],
    },
};

export default DISTRICTS;
