import React from "react";
import SearchForm from "../SearchForm/SearchForm";
import * as S from "./Splash.styled";
import Text from "@FEShared/components/UI/Text/Text";
import SHIELD_IMG from "./assets/shield.png";
import POINT_IMG from "./assets/point.png";
import { observer } from "mobx-react-lite";
import { WHERE_CITY } from "@Shared/consts/CITIES";
import useStore from "@FEClient/logic/store";
import Box from "@FEShared/components/UI/Box/Box";
import Icon from "@FEShared/components/UI/Icon/Icon";
import { ABOVE_WORKSHOPS_COUNT } from "@FEClient/consts/consts";
import { CountUp } from "use-count-up";
import { getCarsAmount } from "./Splash.utils";
import { getSeoPageData } from "../FAQ/FAQ.util";
import { TransMsg, transStr } from "@FEShared/i18n";
import Country from "@Shared/types/enums/Country";
import ABTest from "@FEClient/views/commonComps/ABTest/ABTest";

const Splash: React.FC<{}> = observer(() => {
    const GS = useStore();
    const carsAmount = getCarsAmount(new Date().toDateString(), 1132, 1682);
    const seoPageData = getSeoPageData();
    let splashTitle;
    if (seoPageData) {
        splashTitle = (
            <>
                <TransMsg
                    default={"{seoServiceName} - registracija internetu 24/7"}
                    data={{ seoServiceName: seoPageData.seoServiceName }}
                    id="V3lGkkgn"
                />
            </>
        );
    } else if (GS.adsCampaign.service) {
        splashTitle = transStr("{service} - registraciju internetu 24/7", {
            data: { service: GS.adsCampaign.service },
            id: "DhRH8Kny",
        });
    } else if (GS.adsCampaign.carBrand) {
        splashTitle = (
            <>
                <TransMsg
                    default={
                        "Geriausi {carBrand} autoservisai - registracija internetu 24/7"
                    }
                    data={{ carBrand: GS.adsCampaign.carBrand }}
                    id="S1moGM0b"
                />
            </>
        );
    } else {
        splashTitle = (
            <>
                <TransMsg
                    default={"Registruokis į autoservisą {city} internetu 24/7"}
                    data={{
                        city: GS.adsCampaign.city
                            ? ` ${
                                  WHERE_CITY[window._COUNTRY][
                                      GS.adsCampaign.city
                                  ]
                              }`
                            : "",
                    }}
                    id="D147PTiU"
                />
            </>
        );
    }

    const content = (
        <S.SplashContent>
            <S.LeftSideWrapper>
                <S.SplashTexts>
                    <S.SplashTitle variant="h1">{splashTitle}</S.SplashTitle>
                </S.SplashTexts>
                <SearchForm />
                <S.SplashFeatures>
                    <Box displayFlex alignItems="flex-start">
                        <S.FeatureImg src={SHIELD_IMG} />
                        <Box
                            sx={{
                                mr: 4,
                            }}
                        >
                            <Text color="white.main" semiBold>
                                <TransMsg
                                    default={"100% Darbų garantija"}
                                    id="P7noKWdB"
                                />
                            </Text>
                            <Text
                                color="rgba(255, 255, 255, 0.75)"
                                mt={0.5}
                                fontSize={14}
                            >
                                <Icon className="icon-lock" mr={0.5} />{" "}
                                <TransMsg
                                    default={"Techninio advokato apsauga"}
                                    id="1FHZ1zRi"
                                />
                            </Text>
                            <Text
                                color="rgba(255, 255, 255, 0.75)"
                                mt={0.5}
                                fontSize={14}
                            >
                                <Icon className="icon-lock" mr={0.5} />{" "}
                                <TransMsg
                                    default={"{months} mėn. arba {mileage}"}
                                    data={{
                                        months: window._COUNTRY_META.warranty
                                            .durationMonths,
                                        mileage:
                                            window._COUNTRY_META.warranty
                                                .mileage,
                                    }}
                                    description="warrany description"
                                    id="xgrxAQUZ"
                                />
                            </Text>
                        </Box>
                    </Box>
                    {window._COUNTRY === Country.LT && (
                        <Box
                            displayFlex
                            alignItems="flex-start"
                            sx={(p) => ({
                                [p.breakpoints.down("md")]: {
                                    mt: 2,
                                },
                            })}
                        >
                            <S.FeatureImg src={POINT_IMG} />
                            <Box>
                                <Text color="white.main" semiBold>
                                    <TransMsg
                                        default={
                                            "{ABOVE_WORKSHOPS_COUNT}+ verifikuotų servisų"
                                        }
                                        data={{ ABOVE_WORKSHOPS_COUNT }}
                                        id="titMOEgc"
                                    />
                                </Text>
                                <Text
                                    color="rgba(255, 255, 255, 0.75)"
                                    mt={0.5}
                                    fontSize={14}
                                >
                                    {/* <Icon className="icon-car" mr={0.5} />{" "} */}
                                    <TransMsg
                                        default={
                                            "Remontuojama {Text <{CountUp} >}automobilių"
                                        }
                                        data={{
                                            CountUp: (
                                                <CountUp
                                                    isCounting
                                                    start={10}
                                                    end={carsAmount}
                                                    duration={2}
                                                />
                                            ),
                                            Text: (body) => (
                                                <Text
                                                    display="inline-block"
                                                    width="39px"
                                                >
                                                    {body}
                                                </Text>
                                            ),
                                        }}
                                        id="jLQXyKK9"
                                    />
                                </Text>
                            </Box>
                        </Box>
                    )}
                </S.SplashFeatures>
            </S.LeftSideWrapper>
            {/* <S.ImgWrapper
        flex={1}
        ml={"auto"}
        displayFlex
        alignItems="flex-end"
        sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
                maxWidth: "45%",
            },
        })}
    > */}
            {/* <S.SplashImg src={NEXT_STEP_IMG} /> */}
            {/* </S.ImgWrapper> */}
        </S.SplashContent>
    );

    return (
        <ABTest
            defaultComponent={<S.SplashContainer>{content}</S.SplashContainer>}
            experimentKey="NEW_HOME_PAGE_BG_3"
            newComponent={
                <S.SplashContainer $newBg>{content}</S.SplashContainer>
            }
        />
    );
});

export default Splash;
