import styled from "styled-components";
import ContentContainer from "../ContentContainer/ContentContainer";

export const PicBanner = styled("div")<{ $backgroundImgUrl?: string }>`
    background-image: url(${(p) => p.$backgroundImgUrl});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 128px 0;
`;

export const Title = styled("h1")`
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 24px;

    ${(p) => p.theme.breakpoints.down("md")} {
        font-weight: 500;
        font-size: 24px;
    }
`;

export const GreySection = styled("div")`
    background: #eff0f3;
    color: black;
    text-align: center;
    padding: 96px 0;

    ${(p) => p.theme.breakpoints.down("md")} {
        padding: 48px ${(p) => p.theme.padding.contentPadding};
    }
`;

export const TransparentSection = styled(ContentContainer)`
    padding-top: 128px;
    padding-bottom: 128px;
    color: black;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
`;
