import { CircularProgress } from "@mui/material";
import styled, { css } from "styled-components";
import Logo from "../UI/Logo/Logo";
import { LogoMark } from "../UI/Logo/Logo.styled";

export const LoaderContainer = styled("div")`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${(p) => p.theme.palette.primary.main};
    z-index: 9999;
`;

export const LoaderLogo = styled(Logo)<{ $repairPunk?: boolean }>`
    font-size: 32px;
    color: white;
    align-items: center;
    margin-bottom: 32px;

    ${LogoMark} {
        color: white;
    }

    ${(p) =>
        p.$repairPunk &&
        css`
            .icon-repairpunk-logo {
                font-size: 88px !important;
            }
        `}
`;

export const Loader = styled(CircularProgress).attrs({ size: 48 })`
    color: white;
`;
