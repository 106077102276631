import styled from "styled-components";
import ContentContainer from "../../../commonComps/ContentContainer/ContentContainer";
import Button from "../../../../../../FEShared/components/UI/Button/Button";
import Input from "@FEShared/components/UI/Input/Input";

export const ReviewPageContentContainer = styled(ContentContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;
`;

export const RatingContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px 32px 16px;
`;

export const RatingTitle = styled("div")`
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 24px;
    text-align: center;
`;

export const RatingTitleWorkshopName = styled("span")`
    font-size: 18px;
    font-weight: 500;
`;

export const RatingLabel = styled("div")`
    margin-top: 16px;
    font-weight: 500;
    font-size: 12px;
    min-height: 20px;
    text-transform: uppercase;
`;

export const ConfirmBtn = styled(Button)`
    width: 100%;
    margin-top: 8px;
`;

export const CommentInput = styled(Input)`
    .MuiInputBase-root {
        min-height: 150px;
        align-items: start;
    }
`;
