import React from "react";
import ContentContainer from "../../commonComps/ContentContainer/ContentContainer";
import Country from "@Shared/types/enums/Country";
import SeoServices_LT from "./SeoServices_LT";
import SeoServices_EN from "./SeoServices_EN";

const SeoServices: React.FC = () => {
    return (
        <ContentContainer>
            {window._COUNTRY === Country.LT ? (
                <SeoServices_LT />
            ) : (
                <SeoServices_EN />
            )}
        </ContentContainer>
    );
};

export default SeoServices;
