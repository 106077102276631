export function getCarsAmount(someString, min = 40, max = 110) {
    // DJB2 string hash function
    let hash = 5381;
    for (let i = 0; i < someString.length; i++) {
        const char = someString.charCodeAt(i);
        hash = (hash * 33) ^ char;
    }

    // Ensure the hash is a positive integer
    hash = hash >>> 0;

    // Scale the hash to the specified range [min, max]
    return min + (hash % (max - min + 1));
}
