import { runInAction } from "mobx";
import { UseQueryState } from "urql";
import React from "react";
import IStore from "@FEShared/types/IStore";

const useShowLoadingScreenForQuery = (
    gqlQuery: UseQueryState,
    GS: IStore,
    shouldExecute?: () => Boolean,
    // tbd not really used rn
    idPrefix?: string
) => {
    const queryID = React.useRef(
        idPrefix ? `${idPrefix}-${Math.random()}` : Math.random().toString()
    );

    React.useEffect(() => {
        if (!gqlQuery.operation || gqlQuery.fetching) {
            if (shouldExecute && !shouldExecute()) return;
            runInAction(() => {
                GS.addLoadingGqlQuery(queryID.current);
            });
        } else {
            runInAction(() => {
                GS.removeLoadingGqlQuery(queryID.current);
            });
        }
        return () => {
            runInAction(() => {
                GS.removeLoadingGqlQuery(queryID.current); //eslint-disable-line react-hooks/exhaustive-deps
            });
        };
    }, [gqlQuery.operation, gqlQuery.fetching, GS, shouldExecute]);
};
export default useShowLoadingScreenForQuery;
