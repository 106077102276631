import Country from "@Shared/types/enums/Country";
import { WHERE_CITY } from "@Shared/consts/CITIES";

type Meta = {
    title: string;
    descr: string;
    workshopKeyword: string;
};

export function getDynamicSearchMeta(p: {
    country: Country;
    carBrandOrTOP?: string;
    serviceName?: string;
    city: string;
}): Meta {
    const countryMeta: Meta = {
        title: "",
        descr: "",
        workshopKeyword:
            p.country === Country.LT ? "Autoservisai" : "Mechanics",
    };

    if (p.country === Country.LT) {
        countryMeta.title = p.serviceName
            ? `${p.serviceName} | 
		${p.carBrandOrTOP} Autoservisai ${
                  WHERE_CITY[p.country][p.city] || ""
              } | Kaina, 24/7, Garantija`
            : `
		${p.carBrandOrTOP} Autoservisai ${
                  WHERE_CITY[p.country][p.city] || ""
              } | Kainos, 24/7, Garantija
	`;

        countryMeta.descr = p.serviceName
            ? `${
                  p.serviceName
              } - Kaina, 24/7 Registracija, 100% Garantija. Lygink ${
                  p.carBrandOrTOP
              } servisus ${
                  WHERE_CITY[p.country][p.city]
              } pagal kainą, užimtumą, atsiliepimus, vietą.`
            : `Darbų Kainos, 24/7 Registracija, 100% Garantija. Lygink ${
                  p.carBrandOrTOP
              } servisus ${
                  WHERE_CITY[p.country][p.city]
              } pagal kainą, užimtumą, atsiliepimus, vietą.`;
    } else if (p.country === Country.CA) {
        countryMeta.title = p.serviceName
            ? `${p.serviceName} | ${p.carBrandOrTOP} Mechanics ${
                  WHERE_CITY[p.country][p.city]
              } | Prices, 24/7, Warranty`
            : `${p.carBrandOrTOP} Mechanics ${
                  WHERE_CITY[p.country][p.city]
              } | Prices, 24/7, Warranty`;

        countryMeta.descr = p.serviceName
            ? `${p.serviceName} - Repairs prices, Book 24/7, 100% Warranty. Compare ${p.carBrandOrTOP} mechanics by price, availability, reviews, location`
            : `Repairs prices, Book 24/7, 100% Warranty. Compare ${p.carBrandOrTOP} mechanics by price, availability, reviews, location`;
    } else {
        console.error(`No meta for country: ${p.country}`);
    }
    return countryMeta;
}
