import * as React from "react";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu/Menu";
import * as S from "./ButtonsMenu.styled";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import Icon from "../Icon/Icon";
import { Backdrop, CircularProgress, PaperProps, SxProps } from "@mui/material";
import Button, { ButtonP } from "../Button/Button";

interface Option<T> {
    leftIconClass?: string;
    title: string;
    value?: T;
    isLoading?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

interface ButtonsMenuP<T> {
    menuButtonText?: string;
    menuIconClass?: string;
    /* If you want to show different icon when menu is open */
    openMenuIconClass?: string;
    /* If you want to apply additional button props menu is open */
    openMenuBtnProps?: ButtonP;
    options: Option<T>[];
    value?: T;

    /* All props for the main visible Menu Button */
    mainMenuBtnProps?: ButtonP;
    paperProps?: PaperProps;
    menuProps?: Partial<MenuProps>;
    menuItemProps?: MenuItemProps;
    withBackdrop?: boolean;
}

// taken from https://mui.com/material-ui/react-button-group/#split-button
const ButtonsMenu = observer(<T,>(p: ButtonsMenuP<T>) => {
    const LS = useLocalObservable(() => ({
        anchorEl: null as null | HTMLElement,
        activeOption: p.value
            ? p.options.find((o) => o.value === p.value)
            : null,
    }));

    const open = Boolean(LS.anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        runInAction(() => {
            LS.anchorEl = event.currentTarget;
        });
    };

    const handleClose = () => {
        runInAction(() => {
            LS.anchorEl = null;
        });
    };

    const onButtonClick = (option: Option<T>) => {
        LS.activeOption = option;
        option.onClick();
        handleClose();
    };

    const iconClass =
        open && p.openMenuIconClass ? p.openMenuIconClass : p.menuIconClass;

    return (
        <React.Fragment>
            {p.withBackdrop && <Backdrop open={open} />}
            {!p.menuButtonText && iconClass ? (
                <Button
                    circle
                    aria-controls={open ? "buttons-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="menu"
                    onClick={handleClick}
                    {...p.mainMenuBtnProps}
                    {...(open && p.openMenuBtnProps)}
                    sx={
                        {
                            ...p.mainMenuBtnProps?.sx,
                            ...((open && p.openMenuBtnProps?.sx) || {}),
                        } as SxProps
                    }
                >
                    <Icon className={iconClass} />
                </Button>
            ) : (
                <S.MenuButton
                    leftIconClass={iconClass}
                    aria-controls={open ? "buttons-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="menu"
                    onClick={handleClick}
                    {...p.mainMenuBtnProps}
                    {...(open && p.openMenuBtnProps)}
                    sx={
                        {
                            ...p.mainMenuBtnProps?.sx,
                            ...((open && p.openMenuBtnProps?.sx) || {}),
                        } as SxProps
                    }
                >
                    {p.menuButtonText}
                </S.MenuButton>
            )}
            <Menu
                aria-controls={open ? "buttons-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                open={open}
                anchorEl={LS.anchorEl}
                onClose={handleClose}
                sx={{ mt: 0.5, zIndex: 9999999 }}
                PaperProps={{
                    sx: {
                        color: "white",
                    },
                    ...p.paperProps,
                }}
                {...p.menuProps}
            >
                {p.options.map((option) => (
                    <MenuItem
                        disabled={option.disabled || option.isLoading}
                        key={option.title}
                        onClick={() => onButtonClick(option)}
                        {...p.menuItemProps}
                    >
                        {option.isLoading && (
                            <CircularProgress
                                size={25}
                                sx={{ mr: 1, color: "white" }}
                            />
                        )}
                        {option.leftIconClass && (
                            <S.LeftIcon className={option.leftIconClass} />
                        )}
                        {option.title}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
});

export default ButtonsMenu;
